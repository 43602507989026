import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useMemo,
  useCallback,
  Fragment,
  useRef,
} from "react";

import axios from "axios";

import { Line, Radar, Bar, Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  LineController,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  RadialLinearScale,
  ArcElement,
  BarElement,
} from "chart.js";
import "./App.css";

import io from "socket.io-client";
import eyeFrame from "./assets/eye.png";
import hourHand from "./assets/hour-hand.png";
import minuteHand from "./assets/minute-hand.png";

import noResults from "./assets/no-results.png";

import engineIconImage from "./assets/engine-icon.svg";

import infoIcon from "./assets/info.png";
import accountIcon from "./assets/account.png";
import chatIcon from "./assets/chat.png";
import helpIcon from "./assets/help.png";

import { loadConnectAndInitialize } from "@stripe/connect-js";
import {
  ConnectAccountOnboarding,
  ConnectComponentsProvider,
  ConnectAccountManagement,
  ConnectPayouts,
} from "@stripe/react-connect-js";

import { loadStripe } from "@stripe/stripe-js";

import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";

// import AddWatch from "./AddWatch";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Outlet,
  useLocation,
  useNavigate,
  Navigate,
  useParams,
} from "react-router-dom";

// Register the necessary components
ChartJS.register(
  LineElement,
  PointElement,
  LineController,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  RadialLinearScale,
  ArcElement,
  BarElement,
  ChartDataLabels
);

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_TEST_PUBLISH_KEY);

// Function to get cookie by name
const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
};

// Function to set cookie
const setCookie = (name, value, days) => {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  const expires = `expires=${date.toUTCString()}`;
  document.cookie = `${name}=${value};${expires};path=/`;
};

function toggleBodyScroll(isOpen) {
  if (isOpen) {
    document.body.classList.add("body-no-scroll");
  } else {
    document.body.classList.remove("body-no-scroll");
  }
}

function ModalAffiliate({ isOpen, onClose, children }) {
  if (!isOpen) return null;

  const modalStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0,0,0,0.5)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const contentStyle = {
    backgroundColor: "#1a1a1a",
    padding: "20px",
    borderRadius: "8px",
    width: "300px",
  };

  return (
    <div style={modalStyle}>
      <div style={contentStyle}>
        {children}
        <button onClick={onClose} style={{ marginTop: "10px" }}>
          Close
        </button>
      </div>
    </div>
  );
}

function RecordInteractionModal({ isOpen, onClose, onSubmit }) {
  const [method, setMethod] = useState("");
  const [content, setContent] = useState("");
  const [notes, setNotes] = useState("");

  const handleSubmit = () => {
    onSubmit(method, content, notes);
    onClose();
  };

  return (
    <ModalAffiliate isOpen={isOpen} onClose={onClose}>
      <h3 style={{ color: "white", marginBottom: "10px" }}>
        Record Interaction
      </h3>
      <select
        value={method}
        onChange={(e) => setMethod(e.target.value)}
        style={{ marginBottom: "10px", width: "100%" }}
      >
        <option value="">Select Method</option>
        <option value="call">Call</option>
        <option value="email">Email</option>
        <option value="message">Message</option>
      </select>
      <textarea
        value={content}
        onChange={(e) => setContent(e.target.value)}
        placeholder="Interaction content"
        style={{ marginBottom: "10px", width: "100%" }}
      />
      <textarea
        value={notes}
        onChange={(e) => setNotes(e.target.value)}
        placeholder="Notes"
        style={{ marginBottom: "10px", width: "100%" }}
      />
      <button onClick={handleSubmit}>Submit</button>
    </ModalAffiliate>
  );
}

function UpdateStatusModal({
  isOpen,
  onClose,
  onSubmit,
  currentStatus,
  currentNotes,
}) {
  const [status, setStatus] = useState(currentStatus);
  const [notes, setNotes] = useState(currentNotes);

  const handleSubmit = () => {
    onSubmit(status, notes);
    onClose();
  };

  return (
    <ModalAffiliate isOpen={isOpen} onClose={onClose}>
      <h3 style={{ color: "white", marginBottom: "10px" }}>Update Status</h3>
      <select
        value={status}
        onChange={(e) => setStatus(e.target.value)}
        style={{ marginBottom: "10px", width: "100%" }}
      >
        <option value="New">New</option>
        <option value="Contacted">Contacted</option>
        <option value="Interested">Interested</option>
        <option value="Not Interested">Not Interested</option>
        <option value="Client">Client</option>
      </select>
      <textarea
        value={notes}
        onChange={(e) => setNotes(e.target.value)}
        placeholder="Notes"
        style={{ marginBottom: "10px", width: "100%" }}
      />
      <button onClick={handleSubmit}>Update</button>
    </ModalAffiliate>
  );
}

function ReferralCodeSection({
  referralCodes,
  onGenerateCode,
  onCreateCustomCode,
}) {
  const [customCode, setCustomCode] = useState("");

  const containerStyle = {
    backgroundColor: "#1a1a1a",
    padding: "20px",
    borderRadius: "8px",
    marginBottom: "20px",
  };

  const codeStyle = {
    backgroundColor: "#2a2a2a",
    padding: "10px",
    borderRadius: "4px",
    marginBottom: "10px",
    display: "flex",
    justifyContent: "space-between",
  };

  const buttonStyle = {
    backgroundColor: "#3498db",
    color: "white",
    padding: "10px 15px",
    borderRadius: "4px",
    border: "none",
    cursor: "pointer",
    marginRight: "10px",
  };

  const inputStyle = {
    padding: "10px",
    borderRadius: "4px",
    border: "1px solid #2a2a2a",
    backgroundColor: "#2a2a2a",
    color: "white",
    marginRight: "10px",
  };

  return (
    <div style={containerStyle}>
      <h2 style={{ color: "white", marginBottom: "15px" }}>
        Your Referral Codes
      </h2>
      {referralCodes.map((code) => (
        <div key={code.code} style={codeStyle}>
          <span style={{ color: "white" }}>{code.code}</span>
          <button
            onClick={() => navigator.clipboard.writeText(code.code)}
            style={{
              ...buttonStyle,
              backgroundColor: "transparent",
            }}
          >
            Copy
          </button>
        </div>
      ))}
      <div>
        <button
          onClick={onGenerateCode}
          disabled={referralCodes.length >= 3}
          style={buttonStyle}
        >
          Generate Random Code
        </button>
        <input
          type="text"
          value={customCode}
          onChange={(e) => setCustomCode(e.target.value)}
          placeholder="Custom code"
          style={inputStyle}
        />
        <button
          onClick={() => {
            onCreateCustomCode(customCode);
            // setCustomCode("");
          }}
          disabled={referralCodes.length >= 3 || !customCode}
          style={buttonStyle}
        >
          Create Custom Code
        </button>
      </div>
    </div>
  );
}

const AffiliateBenefits = () => {
  const [animated, setAnimated] = useState(false);

  useEffect(() => {
    setAnimated(true);
  }, []);

  const benefits = [
    {
      icon: "💰",
      title: "20% Monthly Commissions",
      description:
        "Earn a generous lifetime 20% commission on your referrals' monthly subscriptions.",
      features: ["Lifetime recurring monthly income", "No earnings cap"],
    },
    {
      icon: "🎛️",
      title: "Fast Payouts",
      description:
        "Enjoy your earned commissions with automated payouts every 2 days, ensuring quick and reliable payments.",
      features: ["Multiple payment methods", "Automated 2-day payouts"],
    },
    {
      icon: "📊",
      title: "Intuitive Client Manager",
      description:
        "Track and manage your referrals with our user-friendly dashboard.",
      features: [
        "Track your clients and prospects",
        "Client Lead Feed helps you to onboard",
      ],
    },
  ];

  return (
    <div className={`benefits-row ${animated ? "animated" : ""}`}>
      {benefits.map((benefit, index) => (
        <div key={index} className="benefit-card">
          <div className="icon">{benefit.icon}</div>
          <h2>{benefit.title}</h2>
          <p className="affiliate-style-p">{benefit.description}</p>
          <ul className="feature-list">
            {benefit.features.map((feature, featureIndex) => (
              <li key={featureIndex}>{feature}</li>
            ))}
          </ul>
        </div>
      ))}

      <style jsx>{`
        .referrals-table {
          width: 100%;
          border-collapse: collapse;
          margin-top: 10px;
        }

        .referrals-table th,
        .referrals-table td {
          border: 1px solid #444;
          padding: 10px;
          text-align: left;
        }

        .referrals-table th {
          background-color: #3a3a3a;
        }

        .referrals-table tr:nth-child(even) {
          background-color: #2a2a2a;
        }

        .referrals-table tr:hover {
          background-color: #444;
        }
        .benefits-row {
          display: flex;
          justify-content: space-between;
          gap: 20px;
          opacity: 0;
          transform: translateY(50px);
          transition: opacity 0.8s ease, transform 0.8s ease;
          margin: auto;
        }
        .benefits-row.animated {
          opacity: 1;
          transform: translateY(0);
        }
        .benefit-card {
          flex: 1;
          background: #243838;
          border-radius: 8px;
          padding: 20px;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
          transition: transform 0.3s ease, box-shadow 0.3s ease;
        }
        .benefit-card:hover {
          transform: translateY(-5px);
          box-shadow: 0 6px 12px rgba(163, 126, 44, 0.2);
        }
        .icon {
          font-size: 2.5rem;
          margin-bottom: 15px;
          color: #a37e2c;
        }
        h2 {
          font-size: 1.2rem;
          margin-bottom: 10px;
          color: #a37e2c;
        }
        .affiliate-style-p {
          font-size: 0.9rem;
          line-height: 1.4;
          color: #b0b0b0;
        }
        .feature-list {
          list-style-type: none;
          padding: 0;
          margin-top: 15px;
        }
        .feature-list li {
          font-size: 0.85rem;
          margin-bottom: 5px;
          color: #d0d0d0;
        }
        .feature-list li:before {
          content: "✓";
          color: #a37e2c;
          margin-right: 5px;
        }
        @media (max-width: 768px) {
          .benefits-row {
            flex-direction: column;
          }
        }
      `}</style>
    </div>
  );
};

const ClientLeadFeed = ({ monthlyEarnings, referrals }) => {
  const [loading, setLoading] = useState(true);
  const [referralCodes, setReferralCodes] = useState([]);
  const [assignedContacts, setAssignedContacts] = useState([]);
  const [customCode, setCustomCode] = useState("");
  const { addAlert } = useAlert();
  const [interactionModal, setInteractionModal] = useState({
    isOpen: false,
    contactId: null,
  });
  const [statusModal, setStatusModal] = useState({
    isOpen: false,
    contact: null,
  });

  useEffect(() => {
    fetchReferralCodes();
    fetchAssignedContacts();
  }, []);

  const fetchAssignedContacts = useCallback(async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/get_assigned_contacts`,
        { withCredentials: true }
      );
      setAssignedContacts(response.data.assigned_contacts);
    } catch (error) {
      console.error("Error fetching assigned contacts:", error);
      addAlert({
        message: "Error fetching assigned contacts.",
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  }, []);

  const recordInteraction = async (contactId, method, content, notes) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/record_interaction`,
        { contact_id: contactId, method, content, notes },
        { withCredentials: true }
      );
      addAlert({
        message: "Interaction recorded successfully.",
        type: "success",
      });
    } catch (error) {
      console.error("Error recording interaction:", error);
      addAlert({
        message: "Error recording interaction.",
        type: "error",
      });
    }
  };

  const fetchReferralCodes = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/get_referral_codes`,
        { withCredentials: true }
      );
      setReferralCodes(response.data.referral_codes);
    } catch (error) {
      console.error("Error fetching referral codes:", error);
      addAlert({
        message: "Error fetching referral codes.",
        type: "error",
      });
    }
  };

  const generateReferralCode = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/generate_referral_code`,
        {},
        { withCredentials: true }
      );
      fetchReferralCodes();
      addAlert({
        message: "Referral code generated successfully.",
        type: "success",
      });
    } catch (error) {
      console.error("Error generating referral code:", error);
      addAlert({
        message:
          error.response?.data?.error || "Error generating referral code.",
        type: "error",
      });
    }
  };

  const createCustomReferralCode = async (customCode) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/create_custom_referral_code`,
        { custom_code: customCode },
        { withCredentials: true }
      );
      fetchReferralCodes();
      setCustomCode("");
      addAlert({
        message: "Custom referral code created successfully.",
        type: "success",
      });
    } catch (error) {
      console.error("Error creating custom referral code:", error);
      addAlert({
        message:
          error.response?.data?.error || "Error creating custom referral code.",
        type: "error",
      });
    }
  };

  return (
    <>
      <div className="affiliate-info">
        <h2>Commission Rate: 20%</h2>
        <h1>Current Earnings: ${monthlyEarnings.toFixed(2)} / month</h1>

        <h3>Your Referrals</h3>
        <table className="referrals-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Phone Number</th>
              <th>Subscription Status</th>
            </tr>
          </thead>
          <tbody>
            {referrals.map((referral, index) => (
              <tr key={index}>
                <td>{referral.name}</td>
                <td>{referral.phone_number}</td>
                <td>
                  {referral.is_subscribed ? "Subscribed" : "Unsubscribed"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div
        style={{
          marginBottom: "15px",
          color: "#b0b0b0",
          fontSize: "14px",
        }}
      >
        Share these unique codes with potential clients.{" "}
        <strong>
          They must use this code when registering their AutoWatch account
        </strong>
        . When they check-out for a membership using your code, you'll earn a
        commission on their monthly subscription for{" "}
        <span style={{ fontStyle: "italic" }}>as long</span> as they're
        subscribed.
      </div>
      <ReferralCodeSection
        referralCodes={referralCodes}
        onGenerateCode={generateReferralCode}
        onCreateCustomCode={(code) => createCustomReferralCode(code)}
      />
      {/* <div style={{ marginBottom: "15px", color: "#b0b0b0", fontSize: "14px" }}>
        These are potential leads assigned to you. Reach out, build
        relationships, and guide them towards becoming clients. Remember,
        quality interactions increase your chances of success. Unlock more,
        higher quality leads as you convert your prospects into paying
        customers.
      </div>
      <div className="assigned-contacts-section">
        <h3>Your Assigned Contacts</h3>
        <button
          onClick={assignNewContacts}
          disabled={assignedContacts.length >= 6}
        >
          Assign New Contacts
        </button>
        {loading ? (
          <div className="loading">Loading your assigned contacts...</div>
        ) : assignedContacts.length === 0 ? (
          <p>No contacts assigned yet.</p>
        ) : (
          <table className="contacts-table">
            <thead>
              <tr>
                <th>Rank</th>
                <th>Contact ID</th>
                <th>Name</th>
                <th>Phone Number</th>
                <th>Status</th>
                <th>Notes</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {assignedContacts.map((contact) => (
                <tr key={contact.contact_id}>
                  <td>{contact.rank}</td>
                  <td>{contact.contact_id}</td>
                  <td>{contact.name || "N/A"}</td>
                  <td>{contact.phone_number}</td>
                  <td>{contact.status}</td>
                  <td>{contact.notes || "N/A"}</td>
                  <td>
                    <button
                      onClick={() => setStatusModal({ isOpen: true, contact })}
                    >
                      Update Status
                    </button>
                    <button
                      onClick={() =>
                        setInteractionModal({
                          isOpen: true,
                          contactId: contact.contact_id,
                        })
                      }
                    >
                      Record Interaction
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      <RecordInteractionModal
        isOpen={interactionModal.isOpen}
        onClose={() => setInteractionModal({ isOpen: false, contactId: null })}
        onSubmit={(method, content, notes) => {
          recordInteraction(interactionModal.contactId, method, content, notes);
          setInteractionModal({ isOpen: false, contactId: null });
        }}
      />
      <UpdateStatusModal
        isOpen={statusModal.isOpen}
        onClose={() => setStatusModal({ isOpen: false, contact: null })}
        onSubmit={(status, notes) => {
          updateContactStatus(statusModal.contact.contact_id, status, notes);
          setStatusModal({ isOpen: false, contact: null });
        }}
        currentStatus={statusModal.contact?.status}
        currentNotes={statusModal.contact?.notes}
      /> */}
    </>
  );
};

const SubscriptionCheckoutModal = ({ isOpen, onClose, hideCloseButton }) => {
  const [clientSecret, setClientSecret] = useState(null);
  const [checkoutComplete, setCheckoutComplete] = useState(false);
  const [status, setStatus] = useState(null);
  const [customerEmail, setCustomerEmail] = useState("");
  const [showAdvertisingPanel, setShowAdvertisingPanel] = useState(true);
  const [selectedSubscriptionType, setSelectedSubscriptionType] =
    useState(null);

  const navigate = useNavigate();

  // Fetch the Checkout Session when the user selects a subscription type
  const createSession = async (type) => {
    let route = "";
    if (type === "monthly") {
      route = "/create_checkout_session_monthly";
    } else if (type === "yearly") {
      route = "/create_checkout_session_yearly";
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}${route}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include", // Include credentials (cookies)
      });
      const data = await response.json();
      if (data.clientSecret) {
        setClientSecret(data.clientSecret);
      } else if (data.error) {
        console.error(data.error);
      }
    } catch (error) {
      console.error("Error creating checkout session:", error);
    }
  };

  // Handle the return page logic
  const handleReturn = async (sessionId) => {
    if (!sessionId) {
      onClose(); // Close the modal if no sessionId
      return;
    }

    fetch(
      `${process.env.REACT_APP_API_URL}/session_status?session_id=${sessionId}`,
      {
        credentials: "include",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          console.error(data.error);
        } else {
          setStatus(data.status);
          setCustomerEmail(data.customer_email);
          setCheckoutComplete(true);
        }
      });
  };

  // Detect session_id in the URL after Stripe redirects back to the app
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const sessionId = queryParams.get("session_id");
    if (sessionId) {
      handleReturn(sessionId);
    }
  }, []);

  // Handle subscription type selection
  const handleSubscribeNow = (type) => {
    setSelectedSubscriptionType(type);
    setShowAdvertisingPanel(false);
    createSession(type); // Fetch the clientSecret to display the checkout form
  };

  // Handle going back to the advertising panel
  const handleBackToPlans = () => {
    setShowAdvertisingPanel(true);
    setClientSecret(null);
    setSelectedSubscriptionType(null);
  };

  return (
    isOpen && (
      <div className="modal">
        <div className="modal-content">
          {checkoutComplete ? (
            <div className="completion-message">
              {status === "complete" ? (
                <div>
                  <h2>Thank you for joining us!</h2>
                  <p>A confirmation email has been sent to {customerEmail}.</p>
                  <button className="close-button" onClick={onClose}>
                    Close
                  </button>
                </div>
              ) : (
                <div>
                  <h2>Something went wrong with your payment.</h2>
                  <button className="close-button" onClick={onClose}>
                    Try Again
                  </button>
                </div>
              )}
            </div>
          ) : showAdvertisingPanel ? (
            // Advertising Panel Content
            <div className="advertising-panel">
              <h2>Unlock Exclusive Access Today!</h2>
              <p>
                Become a part of our elite community and supercharge your watch
                business with our state-of-the-art lead generator. Experience
                unparalleled growth as our system continuously evolves to bring
                you more opportunities.
              </p>
              <p>
                <strong style={{ color: "rgb(0, 171, 214)" }}>
                  Early adopters
                </strong>{" "}
                enjoy special pricing locked in for life!
              </p>
              <div className="subscription-options">
                <div className="subscription-option">
                  <h3>Monthly Membership</h3>
                  <div className="pricing">
                    <span className="original-price">$99</span>
                    <span className="discounted-price">$59</span>
                    <span className="per-duration">/month</span>
                  </div>
                  <button
                    className="subscribe-button"
                    onClick={() => handleSubscribeNow("monthly")}
                    aria-label="Subscribe Monthly"
                  >
                    Join Monthly
                  </button>
                </div>
                <div className="subscription-option best-value">
                  <div className="badge">Best Value</div>
                  <h3>Annual Membership</h3>
                  <div className="pricing">
                    <span className="original-price">$1,188</span>
                    <span className="discounted-price">$599.99</span>
                    <span className="per-duration">/year</span>
                  </div>
                  <p className="savings">Save over 15% with annual billing!</p>
                  <button
                    className="subscribe-button"
                    onClick={() => handleSubscribeNow("yearly")}
                    aria-label="Subscribe Yearly"
                  >
                    Join Annually
                  </button>
                </div>
              </div>
              {!hideCloseButton && (
                <button className="cancel-button" onClick={onClose}>
                  Maybe Later
                </button>
              )}
            </div>
          ) : clientSecret ? (
            // Checkout Form with Back Button
            <div className="checkout-container">
              <button className="back-button" onClick={handleBackToPlans}>
                &larr; Back to Plans
              </button>
              <EmbeddedCheckoutProvider
                stripe={stripePromise}
                options={{ clientSecret }}
              >
                <EmbeddedCheckout />
              </EmbeddedCheckoutProvider>
            </div>
          ) : (
            // Loading State
            <div className="loading">Loading...</div>
          )}
        </div>

        <style jsx>{`
          /* Modal Styles */
          .modal {
            position: fixed;
            z-index: 99999;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            overflow: auto;
            background-color: rgba(0, 0, 0, 0.8);
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .modal-content {
            background-color: #000000;
            padding: 20px;
            border: 1px solid #014d4e;
            max-width: 800px;
            border-radius: 10px;
            height: auto;
            color: #ffffff;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
          }

          /* Advertising Panel Styles */
          .advertising-panel {
            text-align: center;
          }

          .advertising-panel h2 {
            margin-bottom: 20px;
            color: #e7c872; /* Rolex gold */
          }

          .advertising-panel p {
            margin-bottom: 15px;
            color: #cccccc;
            line-height: 1.5;
          }

          .subscription-options {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            margin-bottom: 20px;
          }

          .subscription-option {
            width: 45%;
            border: 1px solid #014d4e;
            border-radius: 10px;
            padding: 20px;
            background-color: #111111;
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;
          }

          .subscription-option.best-value {
            border-color: #e7c872;
          }

          .badge {
            position: absolute;
            top: -10px;
            right: -10px;
            background-color: #e7c872;
            color: #000000;
            padding: 5px 10px;
            font-size: 12px;
            font-weight: bold;
            border-radius: 5px;
          }

          .subscription-option h3 {
            margin-bottom: 15px;
            color: #e7c872; /* Rolex gold */
          }

          .pricing {
            font-size: 24px;
            margin-bottom: 10px;
            display: flex;
            align-items: baseline;
          }

          .original-price {
            text-decoration: line-through;
            color: #777777;
            margin-right: 10px;
            font-size: 18px;
          }

          .discounted-price {
            color: rgb(0, 171, 214);
            font-weight: bold;
            font-size: 28px;
          }

          .per-duration {
            color: #cccccc;
            margin-left: 5px;
            font-size: 18px;
          }

          .savings {
            color: #e7c872;
            margin-bottom: 15px;
          }

          .subscribe-button {
            margin-top: auto;
            padding: 10px 20px;
            font-size: 16px;
            cursor: pointer;
            background-color: #014d4e; /* Dark teal */
            color: #ffffff;
            border: none;
            border-radius: 5px;
            width: 100%;
            transition: background-color 0.3s ease;
          }

          .subscribe-button:hover {
            background-color: #016f70;
          }

          .cancel-button {
            margin-top: 20px;
            padding: 10px 20px;
            font-size: 16px;
            cursor: pointer;
            background-color: transparent;
            color: #e7c872;
            border: none;
            text-decoration: underline;
          }

          /* Checkout Form Styles */
          .checkout-container {
            position: relative;
          }

          .back-button {
            background-color: transparent;
            color: #e7c872; /* Rolex gold */
            border: none;
            font-size: 16px;
            cursor: pointer;
            margin-bottom: 10px;
            text-decoration: underline;
          }

          .back-button:hover {
            color: #ffffff;
          }

          /* Completion Message Styles */
          .completion-message {
            text-align: center;
          }

          .completion-message h2 {
            color: #e7c872; /* Rolex gold */
          }

          .close-button {
            margin-top: 20px;
            padding: 10px 20px;
            font-size: 16px;
            cursor: pointer;
            background-color: #014d4e;
            color: #ffffff;
            border: none;
            border-radius: 5px;
          }

          .close-button:hover {
            background-color: #016f70;
          }

          /* Loading State Styles */
          .loading {
            text-align: center;
            font-size: 18px;
            color: #e7c872;
          }

          /* Responsive adjustments */
          @media (max-width: 768px) {
            .subscription-options {
              flex-direction: column;
              align-items: center;
            }

            .subscription-option {
              width: 100%;
              margin-bottom: 20px;
            }
          }
        `}</style>
      </div>
    )
  );
};

const Dashboard = () => {
  const [affiliateStatus, setAffiliateStatus] = useState({
    isAffiliate: false,
    onboardingComplete: false,
    connectedAccountId: null,
  });
  const [stripeConnectInstance, setStripeConnectInstance] = useState(null);

  const [loading, setLoading] = useState(true);
  const { currentUser, setCurrentUser } = useAuth();
  const [isComponentLoaded, setIsComponentLoaded] = useState(false);
  const [isTabComponentLoaded, setIsTabComponentLoaded] = useState(false);

  const [referrals, setReferrals] = useState([]);
  const [monthlyEarnings, setMonthlyEarnings] = useState(0.0);

  const [isSubscriptionModalOpen, setIsSubscriptionModalOpen] = useState(false); // Specific name for the modal open state

  const fetchAffiliateData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/affiliate_dashboard_data`,
        { withCredentials: true }
      );
      setReferrals(response.data.referrals);
      setMonthlyEarnings(response.data.monthly_earnings);
    } catch (error) {
      console.error("Error fetching affiliate data:", error);
    }
  };

  // Open the subscription modal and set the checkout route for monthly or yearly
  const openSubscriptionModal = () => {
    setIsSubscriptionModalOpen(true); // Open the subscription modal
  };

  // Close the subscription modal
  const closeSubscriptionModal = () => {
    setIsSubscriptionModalOpen(false); // Close the subscription modal
  };

  let navigate = useNavigate();
  const { addAlert } = useAlert();

  const [selectedTab, setSelectedTab] = useState("clientLeadFeed"); // Default to Client Lead Feed

  const renderTabContent = () => {
    switch (selectedTab) {
      case "clientLeadFeed":
        return (
          <ClientLeadFeed
            monthlyEarnings={monthlyEarnings}
            referrals={referrals}
          />
        );

      case "payouts":
        return (
          <>
            {/* Always render the ConnectComponentsProvider first */}
            <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
              <ConnectPayouts />
            </ConnectComponentsProvider>

            {/* Show the loading spinner based on isTabComponentLoaded */}
            {!isTabComponentLoaded && (
              <div className="loading-placeholder">
                <h2>Preparing Your Payouts Area</h2>
                <p>
                  We're setting up your personalized payouts area. This may take
                  a few moments.
                </p>
                <div className="loading-spinner"></div>
              </div>
            )}
          </>
        );

      case "accountManagement":
        return (
          <>
            {/* Always render the ConnectComponentsProvider first */}
            <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
              <ConnectAccountManagement />
            </ConnectComponentsProvider>

            {/* Show the loading spinner based on isTabComponentLoaded */}
            {!isTabComponentLoaded && (
              <div className="loading-placeholder">
                <h2>Preparing Your Account Area</h2>
                <p>
                  We're setting up your personalized account area. This may take
                  a few moments.
                </p>
                <div className="loading-spinner"></div>
              </div>
            )}
          </>
        );

      default:
        return <ClientLeadFeed />;
    }
  };

  useEffect(() => {
    // Start the timeout when either stripeConnectInstance is set or when selectedTab changes
    if (stripeConnectInstance && selectedTab) {
      setIsTabComponentLoaded(false); // Reset the loading state on tab switch
      const timeout = setTimeout(() => {
        setIsTabComponentLoaded(true); // Set it to true after 4.5 seconds
        setIsComponentLoaded(true);
      }, 4500); // 4.5 seconds delay

      return () => clearTimeout(timeout); // Cleanup on unmount or when dependencies change
    }
  }, [stripeConnectInstance, selectedTab]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const affiliateResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/affiliate_status`,
          { withCredentials: true }
        );
        setAffiliateStatus(affiliateResponse.data);

        if (
          affiliateResponse.data.isAffiliate &&
          !affiliateResponse.data.onboardingComplete
        ) {
          initializeStripeConnect();
        }

        // need to load this anyway for other components

        initializeStripeConnect();

        await fetchAffiliateData();
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const initializeStripeConnect = async () => {
    try {
      // Add these debug logs
      if (currentUser?.anonymous) {
        console.log("Anonymous user detected, skipping Stripe initialization");
        return;
      }

      const fetchClientSecret = async () => {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/create_account_session`,
          {},
          { withCredentials: true }
        );
        return response.data.client_secret;
      };

      const connectInstance = await loadConnectAndInitialize({
        publishableKey: process.env.REACT_APP_STRIPE_TEST_PUBLISH_KEY,
        fetchClientSecret,
        appearance: {
          variables: {
            colorPrimary: "#FFFFFF", // Light text for dark mode
            colorBackground: "#243838", // Dark background
            colorText: "#e0e0e0", // Light text for regular components
            buttonPrimaryColorBackground: "#957327", //  background for primary buttons
            buttonPrimaryColorText: "#FFFFFF", // White text for primary buttons
            buttonSecondaryColorBackground: "#555555", // Dark gray for secondary buttons
            buttonSecondaryColorText: "#FFFFFF", // White text for secondary buttons
            formHighlightColorBorder: "#3498db", // Blue border when focused
          },
          overlays: "dialog", // You can change this to "drawer" if you want a different overlay style
        },
      });
      setStripeConnectInstance(connectInstance);
    } catch (error) {
      console.error("Error initializing Stripe Connect:", error);
    }
  };

  const becomeAffiliate = async () => {
    try {
      addAlert({
        message: "Creating new affiliate...",
        type: "info",
      });
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/become_affiliate`,
        {},
        { withCredentials: true }
      );
      setAffiliateStatus({
        isAffiliate: true,
        onboardingComplete: false,
        connectedAccountId: response.data.connectedAccountId,
      });
      addAlert({
        message: "Affiliate status granted...",
        type: "success",
      });
      initializeStripeConnect();
    } catch (error) {
      addAlert({
        message: "Issue creating affiliate...",
        type: "error",
      });
      console.error("Error becoming affiliate:", error);
    }
  };

  const checkUser = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/current_user`,
        {
          withCredentials: true,
        }
      );
      if (response.data && response.data !== currentUser) {
        setCurrentUser(response.data); // Only update if different
      } // Assuming successful response structure
    } catch (error) {
      console.error("Error checking auth status:", error);
      setCurrentUser(null); // Explicitly handle unauthenticated state
    } finally {
      setLoading(false);
    }
  };

  const handleOnboardingExit = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/affiliate_status`,
        { withCredentials: true }
      );
      setAffiliateStatus(response.data);
      checkUser();
      window.scrollTo(0, 0);
      addAlert({
        message: "Affiliate account linked...",
        type: "success",
      });
      const customResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}/custom_data`,
        { withCredentials: true }
      );
    } catch (error) {
      console.error("Error updating onboarding status:", error);
    }
  };

  if (loading) {
    return <div className="loading">Loading your dashboard...</div>;
  }

  return (
    <div className="dashboard-container">
      <TutorialModal currentUser={currentUser} mode={"dashboard"} />

      <button
        onClick={() => navigate("/")}
        style={{
          position: "relative",
          right: "25vw",
          top: "20px",
          height: "60px",
          borderRadius: "10px",
          backgroundColor: "#48515d",
          zIndex: 999,
        }}
      >
        ← Home
      </button>
      {currentUser && (
        <>
          <h1>
            User:{" "}
            <span style={{ fontWeight: 600, color: "#00ABD6" }}>
              {currentUser.phone_number}{" "}
              {currentUser.is_affiliate && (
                <span
                  style={{
                    fontStyle: "italic",
                    fontSize: "0.8rem",
                    color: "white",
                  }}
                >
                  (AFFILIATE)
                </span>
              )}
              {currentUser.is_subscribed && (
                <span
                  style={{
                    fontStyle: "italic",
                    fontSize: "0.8rem",
                    color: "#957327",
                    marginLeft: "6px",
                  }}
                >
                  (MEMBER)
                </span>
              )}
            </span>
          </h1>

          <div className="custom-data">
            <h2>Your ChronoSpy Membership</h2>

            {currentUser.anonymous ? (
              // Display for anonymous users
              <>
                <p style={{ fontSize: "1.2rem" }}>Tier: Temporary Access</p>
                <button
                  style={{ fontSize: "1.4rem" }}
                  onClick={() =>
                    (window.location.href = "/about#register-form")
                  } // Redirect to register
                >
                  Register Now
                </button>
              </>
            ) : !currentUser.is_subscribed ? (
              // Display for users who aren't subscribed
              <>
                <p style={{ fontSize: "1.2rem" }}>
                  Tier: Free Trial
                  {currentUser.trial_ended && (
                    <span
                      style={{
                        color: "red",
                        marginLeft: "6px",
                      }}
                    >
                      (Trial Expired)
                    </span>
                  )}
                </p>

                {/* Unlock Full Access button always visible if user isn't subscribed */}
                <button
                  style={{ fontSize: "1.4rem" }}
                  onClick={openSubscriptionModal}
                >
                  Unlock Full Access
                </button>
              </>
            ) : (
              // Display for subscribed users
              <p style={{ fontSize: "1.2rem" }}>Tier: Member</p>
            )}

            {currentUser.is_subscribed && (
              <button
                style={{ backgroundColor: "darkgray", marginLeft: "0.5rem" }}
              >
                Cancel Membership
              </button>
            )}
          </div>

          <div className="affiliate-info">
            <h1>Affiliate Program</h1>
            {!affiliateStatus.isAffiliate ? (
              <div>
                <div className="affiliate-benefits-container">
                  <AffiliateBenefits />
                </div>
                {currentUser?.anonymous ? (
                  // If user is anonymous, show the button but prompt them to register
                  <button
                    className="become-affiliate-btn"
                    onClick={() => {
                      addAlert({
                        message:
                          "Please register an account to become an affiliate!",
                        type: "info",
                      });
                    }}
                  >
                    Become an Affiliate
                  </button>
                ) : (
                  // If user is not anonymous, allow them to actually become an affiliate
                  <button
                    className="become-affiliate-btn"
                    onClick={becomeAffiliate}
                  >
                    Become an Affiliate
                  </button>
                )}
              </div>
            ) : (
              <>
                {affiliateStatus.onboardingComplete ? (
                  <div className="onboarding-container">
                    {stripeConnectInstance &&
                      (isComponentLoaded ? (
                        <>
                          <div className="tab-container">
                            <button
                              className={
                                selectedTab === "clientLeadFeed"
                                  ? "active-tab"
                                  : ""
                              }
                              onClick={() => setSelectedTab("clientLeadFeed")}
                            >
                              Client Lead Feed
                            </button>

                            <button
                              className={
                                selectedTab === "payouts" ? "active-tab" : ""
                              }
                              onClick={() => {
                                setIsTabComponentLoaded(false);
                                setSelectedTab("payouts");
                              }}
                            >
                              My Payouts
                            </button>

                            <button
                              className={
                                selectedTab === "accountManagement"
                                  ? "active-tab"
                                  : ""
                              }
                              onClick={() => {
                                setIsTabComponentLoaded(false);
                                setSelectedTab("accountManagement");
                              }}
                            >
                              Affiliate Account Management
                            </button>
                          </div>

                          <div className="tab-content">
                            {renderTabContent()}
                          </div>
                        </>
                      ) : (
                        <div className="loading-placeholder">
                          <h2>Preparing Your Affiliate Area</h2>
                          <p>
                            We're setting up your personalized affiliate area.
                            This may take a few moments.
                          </p>
                          <div className="loading-spinner"></div>
                        </div>
                      ))}
                  </div>
                ) : (
                  <div className="onboarding-container">
                    {stripeConnectInstance ? (
                      <>
                        {!isComponentLoaded && (
                          <div className="loading-placeholder">
                            <h2>Preparing Your Affiliate Onboarding</h2>
                            <p>
                              We're setting up your personalized onboarding
                              process. This may take a few moments.
                            </p>
                            <div className="loading-spinner"></div>
                          </div>
                        )}
                        <ConnectComponentsProvider
                          connectInstance={stripeConnectInstance}
                        >
                          <ConnectAccountOnboarding
                            onExit={handleOnboardingExit}
                            onStepChange={({ step }) => {
                              console.log("step changed", step);
                            }}
                          />
                        </ConnectComponentsProvider>
                      </>
                    ) : (
                      <div className="loading-placeholder">
                        <h2>Preparing Your Affiliate Onboarding</h2>
                        <p>
                          We're setting up your personalized onboarding process.
                          This may take a few moments.
                        </p>
                        <div className="loading-spinner"></div>
                      </div>
                    )}
                  </div>
                )}
              </>
            )}

            <div>
              <SubscriptionCheckoutModal
                isOpen={isSubscriptionModalOpen}
                onClose={closeSubscriptionModal}
                hideCloseButton={false}
              />
            </div>
          </div>
        </>
      )}

      <style jsx>{`
        .dashboard-container {
          padding: 20px;
          font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
          background-color: #000;
          color: #e0e0e0;
          margin: auto;
          width: 65%;
          border-radius: 20px;
          border: 1px solid #1a5457;
        }

        .loading {
          text-align: center;
          font-size: 18px;
          margin-top: 50px;
          color: #b0b0b0;
        }

        .become-affiliate-btn {
          display: block;
          margin: 30px auto;
          padding: 12px 24px;
          background-color: #6772e5;
          color: white;
          border: none;
          border-radius: 6px;
          cursor: pointer;
          font-size: 16px;
          transition: background-color 0.3s ease;
        }

        .become-affiliate-btn:hover {
          background-color: #5469d4;
        }

        .affiliate-info {
          margin-top: 35px;
          margin-bottom: 20px;
        }

        .affiliate-info h1 {
          margin-bottom: 15px;
          color: #ffffff;
        }

        .affiliate-benefits-container {
          display: flex;
          justify-content: space-between;
          margin-bottom: 20px;
          gap: 24px;
        }

        .benefit-tile {
          background-color: #2a2a2a;
          border-radius: 10px;
          padding: 20px;
          text-align: center;
          width: 30%;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
          margin-bottom: 20px;
          transition: transform 0.3s ease;
        }

        .benefit-tile:hover {
          transform: translateY(-5px);
        }

        .icon {
          font-size: 40px;
          margin-bottom: 15px;
          color: #3498db;
          display: block;
        }

        .benefit-tile h3 {
          font-size: 18px;
          margin-bottom: 10px;
          color: #ffffff;
        }

        .benefit-tile p {
          font-size: 14px;
          color: #b0b0b0;
        }

        .onboarding-container {
          position: relative;
          width: 95%;
          margin: 0 auto;
          padding: 20px;
          background-color: #243838;
          border-radius: 10px;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
          min-height: 300px;
        }

        .loading-placeholder {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background-color: #243838;
          z-index: 10;
          border-radius: 10px;
        }

        .loading-placeholder h2 {
          color: #ffffff;
          margin-bottom: 10px;
        }

        .loading-placeholder p {
          color: #b0b0b0;
          margin-bottom: 20px;
          text-align: center;
        }

        .loading-spinner {
          border: 4px solid #3a3a3a;
          border-top: 4px solid #3498db;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          animation: spin 1s linear infinite;
        }

        @keyframes spin {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }

        .custom-data {
          background-color: #243838;
          padding: 20px;
          border-radius: 10px;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
          width: 96%;
          margin: auto;
        }

        .custom-data h2 {
          margin-bottom: 10px;
          color: #ffffff;
        }

        .custom-data p {
          color: #b0b0b0;
          margin-bottom: 15px;
        }

        .custom-data button {
          padding: 10px 20px;
          background-color: #27ae60;
          color: white;
          border: none;
          border-radius: 5px;
          cursor: pointer;
          font-size: 14px;
          transition: background-color 0.3s ease;
        }

        .custom-data button:hover {
          background-color: #219150;
        }

        @media (max-width: 768px) {
          .affiliate-benefits-container {
            flex-direction: column;
            align-items: center;
          }

          .benefit-tile {
            width: 80%;
          }
        }
        .tab-container {
          display: flex;
          margin-bottom: 20px;
        }
        .tab-container button {
          flex: 1;
          padding: 10px;
          background-color: #555;
          color: white;
          border: none;
          cursor: pointer;
          transition: background-color 0.3s ease;
        }
        .tab-container button.active-tab {
          background-color: #957327; // Highlight active tab
        }
        .tab-content {
          background-color: #243838;
          padding: 20px;
          border-radius: 10px;
        }

        /* Added styles for assigned contacts */
        .assigned-contacts-section {
          margin-top: 20px;
          padding: 15px;
          background-color: #2a2a2a;
          border-radius: 8px;
        }

        .contacts-table {
          width: 100%;
          border-collapse: collapse;
          margin-top: 10px;
        }

        .contacts-table th,
        .contacts-table td {
          border: 1px solid #444;
          padding: 10px;
          text-align: left;
        }

        .contacts-table th {
          background-color: #3a3a3a;
        }

        .contacts-table tr:nth-child(even) {
          background-color: #2a2a2a;
        }

        .contacts-table tr:hover {
          background-color: #444;
        }

        @media (max-width: 768px) {
          .contacts-table,
          .contacts-table th,
          .contacts-table td {
            display: block;
          }

          .contacts-table tr {
            margin-bottom: 15px;
          }

          .contacts-table th {
            background-color: transparent;
            color: #ffffff;
            padding: 5px 0;
          }

          .contacts-table td {
            padding: 5px 0;
            border: none;
            border-bottom: 1px solid #444;
          }
        }
      `}</style>
    </div>
  );
};

const WhatsAppFacebookDonut = ({
  whatsappCount,
  facebookCount,
  size = 80,
  thickness = 12,
}) => {
  const total = whatsappCount + facebookCount;

  // Properly handle full WhatsApp case (force 360 deg)
  const wpAngle = total ? (whatsappCount / total) * 360 : 360;

  // Corrected gradient logic to always produce a clean ring
  const gradient =
    facebookCount > 0
      ? `conic-gradient(
          #25D366 0deg ${wpAngle}deg,
          #1877F2 ${wpAngle}deg 360deg
        )`
      : `conic-gradient(#25D366 0deg 360deg)`; // Full green ring when Facebook is 0

  const donutStyle = {
    width: `${size}px`,
    height: `${size}px`,
    borderRadius: "50%",
    background: gradient,
    position: "relative",
    display: "inline-block",
  };

  const innerStyle = {
    position: "absolute",
    top: thickness,
    left: thickness,
    width: size - 2 * thickness,
    height: size - 2 * thickness,
    borderRadius: "50%",
    background: "#000", // Adjusted for dark mode UI
  };

  return (
    <div className="wpfb-donut-container">
      <div className="wpfb-donut" style={donutStyle}>
        <div className="wpfb-donut-inner" style={innerStyle}></div>

        {/* Placeholder ring only appears when Facebook is 0 */}
        {facebookCount === 0 && (
          <div
            className="wpfb-placeholder-ring"
            style={{ width: size, height: size }}
          ></div>
        )}
      </div>

      {/* Legend */}
      <div className="wpfb-legend">
        <div className="wpfb-legend-item">
          <span className="wpfb-legend-color wpfb-whatsapp"></span>
          <span>WhatsApp {whatsappCount}</span>
        </div>
        <div className="wpfb-legend-item">
          <span className="wpfb-legend-color wpfb-facebook"></span>
          <span>Facebook {facebookCount}</span>
        </div>
      </div>
    </div>
  );
};

const EngineHealthCheck = React.memo(() => {
  const { databaseInfo, fetchDatabaseInfo } = useMain();
  const canvasRef = useRef(null);
  const animationRef = useRef(null);
  const positionRef = useRef(0);
  const colorRef = useRef("hsl(120, 100%, 50%)");
  const { addAlert } = useAlert();
  // Extract all the metrics and calculate color just like in your original code
  const averageTime = databaseInfo.average_elapsed_time_last_10;

  // Parse the date strings directly to get the time in milliseconds
  const hasAlerted = useRef(false); // Track whether alert has been shown
  const hasAlertedStale = useRef(false);
  const lastRunTime = new Date(databaseInfo.last_run).getTime();
  const currentTime = Date.now();
  const elapsedTimeSinceLastRun = (currentTime - lastRunTime) / (1000 * 60);

  // Scraper calculation
  const timeRatio = elapsedTimeSinceLastRun / averageTime;
  const scraperHue =
    timeRatio <= 1 ? 120 : Math.max(0, 120 - (timeRatio - 1) * 120);
  const scraperColor = `hsl(${scraperHue}, 100%, 50%)`;

  // Lead Generator calculation
  const leadGeneratorTimeDifference = databaseInfo.time_since_last_lead;
  const leadGeneratorHue =
    leadGeneratorTimeDifference <= 15
      ? 120
      : leadGeneratorTimeDifference <= 60
      ? Math.max(0, 120 - ((leadGeneratorTimeDifference - 15) / 45) * 120)
      : 0;
  const leadGeneratorColor = `hsl(${leadGeneratorHue}, 100%, 50%)`;

  // Matchmaker calculation
  const matchmakerTimeDifference = databaseInfo.time_since_last_matchmaker;
  const matchmakerHue =
    matchmakerTimeDifference <= 0.8
      ? 120
      : matchmakerTimeDifference <= 5
      ? Math.max(0, 120 - ((matchmakerTimeDifference - 0.8) / 4.2) * 120)
      : 0;
  const matchmakerColor = `hsl(${matchmakerHue}, 100%, 50%)`;

  // Calculate weighted average
  const scraperWeight = 0.35;
  const leadGeneratorWeight = 0.45;
  const matchmakerWeight = 0.2;

  const overallHue =
    scraperHue * scraperWeight +
    leadGeneratorHue * leadGeneratorWeight +
    matchmakerHue * matchmakerWeight;

  // Trigger alert only if not already shown
  if (elapsedTimeSinceLastRun > 20 && !hasAlerted.current) {
    addAlert({
      message: "Scraper is having some technical difficulties. Admin notified.",
      type: "error",
    });
    hasAlerted.current = true; // Mark alert as shown
  }

  if (databaseInfo.leads_last_24_hours && !hasAlerted.current) {
    addAlert({
      message: `${databaseInfo.leads_last_24_hours} leads found in the last 24 hours.`,
      type: "success",
      className: "alert-lead-count",
    });
    hasAlerted.current = true; // Mark alert as shown
  }

  if (leadGeneratorTimeDifference.toFixed(2) > 25 && !hasAlertedStale.current) {
    addAlert({
      message: `Scraper lead freshness is outdated by ${leadGeneratorTimeDifference.toFixed(
        2
      )} mins; admin will be notified if the trend persists.`,
      type: "error",
      duration: 6500,
    });
    hasAlertedStale.current = true;
  }

  // const handleToggleInfo = () => {
  //   fetchDatabaseInfo(); // Fetch the latest database info
  //   setInfoVisible(!isInfoVisible);
  // };

  const setupCanvas = (canvas) => {
    const ctx = canvas.getContext("2d");
    const rect = canvas.getBoundingClientRect();
    const dpr = window.devicePixelRatio || 1;

    canvas.width = rect.width * dpr;
    canvas.height = rect.height * dpr;

    ctx.scale(dpr, dpr);
    ctx.lineWidth = 2;
    ctx.lineCap = "round";
    ctx.lineJoin = "round";

    return {
      ctx,
      width: rect.width,
      height: rect.height,
      baseY: rect.height / 2,
      cycleWidth: rect.width / 2,
    };
  };

  const getY = (x, baseY, cycleWidth) => {
    const progress = (x % cycleWidth) / cycleWidth;
    let y = baseY;

    if (progress > 0.1 && progress < 0.15) {
      // P-wave
      const p = (progress - 0.1) / 0.05;
      y = baseY - Math.sin(p * Math.PI) * 15;
    } else if (progress > 0.3 && progress < 0.4) {
      // QRS complex
      if (progress < 0.32) {
        y = baseY + 10; // Q-wave
      } else if (progress < 0.35) {
        y = baseY - 40; // R-wave
      } else if (progress < 0.37) {
        y = baseY + 15; // S-wave
      }
    } else if (progress > 0.45 && progress < 0.5) {
      // T-wave
      const t = (progress - 0.45) / 0.05;
      y = baseY - Math.sin(t * Math.PI) * 15;
    }

    return y;
  };

  const draw = (ctx, width, height, baseY, cycleWidth) => {
    // Clear the canvas
    ctx.clearRect(0, 0, width, height);

    // Adjust trail length
    const trailLength = width / 2.5; // Slightly longer trail for smoother fade

    // Keep step small for smooth lines
    const step = 0.4; // Fine-tune this for density and performance

    // Draw the line with trailing effect
    ctx.beginPath();

    for (let i = 0; i <= trailLength; i += step) {
      const x = (positionRef.current - i + width) % width;
      const y = getY(x, baseY, cycleWidth);

      // Adjust alpha for fade effect
      const alpha = Math.pow(1 - i / trailLength, 1.2);

      if (i === 0) {
        ctx.moveTo(x, y);
      } else {
        const prevX = (positionRef.current - (i - step) + width) % width;
        const prevY = getY(prevX, baseY, cycleWidth);

        ctx.beginPath();
        ctx.strokeStyle = colorRef.current.replace(")", `, ${alpha})`);
        ctx.moveTo(prevX, prevY);
        ctx.lineTo(x, y);
        ctx.stroke();
      }
    }

    // Reduce speed for smoother animation
    positionRef.current = (positionRef.current + 0.3) % width; // Slower motion
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const { ctx, width, height, baseY, cycleWidth } = setupCanvas(canvas);

    const animate = () => {
      draw(ctx, width, height, baseY, cycleWidth);
      animationRef.current = requestAnimationFrame(animate);
    };

    animationRef.current = requestAnimationFrame(animate);

    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, []);

  // Update color when health metrics change
  useEffect(() => {
    colorRef.current = `hsl(${overallHue}, 100%, 50%)`;
  }, [overallHue]);

  return (
    <div className="engine-health-container">
      <canvas
        ref={canvasRef}
        style={{ width: "50px", height: "100%", cursor: "pointer" }}
        onClick={fetchDatabaseInfo}
      />
      <div className="engine-info">
        <div className="engine-status">Lead Engine Health</div>
        <div className="engine-metric">
          Leads Captured in Last 24 Hours:{" "}
          <b>{databaseInfo.leads_last_24_hours}</b>
        </div>
        {/* <div className="engine-metric">
          Leads Captured in Last 7 Days: <b>{databaseInfo.leads_last_7_days}</b>
        </div> */}

        <div style={{ padding: "20px", fontSize: "10px" }}>
          <p>
            Currently, all leads come from WhatsApp. Facebook is coming soon!
          </p>
          <WhatsAppFacebookDonut
            whatsappCount={databaseInfo.leads_last_7_days}
            facebookCount={0}
            size={80}
            thickness={12}
          />
        </div>
        <div className="engine-metric">
          Time Since Last Scanner Cycle:{" "}
          <b>{elapsedTimeSinceLastRun.toFixed(2)} mins</b>
        </div>
        <div className="engine-metric">
          Lead Freshness: <b>{leadGeneratorTimeDifference.toFixed(2)} mins</b>
        </div>
        <div className="engine-metric">
          Time Since Last Matchmaker Process:{" "}
          <b>{matchmakerTimeDifference.toFixed(2)} mins</b>
        </div>
        <div className="orb-container">
          <div
            className="orb"
            style={{
              backgroundColor: leadGeneratorColor,
              boxShadow: `0 0 10px ${leadGeneratorColor}`,
            }}
          >
            Generator
          </div>
          <div
            className="orb"
            style={{
              backgroundColor: matchmakerColor,
              boxShadow: `0 0 10px ${matchmakerColor}`,
            }}
          >
            Matchmaker
          </div>
          <div
            className="orb"
            style={{
              backgroundColor: scraperColor,
              boxShadow: `0 0 10px ${scraperColor}`,
            }}
          >
            Scanner
          </div>
        </div>

        <p style={{ marginTop: "10px", fontSize: "12px" }}>
          <i>
            Admin will be notified automatically if bright-red status persists
            for over 25 minutes.
          </i>
        </p>
      </div>
    </div>
  );
});

const WatchlistCarousel = React.memo(
  ({
    getLatestKeyword,
    variations,
    coreRef,
    keywordId,
    total_matches_not_viewed,
    handleRemove,
    startAnimation,
    fetchUniqueColors,
    setSelectedWatchId,
    setShowFilterModalLayout,
    setExistingFilters,
    showMatchesOnClick,
  }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    // Memoize the limited variations to avoid reshuffling on every render
    const limitedVariations = useMemo(() => {
      return Array.isArray(variations) && variations.length > 0
        ? variations.sort(() => 0.5 - Math.random()).slice(0, 5)
        : [];
    }, [variations]);

    // Memoize the preloaded images to avoid reloading them on every render
    const preloadedImages = useMemo(() => {
      return limitedVariations.map((img) => {
        const image = new Image();
        image.src = `https://images.autowatch.io/images/no_watermark/${sanitizeFilename(
          img.image_link
        )}`;
        return image;
      });
    }, [limitedVariations]);

    // Set up the interval to shuffle through preloaded images
    useEffect(() => {
      if (preloadedImages.length > 0) {
        const interval = setInterval(() => {
          setCurrentIndex(
            (prevIndex) => (prevIndex + 1) % preloadedImages.length
          );
        }, 5000);

        return () => clearInterval(interval);
      }
    }, [preloadedImages]);

    if (limitedVariations.length === 0) {
      return <div className="empty-slot">No images available</div>;
    }

    return (
      <div className="watchlist-carousel">
        <div className="core-ref">{coreRef}</div>
        {!startAnimation && (
          <>
            <button
              className="remove-button"
              onClick={() => handleRemove(keywordId)}
            >
              &times;
            </button>{" "}
            <button
              className="edit-filter-button"
              onClick={async () => {
                try {
                  const updatedKeyword = await getLatestKeyword(keywordId);

                  if (updatedKeyword) {
                    const unique_colors = await fetchUniqueColors(coreRef);

                    setSelectedWatchId(keywordId);
                    setExistingFilters({
                      unique_colors,
                      selected_colors: updatedKeyword.selected_colors
                        ? updatedKeyword.selected_colors
                            .split(",")
                            .map((color) => color.trim())
                        : [],
                      full_set: updatedKeyword.full_set,
                      partial_set: updatedKeyword.partial_set,
                      only_watch: updatedKeyword.only_watch,
                      no_papers_tags: updatedKeyword.no_papers_tags,
                      condition: updatedKeyword.condition
                        ? updatedKeyword.condition
                            .split(",")
                            .map((cond) => cond.trim())
                        : [],
                      date_month: updatedKeyword.date_month,
                      date_year: updatedKeyword.date_year,
                      newer_than: updatedKeyword.newer_than,
                      serial: updatedKeyword.serial,
                      convert_to_usd: updatedKeyword.convert_to_usd,
                      only_usd: updatedKeyword.only_usd,
                    });

                    setShowFilterModalLayout(true);
                    toggleBodyScroll(true);
                  } else {
                    console.warn("No updated keyword found for edit.");
                  }
                } catch (error) {
                  console.error("Error opening edit modal:", error);
                }
              }}
            >
              &#9998;
            </button>
          </>
        )}
        {startAnimation && (
          <div
            className={`total-matches-watchlist ${
              total_matches_not_viewed === 0 ? "grey" : ""
            }`}
          >
            {total_matches_not_viewed}
          </div>
        )}
        {preloadedImages.map((img, index) => (
          <img
            key={img.src + index}
            src={img.src}
            alt={`Watch ${limitedVariations[index].ref.split("-")[0]}`}
            className={`carousel-image ${
              index === currentIndex ? "active" : ""
            }`}
          />
        ))}
      </div>
    );
  }
);

const Countdown = ({ endsAt, totalMatchesThisSession }) => {
  const calculateTimeLeft = () => {
    // Append "Z" if needed to force UTC (endsAt should be an ISO string)
    const endTimeUTC = new Date(endsAt + "Z").getTime();
    const nowUTC = Date.now();
    const difference = endTimeUTC - nowUTC;
    let timeLeft = {};

    if (difference > 0) {
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((difference / (1000 * 60)) % 60);
      const seconds = Math.floor((difference / 1000) % 60);

      timeLeft = {
        totalSeconds: Math.floor(difference / 1000),
        days,
        hours,
        minutes,
        seconds,
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer); // Cleanup
  }, [endsAt]);

  const { totalSeconds, hours, minutes, seconds } = timeLeft;

  const timerComponents = [];

  if (timeLeft.days) {
    timerComponents.push(
      <span key="days">
        {timeLeft.days} day{timeLeft.days > 1 ? "s" : ""}{" "}
      </span>
    );
  }

  if (timeLeft.hours || timeLeft.days) {
    // Show hours even if 0 if there are days so that the user sees the full breakdown
    timerComponents.push(
      <span key="hours">
        {timeLeft.hours} hr{timeLeft.hours !== 1 ? "s" : ""}{" "}
      </span>
    );
  }

  if (timeLeft.minutes) {
    timerComponents.push(
      <span key="minutes">
        {timeLeft.minutes} min{timeLeft.minutes !== 1 ? "s" : ""}{" "}
      </span>
    );
  }

  if (timeLeft.seconds) {
    timerComponents.push(
      <span key="seconds">
        {timeLeft.seconds} sec{timeLeft.seconds !== 1 ? "s" : ""}{" "}
      </span>
    );
  }
  return (
    <div className="session-expires-text">
      {timerComponents.length ? (
        timerComponents
      ) : (
        <span>Session Running...</span>
      )}
    </div>
  );
};

const TutorialModal = ({ currentUser, mode }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [comment, setComment] = useState(""); // Comment content
  const [loading, setLoading] = useState(false); // Track loading state
  const [error, setError] = useState(null); // Track errors
  const [tickets, setTickets] = useState([]); // Store fetched tickets
  const [showResponded, setShowResponded] = useState(true); // Toggle to show/hide responded tickets
  const [showHelp, setShowHelp] = useState(false);
  const { addAlert } = useAlert();

  const userId = currentUser.id;

  const fetchTickets = useCallback(async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/get_tickets/${userId}`,
        {
          method: "GET", // Optional, since GET is the default
          credentials: "include", // Ensure cookies are sent with the request
        }
      );
      const data = await response.json();
      if (response.ok) {
        setTickets(data.tickets);
      } else {
        console.error("Failed to fetch tickets:", data.error);
      }
    } catch (err) {
      console.error("An unexpected error occurred while fetching tickets.");
    }
  }, [userId]);

  useEffect(() => {
    const hasSeenTutorial = getCookie("hasSeenTutorial");
    if (!hasSeenTutorial) {
      setIsOpen(false);
      setCookie("hasSeenTutorial", "true", 365); // Set cookie to expire in 1 year
    }

    fetchTickets(); // Fetch tickets on mount
  }, [fetchTickets]);

  const toggleModal = () => {
    setIsOpen(!isOpen); // Toggle open/close
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleSubmit = useCallback(async () => {
    setLoading(true);
    setError(null);

    const ticketData = { user_id: userId, message: comment };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/submit_ticket`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify(ticketData),
        }
      );

      const data = await response.json();

      if (response.ok) {
        // If submission is successful
        setComment(""); // Clear the comment box
        fetchTickets(); // Refresh tickets
      } else {
        // If there's an error (e.g., ticket already exists)
        setError(data.error || "Failed to submit ticket");
      }
    } catch (err) {
      setError("An unexpected error occurred.");
    } finally {
      setLoading(false);
    }
  }, [comment, userId, fetchTickets]);

  // Filter tickets based on showResponded toggle
  const displayedTickets = showResponded
    ? tickets
    : tickets.filter((ticket) => !ticket.response);

  return (
    <>
      {isOpen ? (
        <div
          className={`tutorial-modal-wrapper ${isOpen ? "open" : "minimized"}`}
        >
          <div className="tutorial-modal-content">
            <button
              className="tutorial-modal-close-button"
              onClick={toggleModal}
            >
              ×
            </button>
            <h2 className="tutorial-modal-header">Welcome to ChronoSpy</h2>
            <div
              className={`tutorial-info-section ${
                showHelp ? "collapsed" : "expanded"
              }`}
            >
              {mode === "engine" && (
                <div className="tutorial-visual-container">
                  <h2 className="tutorial-visual-title">
                    How to Use ChronoSpy
                  </h2>

                  <div className="tutorial-visual-steps">
                    {/* Step 1 */}
                    <div className="tutorial-visual-step">
                      <div className="tutorial-visual-icon">🔍</div>
                      <div className="tutorial-visual-text">
                        <h4>Search</h4>
                        <p>Find your dream watch or check 'Hottest Watches'</p>
                      </div>
                    </div>

                    {/* Step 2 */}
                    <div className="tutorial-visual-step">
                      <div className="tutorial-visual-icon">⭐</div>
                      <div className="tutorial-visual-text">
                        <h4>Add to WatchList</h4>
                        <p>Track any watch with a single click</p>
                      </div>
                    </div>

                    {/* Step 3 */}
                    <div className="tutorial-visual-step">
                      <div className="tutorial-visual-icon">👀</div>
                      <div className="tutorial-visual-text">
                        <h4>AutoWatch</h4>
                        <p>Automatically stay alert for new deals</p>
                      </div>
                    </div>
                  </div>

                  <p className="tutorial-visual-explore">
                    Or simply browse leads at your own pace.
                  </p>
                </div>
              )}
            </div>
            <button
              className="help-toggle-button"
              onClick={() => setShowHelp((prev) => !prev)}
            >
              {showHelp ? "Hide Help" : "Need Help?"}
            </button>

            {showHelp && (
              <div
                className={`ticket-help-section ${
                  showHelp ? "expanded" : "collapsed"
                }`}
              >
                {/* Filter Toggle */}
                <div className="ticket-filter">
                  <label>
                    <input
                      type="checkbox"
                      checked={showResponded}
                      onChange={() => setShowResponded(!showResponded)}
                    />
                    Show Responded Tickets
                  </label>
                </div>

                {/* Display All Tickets and Responses */}
                <div className="ticket-history">
                  <h3>Your Support Tickets</h3>
                  {displayedTickets.length === 0 ? (
                    <p>
                      {showResponded
                        ? "You have not submitted any support tickets yet."
                        : "You have no pending support tickets."}
                    </p>
                  ) : (
                    <ul className="tickets-list">
                      {displayedTickets.map((ticket) => (
                        <li key={ticket.id} className="ticket-item">
                          <div className="ticket-message">
                            <strong>Ticket #{ticket.id}:</strong>{" "}
                            {ticket.message}
                          </div>
                          <div
                            className={`ticket-status ${
                              ticket.response ? "Answered" : "Awaiting"
                            }`}
                          >
                            Status:{" "}
                            {ticket.response ? "Answered" : "Awaiting response"}
                          </div>
                          {ticket.response && (
                            <div className="ticket-response">
                              <strong>Response:</strong> {ticket.response}
                            </div>
                          )}
                          <div className="ticket-date">
                            Submitted on:{" "}
                            {new Date(ticket.created_at).toLocaleString()}
                          </div>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>

                {/* Support Ticket Form */}
                <div className="tutorial-modal-ticket">
                  <h3 className="ticket-header">Need Help?</h3>
                  {error && <p className="error-message">{error}</p>}
                  <textarea
                    className="ticket-comment-box"
                    value={comment}
                    onChange={handleCommentChange}
                    placeholder="Enter your feedback or issue here..."
                    disabled={loading}
                  />
                  <button
                    className="ticket-submit-button"
                    onClick={handleSubmit}
                    disabled={loading || !comment.trim()}
                  >
                    {loading ? "Submitting..." : "Submit"}
                  </button>
                </div>
              </div>
            )}

            <p style={{ fontSize: "12px", color: "#387771", margin: "6px" }}>
              Alpha Version: Expect inaccuracies and issues as we roll-out
              fixes. Thank you for testing.
            </p>
            <div
              style={{
                marginTop: "10px",
                fontSize: "0.85rem",
                color: "gold",
                cursor: "pointer",
              }}
              onClick={() => showDetailedAlert(addAlert)} // Trigger detailed alert
            >
              Read more about upcoming changes
            </div>
          </div>
        </div>
      ) : null}
      {!isOpen ? (
        <button
          className="tutorial-modal-minimized-button"
          onClick={toggleModal}
        >
          <p className="tutorial-modal-description-button">
            <img
              src={helpIcon}
              width="20"
              height="20"
              style={{ marginRight: "7px" }}
            />
          </p>
        </button>
      ) : null}
    </>
  );
};

const PanningEvolution = () => {
  // Generate particles with fixed positions for consistent rendering
  const goldParticles = Array.from({ length: 5 }, () => ({
    size: 2 + Math.random() * 2,
    left: Math.random() * 100,
    top: Math.random() * 100,
    delay: Math.random() * 2,
    speed: 0.5 + Math.random() * 1,
  }));

  const dirtParticles = Array.from({ length: 40 }, () => ({
    size: 1 + Math.random() * 2,
    left: Math.random() * 100,
    top: Math.random() * 100,
    delay: Math.random() * 2,
    speed: 0.5 + Math.random() * 1,
  }));

  return (
    <div
      className="mining-evolution"
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "40px",
        padding: "20px",
        background: "#1a1a1a",
        borderRadius: "12px",
        maxWidth: "600px",
        margin: "0 auto",
      }}
    >
      {/* Stage 1: Enhanced Pan */}
      <div
        className="stage"
        style={{
          background: "#222",
          border: "1px solid #333",
          borderRadius: "8px",
          padding: "20px",
        }}
      >
        <div>
          <div
            className="animation-container"
            style={{
              width: "150px",
              height: "110px",
              position: "relative",
              flexShrink: 0,
              background: "#1a1a1a",
              borderRadius: "4px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "auto",
            }}
          >
            <div
              className="pan-outer"
              style={{
                width: "180px",
                height: "70px",
                background: "#444",
                borderRadius: "50%",
                position: "relative",
                transform: "perspective(400px) rotateX(30deg)",
                animation: "panMotion 3s ease-in-out infinite",
              }}
            >
              <div
                className="pan-inner"
                style={{
                  position: "absolute",
                  top: "5px",
                  left: "5px",
                  right: "5px",
                  bottom: "5px",
                  background: "#555",
                  borderRadius: "50%",
                  overflow: "hidden",
                }}
              >
                <div
                  className="water"
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    background: "rgba(158,197,254,0.3)",
                    animation: "waterMove 3s ease-in-out infinite",
                  }}
                >
                  {/* Main gold nugget */}
                  <div
                    style={{
                      position: "absolute",
                      width: "8px",
                      height: "8px",
                      background: "#FFD700",
                      borderRadius: "50%",
                      bottom: "20%",
                      left: "50%",
                      animation: "shimmerGold 2s infinite",
                    }}
                  />

                  {/* Small gold particles */}
                  {goldParticles.map((particle, i) => (
                    <div
                      key={`gold-${i}`}
                      style={{
                        position: "absolute",
                        width: `${particle.size}px`,
                        height: `${particle.size}px`,
                        background: "#FFD700",
                        borderRadius: "50%",
                        left: `${particle.left}%`,
                        top: `${particle.top}%`,
                        animation: `floatParticle ${particle.speed}s infinite`,
                        animationDelay: `${particle.delay}s`,
                      }}
                    />
                  ))}

                  {/* Dirt particles */}
                  {dirtParticles.map((particle, i) => (
                    <div
                      key={`dirt-${i}`}
                      style={{
                        position: "absolute",
                        width: `${particle.size}px`,
                        height: `${particle.size}px`,
                        background: "rgb(94, 62, 23)",
                        borderRadius: "50%",
                        left: `${particle.left}%`,
                        top: `${particle.top}%`,
                        animation: `floatParticle ${particle.speed}s infinite`,
                        animationDelay: `${particle.delay}s`,
                      }}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              color: "white",
              flex: 1,
            }}
          >
            <h3 style={{ color: "#FFD700", marginBottom: "10px" }}>
              Manual Searching
            </h3>
            <p style={{ fontSize: "14px", lineHeight: "1.5" }}>
              Like panning for gold by hand, dealers currently search through
              thousands of messages manually - it works, but valuable
              opportunities can be easily missed.
            </p>
          </div>
        </div>
      </div>

      <style>
        {`
          @keyframes panMotion {
            0%, 100% { transform: perspective(400px) rotateX(30deg) rotate(-5deg); }
            50% { transform: perspective(400px) rotateX(30deg) rotate(5deg); }
          }

          @keyframes waterMove {
            0%, 100% { transform: translateX(-5px); }
            50% { transform: translateX(5px); }
          }

          @keyframes floatParticle {
            0%, 100% { transform: translate(0, 0); }
            50% { transform: translate(3px, -3px); }
          }

          @keyframes shimmerGold {
            0%, 100% { opacity: 1; transform: scale(1); }
            50% { opacity: 0.7; transform: scale(1.1); }
          }

          @keyframes waterDrop {
            0% { transform: translateY(-100%); opacity: 1; }
            100% { transform: translateY(100%); opacity: 0; }
          }

          @keyframes streamFlow {
            0% { transform: translateY(-100%) rotate(15deg); opacity: 1; }
            100% { transform: translateY(100%) rotate(15deg); opacity: 0; }
          }

          @keyframes collectGold {
            0% { transform: scale(0.8); opacity: 0.8; }
            50% { transform: scale(1.1); opacity: 1; }
            100% { transform: scale(0.8); opacity: 0.8; }
          }

          @keyframes inputFlow {
            0% { transform: translateY(-100%); opacity: 1; }
            100% { transform: translateY(100%); opacity: 0; }
          }

          @keyframes processUnit {
            0%, 100% { transform: translateY(0); }
            50% { transform: translateY(-2px); }
          }

          @keyframes blink {
            0%, 100% { opacity: 1; }
            50% { opacity: 0.5; }
          }
        `}
      </style>
    </div>
  );
};

const SluiceEvolution = () => {
  return (
    <div
      className="mining-evolution"
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "40px",
        padding: "20px",
        background: "#1a1a1a",
        borderRadius: "12px",
        maxWidth: "600px",
        margin: "0 auto",
      }}
    >
      <div
        className="stage"
        style={{
          background: "#222",
          border: "1px solid #333",
          borderRadius: "8px",
          padding: "20px",
        }}
      >
        <div>
          <div
            className="animation-container"
            style={{
              width: "150px",
              height: "110px",
              position: "relative",
              flexShrink: 0,
              background: "#1a1a1a",
              borderRadius: "4px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "auto",
            }}
          >
            {/* Sluice Assembly */}
            <div
              style={{
                position: "relative",
                width: "180px",
                height: "100px",
              }}
            >
              {/* Legs - Positioned first to appear behind */}
              <div
                style={{
                  position: "absolute",
                  top: "20px", // Aligned with sluice box top
                  left: "20px",
                  width: "4px",
                  height: "60px",
                  background: "#555",
                  transform: "rotate(-10deg)", // Match sluice angle
                  transformOrigin: "top",
                }}
              />
              <div
                style={{
                  position: "absolute",
                  top: "20px", // Aligned with sluice box top
                  right: "20px",
                  width: "4px",
                  height: "45px", // Shorter leg
                  background: "#555",
                  transform: "rotate(-10deg)", // Match sluice angle
                  transformOrigin: "top",
                }}
              />

              {/* Main Sluice Box */}
              <div
                style={{
                  position: "absolute",
                  top: "20px",
                  left: "0",
                  width: "100%",
                  height: "50px",
                  background: "#666",
                  borderRadius: "2px",
                  overflow: "hidden",
                  transform: "rotate(-10deg)",
                  transformOrigin: "center",
                }}
              >
                {/* Riffles and Gold */}
                {Array.from({ length: 6 }).map((_, i) => (
                  <div key={`riffle-${i}`}>
                    {/* Riffle Line */}
                    <div
                      style={{
                        position: "absolute",
                        width: "100%",
                        height: "1px",
                        background: "#777",
                        top: `${16 + i * 12}%`,
                      }}
                    />
                    {/* Gold Dots - Two per line */}
                    <div
                      style={{
                        position: "absolute",
                        width: "3px",
                        height: "3px",
                        background: "#FFD700",
                        borderRadius: "50%",
                        top: `${16 + i * 12}%`,
                        left: "30%",
                        animation: "shimmerGold 2s infinite",
                      }}
                    />
                    <div
                      style={{
                        position: "absolute",
                        width: "3px",
                        height: "3px",
                        background: "#FFD700",
                        borderRadius: "50%",
                        top: `${16 + i * 12}%`,
                        left: "70%",
                        animation: "shimmerGold 2s infinite",
                        animationDelay: "1s",
                      }}
                    />
                  </div>
                ))}

                {/* Subtle Water Flow Effect */}
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    background:
                      "linear-gradient(180deg, rgba(158,197,254,0.1) 0%, rgba(158,197,254,0.05) 100%)",
                    animation: "waterFlow 2s linear infinite",
                  }}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              color: "white",
              flex: 1,
            }}
          >
            <h3 style={{ color: "#FFD700", marginBottom: "10px" }}>
              Current System
            </h3>
            <p style={{ fontSize: "14px", lineHeight: "1.5" }}>
              Like a basic sluice box that continuously catches gold as water
              flows through, our current system automatically processes messages
              and captures opportunities that might be missed by manual
              searching.
            </p>
          </div>
        </div>

        <style>
          {`
          @keyframes waterFlow {
            0% { transform: translateY(0); opacity: 0.5; }
            100% { transform: translateY(100%); opacity: 0.2; }
          }

          @keyframes shimmerGold {
            0%, 100% { opacity: 1; transform: scale(1); }
            50% { opacity: 0.7; transform: scale(1.1); }
          }
        `}
        </style>
      </div>
    </div>
  );
};

const FinalEvolution = () => {
  return (
    <div
      className="mining-evolution"
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "40px",
        padding: "20px",
        background: "#1a1a1a",
        borderRadius: "12px",
        maxWidth: "600px",
        margin: "0 auto",
      }}
    >
      <div
        className="stage"
        style={{
          background: "#222",
          border: "1px solid #333",
          borderRadius: "8px",
          padding: "20px",
        }}
      >
        <div>
          <div
            className="animation-container"
            style={{
              width: "150px",
              height: "110px",
              position: "relative",
              flexShrink: 0,
              background: "#1a1a1a",
              borderRadius: "4px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "auto",
            }}
          >
            {/* Advanced Processing Plant */}
            <div
              style={{
                position: "relative",
                width: "200px",
                height: "140px",
              }}
            >
              {/* Main Processing Unit */}
              <div
                style={{
                  position: "absolute",
                  top: "20px",
                  left: "50%",
                  transform: "translateX(-50%)",
                  width: "160px",
                  height: "100px",
                  background: "#444",
                  borderRadius: "6px",
                  display: "flex",
                  flexDirection: "column",
                  padding: "4px",
                  gap: "4px",
                }}
              >
                {/* Input Section with Feeder */}
                <div
                  style={{
                    height: "30px",
                    display: "flex",
                    gap: "4px",
                  }}
                >
                  {/* Input Hopper */}
                  <div
                    style={{
                      width: "40px",
                      height: "100%",
                      background: "#555",
                      borderRadius: "4px",
                      position: "relative",
                      overflow: "hidden",
                    }}
                  >
                    {/* Falling Material Animation */}
                    {Array.from({ length: 5 }).map((_, i) => (
                      <div
                        key={`input-${i}`}
                        style={{
                          position: "absolute",
                          width: "4px",
                          height: "4px",
                          background: "#777",
                          borderRadius: "50%",
                          animation: "fallInput 1s infinite",
                          animationDelay: `${i * 0.2}s`,
                          left: `${10 + i * 5}px`,
                        }}
                      />
                    ))}
                  </div>

                  {/* Processing Display */}
                  <div
                    style={{
                      flex: 1,
                      background: "#333",
                      borderRadius: "4px",
                      position: "relative",
                    }}
                  >
                    {/* Processing Indicators */}
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "10px",
                        width: "6px",
                        height: "6px",
                        background: "#FFD700",
                        borderRadius: "50%",
                        transform: "translateY(-50%)",
                        animation: "pulse 1s infinite",
                      }}
                    />
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        right: "10px",
                        width: "6px",
                        height: "6px",
                        background: "#FFD700",
                        borderRadius: "50%",
                        transform: "translateY(-50%)",
                        animation: "pulse 1s infinite",
                        animationDelay: "0.5s",
                      }}
                    />
                  </div>
                </div>

                {/* Main Processing Chambers */}
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    gap: "4px",
                  }}
                >
                  {/* Three Processing Chambers */}
                  {Array.from({ length: 3 }).map((_, i) => (
                    <div
                      key={`chamber-${i}`}
                      style={{
                        flex: 1,
                        background: "#555",
                        borderRadius: "4px",
                        position: "relative",
                        overflow: "hidden",
                      }}
                    >
                      {/* Processing Animation */}
                      <div
                        style={{
                          position: "absolute",
                          top: "0",
                          left: "0",
                          width: "100%",
                          height: "100%",
                          background:
                            "linear-gradient(0deg, #666 25%, transparent 25%)",
                          backgroundSize: "100% 20px",
                          animation: "process 1s infinite linear",
                          animationDelay: `${i * 0.3}s`,
                        }}
                      />
                      {/* Gold Indicator */}
                      <div
                        style={{
                          position: "absolute",
                          bottom: "4px",
                          left: "50%",
                          transform: "translateX(-50%)",
                          width: "6px",
                          height: "6px",
                          background: "#FFD700",
                          borderRadius: "50%",
                          animation: "shimmer 2s infinite",
                          animationDelay: `${i * 0.3}s`,
                        }}
                      />
                    </div>
                  ))}
                </div>

                {/* Output Collection */}
                <div
                  style={{
                    height: "20px",
                    background: "#FFD700",
                    borderRadius: "4px",
                    position: "relative",
                    overflow: "hidden",
                  }}
                >
                  {/* Gold Pile Animation */}
                  {Array.from({ length: 5 }).map((_, i) => (
                    <div
                      key={`gold-${i}`}
                      style={{
                        position: "absolute",
                        width: "8px",
                        height: "8px",
                        background: "#FFD700",
                        borderRadius: "50%",
                        bottom: "2px",
                        left: `${20 + i * 30}px`,
                        animation: "collect 2s infinite",
                        animationDelay: `${i * 0.4}s`,
                      }}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              color: "white",
              flex: 1,
            }}
          >
            <h3 style={{ color: "#FFD700", marginBottom: "10px" }}>
              Future Vision
            </h3>
            <p style={{ fontSize: "14px", lineHeight: "1.5" }}>
              Like modern mining operations that process material with
              precision, our advanced system will instantly identify and match
              specific watches with unprecedented accuracy, transforming how
              opportunities are discovered.
            </p>
          </div>
        </div>

        <style>
          {`
          @keyframes fallInput {
            0% { transform: translateY(-100%); opacity: 1; }
            100% { transform: translateY(100%); opacity: 0; }
          }

          @keyframes pulse {
            0%, 100% { opacity: 1; transform: translateY(-50%) scale(1); }
            50% { opacity: 0.6; transform: translateY(-50%) scale(1.2); }
          }

          @keyframes process {
            0% { transform: translateY(0); }
            100% { transform: translateY(20px); }
          }

          @keyframes shimmer {
            0%, 100% { opacity: 1; transform: translateX(-50%) scale(1); }
            50% { opacity: 0.7; transform: translateX(-50%) scale(1.2); }
          }

          @keyframes collect {
            0%, 100% { transform: scale(1); }
            50% { transform: scale(1.1); }
          }
        `}
        </style>
      </div>
    </div>
  );
};

const VisionModal = () => {
  return (
    <div
      style={{
        lineHeight: "1.4em",
        fontSize: "0.85rem",
        textAlign: "left",
        maxWidth: "100%",
        wordBreak: "break-word",
        padding: "15px",
        overflowY: "auto",
        maxHeight: "80vh",
      }}
    >
      <h5
        style={{
          fontSize: "15px",
          fontWeight: "600",
          marginTop: 0,
          marginBottom: "15px",
        }}
      >
        Our Vision: From Panning to Precision Mining
      </h5>

      <div
        style={{
          fontSize: "13px",
          color: "white",
          marginBottom: "20px",
        }}
      >
        <p>
          Think of the watch market as a vast goldfield. Right now, dealers are
          like prospectors, manually sifting through endless streams of messages
          hoping to spot valuable opportunities.
        </p>
      </div>

      <PanningEvolution />

      <div>
        <h6
          style={{
            fontSize: "13px",
            fontWeight: "500",
            marginBottom: "8px",
            color: "#FFD700",
          }}
        >
          Our First Mining Rig (Current Version)
        </h6>

        <p
          style={{
            fontSize: "12px",
            color: "white",
          }}
        >
          We've built our first automated sifting machine. Like early mining
          equipment, it's processing more material than manual panning ever
          could. It might miss some nuggets and occasionally mistake pyrite for
          gold, but it's already saving prospectors hours of manual work.
        </p>
      </div>

      <SluiceEvolution />

      <div>
        <h6
          style={{
            fontSize: "13px",
            fontWeight: "500",
            marginBottom: "8px",
            color: "#FFD700",
          }}
        >
          The Next Generation Rig (Coming Soon)
        </h6>

        <p
          style={{
            fontSize: "12px",
            color: "white",
          }}
        >
          We're designing precision equipment that won't just find gold - it'll
          identify specific karats, sizes, and qualities instantly. Imagine a
          machine that can spot that exact 18k nugget you're looking for among
          tons of material, in seconds.
        </p>
      </div>

      <FinalEvolution />

      <div
        style={{
          backgroundColor: "rgba(255,215,0,0.1)",
          padding: "12px",
          borderRadius: "6px",
          marginTop: "10px",
          border: "1px solid rgba(255,215,0,0.2)",
        }}
      >
        <p
          style={{
            fontSize: "12px",
            color: "white",
            fontStyle: "italic",
            margin: 0,
          }}
        >
          "Every scoop of dirt our machine processes helps refine our
          technology. While our current rig is just the prototype, it's laying
          the groundwork for a revolution in how we find market opportunities."
        </p>
      </div>
    </div>
  );
};

const MainHeader = () => {
  const { databaseInfo } = useMain();

  const { addAlert } = useAlert();

  return (
    <h1>
      <div style={{ textAlign: "center" }}>
        <div style={{ marginTop: "-40px", marginBottom: "-60px" }}>
          <div
            style={{ cursor: "help" }}
            onClick={() => {
              addAlert({
                message: <VisionModal />,
                type: "info",
                duration: 250000,
                className: "alert-vision",
              });
            }}
            className="logo-container fade-in-delay-logo-container"
          >
            <img
              src={eyeFrame}
              className="eye-frame"
              width="170"
              height="170"
            />

            <img
              src={hourHand}
              className="hour-hand-logo"
              width="153"
              height="153"
            />

            <img
              src={minuteHand}
              className="minute-hand-logo"
              width="153"
              height="153"
            />
          </div>
        </div>
        <h3 style={{ color: "white", marginLeft: "40px" }}>
          ChronoSpy
          <span
            style={{
              marginLeft: "2px",
              fontSize: "0.2em",
              color: "white",
            }}
          >
            ALPHA v1.2
          </span>
        </h3>

        <p
          style={{
            color: "white",
            fontSize: "13px",
            marginTop: "-35px",
          }}
        >
          &mdash; Instant Watch Leads for Dealers &mdash;
        </p>

        {databaseInfo.elapsed_time && (
          <div style={{ marginBottom: "-50px" }}>
            <EngineHealthCheck />
          </div>
        )}
      </div>
    </h1>
  );
};

const ModalAutoWatchExplainer = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const features = [
    { icon: "🔍", text: "Automates your watch search 24/7" },
    { icon: "📱", text: "Instant notifications on matches" },
    { icon: "✉️", text: "Control everything via email or text" },
    { icon: "⏱️", text: "Save time and never miss a deal" },
  ];

  const explainerStyles = {
    container: {
      marginBottom: "20px",
      padding: "15px",
      backgroundColor: "#0f181a", // Dark background
      borderRadius: "5px",
      boxShadow: "0 2px 4px rgba(255,255,255,0.1)", // Light shadow
    },
    title: {
      fontSize: "1.2rem",
      marginBottom: "10px",
      textAlign: "center",
      color: "#D4AF37", // Rolex gold
    },
    featureContainer: {
      height: "60px",
      position: "relative",
      overflow: "hidden",
    },
    feature: {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      transition: "opacity 0.5s, transform 0.5s",
      color: "#fff", // White text
    },
    icon: {
      fontSize: "24px",
      marginRight: "10px",
    },
    text: {
      fontSize: "16px",
      color: "#fff", // White text
    },
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % features.length);
    }, 3000); // Change feature every 3 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <div style={explainerStyles.container}>
      <h3 style={explainerStyles.title}>AutoWatch Features</h3>
      <div style={explainerStyles.featureContainer}>
        {features.map((feature, index) => (
          <div
            key={index}
            style={{
              ...explainerStyles.feature,
              opacity: index === currentIndex ? 1 : 0,
              transform: `translateY(${index === currentIndex ? 0 : "10px"})`,
            }}
          >
            <span style={explainerStyles.icon}>{feature.icon}</span>
            <span style={explainerStyles.text}>{feature.text}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

const DisclaimerModal = ({
  isOpen,
  onClose,
  onConfirm,
  action,
  currentUser,
}) => {
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [emailOnMatch, setEmailOnMatch] = useState(true);
  const [textOnMatch, setTextOnMatch] = useState(false);

  useEffect(() => {
    if (currentUser) {
      setPhoneNumber(currentUser.phone_number || "");
      setEmail(currentUser.email || "");
    }
  }, [currentUser]);

  if (!isOpen) return null;

  const handleConfirm = () => {
    onConfirm({
      email,
      phoneNumber,
      emailOnMatch,
      textOnMatch,
    });
  };

  const modalStyles = {
    modalOverlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgb(28 30 30 / 90%)", // Dark overlay
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 1000,
    },

    modalTitle: {
      fontSize: "1.5rem",
      marginBottom: "15px",
      color: "#D4AF37", // Rolex gold
    },
    modalText: {
      marginBottom: "20px",
      lineHeight: 1.5,
      color: "#B0BEC5", // Light gray for text
    },
    modalButtons: {
      display: "flex",
      justifyContent: "center",
    },
    modalButton: {
      padding: "10px 20px",
      border: "none",
      borderRadius: "3px",
      cursor: "pointer",
      fontSize: "1rem",
      transition: "background-color 0.3s",
    },
    cancelButton: {
      backgroundColor: "#333", // Dark button
      color: "#fff",
      marginRight: "10px",
    },
    confirmButton: {
      backgroundColor: "#008080", // Teal accent
      color: "white",
    },
    inputContainer: {
      marginBottom: "20px",
    },
    input: {
      width: "90%",
      padding: "8px",
      marginBottom: "10px",
      borderRadius: "3px",
      border: "1px solid #555", // Darker border
      backgroundColor: "#1c1c1c", // Dark input background
      color: "#fff", // White text
    },
    checkboxContainer: {
      display: "flex",
      alignItems: "center",
      marginBottom: "10px",
    },
  };

  return (
    <div style={modalStyles.modalOverlay}>
      <div className="modal-content-start-autowatch">
        <h2 style={modalStyles.modalTitle}>
          {action === "start" ? "Start" : "Stop"}
        </h2>
        {action === "start" && <ModalAutoWatchExplainer />}
        <p style={modalStyles.modalText}>
          {action === "start"
            ? "You are about to start an AutoWatch session. This will run for up to 72 hours or until you stop it manually."
            : "You are about to stop your current AutoWatch session."}
        </p>
        {action === "start" && (
          <div style={modalStyles.inputContainer}>
            <input
              type="email"
              placeholder="Email"
              value={email}
              disabled
              onChange={(e) => setEmail(e.target.value)}
              style={modalStyles.input}
            />
            <div style={modalStyles.checkboxContainer}>
              {!currentUser.anonymous ? (
                <>
                  <input
                    type="checkbox"
                    id="emailOnMatch"
                    checked={emailOnMatch}
                    onChange={(e) => setEmailOnMatch(e.target.checked)}
                  />

                  <label style={{ color: "#B0BEC5" }} htmlFor="emailOnMatch">
                    Receive email on match
                  </label>
                </>
              ) : (
                <>
                  <input
                    type="checkbox"
                    id="emailOnMatch"
                    checked={emailOnMatch}
                    disabled
                    onChange={(e) => setEmailOnMatch(e.target.checked)}
                  />

                  <label style={{ color: "#B0BEC5" }} htmlFor="emailOnMatch">
                    Receive email on match (register to unlock)
                  </label>
                </>
              )}
            </div>
            <input
              type="tel"
              placeholder="Phone Number"
              disabled
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              style={modalStyles.input}
            />
            <div style={modalStyles.checkboxContainer}>
              <input
                type="checkbox"
                id="textOnMatch"
                checked={textOnMatch}
                disabled
                onChange={(e) => setTextOnMatch(e.target.checked)}
              />
              <label style={{ color: "#B0BEC5" }} htmlFor="textOnMatch">
                Receive text on match (coming soon)
              </label>
            </div>
          </div>
        )}
        <div style={modalStyles.modalButtons}>
          <button
            style={{
              ...modalStyles.modalButton,
              ...modalStyles.cancelButton,
            }}
            onClick={onClose}
          >
            Back
          </button>
          <button
            style={{
              ...modalStyles.modalButton,
              ...modalStyles.confirmButton,
            }}
            onClick={handleConfirm}
          >
            {action === "start" ? "Start" : "Stop"}
          </button>
        </div>
      </div>
    </div>
  );
};

const Layout = () => {
  // to conditionally render stuff here in the layout depending on route
  // const location = useLocation();
  // const showHeaderFooter = location.pathname !== "/exclude-path";

  const location = useLocation();
  // Show the back button on every page except the home page
  const showBackButton = location.pathname !== "/about";

  const showWatchList = location.pathname == "/";

  const [matchesInfo, setMatchesInfo] = useState({});
  const [loading, setLoading] = useState(false);
  const [showMatchesModal, setShowMatchesModal] = useState(false);

  //for the matches lead image

  const [showMatchLeadImageModal, setShowMatchLeadImageModal] = useState(false);
  const [matchModalImageUrl, setMatchModalImageUrl] = useState("");
  const [shouldShakeButton, setShouldShakeButton] = useState(false);

  const [showSessionsModal, setShowSessionsModal] = useState(false);

  const fetchSessionMatches = async (sessionId, page = 1) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/autowatch-sessions/${sessionId}/matches?page=${page}&per_page=10`,
        { withCredentials: true }
      );
      console.log(response.data);
      // Use response.data.matches, response.data.total_matches, etc. for your UI
    } catch (error) {
      console.error("Error fetching session matches:", error);
    }
  };

  // Function to open the modal with the selected image
  const openMatchLeadImageModal = (imageUrl) => {
    setMatchModalImageUrl(imageUrl);
    setShowMatchLeadImageModal(true);
  };

  // Function to close the modal
  const closeMatchLeadImageModal = () => {
    setShowMatchLeadImageModal(false);
    setMatchModalImageUrl("");
  };

  const [insideOfThisWatchMatches, setInsideOfThisWatchMatches] = useState();

  const [animatedSlots, setAnimatedSlots] = useState([]); // New state

  const [endTime, setEndTime] = useState(null);

  const [totalMatchesThisSession, setTotalMatchesThisSession] = useState();

  const [selectedWatchId, setSelectedWatchId] = useState(null);

  const [existingFilters, setExistingFilters] = useState({
    selected_colors: [],
    full_set: false,
    partial_set: false,
    only_watch: false,
    no_papers_tags: false,
    condition: [],
    date_month: "",
    date_year: "",
    newer_than: false,
    serial: "",
    convert_to_usd: false,
    only_usd: false,
  });

  const { addAlert } = useAlert();

  const showMatchesOnClick = (watch_keyword_id) => {
    setLoading(true);
    toggleBodyScroll(true);
    setShowMatchesModal(true);
    fetchMatches(1, watch_keyword_id);
    setInsideOfThisWatchMatches(watch_keyword_id);
  };

  const openWhatsAppChat = (phoneNumber) => {
    if (!phoneNumber) {
      alert(
        "Phone number not available - fixing this soon. Sorry for inconvenience."
      );
      return;
    }
    const formattedPhoneNumber = phoneNumber.replace(/\D/g, "");
    window.open(`https://wa.me/${formattedPhoneNumber}`, "_blank");
  };

  // Define the submitFeedback function for incorrect detections
  const handleIncorrectDetection = (watchLeadId) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/submit_feedback`,
        {
          user_id: currentUser.id, // Current user submitting feedback
          watch_lead_id: watchLeadId,
        },
        { withCredentials: true }
      )
      .then(() => {
        addAlert({
          message: "Feedback submitted. Thank you.",
          type: "success",
        });
      })
      .catch((error) => console.error("Error:", error));
  };

  const { currentUser } = useAuth();

  const [showFilterModalLayout, setShowFilterModalLayout] = useState();

  const {
    isActionButtonMinimized,
    setIsActionButtonMinimized,
    pricePulseStatus,
    setPricePulseStatus,
    isBuy,
    setIsBuy,
    watchList,
    setWatchList,
    startAnimation,
    emails,
    emailNotificationStatus,
    pages,
    setPages,
    inputRef,
    setEmails,
    setStartAnimation,
    textNotificationStatus,
    phoneNumbers,
    setPhoneNumbers,
    setEmailNotificationStatus,
    setTextNotificationStatus,
    showKeywordLimitModal,
    setShowTooltip,
    setShowKeywordLimitModal,
  } = useMain();

  // for modal on start/stop autowatch button
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalAction, setModalAction] = useState(null);

  const handleStartAutowatch = () => {
    setModalAction("start");
    setIsModalOpen(true);
  };

  const handleStopAutowatch = () => {
    setModalAction("stop");
    setIsModalOpen(true);
  };

  const handleConfirmAction = (userData) => {
    setIsModalOpen(false);
    if (modalAction === "start") {
      startAutowatch(userData);
    } else {
      stopAutowatch(currentUser.id);
    }
  };

  const watchListRef = useRef(watchList);

  // for the interval 24 seconds fetchKeywords in this layout (autowatch scanning on)
  useEffect(() => {
    watchListRef.current = watchList;
  }, [watchList]);

  const [activeTab, setActiveTab] = useState(
    watchList && watchList.length > 0 ? watchList[0]?.id : null
  ); // Default to the first watch's ID

  const watchRefs = useRef([]);
  if (watchList && watchRefs.current.length !== watchList.length) {
    // Assign or reassign refs to the array elements
    watchRefs.current = watchList.map(
      (_, i) => watchRefs.current[i] ?? React.createRef()
    );
  }

  const toggleEmailNotification = useCallback((keywordid) => {
    setEmailNotificationStatus((prevState) => ({
      ...prevState,
      [keywordid]: !prevState[keywordid],
    }));
  });

  const toggleTextNotification = useCallback((keywordid) => {
    setTextNotificationStatus((prevState) => ({
      ...prevState,
      [keywordid]: !prevState[keywordid],
    }));
  });

  const handleTextUpdate = useCallback(async (keywordId) => {
    const phoneNumber = phoneNumbers[keywordId];
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/turn_on_auto_text`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          credentials: "include",
          body: JSON.stringify({
            keyword_id: keywordId,
            phone_number: phoneNumber,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("HTTP status " + response.status);
      }
      toggleTextNotification(keywordId);
      fetchKeywords();
    } catch (error) {
      console.error(`Fetch request failed: ${error}`);
    }
  });

  const handleEmailUpdate = useCallback(async (keywordId) => {
    const email = emails[keywordId];
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/turn_on_auto_email`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          credentials: "include",
          body: JSON.stringify({ keyword_id: keywordId, email }),
        }
      );

      if (!response.ok) {
        throw new Error("HTTP status " + response.status);
      }
      toggleEmailNotification(keywordId);
      fetchKeywords();
    } catch (error) {
      console.error(`Fetch request failed: ${error}`);
    }
  });

  const startAutowatch = async (userData) => {
    setIsActionButtonMinimized(false);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/start_autowatch`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          credentials: "include",
          body: JSON.stringify({
            user_id: currentUser.id,
            email: userData.email,
            phone_number: userData.phoneNumber,
            email_on_match: userData.emailOnMatch,
            text_on_match: userData.textOnMatch,
          }),
        }
      );

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || "HTTP status " + response.status);
      }

      if (data.status === "success") {
        addAlert({
          message: "Starting AutoWatch...",
          type: "success",
        });
        setStartAnimation(true);
        fetchAutowatchSession();
      } else {
        addAlert({
          message: data.message || "Failed to start AutoWatch",
          type: "error",
        });
      }
    } catch (error) {
      console.error(`Fetch request failed: ${error}`);
      addAlert({
        message: error.message || "Failed to start AutoWatch",
        type: "error",
      });
    }
  };

  const stopAutowatch = async (user_id) => {
    setIsActionButtonMinimized(false);
    addAlert({
      message: "Stopping AutoWatch...",
      type: "info",
    });
    setStartAnimation(false);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/stop_autowatch`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          credentials: "include",
          body: JSON.stringify({
            user_id: user_id,
          }),
        }
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || "HTTP status " + response.status);
      }

      if (data.status === "success") {
        addAlert({
          message: "AutoWatch stopped successfully",
          type: "success",
        });

        fetchKeywords();

        // You might want to clear any stored session info here
        // For example:
        // setSessionInfo(null);
      } else {
        addAlert({
          message: data.message || "Failed to stop AutoWatch",
          type: "error",
        });
      }
    } catch (error) {
      console.error(`Fetch request failed: ${error}`);
      addAlert({
        message: error.message || "Failed to stop AutoWatch",
        type: "error",
      });
    }
  };

  const turnOffEmailUpdates = useCallback(async (keywordId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/turn_off_auto_email`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          credentials: "include",
          body: JSON.stringify({ keyword_id: keywordId }),
        }
      );

      if (!response.ok) {
        throw new Error("HTTP status " + response.status);
      }
      toggleEmailNotification(keywordId);
      fetchKeywords();
    } catch (error) {
      console.error(`Fetch request failed: ${error}`);
    }
  });

  const turnOffTextUpdates = useCallback(async (keywordId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/turn_off_auto_text`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          credentials: "include",
          body: JSON.stringify({ keyword_id: keywordId }),
        }
      );

      if (!response.ok) {
        throw new Error("HTTP status " + response.status);
      }
      toggleTextNotification(keywordId);
      fetchKeywords();
    } catch (error) {
      console.error(`Fetch request failed: ${error}`);
    }
  });

  const processKeywords = (responseData) => {
    if (!responseData || !Array.isArray(responseData)) {
      console.error("Invalid responseData:", responseData);
      return;
    }

    // Existing logic to handle animations
    let shouldStartAnimation = responseData.some((item) => item?.scanning_on);
    setStartAnimation(shouldStartAnimation);

    let arraysAreEqual = true;
    const previousWatchList = watchListRef.current;
    const updatedWatchIds = [];

    if (previousWatchList && previousWatchList.length === responseData.length) {
      for (let i = 0; i < previousWatchList.length; i++) {
        if (
          previousWatchList[i].total_matches_not_viewed !==
          responseData[i].total_matches_not_viewed
        ) {
          arraysAreEqual = false;
          if (
            responseData[i].total_matches_not_viewed >
            previousWatchList[i].total_matches_not_viewed
          ) {
            updatedWatchIds.push(responseData[i].id);
          }
        }
      }
    } else {
      arraysAreEqual = false;
    }

    if (!arraysAreEqual) {
      if (updatedWatchIds.length > 0) {
        setAnimatedSlots((prev) => [...prev, ...updatedWatchIds]);

        // Remove animation class after 1 second
        setTimeout(() => {
          setAnimatedSlots((prev) =>
            prev.filter((id) => !updatedWatchIds.includes(id))
          );
        }, 1000);
      }

      setWatchList(responseData);
    }

    watchListRef.current = responseData;
  };

  const fetchUniqueColors = useCallback((reference) => {
    // Notify the user that the request is in progress

    // Return the promise so the caller can chain .then()
    return axios
      .get(`${process.env.REACT_APP_API_URL}/api/search/colors`, {
        params: { ref: reference },
        withCredentials: true,
      })
      .then((response) => {
        // Extract the list of unique colors from the response
        const { unique_colors } = response.data;

        return unique_colors; // Return the unique colors
      })
      .catch((error) => {
        console.error("Error:", error);

        return null; // Return null in case of an error
      });
  });

  const getLatestKeyword = async (keyword_id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/user-keywords/${currentUser.id}`,
        { withCredentials: true }
      );

      const updatedKeyword = response.data.find((kw) => kw.id === keyword_id);
      if (!updatedKeyword) {
        console.warn("Keyword not found:", keyword_id);
        return null;
      }
      return updatedKeyword;
    } catch (error) {
      return null;
    }
  };

  const fetchKeywords = async (page = 1, keyword_id = null) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/user-keywords/${currentUser.id}?page=${page}`,
        { withCredentials: true }
      );

      processKeywords(response.data);

      if (response.data.length > 0 && keyword_id) {
        const keywordData = response.data.find((kw) => kw.id === keyword_id);
        setMatchesInfo((prevInfo) => ({
          ...prevInfo,
          [keyword_id]: {
            ...prevInfo[keyword_id],
            totalPages: keywordData ? keywordData.pages : 0,
            currentPage: page,
          },
        }));
      }
    } catch (error) {
      console.error("Error in fetchKeywords:", error);
    }
  };

  const fetchAutowatchSession = async () => {
    try {
      // Fetch the active autowatch session for the given user
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/autowatch_session/${currentUser.id}`,
        {
          withCredentials: true, // Ensure cookies are sent with the request
        }
      );

      // Check if the response is successful
      if (response.data.status === "success") {
        // Extract the ends_at value
        const endTime = new Date(response.data.ends_at);

        // Here, you can do further processing with `endTime` or set it in your state.
        setEndTime(endTime);
        setTotalMatchesThisSession(response.data.computed_matches);
      } else {
        console.log("get out of here stalker"); // Handle cases where no active session is found
      }
    } catch (error) {
      console.error("Error fetching autowatch session:", error);
    }
  };

  // Create a ref to store accumulated match IDs
  const accumulatedMatchesIdsRef = React.useRef({});

  const fetchMatches = async (page = 1, keyword_id) => {
    setLoading(true);
    try {
      const endpoint = `${process.env.REACT_APP_API_URL}/user-keywords/${currentUser.id}/keyword/${keyword_id}/matches?page=${page}`;

      const response = await axios.get(endpoint, {
        withCredentials: true, // Ensure cookies are sent with the request
      });

      const freshMatchIds = response.data.matches.map((match) => match.id);

      // Update the ref with fresh match IDs
      accumulatedMatchesIdsRef.current[keyword_id] = [
        ...(accumulatedMatchesIdsRef.current[keyword_id] || []),
        ...freshMatchIds.filter(
          (id) =>
            !(accumulatedMatchesIdsRef.current[keyword_id] || []).includes(id)
        ),
      ];

      // Update the state to reflect the new matches for rendering
      setMatchesInfo((prevInfo) => ({
        ...prevInfo,
        [keyword_id]: {
          ...prevInfo[keyword_id],
          matches: response.data.matches,
          last10Matches: response.data.last_10_matches,
          totalPages: response.data.pages,
          currentPage: response.data.current_page,
          perPage: response.data.per_page,
          total_matches: response.data.total_matches,
          new_matches_count: response.data.new_matches_count,
          matchesIds: accumulatedMatchesIdsRef.current[keyword_id],
        },
      }));

      setLoading(false);
    } catch (error) {
      console.error("Error fetching matches:", error);
      setLoading(false);
    }
  };

  const checkReposts = async (match) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/lead-post-count`,

        {
          ref: match.watch_lead.watch_ref,
          message_sender: match.phone_number, // Adjust if necessary
          usd_price: match.watch_lead.usd_price,
        },
        {
          withCredentials: true, // Ensure cookies are sent with the request
        }
      );

      const { post_count } = response.data;

      // Update the match with the post count
      setMatchesInfo((prevInfo) => {
        const updatedMatches = prevInfo[insideOfThisWatchMatches].matches.map(
          (m) => {
            if (m.id === match.id) {
              return { ...m, postCount: post_count };
            }
            return m;
          }
        );
        return {
          ...prevInfo,
          [insideOfThisWatchMatches]: {
            ...prevInfo[insideOfThisWatchMatches],
            matches: updatedMatches,
          },
        };
      });
    } catch (error) {
      console.error("Error fetching post count:", error);
    }
  };

  const markMatchesAsViewed = async (keyword_id) => {
    try {
      const matchIdsToMark = accumulatedMatchesIdsRef.current[keyword_id] || [];
      if (matchIdsToMark.length === 0) {
        return;
      }

      const endpoint = `${process.env.REACT_APP_API_URL}/api/user-keywords/${currentUser.id}/keyword/${keyword_id}/mark-as-viewed`;
      await axios.post(
        endpoint,
        { match_ids: matchIdsToMark },
        { withCredentials: true }
      );
      // get keywords again to update the total_matches variable (green number)
      fetchKeywords();
      setMatchesInfo([]);
      addAlert({
        message: `Marking ${matchIdsToMark.length} matches as viewed...`,
        type: "info",
      });

      // Clear the accumulated IDs after successfully marking them as viewed
      accumulatedMatchesIdsRef.current[keyword_id] = [];
    } catch (error) {
      console.error("Error marking matches as viewed:", error);
    }
  };

  const removeKeyword = useCallback(async (keywordId) => {
    try {
      // Call your API endpoint here to remove the keyword
      addAlert({
        message: "Removing watch...",
        type: "info",
      });
      await axios.post(
        `${process.env.REACT_APP_API_URL}/remove_keyword`,
        {
          user_id: currentUser.id, // Replace with the appropriate user ID
          keyword_id: keywordId,
        },
        { withCredentials: true }
      );
      await fetchKeywords(); // Refresh the keyword list after removing a keyword
      addAlert({
        message: "Watch removed.",
        type: "success",
      });
    } catch (error) {
      console.error("Error:", error);
    }
  });

  useEffect(() => {
    if (currentUser) {
      fetchKeywords();
      fetchAutowatchSession();
    }
  }, []);

  useEffect(() => {
    let interval;

    if (startAnimation) {
      interval = setInterval(() => {
        fetchKeywords();
        fetchAutowatchSession();
      }, 24000);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [startAnimation]); // Only depend on startAnimation

  const handleEmptySlotClick = () => {
    if (inputRef.current) {
      inputRef.current.focus();
      setShowTooltip(true); // Show tooltip
      setTimeout(() => {
        setShowTooltip(false); // Hide tooltip after 3 seconds
      }, 3000);
    }
  };

  const bubbles = useMemo(
    () =>
      Array.from({ length: 14 }).map((_, i) => (
        <div
          key={i}
          className="bubble"
          style={{
            "--x-position": Math.random(), // Random horizontal position (0 to 1) to spread bubbles across
          }}
        ></div>
      )),
    []
  );

  // Updated JavaScript version (to match Python logic)
  const detectAPReference = (ref) => {
    // Check if the ref matches the AP format with 3 or more dots
    const apRegex = /^[\w]+\.[\w]+\.[\w]+\.[\w]+([-.][\w])?$/;
    return apRegex.test(ref);
  };

  const getCoreRef = (ref) => {
    const apRef = detectAPReference(ref);
    if (apRef) {
      return ref.split(".")[0];
    } else {
      return ref.split("-")[0];
    }
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Original logic
  const totalSlots = 6;
  let gap = 30;
  let slotWidth = 60;
  let scalingFactor = 1.3 * 0.9058;

  // Adjust based on windowWidth
  if (windowWidth < 570) {
    gap = 20;
    slotWidth = 50;
    scalingFactor = 0.9;
  } else if (windowWidth < 768) {
    gap = 20;
    slotWidth = 50;
    scalingFactor = 1.35;
  } else if (windowWidth < 1024) {
    gap = 25;
    slotWidth = 55;
    scalingFactor = 1.35;
  }

  const visibleSlots = watchList?.filter((watch) => !!watch)?.length || 0;
  const unitWidth = slotWidth + gap;
  const baseMargin = (totalSlots - visibleSlots) * unitWidth;
  const marginLeft = Math.round(baseMargin * scalingFactor);
  const handleToggleClick = () => {
    setIsActionButtonMinimized(!isActionButtonMinimized);

    // Remove any existing shake class first
    setShouldShakeButton(false);

    // Wait for minimize/expand transition to complete before shaking
    setTimeout(() => {
      setShouldShakeButton(true);
      // Remove shake class after animation completes
      setTimeout(() => setShouldShakeButton(false), 1200);
    }, 900);
  };

  return watchList ? (
    <div className="container">
      <DisclaimerModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={handleConfirmAction}
        action={modalAction}
        currentUser={currentUser}
      />
      <div className="action-buttons">
        {showWatchList && (
          <div
            className={`action-buttons-container ${
              isActionButtonMinimized ? "minimized-container" : ""
            } ${startAnimation ? "animated" : ""}`}
          >
            {watchList.length > 0 &&
              !startAnimation &&
              !isActionButtonMinimized && (
                <button className="start-btn" onClick={handleStartAutowatch}>
                  START
                </button>
              )}
            {watchList.length > 0 &&
              startAnimation &&
              !isActionButtonMinimized && (
                <button
                  className="stop-btn"
                  onClick={currentUser && handleStopAutowatch}
                >
                  STOP
                </button>
              )}
            <button
              className={`toggle-btn ${
                shouldShakeButton ? "toggle-btn-shake" : ""
              }`}
              onClick={handleToggleClick}
            >
              {isActionButtonMinimized ? "▲" : "▼"}
            </button>

            <button
              className={`toggle-btn-sessions ${
                shouldShakeButton ? "toggle-btn-shake" : ""
              }`}
              onClick={() => setShowSessionsModal(true)}
            >
              Sessions
            </button>

            {startAnimation && (
              <div className="matches-this-session">
                Session matches: {totalMatchesThisSession}
              </div>
            )}

            {startAnimation && (
              <div>
                <Countdown
                  endsAt={endTime}
                  totalMatchesThisSession={totalMatchesThisSession}
                />
              </div>
            )}

            {showSessionsModal && (
              <SessionsModal
                userId={currentUser.id}
                onClose={() => setShowSessionsModal(false)}
                fetchSessionMatches={fetchSessionMatches}
                openWhatsAppChat={openWhatsAppChat}
              />
            )}

            <div className="watchlist-content">
              {startAnimation && <div className="bubble-layer">{bubbles}</div>}

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                  marginBottom: "13px",
                }}
              >
                <h3
                  className="watchlist-title"
                  style={{ flex: "1", textAlign: "center" }}
                >
                  {startAnimation ? "AutoWatching..." : "Your Watchlist"}
                </h3>
              </div>

              <div
                className="watchlist-container"
                style={startAnimation ? { marginLeft: `${marginLeft}px` } : {}}
              >
                {[...Array(6)].map((_, index) => {
                  const watch = watchList[index];
                  const isAnimated = watch && animatedSlots.includes(watch.id);
                  const isEmpty = !watch && startAnimation;
                  const isLastTwo = index >= 4;

                  let slotType = "None";
                  if (isLastTwo) {
                    slotType = "locked-slot";
                  } else {
                    slotType = "watch-slot";
                  }

                  return (
                    <div
                      key={watch ? watch.id : `empty-${index}`}
                      className={`${slotType} ${
                        isAnimated ? "animate-update" : ""
                      } ${watch && startAnimation ? "group3" : ""} ${
                        isEmpty ? "hidden" : ""
                      } ${watch && "filled-slot"}`}
                      style={{ position: "relative", cursor: "pointer" }}
                      onClick={() => {
                        if (watch.total_matches_not_viewed !== 0) {
                          showMatchesOnClick(watch.id);
                        }
                      }}
                    >
                      {watch ? (
                        <WatchlistCarousel
                          getLatestKeyword={getLatestKeyword}
                          variations={watch.watch.variations}
                          coreRef={getCoreRef(watch.watch.ref)}
                          keywordId={watch.id}
                          total_matches_not_viewed={
                            watch.total_matches_not_viewed
                          }
                          handleRemove={removeKeyword}
                          startAnimation={startAnimation}
                          fetchUniqueColors={fetchUniqueColors}
                          setSelectedWatchId={setSelectedWatchId}
                          setShowFilterModalLayout={setShowFilterModalLayout}
                          setExistingFilters={setExistingFilters}
                          showMatchesOnClick={showMatchesOnClick}
                        />
                      ) : (
                        <div
                          onClick={handleEmptySlotClick}
                          className="empty-slot"
                          style={{ cursor: "pointer" }}
                        >
                          +
                        </div>
                      )}
                      {isLastTwo && (
                        <>
                          {/* Two crossing chains - simple version that worked */}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            style={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              width: "100%",
                              height: "100%",
                            }}
                            viewBox={`0 0 ${slotWidth} ${slotWidth}`}
                          >
                            {/* First chain - bottom left to top right */}
                            <path
                              d={`M5,${slotWidth - 5} L${slotWidth - 5},5`}
                              stroke="#666666"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeDasharray="4 3"
                            />
                            {/* Second chain - top left to bottom right */}
                            <path
                              d={`M5,5 L${slotWidth - 5},${slotWidth - 5}`}
                              stroke="#666666"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeDasharray="4 3"
                            />
                          </svg>
                          {/* Lock icon */}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={slotWidth / 2}
                            height={slotWidth / 2}
                            style={{
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                              zIndex: 1,
                            }}
                            viewBox="0 0 24 24"
                            fill="#666666"
                          >
                            <path d="M12 17a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z" />
                            <path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2ZM9 6c0-1.66 1.34-3 3-3s3 1.34 3 3v2H9V6Z" />
                          </svg>
                        </>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </div>
      <Outlet /> {/* This renders the current route's component */}
      {showMatchesModal && (
        <div
          className="modal-container"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(0,0,0,0.5)",
            zIndex: 9999,
          }}
        >
          <div
            className="modal"
            style={{
              display: "flex",
              flexDirection: "column",
              backgroundColor: "#4f4f4f",
              borderRadius: "25px",
              maxWidth: "900px",
              width: "80%",
              maxHeight: "80vh",
              overflow: "hidden",
              position: "relative",
            }}
          >
            {/* Scrollable content area */}
            <div
              className="modal-content"
              style={{
                flex: 1,
                overflowY: "auto",
                padding: "20px",
                color: "#fff",
              }}
            >
              {loading ? (
                <div className="loader-container">
                  <div className="loader-text-extra">Fetching Matches</div>
                  <div className="loader"></div>
                </div>
              ) : (
                <>
                  {/* HEADER */}
                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "20px",
                    }}
                  >
                    <h2 className="modal-header" style={{ margin: "0 auto" }}>
                      Matches
                    </h2>
                  </div>

                  {/* MATCHES GRID */}
                  <div
                    className="matches-grid"
                    style={{
                      display: "grid",
                      // This ensures a responsive layout that wraps cards
                      // from min 220px up to fill space:
                      gridTemplateColumns:
                        "repeat(auto-fill, minmax(220px, 1fr))",
                      gap: "20px",
                      margin: "auto",
                      width: "60%",
                    }}
                  >
                    {matchesInfo[insideOfThisWatchMatches]?.matches
                      .sort((a, b) => new Date(b.time) - new Date(a.time))
                      .map((match) => {
                        const isChecked = matchesInfo[
                          insideOfThisWatchMatches
                        ].matchesIds.includes(match.id);
                        const lead = match.watch_lead;

                        return (
                          <div
                            key={match.id}
                            className="match-card"
                            style={{
                              position: "relative",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              backgroundColor: "rgba(0, 0, 0, 0.2)",
                              padding: "1em",
                              borderRadius: "25px",
                              boxShadow: "0 1px 3px rgba(0, 0, 0, 0.15)",
                              transition: "transform 0.2s",
                              color: "#fff",
                              border: "1px solid rgba(255, 215, 0, 0.3)",
                            }}
                          >
                            {/* SEGMENT TEXT */}
                            <div
                              style={{
                                fontSize: "1.35em",
                                textAlign: "center",
                                padding: "0.5em",
                                backgroundColor: "rgba(0, 0, 0, 0.3)",
                                borderRadius: "8px",
                                width: "100%",
                                marginBottom: "0.75em",
                              }}
                            >
                              {lead.segment_text}
                            </div>

                            {/* LeadItem */}
                            <LeadItem
                              key={lead.id}
                              lead={lead}
                              handleIncorrectDetection={
                                handleIncorrectDetection
                              }
                            />

                            {/* "View Source" ICONS ROW (WhatsApp + Messenger) */}
                            <div
                              style={{
                                display: "flex",
                                gap: "8px",
                                marginTop: "4px",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                                flexWrap: "wrap",
                              }}
                              onClick={() =>
                                openWhatsAppChat(
                                  match.contact_phone_number ||
                                    match.phone_number
                                )
                              }
                            >
                              <span
                                style={{ fontSize: "0.85em", opacity: 0.7 }}
                              >
                                View Source:
                              </span>

                              {/* WhatsApp Icon */}
                              <svg
                                width="12"
                                height="12"
                                viewBox="0 0 24 24"
                                style={{ opacity: true ? 1 : 0.3 }}
                              >
                                <path
                                  fill={true ? "#25D366" : "#808080"}
                                  d="M17.472 14.382c-.297-.149-1.758-.867-2.03-.967-.273-.099-.471-.148-.67.15-.197.297-.767.966-.94 1.164-.173.199-.347.223-.644.075-.297-.15-1.255-.463-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.298-.347.446-.52.149-.174.198-.298.298-.497.099-.198.05-.371-.025-.52-.075-.149-.669-1.612-.916-2.207-.242-.579-.487-.5-.669-.51-.173-.008-.371-.01-.57-.01-.198 0-.52.074-.792.372-.272.297-1.04 1.016-1.04 2.479 0 1.462 1.065 2.875 1.213 3.074.149.198 2.096 3.2 5.077 4.487.709.306 1.262.489 1.694.625.712.227 1.36.195 1.871.118.571-.085 1.758-.719 2.006-1.413.248-.694.248-1.289.173-1.413-.074-.124-.272-.198-.57-.347m-5.421 7.403h-.004a9.87 9.87 0 01-5.031-1.378l-.361-.214-3.741.982.998-3.648-.235-.374a9.86 9.86 0 01-1.51-5.26c.001-5.45 4.436-9.884 9.888-9.884 2.64 0 5.122 1.03 6.988 2.898a9.825 9.825 0 012.893 6.994c-.003 5.45-4.437 9.884-9.885 9.884m8.413-18.297A11.815 11.815 0 0012.05 0C5.495 0 .16 5.335.157 11.892c0 2.096.547 4.142 1.588 5.945L.057 24l6.305-1.654a11.882 11.882 0 005.683 1.448h.005c6.554 0 11.89-5.335 11.893-11.893a11.821 11.821 0 00-3.48-8.413"
                                />
                              </svg>

                              {/* Messenger Icon */}
                              <svg
                                width="12"
                                height="12"
                                viewBox="0 0 24 24"
                                style={{
                                  opacity:
                                    lead.source === "messenger" ? 1 : 0.3,
                                }}
                              >
                                <path
                                  fill={
                                    lead.source === "messenger"
                                      ? "#00B2FF"
                                      : "#808080"
                                  }
                                  d="M12 2C6.477 2 2 6.145 2 11.243c0 2.966 1.475 5.611 3.778 7.322V22l3.455-1.9c.88.246 1.82.378 2.777.378 5.523 0 10-4.145 10-9.243C22 6.146 17.523 2 12 2zm1.093 12.408l-2.586-2.77-5.046 2.77 5.543-5.89 2.648 2.768 4.971-2.768-5.53 5.89z"
                                />
                              </svg>
                            </div>

                            {/* Contact Info / "Viewed" / Reposts */}
                            <div
                              style={{
                                marginTop: "0.75em",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                gap: "10px",
                                width: "100%",
                              }}
                            >
                              <button
                                onClick={() =>
                                  openWhatsAppChat(
                                    match.contact_phone_number ||
                                      match.phone_number
                                  )
                                }
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "10px",
                                  background: "none",
                                  border: "none",
                                  cursor: "pointer",
                                  color: "#fff",
                                  padding: "0.5em",
                                  borderRadius: "8px",
                                  transition: "background-color 0.2s",
                                  width: "100%",
                                  justifyContent: "center",
                                }}
                              >
                                <img
                                  src={
                                    match.profile_image_url ||
                                    `https://images.autowatch.io/images/screenshots/${match.contact_phone_number}.png`
                                  }
                                  // alt={match.contact_phone_number}
                                  style={{
                                    width: "30px",
                                    height: "30px",
                                    borderRadius: "50%",
                                    objectFit: "cover",
                                  }}
                                  onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.style.display = "none";
                                  }}
                                />
                                <span>
                                  {match.phone_number ||
                                    match.contact_phone_number ||
                                    match.contact_name ||
                                    match.message_sender}
                                </span>
                              </button>

                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "10px",
                                }}
                              >
                                <span style={{ fontSize: "0.8em" }}>
                                  Viewed
                                </span>
                                <input
                                  type="checkbox"
                                  checked={isChecked}
                                  onChange={() => {
                                    setMatchesInfo((prevInfo) => {
                                      const updatedIds = isChecked
                                        ? prevInfo[
                                            insideOfThisWatchMatches
                                          ].matchesIds.filter(
                                            (id) => id !== match.id
                                          )
                                        : [
                                            ...prevInfo[
                                              insideOfThisWatchMatches
                                            ].matchesIds,
                                            match.id,
                                          ];
                                      accumulatedMatchesIdsRef.current[
                                        insideOfThisWatchMatches
                                      ] = updatedIds;
                                      return {
                                        ...prevInfo,
                                        [insideOfThisWatchMatches]: {
                                          ...prevInfo[insideOfThisWatchMatches],
                                          matchesIds: updatedIds,
                                        },
                                      };
                                    });
                                  }}
                                  style={{ transform: "scale(1.5)" }}
                                />
                              </div>

                              <button
                                onClick={() => checkReposts(match)}
                                style={{
                                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                                  border: "none",
                                  padding: "0.5em 1em",
                                  borderRadius: "8px",
                                  color: "white",
                                  cursor: "pointer",
                                }}
                              >
                                Check Reposts
                              </button>

                              {match.postCount !== undefined && (
                                <div
                                  style={{ fontSize: "0.8em", color: "#ccc" }}
                                >
                                  Posted {match.postCount} times in the last 3
                                  days
                                </div>
                              )}

                              {/* Time Ago */}
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  width: "100%",
                                  fontSize: "0.8em",
                                  color: "#ccc",
                                }}
                              >
                                <span>{timeAgo(match.time)}</span>
                              </div>
                            </div>

                            {/* Polaroid Image */}
                            {lead.lead_image_url && (
                              <div
                                style={{
                                  marginTop: "1em",
                                  backgroundColor: "#fff",
                                  border: "1px solid #ccc",
                                  padding: "10px",
                                  borderRadius: "5px",
                                  width: "90px",
                                  textAlign: "center",
                                  boxShadow: "0 2px 6px rgba(0,0,0,0.2)",
                                }}
                              >
                                <img
                                  style={{
                                    width: "100%",
                                    borderRadius: "3px",
                                    objectFit: "cover",
                                    cursor: "pointer",
                                  }}
                                  src={lead.lead_image_url}
                                  alt="Lead"
                                  onClick={() =>
                                    openMatchLeadImageModal(lead.lead_image_url)
                                  }
                                  onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.style.display = "none";
                                  }}
                                />
                              </div>
                            )}

                            {/* HighlightedMessage */}
                            <div style={{ marginTop: "1em", width: "100%" }}>
                              <HighlightedMessage
                                message={lead.message}
                                segmentText={lead.segment_text}
                              />
                            </div>

                            {/* TAGS row */}
                            <div
                              style={{
                                display: "flex",
                                flexWrap: "wrap",
                                justifyContent: "center",
                                gap: "5px",
                                marginTop: "1em",
                                width: "100%",
                              }}
                            >
                              {lead.year && (
                                <div
                                  style={{
                                    backgroundColor: "rgba(212, 160, 23, 0.85)",
                                    color: "#000000",
                                    padding: "3px 8px",
                                    borderRadius: "12px",
                                    boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
                                    textTransform: "uppercase",
                                    fontSize: "0.5em",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  {lead.year}
                                </div>
                              )}
                              {lead.condition && (
                                <div
                                  style={{
                                    backgroundColor: "rgba(0, 127, 127, 0.85)",
                                    color: "#ffffff",
                                    padding: "3px 8px",
                                    borderRadius: "12px",
                                    boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
                                    textTransform: "uppercase",
                                    fontSize: "0.5em",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  {lead.condition}
                                </div>
                              )}
                              {lead.material && (
                                <div
                                  style={{
                                    backgroundColor: "rgba(33, 124, 95, 0.85)",
                                    color: "#ffffff",
                                    padding: "3px 8px",
                                    borderRadius: "12px",
                                    boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
                                    textTransform: "uppercase",
                                    fontSize: "0.5em",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  {lead.material}
                                </div>
                              )}
                              {lead.color && (
                                <div
                                  style={{
                                    backgroundColor:
                                      lead.color.toLowerCase() === "gold"
                                        ? "rgba(212, 160, 23, 0.85)"
                                        : "rgba(123, 32, 42, 0.85)",
                                    color: "#ffffff",
                                    padding: "3px 8px",
                                    borderRadius: "12px",
                                    boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
                                    textTransform: "uppercase",
                                    fontSize: "0.5em",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  {lead.color}
                                </div>
                              )}
                              {lead.box_paper_tag && (
                                <div
                                  style={{
                                    backgroundColor: "rgba(29, 51, 56, 0.9)",
                                    color: "#ffffff",
                                    padding: "3px 8px",
                                    borderRadius: "12px",
                                    boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
                                    textTransform: "uppercase",
                                    fontSize: "0.5em",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  {lead.box_paper_tag}
                                </div>
                              )}
                              {lead.special_features &&
                                lead.special_features
                                  .split(",")
                                  .map((feature, i) => (
                                    <div
                                      key={i}
                                      style={{
                                        backgroundColor:
                                          "rgba(0, 107, 107, 0.85)",
                                        color: "#ffffff",
                                        padding: "3px 8px",
                                        borderRadius: "12px",
                                        fontSize: "0.5em",
                                        boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
                                        textTransform: "uppercase",
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      {feature.trim()}
                                    </div>
                                  ))}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </>
              )}
            </div>

            {/* FOOTER BAR (always visible) */}
            <div
              style={{
                backgroundColor: "#6f6f6f",
                padding: "10px",
                borderRadius: "0 0 25px 25px",
              }}
            >
              {/* Always show, even if loading; just disable buttons if so */}
              <div
                style={{
                  marginBottom: "20px",
                  display: "flex",
                  gap: "20px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <button
                  onClick={() =>
                    fetchMatches(
                      matchesInfo[insideOfThisWatchMatches].currentPage - 1,
                      insideOfThisWatchMatches
                    )
                  }
                  disabled={
                    loading ||
                    matchesInfo[insideOfThisWatchMatches]?.currentPage === 1
                  }
                  style={{
                    backgroundColor: "rgba(0,0,0,0.15)",
                    border: "none",
                    padding: "0.5em 1em",
                    borderRadius: "8px",
                    color: loading ? "#aaa" : "#fff",
                    cursor: loading ? "not-allowed" : "pointer",
                  }}
                >
                  Previous
                </button>

                <span style={{ fontWeight: "bold" }}>
                  Page {matchesInfo[insideOfThisWatchMatches]?.currentPage || 1}{" "}
                  of {matchesInfo[insideOfThisWatchMatches]?.totalPages || 0}
                </span>

                <button
                  onClick={() =>
                    fetchMatches(
                      matchesInfo[insideOfThisWatchMatches].currentPage + 1,
                      insideOfThisWatchMatches
                    )
                  }
                  disabled={
                    loading ||
                    matchesInfo[insideOfThisWatchMatches]?.currentPage ===
                      matchesInfo[insideOfThisWatchMatches]?.totalPages
                  }
                  style={{
                    backgroundColor: "rgba(0,0,0,0.15)",
                    border: "none",
                    padding: "0.5em 1em",
                    borderRadius: "8px",
                    color: loading ? "#aaa" : "#fff",
                    cursor: loading ? "not-allowed" : "pointer",
                  }}
                >
                  Next
                </button>
              </div>

              <div
                style={{
                  marginBottom: "10px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <button
                  onClick={() =>
                    setMatchesInfo((prevInfo) => {
                      const currentMatches =
                        prevInfo[insideOfThisWatchMatches].matches || [];
                      const currentChecked =
                        prevInfo[insideOfThisWatchMatches].matchesIds;
                      const allChecked = currentMatches.every((match) =>
                        currentChecked.includes(match.id)
                      );
                      const updatedIds = allChecked
                        ? [] // Uncheck all if they're all checked.
                        : currentMatches.map((match) => match.id); // Otherwise, check all.
                      // Update the ref as well.
                      accumulatedMatchesIdsRef.current[
                        insideOfThisWatchMatches
                      ] = updatedIds;
                      return {
                        ...prevInfo,
                        [insideOfThisWatchMatches]: {
                          ...prevInfo[insideOfThisWatchMatches],
                          matchesIds: updatedIds,
                        },
                      };
                    })
                  }
                  style={{
                    backgroundColor: "rgba(0,0,0,0.15)",
                    border: "none",
                    padding: "0.5em 1em",
                    borderRadius: "8px",
                    color: "#fff",
                    cursor: "pointer",
                  }}
                >
                  Unview All
                </button>
              </div>

              <button
                className="modal-close-btn"
                onClick={() => {
                  setShowMatchesModal(false);
                  toggleBodyScroll(false);
                  markMatchesAsViewed(insideOfThisWatchMatches);
                }}
                disabled={loading}
                style={{
                  backgroundColor: loading
                    ? "rgba(0,0,0,0.2)"
                    : "rgba(0,0,0,0.15)",
                  border: "none",
                  padding: "0.5em 1em",
                  borderRadius: "8px",
                  color: loading ? "#aaa" : "#fff",
                  cursor: loading ? "not-allowed" : "pointer",
                  display: "block",
                  margin: "0 auto",
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
      {showMatchLeadImageModal && (
        <div
          className="lead-image-modal-backdrop"
          onClick={closeMatchLeadImageModal}
        >
          <div
            className="lead-image-modal-content"
            onClick={(e) => e.stopPropagation()}
          >
            <img
              src={matchModalImageUrl}
              alt="Full-size Lead Image"
              className="lead-image-modal-image"
            />
            <button
              className="lead-image-close-modal-btn"
              onClick={closeMatchLeadImageModal}
            >
              Close
            </button>
          </div>
        </div>
      )}
      {showFilterModalLayout && (
        <WatchlistFilterModalLayout
          showFilterModalLayout={showFilterModalLayout}
          setShowFilterModalLayout={setShowFilterModalLayout}
          currentUser={currentUser}
          selectedWatchId={selectedWatchId}
          isBuy={isBuy}
          fetchKeywords={fetchKeywords}
          addAlert={addAlert}
          existingFilters={existingFilters}
        />
      )}
    </div>
  ) : (
    <>
      {currentUser ? (
        <div>
          <div className="loader-container">
            <div className="loader"></div>
            <div className="loader-text" style={{ fontSize: "24px" }}>
              Revving AutoWatch Engine...
            </div>
          </div>
        </div>
      ) : (
        <Navigate to="/" replace /> // used to be /login but refactored flow of site - login now on homepage
      )}
    </>
  );
};

const SpinningClock = () => {
  const watchFaceRef = useRef(null);
  const hourHandRef = useRef(null);
  const minuteHandRef = useRef(null);
  const radarScanRef = useRef(null);

  useEffect(() => {
    const watchFace = watchFaceRef.current;
    const hourHand = hourHandRef.current;
    const minuteHand = minuteHandRef.current;
    const radarScan = radarScanRef.current;

    let hourDegree = 0;
    let minuteDegree = 0;

    // Object pools for dots
    const greenDotPool = [];
    const goldDotPool = [];
    const redDotPool = [];
    const greyDotPool = [];

    const spinClock = () => {
      hourDegree += 0.07;
      minuteDegree += 1.05;

      // Batch style updates
      hourHand.style.transform = `rotate(${hourDegree}deg)`;
      minuteHand.style.transform = `rotate(${minuteDegree}deg)`;
      radarScan.style.transform = `rotate(${minuteDegree}deg)`;

      // Adjusted probabilities to reduce dot creation frequency
      if (Math.random() < 0.1) {
        createGreenDot(minuteDegree - 80);
      }
      if (Math.random() < 0.004) {
        createGoldDot(minuteDegree - 80);
      }
      if (Math.random() < 0.05) {
        createRedDot(minuteDegree - 80);
      }
      if (Math.random() < 0.1) {
        createGreyDot(minuteDegree - 80);
      }

      requestAnimationFrame(spinClock);
    };

    // Function to create or reuse green dots
    const createGreenDot = (angle) => {
      let dataPoint;
      if (greenDotPool.length > 0) {
        dataPoint = greenDotPool.pop();
      } else {
        dataPoint = document.createElement("div");
        dataPoint.className = "data-point green";
      }

      const radius = 20 + Math.random() * 55;
      const x = 75 + radius * Math.cos((angle * Math.PI) / 180);
      const y = 75 + radius * Math.sin((angle * Math.PI) / 180);

      dataPoint.style.left = `${x}px`;
      dataPoint.style.top = `${y}px`;
      dataPoint.style.transform = "scale(0.5)";
      dataPoint.style.opacity = "0";
      dataPoint.style.setProperty("--tx", `${75 - x}px`);
      dataPoint.style.setProperty("--ty", `${75 - y}px`);

      watchFace.appendChild(dataPoint);

      const convergeDelay = 1.3 + Math.random() * 1.3; // Random delay for converge animation

      dataPoint.style.animation = `popIn 0.6s ease-out forwards, converge 1.5s forwards ${convergeDelay}s`;

      const animationEndHandler = (event) => {
        if (event.animationName === "converge") {
          if (watchFace.contains(dataPoint)) {
            watchFace.removeChild(dataPoint);
            greenDotPool.push(dataPoint); // Return to pool
            dataPoint.removeEventListener("animationend", animationEndHandler);
          }
        }
      };

      dataPoint.addEventListener("animationend", animationEndHandler);
    };

    // Function to create or reuse gold dots
    const createGoldDot = (angle) => {
      let dataPoint;
      if (goldDotPool.length > 0) {
        dataPoint = goldDotPool.pop();
      } else {
        dataPoint = document.createElement("div");
        dataPoint.className = "data-point gold";
      }

      const radius = 20 + Math.random() * 55;
      const x = 75 + radius * Math.cos((angle * Math.PI) / 180);
      const y = 75 + radius * Math.sin((angle * Math.PI) / 180);

      dataPoint.style.left = `${x}px`;
      dataPoint.style.top = `${y}px`;
      dataPoint.style.transform = "scale(0.5)";
      dataPoint.style.opacity = "0";
      dataPoint.style.setProperty("--tx", `${75 - x}px`);
      dataPoint.style.setProperty("--ty", `${75 - y}px`);

      watchFace.appendChild(dataPoint);

      const convergeDelay = 1.3 + Math.random() * 1.3;

      dataPoint.style.animation = `popIn 0.6s ease-out forwards, converge 1.5s forwards ${convergeDelay}s`;

      const animationEndHandler = (event) => {
        if (event.animationName === "converge") {
          if (watchFace.contains(dataPoint)) {
            watchFace.removeChild(dataPoint);
            goldDotPool.push(dataPoint);
            dataPoint.removeEventListener("animationend", animationEndHandler);
          }
        }
      };

      dataPoint.addEventListener("animationend", animationEndHandler);
    };

    // Function to create or reuse red dots
    const createRedDot = (angle) => {
      let dataPoint;
      if (redDotPool.length > 0) {
        dataPoint = redDotPool.pop();
      } else {
        dataPoint = document.createElement("div");
        dataPoint.className = "data-point red";
      }

      const radius = 20 + Math.random() * 55;
      const x = 75 + radius * Math.cos((angle * Math.PI) / 180);
      const y = 75 + radius * Math.sin((angle * Math.PI) / 180);

      dataPoint.style.left = `${x}px`;
      dataPoint.style.top = `${y}px`;
      dataPoint.style.transform = "scale(0.5)";
      dataPoint.style.opacity = "0";
      dataPoint.style.setProperty("--tx", `${(x - 75) * 1.2}px`);
      dataPoint.style.setProperty("--ty", `${(y - 75) * 1.2}px`);

      watchFace.appendChild(dataPoint);

      const disperseDelay = 0.65 + Math.random() * 0.65;

      dataPoint.style.animation = `popIn 0.6s ease-out forwards, disperse 2s forwards ${disperseDelay}s`;

      const animationEndHandler = (event) => {
        if (event.animationName === "disperse") {
          if (watchFace.contains(dataPoint)) {
            watchFace.removeChild(dataPoint);
            redDotPool.push(dataPoint);
            dataPoint.removeEventListener("animationend", animationEndHandler);
          }
        }
      };

      dataPoint.addEventListener("animationend", animationEndHandler);
    };

    // Function to create or reuse grey dots
    const createGreyDot = (angle) => {
      let dataPoint;
      if (greyDotPool.length > 0) {
        dataPoint = greyDotPool.pop();
      } else {
        dataPoint = document.createElement("div");
        dataPoint.className = "data-point grey";
      }

      const radius = 20 + Math.random() * 55;
      const x = 75 + radius * Math.cos((angle * Math.PI) / 180);
      const y = 75 + radius * Math.sin((angle * Math.PI) / 180);

      dataPoint.style.left = `${x}px`;
      dataPoint.style.top = `${y}px`;
      dataPoint.style.transform = "scale(0.5)";
      dataPoint.style.opacity = "0";
      dataPoint.style.setProperty("--tx", `${(x - 75) * 0.7}px`);
      dataPoint.style.setProperty("--ty", `${(y - 75) * 0.7}px`);

      watchFace.appendChild(dataPoint);

      const fadeDelay = 0.65 + Math.random() * 0.65;

      dataPoint.style.animation = `popIn 0.6s ease-out forwards, fade 1.8s forwards ${fadeDelay}s`;

      const animationEndHandler = (event) => {
        if (event.animationName === "fade") {
          if (watchFace.contains(dataPoint)) {
            watchFace.removeChild(dataPoint);
            greyDotPool.push(dataPoint);
            dataPoint.removeEventListener("animationend", animationEndHandler);
          }
        }
      };

      dataPoint.addEventListener("animationend", animationEndHandler);
    };

    spinClock();
  }, []);

  return (
    <div className="watch-face" ref={watchFaceRef}>
      <div className="grid-wrapper">
        <div className="grid-overlay">
          <div className="cross"></div>
          <div className="circle"></div>
        </div>
      </div>
      <div className="hand hour-hand" ref={hourHandRef}></div>
      <div className="hand minute-hand" ref={minuteHandRef}></div>
      <div className="center-dot"></div>
      <div className="radar-scan" ref={radarScanRef}></div>
      <style jsx>{`
        .watch-face {
          width: 150px;
          height: 150px;
          border: 2px solid #ffd700;
          border-radius: 50%;
          position: relative;
          margin: auto;
          box-shadow: 0 0 10px rgba(255, 215, 0, 0.5);
          overflow: visible; /* Allow dots to overflow */
        }

        .grid-wrapper {
          width: 100%;
          height: 100%;
          overflow: hidden; /* Confine the grid to the watch face */
          border-radius: 50%; /* Ensure the grid stays within the circular bounds */
          position: absolute;
          z-index: 0;
        }

        .grid-overlay {
          position: absolute;
          width: 100%;
          height: 100%;
        }

        .cross {
          position: absolute;
          width: 100%;
          height: 100%;
          background: linear-gradient(
              to right,
              transparent 49.5%,
              rgba(255, 215, 0, 0.1) 50%,
              transparent 50.5%
            ),
            linear-gradient(
              to bottom,
              transparent 49.5%,
              rgba(255, 215, 0, 0.1) 50%,
              transparent 50.5%
            );
          z-index: 1;
        }

        .circle {
          position: absolute;
          width: 100%;
          height: 100%;
          background: radial-gradient(
            circle,
            rgba(255, 215, 0, 0.1) 1px,
            transparent 1px
          );
          background-size: 30px 30px; /* Adjust size for larger or smaller circles */
          z-index: 1;
        }

        .hand {
          position: absolute;
          background-color: #ffd700;
          transform-origin: bottom center;
          transform: rotate(0deg);
          will-change: transform;
        }

        .hour-hand {
          width: 3px;
          height: 37.5px;
          top: 37.5px;
          left: 73.5px;
        }

        .minute-hand {
          width: 2px;
          height: 52.5px;
          top: 22.5px;
          left: 74px;
        }

        .data-point {
          position: absolute;
          width: 2px;
          height: 2px;
          border-radius: 50%;
          opacity: 0;
          transition: all 0.5s ease-in-out;
          will-change: transform, opacity;
        }

        .data-point.gold {
          width: 3.4px;
          height: 3.4px;
          transition: all 0.2s ease-in-out;
        }

        .data-point.green {
          background-color: #4caf50;
        }

        .data-point.gold {
          background-color: #ffd700;
        }

        .data-point.red {
          background-color: #ff0000;
        }

        .data-point.grey {
          background-color: #888888;
        }

        .center-dot {
          position: absolute;
          width: 6px;
          height: 6px;
          background-color: #ffd700;
          border-radius: 50%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          box-shadow: 0 0 5px #ffd700;
        }

        .radar-scan {
          width: 100%;
          height: 100%;
          background: conic-gradient(
            from 0deg,
            rgba(255, 215, 0, 0.2),
            transparent
          );
          position: absolute;
          border-radius: 50%;
          z-index: 1;
          pointer-events: none;
          will-change: transform;
        }

        @keyframes converge {
          0% {
            transform: scale(1) translate(0, 0);
            opacity: 1;
          }
          100% {
            transform: scale(0) translate(var(--tx), var(--ty));
            opacity: 0;
          }
        }

        @keyframes disperse {
          0% {
            transform: scale(1) translate(0, 0);
            opacity: 1;
          }
          100% {
            transform: scale(1.5)
              translate(calc(var(--tx) / 1.5), calc(var(--ty) / 1.5));
            opacity: 0;
          }
        }

        @keyframes fade {
          0% {
            transform: scale(1) translate(0, 0);
            opacity: 1;
          }
          100% {
            transform: scale(1) translate(var(--tx), var(--ty));
            opacity: 0;
          }
        }

        @keyframes popIn {
          0% {
            transform: scale(0.5);
            opacity: 0;
          }
          50% {
            transform: scale(1.2);
            opacity: 1;
          }
          100% {
            transform: scale(1);
            opacity: 1;
          }
        }
      `}</style>
    </div>
  );
};

const AutoWatchIceberg = () => {
  const svgRef = useRef(null);

  useEffect(() => {
    const svg = svgRef.current;
    if (!svg) return;

    // Create stars
    const starsGroup = svg.getElementById("stars");
    for (let i = 0; i < 30; i++) {
      const star = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "circle"
      );
      star.setAttribute("cx", Math.random() * 800);
      star.setAttribute("cy", Math.random() * 300);
      star.setAttribute("r", Math.random() * 1.5 + 0.5);
      star.setAttribute("fill", "#e6f1ff");
      star.setAttribute("class", "star");
      starsGroup.appendChild(star);

      const animate = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "animate"
      );
      animate.setAttribute("attributeName", "opacity");
      animate.setAttribute(
        "values",
        `${Math.random() * 0.5 + 0.5};0.1;${Math.random() * 0.5 + 0.5}`
      );
      animate.setAttribute("dur", `${Math.random() * 3 + 2}s`);
      animate.setAttribute("repeatCount", "indefinite");
      star.appendChild(animate);
    }

    // Create underwater particles (bubbles)
    const particlesGroup = svg.getElementById("particles");
    for (let i = 0; i < 15; i++) {
      const particle = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "circle"
      );
      particle.setAttribute("cx", Math.random() * 800);
      particle.setAttribute("cy", Math.random() * 300 + 300);
      particle.setAttribute("r", Math.random() * 2 + 1);
      particle.setAttribute("fill", "#9ec5e6"); // Updated to water-like blue
      particle.setAttribute("opacity", "0.5"); // Add some transparency to bubbles
      particle.setAttribute("class", "particle");
      particlesGroup.appendChild(particle);

      const animate = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "animate"
      );
      animate.setAttribute("attributeName", "cy");
      animate.setAttribute(
        "values",
        `${particle.getAttribute("cy")};${
          parseInt(particle.getAttribute("cy")) - 50
        };${particle.getAttribute("cy")}`
      );
      animate.setAttribute("dur", `${Math.random() * 10 + 5}s`);
      animate.setAttribute("repeatCount", "indefinite");
      particle.appendChild(animate);
    }
  }, []);

  return (
    <>
      {" "}
      <div className="iceberg">
        <div
          style={{
            width: "100%",
            maxWidth: "800px",
            position: "relative",
            overflow: "hidden",
            borderRadius: "27%",
          }}
        >
          <svg
            ref={svgRef}
            viewBox="0 0 800 600"
            xmlns="http://www.w3.org/2000/svg"
            style={{ width: "100%", height: "auto" }}
          >
            <defs>
              <radialGradient
                id="moonGlow"
                cx="50%"
                cy="50%"
                r="50%"
                fx="50%"
                fy="50%"
              >
                <stop
                  offset="0%"
                  style={{
                    stopColor: "#cccccc",
                    stopOpacity: 0.8,
                  }}
                />{" "}
                {/* Softer moon color */}
                <stop
                  offset="100%"
                  style={{ stopColor: "#cccccc", stopOpacity: 0 }}
                />
              </radialGradient>
              <filter id="underwater-blur">
                <feGaussianBlur in="SourceGraphic" stdDeviation="5" />
              </filter>
              <linearGradient id="nightSky" x1="0%" y1="0%" x2="0%" y2="100%">
                <stop offset="0%" style={{ stopColor: "#0f181a" }} />
                <stop offset="100%" style={{ stopColor: "#0f181a" }} />
              </linearGradient>
              <linearGradient
                id="waterGradient"
                x1="0%"
                y1="0%"
                x2="0%"
                y2="100%"
              >
                <stop offset="0%" style={{ stopColor: "#2a4a73" }} />
                <stop offset="100%" style={{ stopColor: "#172a45" }} />
              </linearGradient>
              <linearGradient
                id="underwaterGradient"
                x1="0%"
                y1="0%"
                x2="100%"
                y2="100%"
              >
                <stop offset="0%" style={{ stopColor: "#3a5a83" }} />
                <stop offset="100%" style={{ stopColor: "#2a4a73" }} />
              </linearGradient>
              <linearGradient
                id="icebergGradient"
                x1="0%"
                y1="0%"
                x2="100%"
                y2="100%"
              >
                <stop offset="0%" style={{ stopColor: "#7aa2d6" }} />
                <stop offset="100%" style={{ stopColor: "#4a6fa5" }} />
              </linearGradient>
            </defs>

            <rect width="800" height="600" fill="url(#nightSky)" />
            <g id="stars"></g>
            {/* Adjusted Moon and Reflection */}
            <circle cx="700" cy="100" r="60" fill="url(#moonGlow)" />
            <circle cx="700" cy="100" r="50" fill="#cccccc" opacity="0.6">
              {" "}
              {/* Softer moon appearance */}
              <animate
                attributeName="opacity"
                values="0.6;0.8;0.6"
                dur="5s"
                repeatCount="indefinite"
              />
            </circle>

            <path
              id="water"
              d="M0 260 Q200 240, 400 260 T800 260 V600 H0 Z"
              fill="url(#waterGradient)"
            >
              <animate
                attributeName="d"
                values="
            M0 260 Q200 240, 400 260 T800 260 V600 H0 Z;
            M0 260 Q200 280, 400 260 T800 260 V600 H0 Z;
            M0 260 Q200 240, 400 260 T800 260 V600 H0 Z
            "
                dur="10s"
                repeatCount="indefinite"
              />
            </path>

            <g id="iceberg">
              {/* Underwater part remains the same */}
              <path
                d="M200 260 L160 600 H640 L600 260 Z"
                fill="url(#underwaterGradient)"
                opacity="0.8"
                filter="url(#underwater-blur)"
              />
              <path
                d="M200 260 Q400 240, 600 260 L640 600 H160 L200 260"
                fill="#4a6fa5"
                opacity="0.9"
              />

              {/* Adjusted above-water part to be smaller */}
              <path
                d="M320 200 Q400 180, 480 200 L600 260 Q400 240, 200 260 L320 200"
                fill="url(#icebergGradient)"
              />
              <path
                d="M320 200 Q400 180, 480 200 L440 220 Q400 210, 360 220 L320 200"
                fill="#a8c5e6"
                opacity="0.5"
              />
            </g>

            {/* Updated bubbles with water-like appearance */}
            <ellipse
              cx="700"
              cy="320"
              rx="40"
              ry="20"
              fill="#9ec5e6"
              opacity="0.2"
              filter="url(#underwater-blur)"
            >
              {" "}
              {/* Water-like bubbles */}
              <animate
                attributeName="opacity"
                values="0.1;0.18;0.1"
                dur="5s"
                repeatCount="indefinite"
              />
            </ellipse>

            <text
              x="400"
              y="185"
              textAnchor="middle"
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                fill: "#e6f1ff",
                opacity: 1,
                transition: "opacity 0.5s ease, transform 0.5s ease",
              }}
              id="currentSourcesLabel"
            >
              Traditional Sources
            </text>
            <text
              x="400"
              y="560"
              textAnchor="middle"
              style={{
                fontSize: "22px",
                fontWeight: "bold",
                fill: "#e6f1ff",
                opacity: 1,
                transition: "opacity 0.5s ease, transform 0.5s ease",
              }}
              id="autoWatchLabel"
            >
              ChronoSpy Hidden Opportunities
            </text>

            <g id="particles"></g>
          </svg>
          <div id="glowEffect"></div>
        </div>
      </div>{" "}
      <div className="autowatch-depth-finder">
        <h2 style={{ marginTop: 0, color: "#A48C3D" }}>
          ChronoSpy Depth Finder
        </h2>{" "}
        {/* Updated with Rolex gold */}
        <p style={{ lineHeight: 1.6 }}>
          Dive deep into the luxury watch market with ChronoSpy. Our innovative
          platform reveals a vast network of hidden opportunities that
          traditional sources can't match.{" "}
          <span style={{ fontStyle: "italic" }}>
            Like an iceberg, the real bulk lies beneath the surface
          </span>
          .
        </p>
      </div>
    </>
  );
};

const LeadItem = ({ lead, handleIncorrectDetection }) => {
  const [showFeedback, setShowFeedback] = useState(false);

  return (
    <div
      className="price-lead-style"
      style={{
        "--bg-color": lead.usd_price
          ? "rgba(212, 160, 23, 0.15)"
          : "rgba(33, 124, 95, 0.15)",
        "--border-color": lead.usd_price ? "#d4a017" : "#217c5f",
        "--text-color": lead.usd_price ? "#d4a017" : "#217c5f",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "0.5em",
        borderRadius: "12px",
        textAlign: "center",
        position: "relative",
      }}
    >
      {lead.usd_price ? (
        <span style={{ fontSize: "1em", fontWeight: "bold" }}>
          ${lead.usd_price.toLocaleString()}
        </span>
      ) : lead.buy_intent === "confirmed_intent" ||
        lead.buy_intent === "possible_lead" ? (
        <div
          className="buyer-badge"
          style={{
            display: "flex",
            alignItems: "center",
            gap: "6px",
            padding: "2px 4px",
            borderRadius: "12px",
            fontSize: "0.85em",
            fontWeight: "bold",
            transition: "all 0.2s ease-in-out",
            backgroundColor: "rgba(37, 211, 102, 0.15)",
            color: "#25D366",
            cursor: "pointer",
            position: "relative",
          }}
        >
          🔍 Buyer
          {/* Small "More" (≡) button */}
          <button
            onClick={(e) => {
              e.stopPropagation();
              setShowFeedback(!showFeedback);
            }}
            style={{
              background: "transparent",
              border: "none",
              fontSize: "0.7em",
              cursor: "pointer",
              color: "#bbb",
              margin: "-10px",
              display: "flex",
              alignItems: "center",
            }}
          >
            ☰
          </button>
          {/* Tooltip-style feedback area */}
          {showFeedback && (
            <div
              style={{
                position: "absolute",
                top: "120%",
                left: "50%",
                transform: "translateX(-50%)",
                backgroundColor: "rgba(0, 0, 0, 0.9)",
                color: "white",
                padding: "8px",
                borderRadius: "8px",
                fontSize: "0.75em",
                whiteSpace: "nowrap",
                boxShadow: "0px 4px 8px rgba(0,0,0,0.2)",
                zIndex: 10,
                textAlign: "center",
              }}
            >
              Not a buyer?
              <button
                onClick={() => {
                  handleIncorrectDetection(lead.id);
                  setShowFeedback(false);
                }}
                style={{
                  display: "block",
                  marginTop: "5px",
                  background: "transparent",
                  border: "1px solid #E74C3C",
                  color: "#E74C3C",
                  padding: "4px 8px",
                  borderRadius: "6px",
                  fontSize: "0.75em",
                  cursor: "pointer",
                  transition: "all 0.2s ease-in-out",
                }}
              >
                Submit Feedback
              </button>
            </div>
          )}
        </div>
      ) : (
        <span
          style={{
            fontSize: "0.8em",
            fontWeight: "bold",
            color: "#bbb",
            fontStyle: "italic",
            backgroundColor: "rgba(128, 128, 128, 0.1)",
            padding: "4px 8px",
            borderRadius: "8px",
          }}
        >
          Unverified Lead
        </span>
      )}
    </div>
  );
};

const HighlightedMessage = ({ message, segmentText }) => {
  const [isVisible, setIsVisible] = useState(false);
  const highlightRef = useRef(null);
  const containerRef = useRef(null);
  const messageRef = useRef(null);

  const findSegmentPosition = () => {
    const messageLC = message.toLowerCase();
    const segmentLC = segmentText.toLowerCase();
    return messageLC.indexOf(segmentLC);
  };

  useEffect(() => {
    if (isVisible) {
      // Scroll to highlight
      setTimeout(() => {
        if (highlightRef.current && containerRef.current) {
          highlightRef.current.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "center",
          });
        }
      }, 100);

      // Add click listener for outside clicks
      const handleClickOutside = (event) => {
        if (messageRef.current && !messageRef.current.contains(event.target)) {
          setIsVisible(false);
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [isVisible]);

  const segmentStart = findSegmentPosition();
  const segmentEnd = segmentStart + segmentText.length;

  const messageContent =
    segmentStart === -1 ? (
      <span style={{ color: "#e0e0e0" }}>{message}</span>
    ) : (
      <>
        {message.substring(0, segmentStart)}
        <span
          ref={highlightRef}
          style={{
            backgroundColor: "rgba(255, 223, 0, 0.3)",
            padding: "2px 4px",
            borderRadius: "3px",
          }}
        >
          {message.substring(segmentStart, segmentEnd)}
        </span>
        {message.substring(segmentEnd)}
      </>
    );

  return (
    <>
      <button
        onClick={() => setIsVisible(true)}
        style={{
          backgroundColor: "transparent",
          border: "1px solid rgba(255, 255, 255, 0.2)",
          color: "#4a9eff",
          padding: "4px 12px",
          borderRadius: "12px",
          fontSize: "12px",
          cursor: "pointer",
          marginTop: "8px",
          transition: "all 0.2s ease",
        }}
        onMouseEnter={(e) =>
          (e.target.style.backgroundColor = "rgba(74, 158, 255, 0.1)")
        }
        onMouseLeave={(e) => (e.target.style.backgroundColor = "transparent")}
      >
        View Source
      </button>

      {isVisible && (
        <div
          ref={messageRef}
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            maxWidth: "600px",
            maxHeight: "30vh",
            backgroundColor: "#1a1a1a",
            border: "1px solid #333",
            borderRadius: "8px",
            padding: "20px",
            zIndex: 9999,
          }}
        >
          {/* Added header section */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              alignItems: "center",
              marginBottom: "15px",
              paddingBottom: "15px",
              borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
            }}
          >
            <span style={{ color: "#fff", fontSize: "14px" }}>
              Source Message
            </span>
            <button
              onClick={() => alert("place a feedback system here")}
              disabled
              style={{
                backgroundColor: "transparent",
                border: "1px solid rgba(255, 82, 82, 0.3)",
                color: "#ff5252",
                padding: "2px 6px",
                borderRadius: "12px",
                fontSize: "11px",
                cursor: "pointer",
                transition: "all 0.2s ease",
                marginTop: "5px",
              }}
              onMouseEnter={(e) => {
                e.target.style.backgroundColor = "rgba(255, 82, 82, 0.1)";
                e.target.style.border = "1px solid rgba(255, 82, 82, 0.5)";
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = "transparent";
                e.target.style.border = "1px solid rgba(255, 82, 82, 0.3)";
              }}
            >
              Report Inaccurate Lead
            </button>
          </div>

          <div
            ref={containerRef}
            style={{
              color: "#fff",
              fontSize: "13px",
              lineHeight: "1.5",
              whiteSpace: "pre-wrap",
              wordBreak: "break-word",
              maxHeight: "calc(30vh - 40px - 50px)", // Adjusted for header
              overflowY: "auto",
              scrollBehavior: "smooth",
            }}
          >
            {messageContent}
          </div>
        </div>
      )}
    </>
  );
};

const LeadFeed = ({
  setWatchIdsList,
  setShowLeadsModal,
  setUniqueColors,
  setMeanUsdPrice,
  setSelectedWatchId,
  setShowFilterModalCreateWatchlist,
  fetchUniqueColors,
  startAnimation,
  addKeyword,
  handleSelectSuggestion,
}) => {
  const [organizedData, setOrganizedData] = useState([]);
  const intervalRef = useRef(null);
  const [lastFetchedTime, setLastFetchedTime] = useState(null);
  const [sourceVisibleLeadId, setSourceVisibleLeadId] = useState(null);
  const [chosenRef, setChosenRef] = useState("Fetching latest leads...");
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);

  const { setCurrentSelectedReference } = useMain();

  const { databaseInfo } = useMain();

  const { addAlert, removeAllAlerts } = useAlert();

  const { currentUser } = useAuth();

  // Add this near your other state declarations
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  // should really make this a general function (global) since I use it 3x so far...

  // Define the submitFeedback function for incorrect detections
  const handleIncorrectDetection = (watchLeadId) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/submit_feedback`,
        {
          user_id: currentUser.id, // Current user submitting feedback
          watch_lead_id: watchLeadId,
        },
        { withCredentials: true }
      )
      .then(() => {
        addAlert({
          message: "Feedback submitted. Thank you.",
          type: "success",
        });
      })
      .catch((error) => console.error("Error:", error));
  };

  // Add this useEffect to handle resize events
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const fetchAndShowLeads = async (ref) => {
    try {
      setLoading(true);

      const idsResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/get-watch-ids`,
        {
          params: { ref },
          withCredentials: true,
        }
      );
      const { watch_ids, core_ref } = idsResponse.data;

      setChosenRef("Almost done...");

      const colorsResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/search/colors`,
        {
          params: { ref: core_ref },
          withCredentials: true,
        }
      );
      const { unique_colors } = colorsResponse.data;

      setWatchIdsList(watch_ids);
      setUniqueColors(unique_colors);
      setMeanUsdPrice(null);
      setShowLeadsModal(true);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching lead data:", error);
      setLoading(false);
    }
  };

  const fetchWatchLeads = useCallback(async () => {
    try {
      setLoading(true);
      const params = { limit: 20 };
      if (lastFetchedTime) {
        params.since_time = lastFetchedTime;
      }

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/get_watch_leads_for_feed`,
        { params, withCredentials: true }
      );

      if (response.data.status === "success") {
        const newLeads = response.data.data;
        if (newLeads.length > 0) {
          const latestLeadTime = newLeads[0].created_at;
          setLastFetchedTime(latestLeadTime);
          setOrganizedData((prevData) => {
            const updatedData = [...newLeads, ...prevData];
            return updatedData.slice(0, 20);
          });
        }
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching watch leads:", error);
      setLoading(false);
    }
  }, [lastFetchedTime]);

  useEffect(() => {
    fetchWatchLeads();
    intervalRef.current = setInterval(() => {
      fetchWatchLeads();
    }, 60000);

    return () => {
      clearInterval(intervalRef.current);
    };
  }, [fetchWatchLeads]);

  const getTimeDifference = (createdAt) => {
    const now = new Date();
    const createdDate = new Date(createdAt);
    const diffInSeconds = Math.floor((now - createdDate) / 1000);

    if (diffInSeconds < 60) {
      return `${diffInSeconds} seconds ago`;
    } else if (diffInSeconds < 3600) {
      const minutes = Math.floor(diffInSeconds / 60);
      return `${minutes} minutes ago`;
    } else if (diffInSeconds < 86400) {
      const hours = Math.floor(diffInSeconds / 3600);
      return `${hours} hours ago`;
    } else {
      const days = Math.floor(diffInSeconds / 86400);
      return `${days} days ago`;
    }
  };

  const LEADS_PER_PAGE = isMobile ? 2 : 2;
  const totalPages = Math.ceil(organizedData.length / LEADS_PER_PAGE);

  const displayedLeads = organizedData.slice(
    currentPage * LEADS_PER_PAGE,
    (currentPage + 1) * LEADS_PER_PAGE
  );

  return (
    <>
      <hr />

      {!loading && (
        <>
          {" "}
          <h4>Market Opportunities</h4>
          {databaseInfo.leads_last_7_days ? (
            <div>
              <p style={{ fontSize: "10px", marginTop: "-18px" }}>
                {databaseInfo.leads_last_7_days} leads found in last 24 hrs
              </p>
            </div>
          ) : (
            <div>
              <p style={{ fontSize: "10px", marginTop: "-18px" }}>
                Analyzing Leads...
              </p>
            </div>
          )}
        </>
      )}

      {loading ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            margin: "auto",
            gridColumn: "1 / -1",
          }}
        >
          <div
            className="loader"
            style={{ margin: "auto", marginBottom: "15px" }}
          ></div>
          <div style={{ margin: "auto", fontSize: "24px" }}>{chosenRef}</div>
        </div>
      ) : null}

      <div
        className="latest-leads"
        style={{
          display: "grid",
          gridTemplateColumns: isMobile ? "repeat(2, 1fr)" : "repeat(2, 1fr)",
          gap: "20px",
        }}
      >
        {loading
          ? null
          : displayedLeads.map((lead, index) => {
              return (
                <div
                  className="animated-lead"
                  key={lead.id}
                  style={{
                    position: "relative",
                    display: "inline-flex",
                    flexDirection: "column",
                    alignItems: "center",
                    backgroundColor: "rgba(0, 0, 0, 0.2)",
                    padding: "0.5em",
                    borderRadius: "25px",
                    boxShadow: "0 1px 3px rgba(0, 0, 0, 0.15)",
                    minWidth: "100px",
                    transition: "transform 0.2s",
                    color: "#fff",
                    border: `1px solid rgba(255, 215, 0, ${0.1})`,
                  }}
                >
                  {/* TAGS CONTAINER (ALL TAGS STACKED) */}
                  <div
                    style={{
                      position: "absolute",
                      right: "-18px",
                      top: "15%",
                      transform: "translateY(-50%)",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      gap: "5px",
                      pointerEvents: "none", // so clicks pass through
                      zIndex: 99,
                    }}
                  >
                    {lead.year && (
                      <div
                        style={{
                          backgroundColor: "rgba(212, 160, 23, 0.85)",
                          color: "#000000",
                          padding: "3px 8px",
                          borderRadius: "12px",
                          boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
                          textTransform: "uppercase",
                          fontSize: "0.5em",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {lead.year}
                      </div>
                    )}

                    {lead.condition && (
                      <div
                        style={{
                          backgroundColor: "rgba(0, 127, 127, 0.85)",
                          color: "#ffffff",
                          padding: "3px 8px",
                          borderRadius: "12px",
                          boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
                          textTransform: "uppercase",
                          fontSize: "0.5em",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {lead.condition}
                      </div>
                    )}

                    {lead.material && (
                      <div
                        style={{
                          backgroundColor: "rgba(33, 124, 95, 0.85)",
                          color: "#ffffff",
                          padding: "3px 8px",
                          borderRadius: "12px",
                          boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
                          textTransform: "uppercase",
                          fontSize: "0.5em",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {lead.material}
                      </div>
                    )}

                    {lead.color && (
                      <div
                        style={{
                          backgroundColor:
                            lead.color.toLowerCase() === "gold"
                              ? "rgba(212, 160, 23, 0.85)"
                              : "rgba(123, 32, 42, 0.85)",
                          color: "#ffffff",
                          padding: "3px 8px",
                          borderRadius: "12px",
                          boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
                          textTransform: "uppercase",
                          fontSize: "0.5em",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {lead.color}
                      </div>
                    )}

                    {lead.box_paper_tag && (
                      <div
                        style={{
                          backgroundColor: "rgba(29, 51, 56, 0.9)",
                          color: "#ffffff",
                          padding: "3px 8px",
                          borderRadius: "12px",
                          boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
                          textTransform: "uppercase",
                          fontSize: "0.5em",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {lead.box_paper_tag}
                      </div>
                    )}

                    {/* If special_features is present, each is stacked below the above tags */}
                    {lead.special_features &&
                      lead.special_features.split(",").map((feature, i) => (
                        <div
                          key={i}
                          style={{
                            backgroundColor: "rgba(0, 107, 107, 0.85)",
                            color: "#ffffff",
                            padding: "3px 8px",
                            borderRadius: "12px",
                            fontSize: "0.5em",
                            boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
                            textTransform: "uppercase",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {feature.trim()}
                        </div>
                      ))}
                  </div>
                  {/* END TAGS CONTAINER */}

                  <div
                    onMouseEnter={() => setSourceVisibleLeadId(lead.id)}
                    onMouseLeave={() => setSourceVisibleLeadId(null)}
                  >
                    {lead.image_link && (
                      <>
                        <div
                          style={{
                            position: "absolute",
                            top: "5px",
                            left: "5px",
                            display: "flex",
                            alignItems: "center",
                            gap: "3px",
                            backgroundColor: "rgba(0,0,0,0.3)",
                            padding: "2px 6px",
                            // The requested border & radius:
                            border: "1px solid rgba(255, 255, 255, 0.3)",
                            borderRadius: "25px 4px 4px 4px",
                            paddingLeft: "12px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            // same function call from the parent
                            handleSelectSuggestion(lead.best_match_ref, false);
                          }}
                        >
                          <span style={{ color: "#fff", fontWeight: "bold" }}>
                            $
                          </span>
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="#fff"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            {/* A simple zigzag line to look like a stock chart */}
                            <path d="M2 18L9 11l4 4 9-9" />
                          </svg>
                        </div>
                        <AnimatedCarousel
                          images={(() => {
                            try {
                              // If it's already an array, don't try to parse it
                              if (Array.isArray(lead.image_link)) {
                                return lead.image_link.map((link) => ({
                                  image_link: link,
                                  ref: lead.best_match_ref,
                                }));
                              }
                              // Try to parse the JSON string
                              const links = JSON.parse(lead.image_link);
                              return links.map((link) => ({
                                image_link: link,
                                ref: lead.best_match_ref,
                              }));
                            } catch (e) {
                              // If parsing fails, treat it as a single image
                              console.log("Error parsing image links:", e);
                              return [
                                {
                                  image_link: lead.image_link,
                                  ref: lead.best_match_ref,
                                },
                              ];
                            }
                          })()}
                          smaller={true}
                        />
                      </>
                    )}
                    <div
                      className="lead-feed-info"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <span
                        style={{
                          fontWeight: "bold",
                          color: "#fff",
                          marginBottom: "0.2em",
                        }}
                      >
                        {lead.best_match_ref}
                      </span>
                      <LeadItem
                        key={lead.id}
                        lead={lead}
                        handleIncorrectDetection={handleIncorrectDetection}
                      />

                      <span
                        style={{
                          color: "#fff",
                          marginBottom: "0.2em",
                          marginTop: "0.2em",
                        }}
                      >
                        {lead.phone_number}
                      </span>

                      <span
                        style={{
                          color: "#ccc",
                          fontSize: "0.8em",
                        }}
                      >
                        {getTimeDifference(lead.created_at)}
                      </span>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        gap: "8px",
                        marginTop: "4px",
                        justifyContent: "center",
                      }}
                    >
                      {/* WhatsApp Icon */}
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 24 24"
                        style={{ opacity: true ? 1 : 0.3 }}
                      >
                        <path
                          fill={true ? "#25D366" : "#808080"}
                          d="M17.472 14.382c-.297-.149-1.758-.867-2.03-.967-.273-.099-.471-.148-.67.15-.197.297-.767.966-.94 1.164-.173.199-.347.223-.644.075-.297-.15-1.255-.463-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.298-.347.446-.52.149-.174.198-.298.298-.497.099-.198.05-.371-.025-.52-.075-.149-.669-1.612-.916-2.207-.242-.579-.487-.5-.669-.51-.173-.008-.371-.01-.57-.01-.198 0-.52.074-.792.372-.272.297-1.04 1.016-1.04 2.479 0 1.462 1.065 2.875 1.213 3.074.149.198 2.096 3.2 5.077 4.487.709.306 1.262.489 1.694.625.712.227 1.36.195 1.871.118.571-.085 1.758-.719 2.006-1.413.248-.694.248-1.289.173-1.413-.074-.124-.272-.198-.57-.347m-5.421 7.403h-.004a9.87 9.87 0 01-5.031-1.378l-.361-.214-3.741.982.998-3.648-.235-.374a9.86 9.86 0 01-1.51-5.26c.001-5.45 4.436-9.884 9.888-9.884 2.64 0 5.122 1.03 6.988 2.898a9.825 9.825 0 012.893 6.994c-.003 5.45-4.437 9.884-9.885 9.884m8.413-18.297A11.815 11.815 0 0012.05 0C5.495 0 .16 5.335.157 11.892c0 2.096.547 4.142 1.588 5.945L.057 24l6.305-1.654a11.882 11.882 0 005.683 1.448h.005c6.554 0 11.89-5.335 11.893-11.893a11.821 11.821 0 00-3.48-8.413"
                        />
                      </svg>

                      {/* Facebook Messenger Icon */}
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 24 24"
                        style={{
                          opacity: lead.source === "messenger" ? 1 : 0.3,
                        }}
                      >
                        <path
                          fill={
                            lead.source === "messenger" ? "#00B2FF" : "#808080"
                          }
                          d="M12 2C6.477 2 2 6.145 2 11.243c0 2.966 1.475 5.611 3.778 7.322V22l3.455-1.9c.88.246 1.82.378 2.777.378 5.523 0 10-4.145 10-9.243C22 6.146 17.523 2 12 2zm1.093 12.408l-2.586-2.77-5.046 2.77 5.543-5.89 2.648 2.768 4.971-2.768-5.53 5.89z"
                        />
                      </svg>
                    </div>
                    <HighlightedMessage
                      message={lead.message}
                      segmentText={lead.segment_text}
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    <button
                      onClick={async () => {
                        try {
                          setSelectedWatchId(lead.watch_id);
                          const filters = {
                            selected_colors: [],
                            full_set: false,
                            partial_set: false,
                            only_watch: false,
                            no_papers_tags: false,
                            condition: [],
                            date_month: "",
                            date_year: "",
                            newer_than: false,
                            serial: "",
                            only_usd: false,
                            manual_filters: [],
                          };
                          addKeyword(filters, lead.watch_id);
                        } catch (error) {
                          console.error("Error fetching colors:", error);
                        }
                      }}
                      disabled={startAnimation}
                      style={{
                        border: "1px solid #FF6F61",
                        borderRadius: "10px 0 0 10px",
                        padding: "0.2em 0.5em",
                        color: "#FF6F61",
                        fontSize: "0.8em",
                        cursor: "pointer",
                        transition: "background-color 0.2s",
                        marginTop: "0.9em",
                        marginBottom: "0.2em",
                        backgroundColor: "transparent",
                      }}
                    >
                      + Watch
                    </button>
                    <button
                      onClick={() => {
                        toggleBodyScroll(true);
                        setChosenRef(
                          "Exploring leads for " + lead.best_match_ref
                        );
                        setCurrentSelectedReference(lead.best_match_ref);
                        fetchAndShowLeads(lead.best_match_ref);
                      }}
                      style={{
                        border: "1px solid #F2E6D9",
                        borderRadius: "0 10px 10px 0",
                        padding: "0.2em 0.5em",
                        color: "#F2E6D9",
                        fontSize: "0.8em",
                        cursor: "pointer",
                        transition: "background-color 0.2s",
                        backgroundColor: "transparent",
                        marginTop: "0.9em",
                        marginBottom: "0.2em",
                      }}
                    >
                      Leads {">"}
                    </button>
                  </div>
                </div>
              );
            })}
      </div>

      {!loading && (
        <>
          <div
            style={{
              display: "inline-flex",
              alignItems: "center",
              gap: "4px",
              marginBottom: "7px",
            }}
          >
            <small
              style={{
                fontSize: "10px",
                color: "#666",
                fontWeight: 500,
                letterSpacing: "0.25px",
              }}
            >
              AI-Powered Lead Detection (Beta)
            </small>
            <div
              className="tooltip-container"
              style={{
                position: "relative",
                display: "inline-block",
              }}
              onClick={() => {
                addAlert({
                  message: (
                    <div
                      style={{
                        lineHeight: "1.4em",
                        fontSize: "0.85rem",
                        textAlign: "left",
                        maxWidth: "100%",
                        wordBreak: "break-word",
                        padding: "10px",
                        overflowY: "auto",
                        maxHeight: "80vh",
                      }}
                    >
                      <h5
                        style={{
                          fontSize: "13px",
                          fontWeight: "600",
                          marginTop: 0,
                          marginBottom: "12px",
                          color: "#e8e8e8",
                        }}
                      >
                        Beta AI Lead Detection System
                      </h5>

                      <p
                        style={{
                          fontSize: "12px",
                          color: "white",
                          marginBottom: "10px",
                        }}
                      >
                        Our early-stage system uses NLP to help identify
                        opportunities, but it’s still learning, and may not
                        always be accurate! We're aware of these issues and plan
                        to refine our model training and improvements soon.
                      </p>

                      <div
                        style={{
                          fontSize: "12px",
                          color: "white",
                          marginTop: "14px",
                          padding: "8px 12px",
                          backgroundColor: "rgba(255,255,255,0.1)",
                          borderRadius: "4px",
                        }}
                      >
                        <span style={{ fontWeight: 500 }}>Recommendation:</span>{" "}
                        Please verify leads using the{" "}
                        <span style={{ fontWeight: 900 }}>'View Source' </span>
                        button independently while we continue to refine our AI
                        capabilities.
                      </div>
                      <button
                        style={{ fontSize: "11px", marginTop: "10px" }}
                        onClick={() => {
                          removeAllAlerts();
                          showQuickExplanationAlert(addAlert);
                        }}
                      >
                        Learn More
                      </button>
                    </div>
                  ),
                  type: "info",
                  isTooltip: false,
                  duration: 15000,
                  className: "alert-detected-leads",
                });
              }}
            >
              <button
                style={{
                  width: "16px",
                  height: "16px",
                  borderRadius: "50%",
                  border: "1px solid #aaa",
                  backgroundColor: "#f8f8f8",
                  color: "#666",
                  fontSize: "11px",
                  cursor: "help",
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: 0,
                  marginLeft: "4px",
                  transition: "all 0.2s ease",
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.backgroundColor = "#eee";
                  e.currentTarget.style.borderColor = "#999";
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.backgroundColor = "#f8f8f8";
                  e.currentTarget.style.borderColor = "#aaa";
                }}
              >
                ?
              </button>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "10px",
              marginTop: "10px",
            }}
          >
            <button
              onClick={() => setCurrentPage((prev) => Math.max(0, prev - 1))}
              disabled={currentPage === 0}
              style={{
                padding: "5px 10px",
                backgroundColor: "transparent",
                border: "1px solid #F2E6D9",
                borderRadius: "5px",
                color: "#F2E6D9",
                cursor: currentPage === 0 ? "not-allowed" : "pointer",
                opacity: currentPage === 0 ? 0.5 : 1,
              }}
            >
              ←
            </button>
            <button
              onClick={() =>
                setCurrentPage((prev) => Math.min(totalPages - 1, prev + 1))
              }
              disabled={currentPage >= totalPages - 1}
              style={{
                padding: "5px 10px",
                backgroundColor: "transparent",
                border: "1px solid #F2E6D9",
                borderRadius: "5px",
                color: "#F2E6D9",
                cursor:
                  currentPage >= totalPages - 1 ? "not-allowed" : "pointer",
                opacity: currentPage >= totalPages - 1 ? 0.5 : 1,
              }}
            >
              →
            </button>
          </div>
        </>
      )}

      <hr />
    </>
  );
};

const AutoWatchDashboard = () => {
  const { addAlert } = useAlert();
  const [organizedData, setOrganizedData] = useState([]);
  const [isTabVisible, setIsTabVisible] = useState(true);
  const animationContainerRef = useRef(null);
  const autoWatchRef = useRef(null);
  const animationFrameRef = useRef(null);
  const particlesRef = useRef([]);
  const [isAnimating, setIsAnimating] = useState(false);
  const intervalRef = useRef(null);
  const organizedDataRef = useRef(null); // Reference for scrolling
  const timeoutsRef = useRef([]); // Reference for timeouts
  const isUnmountedRef = useRef(false);

  const messages = [
    "126233ng white jub $137000 n5",
    "Looking for 5726/1A New or like new 2023/2024",
    "WSSA0023 HK$30,500-17%",
    "IW391027 $55,500hkd -32% 2/2023",
    "AB2020161C1S1 $40800",
    "🆕6104R 2022 $4.3m",
    "Used 116503ng bk 2018 $180k",
    "used 5147g blue 22y 349k",
  ];

  const hardcodedLeads = [
    {
      brand: "Rolex",
      ref: "126233ng",
      condition: "New",
      year: "2024",
      price: "$137,000",
      leadType: "Sell",
      model: "Datejust 36",
      details: "White dial, Jubilee bracelet",
    },
    {
      brand: "Patek Philippe",
      ref: "5726/1A",
      condition: "New",
      year: "2023/2024",
      price: "On request",
      leadType: "Buy",
      model: "Nautilus",
      details: "Annual Calendar, Moon Phases",
    },
    {
      brand: "Cartier",
      ref: "WSSA0023",
      condition: "New",
      year: "2024",
      price: "HK$30,500",
      leadType: "Sell",
      model: "Santos de Cartier",
      details: "17% discount",
    },
    {
      brand: "IWC",
      ref: "IW391027",
      condition: "New",
      year: "2023",
      price: "HK$55,500",
      leadType: "Sell",
      model: "Portugieser Chronograph",
      details: "32% discount",
    },
    {
      brand: "A. Lange & Söhne",
      ref: "AB2020161C1S1",
      condition: "New",
      year: "2024",
      price: "$40,800",
      leadType: "Sell",
      model: "Lange 1",
      details: "White gold, Black dial",
    },
    {
      brand: "Patek Philippe",
      ref: "6104R",
      condition: "New",
      year: "2022",
      price: "$4,300,000",
      leadType: "Sell",
      model: "Minute Repeater Tourbillon",
      details: "Rose gold, Rare complication",
    },
    {
      brand: "Rolex",
      ref: "116503ng",
      condition: "Used",
      year: "2018",
      price: "$180,000",
      leadType: "Sell",
      model: "Cosmograph Daytona",
      details: "Black dial, Yellow gold and steel",
    },
    {
      brand: "Patek Philippe",
      ref: "5147G",
      condition: "Used",
      year: "2022",
      price: "$349,000",
      leadType: "Sell",
      model: "Complications",
      details: "Blue dial, White gold",
    },
  ];

  useEffect(() => {
    isUnmountedRef.current = false; // Track if the component is unmounted

    // Cleanup function when the component unmounts
    return () => {
      isUnmountedRef.current = true; // Mark the component as unmounted
      cancelAnimationFrame(animationFrameRef.current); // Stop animations
      clearInterval(intervalRef.current); // Clear intervals

      // Clear all timeouts
      timeoutsRef.current.forEach((timeoutId) => clearTimeout(timeoutId));
      timeoutsRef.current = [];

      // Remove particles
      particlesRef.current.forEach((particle) => particle.remove());
      particlesRef.current = [];
    };
  }, []);

  // Scroll to the bottom when new data is added to the organizedData array
  useEffect(() => {
    if (organizedDataRef.current) {
      organizedDataRef.current.scrollTo({
        top: organizedDataRef.current.scrollHeight,
        behavior: "smooth", // Enable smooth scrolling
      });
    }
  }, [organizedData]); // Triggered every time organizedData changes

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsTabVisible(!document.hidden);
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      clearInterval(intervalRef.current);
      cancelAnimationFrame(animationFrameRef.current); // Clear any active animation frame
      timeoutsRef.current.forEach((timeoutId) => clearTimeout(timeoutId)); // Clear all timeouts on unmount
    };
  }, []);

  useEffect(() => {
    if (isTabVisible && !isAnimating) {
      startAnimation();
    } else if (!isTabVisible) {
      stopAnimation();
    }
  }, [isTabVisible]);

  const stopAnimation = useCallback(() => {
    cancelAnimationFrame(animationFrameRef.current);
    clearInterval(intervalRef.current);
    particlesRef.current.forEach((particle) => particle.remove());
    particlesRef.current = [];
    setIsAnimating(false);
  }, []);

  const MAX_PARTICLES = 40; // Limit to 75 particles at a time
  const createParticle = useCallback(
    (x, y) => {
      if (!isTabVisible || particlesRef.current.length >= MAX_PARTICLES) return;

      const particle = document.createElement("div");
      particle.className = "particle";
      const size = Math.random() * 0.5 + 0.25; // Increase size
      particle.style.width = `${size}em`;
      particle.style.height = `${size}em`;
      particle.style.transform = `translate(${x}px, ${y}px)`;
      animationContainerRef.current?.appendChild(particle);

      const angle = Math.random() * Math.PI * 2;
      const speed = Math.random() * 1 + 0.5;
      const vx = Math.cos(angle) * speed;
      const vy = Math.sin(angle) * speed;
      let opacity = 1;

      const animateParticle = () => {
        if (!isTabVisible) return;

        const currentTransform = particle.style.transform.match(
          /translate\((.*)px,\s*(.*)px\)/
        );
        const currentX = parseFloat(currentTransform[1]);
        const currentY = parseFloat(currentTransform[2]);

        particle.style.transform = `translate(${currentX + vx}px, ${
          currentY + vy
        }px)`;
        opacity -= 0.01; // Slower fade out
        particle.style.opacity = opacity.toString();

        if (opacity > 0) {
          requestAnimationFrame(animateParticle);
        } else {
          particle.remove();
          particlesRef.current = particlesRef.current.filter(
            (p) => p !== particle
          );
        }
      };

      requestAnimationFrame(animateParticle);
      particlesRef.current.push(particle);
    },
    [isTabVisible]
  );

  const createMessage = useCallback(
    (message, index) => {
      if (!isTabVisible || !autoWatchRef.current) return;

      const messageElement = document.createElement("div");
      messageElement.className = "message";
      messageElement.textContent = message;
      messageElement.style.top = `${Math.random() * 24 + 10}%`;
      messageElement.style.left = "10%";
      animationContainerRef.current?.appendChild(messageElement);

      const timeoutId = setTimeout(() => {
        if (isUnmountedRef.current || !isTabVisible || !autoWatchRef.current)
          return;
        messageElement.style.opacity = "1";
        messageElement.style.transform = "scale(1.1)";
      }, index * 200);

      timeoutsRef.current.push(timeoutId);

      setTimeout(() => {
        if (isUnmountedRef.current || !isTabVisible || !autoWatchRef.current) {
          messageElement.remove();
          return;
        }

        const rect = messageElement.getBoundingClientRect();
        const autoWatchRect = autoWatchRef.current.getBoundingClientRect();
        const containerRect =
          animationContainerRef.current.getBoundingClientRect();

        const startX = rect.left - containerRect.left;
        const startY = rect.top - containerRect.top;
        const endX =
          autoWatchRect.left - containerRect.left + autoWatchRect.width / 2;
        const endY =
          autoWatchRect.top - containerRect.top + autoWatchRect.height / 2;

        let progress = 0;
        const animateToCenter = () => {
          if (isUnmountedRef.current || !isTabVisible) {
            messageElement.remove();
            return;
          }

          progress += 0.02;
          const currentX = startX + (endX - startX) * progress;
          const currentY = startY + (endY - startY) * progress;

          messageElement.style.transform = `translate(${currentX}px, ${currentY}px) scale(${
            1 - progress * 0.5
          })`;
          messageElement.style.opacity = (1 - progress).toString();

          createParticle(currentX, currentY);

          if (progress < 1) {
            animationFrameRef.current = requestAnimationFrame(animateToCenter);
          } else {
            messageElement.remove();
            setOrganizedData((prevData) => [
              ...prevData,
              hardcodedLeads[index],
            ]);
          }
        };

        animationFrameRef.current = requestAnimationFrame(animateToCenter);
      }, index * 200 + 2000);
    },
    [isTabVisible, createParticle]
  );

  const startAnimation = useCallback(() => {
    if (isAnimating) return; // Prevent starting another animation loop

    setIsAnimating(true);
    setOrganizedData([]); // Reset the data before new animation

    messages.forEach((message, index) => {
      setTimeout(() => createMessage(message, index), index * 1000);
    });

    clearInterval(intervalRef.current); // Clear previous interval if exists
    intervalRef.current = setInterval(() => {
      setIsAnimating(false);
      startAnimation(); // Loop animation process
    }, messages.length * 1000 + 5000);
  }, [isAnimating, messages, createMessage]);

  return (
    <>
      <div id="animation-container" ref={animationContainerRef}>
        <div id="auto-watch" ref={autoWatchRef}>
          ChronoSpy
        </div>
        <div id="organized-data" ref={organizedDataRef}>
          {organizedData.map((lead, index) => (
            <div
              key={index}
              className="data-row"
              style={{ animationDelay: `${index * 0.1}s` }}
            >
              <span className="brand">{lead.brand}</span>
              <span className="ref">{lead.ref}</span>
              <span className="model">{lead.model}</span>
              <span className="condition">{lead.condition}</span>
              <span className="year">{lead.year}</span>
              <span className="price">{lead.price}</span>
              <span className={`lead-type ${lead.leadType.toLowerCase()}1`}>
                {lead.leadType}
              </span>
              <span className="details">{lead.details}</span>
            </div>
          ))}
        </div>
        <div id="whatsapp-icon" className="platform-icon">
          W
        </div>
        <div id="facebook-icon" className="platform-icon">
          f
        </div>
        <div className="autowatch-decoder">
          <h2 style={{ marginTop: 0, color: "#A48C3D" }}>ChronoSpy Decoder</h2>{" "}
          <p style={{ lineHeight: 1.6 }}>
            Unravel the chaos of unstructured group messages with our advanced
            system. It intelligently parses loose, fragmented data and
            transforms it into clear, actionable watch leads.{" "}
            <span style={{ fontStyle: "italic" }}>
              From noise to clarity—find your next opportunity in the details
              others overlook
            </span>
            .
          </p>
          <button
            style={{ fontSize: "11px", marginTop: "10px" }}
            onClick={() => {
              showQuickExplanationAlert(addAlert);
            }}
          >
            Learn More
          </button>
        </div>
      </div>
      <style jsx>{`
        @import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;700&display=swap");

        #animation-container {
          width: 80vw;
          height: 80vh;
          max-width: 1000px;
          max-height: 600px;
          margin: auto;
          position: relative;
          background-color: rgba(23, 42, 69, 0.2);
          border-radius: 1.5em;
          box-shadow: 0 0 2em rgba(122, 162, 214, 0.5);
        }
        .message {
          position: absolute;
          background-color: rgba(42, 74, 115, 0.8);
          border-radius: 0.8em;
          padding: 0.8em;
          color: #e6f1ff;
          font-size: 1em;
          opacity: 0;
          transition: all 0.5s ease;
          max-width: 15em;
          box-shadow: 0 0 0.5em rgba(122, 162, 214, 0.3);
        }
        #organized-data {
          position: absolute;
          right: 5%;
          top: 50%;
          transform: translateY(-50%);
          width: 17%;
          height: 80%;
          background-color: rgba(23, 42, 69, 0.9);
          border-radius: 0.8em;
          display: flex;
          flex-direction: column;
          overflow-y: auto;
          padding: 1em;
          box-shadow: 0 0 1em rgba(122, 162, 214, 0.5);
        }
        .data-row {
          background-color: rgba(58, 90, 131, 0.8);
          margin: 0.3em 0;
          padding: 0.5em;
          border-radius: 0.4em;
          font-size: 1em;
          color: #e6f1ff;
          opacity: 0;
          animation: fadeIn 0.5s ease forwards;
        }
        @keyframes fadeIn {
          from {
            opacity: 0;
            transform: translateY(1em);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }
        .data-row .brand {
          font-weight: bold;
          color: #ffd700;
          display: block;
        }
        .data-row .ref {
          font-weight: bold;
          color: #7aa2d6;
          display: block;
          font-size: 1.4em;
        }
        .data-row .model {
          color: #e6f1ff;
          font-style: italic;
          display: block;
        }
        .data-row .condition {
          color: #e6f1ff;
          display: block;
        }
        .data-row .year {
          color: #ff9800;
          display: block;
        }
        .data-row .price {
          color: #4caf50;
          font-weight: bold;
          display: block;
        }
        .data-row .lead-type {
          font-size: 0.7em;
          display: block;
        }
        .data-row .lead-type.buy1 {
          color: #ff9800;
        }
        .data-row .lead-type.sell1 {
          color: #2196f3;
        }
        .data-row .details {
          color: #bbb;
          font-size: 0.64em;
          display: block;
          margin-top: 0.3em;
        }
        #auto-watch {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 20%;
          height: 20%;
          background-color: rgba(122, 162, 214, 0.2);
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #7aa2d6;
          font-weight: bold;
          font-size: 1.5em;
          box-shadow: 0 0 1.5em rgba(122, 162, 214, 0.5);
          animation: pulse 2s infinite;
        }
        @keyframes pulse {
          0% {
            box-shadow: 0 0 1.5em rgba(122, 162, 214, 0.5);
          }
          50% {
            box-shadow: 0 0 2.5em rgba(122, 162, 214, 0.8);
          }
          100% {
            box-shadow: 0 0 1.5em rgba(122, 162, 214, 0.5);
          }
        }
        .platform-icon {
          position: absolute;
          width: 3em;
          height: 3em;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1.2em;
          color: white;
          opacity: 0.8;
        }
        #whatsapp-icon {
          left: 10%;
          top: 20%;
          background-color: #25d366;
        }
        #facebook-icon {
          left: 15%;
          top: 40%;
          background-color: #4267b2;
        }
        .particle {
          position: absolute;
          background-color: rgba(122, 162, 214, 0.5);
          border-radius: 50%;
          pointer-events: none;
        }

        @media (max-width: 576px) {
          #organized-data {
            width: 40%;
            height: 400px;
            top: 70%;
            right: -10%;
          }

          .data-row {
            font-size: 0.8em; /* Smaller font size for mobile */
            padding: 0.4em;
            margin: 0.2em 0;
          }

          .data-row .brand {
            font-size: 1em;
          }

          .data-row .ref {
            font-size: 1.2em;
          }

          .data-row .details {
            font-size: 0.5em;
          }

          #auto-watch {
            width: 50%;
            height: 50%;
            font-size: 1em;
          }

          .platform-icon {
            width: 2em;
            height: 2em;
            font-size: 1em;
          }

          #whatsapp-icon {
            top: 10%;
            left: 5%;
          }

          #facebook-icon {
            top: 30%;
            left: 8%;
          }

          .message {
            font-size: 0.6em;
          }
        }
      `}</style>
    </>
  );
};

const AnimatedHeader = () => {
  const words = ["Valuable", "Precious", "Critical", "Money."];
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [showSubHeader, setShowSubHeader] = useState(false);

  useEffect(() => {
    if (currentWordIndex < words.length - 1) {
      const interval = setInterval(() => {
        setCurrentWordIndex((prevIndex) => prevIndex + 1);
      }, 900); // Change word every 1.3 seconds

      return () => {
        clearInterval(interval);
      };
    }
  }, [currentWordIndex, words.length]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowSubHeader(true);
    }, 910 * 4); // Show subheader after 5200ms

    return () => clearTimeout(timeout);
  }, []);

  const displayWord = words[currentWordIndex];

  return (
    <div>
      <h1 className="main-header">
        Your Time Is{" "}
        <span key={displayWord} className="fade">
          {displayWord}
        </span>
      </h1>

      {/* Render the subheader from the start */}
      <h3 className={`subheader ${showSubHeader ? "visible" : ""}`}>
        Effortlessly Scan the Watch Market in Seconds
      </h3>
    </div>
  );
};

const Register = () => {
  const { setCurrentUser } = useAuth(); // Get setCurrentUser function from auth context
  const { addAlert } = useAlert();
  const navigate = useNavigate();

  // State variables
  const [step, setStep] = useState(2);
  const [alphaCode, setAlphaCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [businessAddress, setBusinessAddress] = useState("");
  const [error, setError] = useState("");
  const [referralCode, setReferralCode] = useState("");

  // Function to log in the user after registration
  const handleLoginAfterRegistration = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/login`,
        {
          phone_number: phoneNumber,
          password: password,
        },
        { withCredentials: true }
      );
      if (response.data.message === "Login successful") {
        addAlert({
          message: "Logged in successfully.",
          type: "success",
        });
        window.scrollTo(0, 0);
        setCurrentUser(response.data); // Update the currentUser state
        navigate("/about"); // Navigate to the desired page after login
      } else {
        setError(response.data.message || "Login after registration failed.");
      }
    } catch (error) {
      setError(
        "Login after registration failed. Please try logging in manually."
      );
    }
  };

  // Handle invitation code verification
  const handleInvitationVerification = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/verify_invitation`,
        { alpha_code: alphaCode },
        {
          withCredentials: true, // Ensure cookies are sent with the request
        }
      );
      if (response.data.message === "Invitation code verified") {
        setStep(2);
        setError("");
      } else {
        setError(response.data.message || "Invalid invitation code");
      }
    } catch (err) {
      setError("Invitation verification failed. Try again.");
    }
  };

  const handlePhoneRegistration = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/register`,
        {
          phone_number: phoneNumber,
          name,
          email,
          business_name: businessName, // Optional
          business_address: businessAddress || "", // Optional
          referral_code: referralCode || "", // Ensure empty string if not filled
        },
        { withCredentials: true }
      );
      if (response.data.message === "Verification code sent to your phone") {
        setStep(3);
        setError("");
      } else {
        setError(response.data.message || "Registration failed");
      }
    } catch (err) {
      setError(
        err.response?.data?.message || "Registration failed. Try again."
      );
    }
  };

  // Handle SMS verification
  const handleVerification = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/verify`,
        {
          phone_number: phoneNumber,
          verification_code: verificationCode,
        },
        {
          withCredentials: true, // Ensure cookies are sent with the request
        }
      );
      if (response.data.message.includes("verified")) {
        setStep(4);
        setError("");
      } else {
        setError(response.data.message || "Invalid verification code.");
      }
    } catch (err) {
      setError("Verification failed. Try again.");
    }
  };

  // Handle setting the password and confirm password
  const handleSetPassword = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/set_password`,
        { phone_number: phoneNumber, password: password },
        {
          withCredentials: true, // Ensure cookies are sent with the request
        }
      );
      if (response.data.message.includes("Password set successfully")) {
        await handleLoginAfterRegistration();
        setStep(5);
        setError("");
      } else {
        setError(response.data.message || "Failed to set password.");
      }
    } catch (err) {
      setError("Setting password failed. Try again.");
    }
  };

  return (
    <div id="register-form" className="registration-modal">
      {step === 1 && (
        <div>
          <h2 className="invitation-heading">Early Access</h2>
          <form
            onSubmit={handleInvitationVerification}
            className="invitation-form"
          >
            <label htmlFor="alphaCode" className="invitation-label">
              Invitation Code
            </label>
            <input
              type="text"
              id="alphaCode"
              value={alphaCode}
              onChange={(e) => setAlphaCode(e.target.value)}
              required
              placeholder="Your 8-digit invitation code"
              className="invitation-input"
            />
            <button type="submit" className="invitation-submit-button">
              Verify Invitation
            </button>
          </form>
        </div>
      )}

      {step === 2 && (
        <div>
          <h2 className="registration-heading">Registration</h2>
          <form
            onSubmit={handlePhoneRegistration}
            className="registration-form"
          >
            <label htmlFor="name" className="registration-label">
              Full Name
            </label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              placeholder="Your full name"
              className="registration-input"
            />
            <label htmlFor="email" className="registration-label">
              Email
            </label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              placeholder="Your email"
              className="registration-input"
            />
            <label htmlFor="businessName" className="registration-label">
              Business Name
            </label>
            <input
              type="text"
              id="businessName"
              value={businessName}
              onChange={(e) => setBusinessName(e.target.value)}
              placeholder="Your business name"
              className="registration-input"
            />
            <label htmlFor="businessAddress" className="registration-label">
              Business Address (Optional)
            </label>
            <input
              type="text"
              id="businessAddress"
              value={businessAddress}
              onChange={(e) => setBusinessAddress(e.target.value)}
              placeholder="Your business address"
              className="registration-input"
            />
            <label htmlFor="referralCode" className="registration-label">
              Referral Code (Optional)
            </label>
            <input
              type="text"
              id="referralCode"
              value={referralCode}
              onChange={(e) => setReferralCode(e.target.value)}
              placeholder="Enter referral code if you have one"
              className="registration-input"
            />

            <label htmlFor="phoneNumber" className="registration-label">
              Phone Number
            </label>
            <input
              type="tel"
              id="phoneNumber"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              required
              placeholder="Will receive a verification text"
              className="registration-input"
            />
            <button type="submit" className="registration-submit-button">
              Send Verification Code
            </button>
          </form>
        </div>
      )}

      {step === 3 && (
        <div>
          <h2 className="verification-heading">Verify Identity</h2>
          <form onSubmit={handleVerification} className="verification-form">
            <label htmlFor="verificationCode" className="verification-label">
              Verification Code
            </label>
            <input
              type="text"
              id="verificationCode"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
              required
              placeholder="Enter the code sent to you"
              className="verification-input"
            />
            <button type="submit" className="verification-submit-button">
              Confirm Identity
            </button>
          </form>
        </div>
      )}

      {step === 4 && (
        <div>
          <h2 className="password-heading">Secure Your Access</h2>
          <form onSubmit={handleSetPassword} className="password-form">
            <label htmlFor="password" className="password-label">
              Create Password
            </label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              placeholder="Your secure passkey"
              className="password-input"
            />
            <label htmlFor="confirmPassword" className="password-label">
              Confirm Password
            </label>
            <input
              type="password"
              id="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              placeholder="Confirm your passkey"
              className="password-input"
            />
            <button type="submit" className="password-submit-button">
              Finalize Access
            </button>
          </form>
        </div>
      )}

      {step === 5 && (
        <div>
          <h2 className="welcome-heading">Welcome</h2>
          <p className="welcome-text">
            Your exclusive access has been granted.
            <br /> <br /> Welcome to{" "}
            <span className="welcome-highlight">ChronoSpy Alpha</span>.
          </p>
          <Link
            to="/"
            className="get-started-button watchlist-highlight-button-special"
            onClick={() => window.scrollTo(0, 0)}
          >
            Get Started
          </Link>
        </div>
      )}

      {error && <div className="registration-error">{error}</div>}
    </div>
  );
};

const AuthPage = () => {
  const { currentUser, setCurrentUser } = useAuth();
  const { addAlert } = useAlert();
  const [activeForm, setActiveForm] = useState("register"); // 'login' or 'register'

  // Logout function
  const handleLogout = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/logout`,
        {},
        { withCredentials: true }
      );
      if (response.status === 200) {
        addAlert({
          message: "See you later...",
          type: "info",
        });
        setCurrentUser(null); // Update currentUser to null after logout
      } else {
        console.error("Logout failed:", response.data.message);
      }
    } catch (error) {
      console.error("An error occurred during logout:", error);
    }
  };

  const styles = {
    container: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "#fff",
      fontFamily: "Arial, sans-serif",
      marginBottom: "20px",
    },
    modal: {
      background: "rgba(26, 32, 44, 0.8)",
      backdropFilter: "blur(10px)",
      borderRadius: "15px",
      padding: "2rem",
      width: "90%",
      maxWidth: "500px",
      boxShadow: "0 10px 30px rgba(0, 0, 0, 0.3)",
      border: "1px solid rgba(163, 126, 44, 0.3)",
      textAlign: "center",
    },
    tabs: {
      display: "flex",
      marginBottom: "2rem",
    },
    tab: {
      flex: 1,
      padding: "1rem",
      textAlign: "center",
      cursor: "pointer",
      fontWeight: 600,
      textTransform: "uppercase",
      letterSpacing: "1px",
      backgroundColor: "rgba(255, 255, 255, 0.05)",
      borderRadius: "5px 5px 0 0",
      border: "1px solid rgba(163, 126, 44, 0.3)",
      borderBottom: "none",
      color: "#a37e2c",
    },
    activeTab: {
      backgroundColor: "#293241",
      color: "#fff",
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "center",
      gap: "1rem",
      marginTop: "2rem",
    },
    button: {
      padding: "0.75rem 1.5rem",
      background: "linear-gradient(45deg, #a37e2c, #d4af37)",
      border: "none",
      borderRadius: "5px",
      color: "#fff",
      cursor: "pointer",
      fontSize: "1rem",
      fontWeight: 600,
      textTransform: "uppercase",
      letterSpacing: "1px",
      textDecoration: "none",
    },
    button_account: {
      padding: "0.75rem 1.5rem",
      background: "linear-gradient(45deg, rgb(37 105 110), rgb(41 56 59))",
      border: "none",
      borderRadius: "5px",
      color: "#fff",
      cursor: "pointer",
      fontSize: "1rem",
      fontWeight: 600,
      textTransform: "uppercase",
      letterSpacing: "1px",
      textDecoration: "none",
    },
    logoutButton: {
      padding: "0.75rem 1.5rem",
      background: "rgba(255, 255, 255, 0.1)",
      border: "1px solid rgba(163, 126, 44, 0.3)",
      borderRadius: "5px",
      color: "#fff",
      cursor: "pointer",
      fontSize: "1rem",
      fontWeight: 600,
      textTransform: "uppercase",
      letterSpacing: "1px",
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.modal}>
        {currentUser && !currentUser.anonymous ? (
          // If the user is logged in, display the "Get Started" button
          <>
            <h2 style={{ color: "#a37e2c", marginBottom: "1.5rem" }}>
              Welcome
            </h2>
            <p style={{ marginBottom: "1.5rem" }}>
              Your exclusive access has been granted.
              <br /> <br /> Welcome to{" "}
              <span style={{ fontWeight: 600 }}>ChronoSpy Alpha</span>.
            </p>

            <div style={styles.buttonContainer}>
              <Link
                to="/"
                style={styles.button}
                onClick={() => window.scrollTo(0, 0)}
                className="front-page-button group3"
              >
                Go to App
              </Link>
              <Link
                to="/dashboard"
                style={styles.button_account}
                onClick={() => window.scrollTo(0, 0)}
                className="front-page-button"
              >
                My Account
              </Link>
              <button
                onClick={handleLogout}
                style={styles.logoutButton}
                className="front-page-button"
              >
                Log Out
              </button>
            </div>
          </>
        ) : (
          // If the user is not logged in, display the login/register forms
          <>
            <div style={styles.tabs}>
              <div
                style={{
                  ...styles.tab,
                  ...(activeForm === "login" ? styles.activeTab : {}),
                }}
                onClick={() => setActiveForm("login")}
              >
                Login
              </div>
              <div
                style={{
                  ...styles.tab,
                  ...(activeForm === "register" ? styles.activeTab : {}),
                }}
                onClick={() => setActiveForm("register")}
              >
                Register
              </div>
            </div>
            {activeForm === "login" ? <LoginForm /> : <Register />}
          </>
        )}
      </div>
    </div>
  );
};

const HomePage = () => {
  const navigate = useNavigate();

  const { addAlert } = useAlert();

  // Direct mapping from the exact brand names to image filenames
  const imagePaths = {
    "A. Lange & Söhne": "A. Lange & Söhne.png",
    "Alexander Shorokhoff": "alexander_shorokhoff.png",
    Anonimo: "anonimo.png",
    "Arnold & Son": "arnold_son.png",
    "Audemars Piguet": "audemarspiguet.png",
    "Baume et Mercier": "Baume_&_Mercier_logo.png",
    "Bell & Ross": "bell-ross.png",
    Blancpain: "Blancpain-logo.png",
    Breitling: "Breitling-Logo.png",
    Breguet: "Breguet_logo.png",
    BULGARI: "Bvlgari-Logo.png",
    "Carl F. Bucherer": "carlbuch.png",
    Cartier: "Cartier.png",
    Chopard: "Logo_Chopard.png",
    Doxa: "Doxa-logo.png",
    "F.P. Journe": "fp-journe.png",
    "Franck Muller Geneve": "Franck_Muller_Logo.png",
    "Frédérique Constant": "frederique-constant.png",
    "Girard-Perregaux": "Girard-Perregaux_logo.png",
    "Glashütte Original": "glashutte_orig.png",
    "Grand Seiko": "Grand_Seiko_Logo.png",
    "H. Moser & Cie": "Moser_Logo.png",
    Hublot: "hublot.png",
    IWC: "IWS_schaffhausen.png",
    "Jacob & Co.": "jacob.png",
    "Jaeger-LeCoultre": "jaeger_lecoultre.png",
    "Jaquet Droz": "JaquetDroz.png",
    "Laurent Ferrier": "laurent-ferrier-logo.png",
    Longines: "Longines-logo.png",
    "MB&F": "mbef.png",
    "Maurice Lacroix": "maurice-lacroix-logo.png",
    Montblanc: "Montblanc_logo.png",
    "Nomos Glashütte": "nomos_glashutte.png",
    Omega: "Omega_Logo.png",
    Panerai: "Panerai-Logo.png",
    "Parmigiani Fleurier": "Logo_Parmigiani.png",
    "Patek Philippe": "patekphilippe.png",
    Piaget: "piaget.png",
    "Richard Mille": "richard_mille.png",
    "Roger Dubuis": "Roger-DuBuis.png",
    Rolex: "rolex.png",
    "TAG Heuer": "TAG_Heuer_Logo.png",
    TUDOR: "Tudor-Logo.png",
    "Ulysse Nardin": "un-new-logo.png",
    "Vacheron Constantin": "Vacheron-Constantin-Logo.png",
    "Van Cleef & Arpels": "van-cleef-and-arpels-logo.png",
    Zenith: "LOGO_ZENITH.png",
    Breguet: "Breguet_logo.png",
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/brands`)
      .then((response) => {
        setBrandsToDisplay(response.data);
        // if (!window.location.href.includes("#register-form")) {
        //   setTimeout(() => {
        //     showQuickExplanationAlert(addAlert);
        //   }, 6500);
        // }
      })
      .catch((error) => {
        console.error("Error fetching brands:", error);
      });
  }, []);

  const [brandsToDisplay, setBrandsToDisplay] = useState([]);

  const filteredBrands = useMemo(() => {
    return brandsToDisplay.filter((brand) => brand && brand.toLowerCase());
  }, [brandsToDisplay]);

  const BrandTile = React.memo(({ brandName, imagePath, onSelect, style }) => {
    return (
      <div className="brand-tile-parent">
        <div className="brand-tile" style={style}>
          <img src={imagePath} alt={brandName} className="brand-logo" />
          <div className="brand-name">{brandName}</div>
        </div>
      </div>
    );
  });

  const displayBrands = () => {
    if (filteredBrands.length === 0) return <div>No brands found.</div>;

    return filteredBrands.map((brandName, index) => {
      let imagePath;

      if (brandName !== "undetermined") {
        imagePath =
          "https://images.autowatch.io/images/resized/" + imagePaths[brandName];
      } else {
        imagePath = null;
      }

      const animationDelay = `${index * 0.04}s`;
      if (imagePath) {
        return (
          <BrandTile
            key={brandName}
            brandName={brandName}
            imagePath={imagePath}
            style={{ animationDelay }}
          />
        );
      } else {
        return null;
      }
    });
  };

  const { startAnimation, watchList } = useMain();

  const autoWatchMainMovingBorderIfScanOn = () => {
    if (startAnimation) {
      return "watchlist-highlight-button-special group3";
    } else {
      return "watchlist-highlight-button-special";
    }
  };

  return (
    <>
      <div className="container" style={{ minHeight: "100vh" }}>
        <button
          onClick={() => navigate("/")}
          className="goto-engine-button group3"
          style={{ position: "absolute" }}
        ></button>

        <button
          onClick={() => navigate("/dashboard")}
          className="goto-account-button"
        ></button>

        <AnimatedHeader />

        <SpinningClock />
        <p className="header-text">
          Discover the perfect luxury watch with ChronoSpy, your personal lead
          engine that scans the market around the clock. Turn scattered
          information into focused, actionable insights. Analyze trends, build
          personalized watchlists, and{" "}
          <span
            style={{
              fontStyle: "italic",
              textDecoration: "underline",
              fontWeight: 600,
            }}
          >
            let our system automatically notify you when the perfect watch
            appears on your radar
          </span>
          .
        </p>

        <p className="header-subtext">
          ChronoSpy is currently in alpha, and we’re inviting a select group of
          users to test and refine the system. We’re continuously improving, and
          your feedback will shape the future of this tool.
        </p>

        <hr />

        <h2
          style={{
            fontWeight: 600,
            fontSize: "2.5rem",
            marginTop: "80px",
            textAlign: "center",
            color: "#ffffff",
          }}
        >
          Why Choose ChronoSpy?
        </h2>

        <AutoWatchDashboard />

        <p className="header-subtext3">
          <br /> <br />
          Instead of relying on platforms with slower, manual listings—or
          services that provide basic search over aggregated messages—we offer
          you refined, actionable leads drawn directly from the heart of dealer
          activity.
        </p>

        <p
          style={{
            margin: "20px",
            marginTop: "80px",
            fontSize: "1.8rem",
            fontWeight: "600",
            textAlign: "center",
            color: "#A48C3D",
          }}
        >
          Unlock Hidden Opportunities in the Luxury Watch Market
        </p>

        <AutoWatchIceberg />

        <p className="header-subtext2">
          <span style={{ fontStyle: "italic" }}>
            In the luxury watch market, countless premium leads lie beneath the
            surface, inaccessible through outdated methods.
          </span>{" "}
          <br /> <br />
          ChronoSpy plunges into exclusive dealer networks, unearthing these
          hidden leads and presenting them in a seamless, user-friendly
          platform.{" "}
          <span style={{ fontStyle: "italic", fontWeight: 600 }}>
            We transform overwhelming offers into clear, actionable insights,
            ensuring you never miss out on the perfect timepiece again
          </span>
          .
        </p>

        <AuthPage />

        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            padding: "0 20px",
            width: "80%%",
            margin: "auto",
            marginTop: "80px",
          }}
        >
          {[
            {
              title: "100x Hotter Leads",
              text: "Exclusive, Highly Targeted Leads",
              description:
                "Access quality exclusive dealer leads to get the freshest opportunities first.",
            },
            {
              title: "Smart Filtering",
              text: "Curate Your Ideal Watch Lead",
              description:
                "Organize leads by specific details like color and date with precision.",
            },
            {
              title: "Automated Alerts",
              text: "Effortless Lead Automation",
              description:
                "Get instant notifications when your ideal watch hits the market.",
            },
          ].map((content, index) => (
            <div
              key={index}
              className="common-gradient"
              style={{
                flex: "1 1 calc(33.33% - 20px)",
                margin: "10px",
                padding: "20px",
                borderRadius: "10px",
                backgroundColor: "rgba(26, 32, 44, 0.4)",
                color: "#f0f0f0",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
                textAlign: "center",
                maxWidth: "320px",
              }}
            >
              <h3
                style={{
                  fontWeight: 600,
                  fontSize: "1.5rem",
                  color: "rgb(164, 140, 61)",
                }}
              >
                {content.title}
              </h3>
              <p style={{ fontSize: "14px", marginTop: "10px" }}>
                {content.text} <br /> <br />
                <span style={{ fontStyle: "italic", fontWeight: 600 }}>
                  {content.description}
                </span>
                .
              </p>
            </div>
          ))}
        </div>

        {/* <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "0 20px",
            margin: "auto",
            marginTop: "60px",
            gap: "40px", // Adding a gap between the columns
            width: "60%",
          }}
        >
          <div
            className="holographic"
            style={{
              flex: "1",
              padding: "20px",
              borderRadius: "10px",
              backgroundColor: "rgba(26, 32, 44, 0.8)",
              color: "#f0f0f0",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between", // Ensures even spacing of content within each box
              minHeight: "200px", // Optional: Set a minimum height for consistency
            }}
          >
            <h3
              style={{
                fontWeight: 600,
                fontSize: "1.5rem",
                color: "rgb(164, 140, 61)",
              }}
            >
              <p style={{ fontStyle: "italic" }}>Coming Soon</p>
              <span style={{ fontWeight: 500 }}>AutoWatch Group Chat</span>
            </h3>
            <p style={{ fontSize: "14px", marginTop: "10px" }}>
              <span style={{ fontWeight: 400 }}>AutoWatch Group Chat</span>{" "}
              blends familiar real-time messaging with an organized,
              dealer-friendly platform. Enjoy seamless conversations and
              enhanced features that streamline your workflow—no learning curve.
            </p>
          </div>

          <div
            className="holographic"
            style={{
              flex: "1",
              padding: "20px",
              borderRadius: "10px",
              backgroundColor: "rgba(26, 32, 44, 0.8)",
              color: "#f0f0f0",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              minHeight: "200px", // Optional: Same minimum height for consistency
            }}
          >
            <h3
              style={{
                fontWeight: 600,
                fontSize: "1.5rem",
                color: "rgb(164, 140, 61)",
              }}
            >
              <p style={{ fontStyle: "italic" }}>Coming Soon</p>
              <span style={{ fontWeight: 500 }}>WatchLink Network Update</span>
            </h3>
            <p style={{ fontSize: "14px", marginTop: "10px" }}>
              The <span style={{ fontWeight: 400 }}>WatchLink Network</span> is
              expanding. Soon, you can post listings across multiple markets and
              receive real-time leads from a wider audience. Enjoy smarter lead
              distribution and complete market visibility—all in one place.
            </p>
          </div>
        </div> */}

        {/* <div
          style={{
            marginTop: "50px",
            textAlign: "center",
            padding: "20px",
            fontSize: "1.8rem",
            fontWeight: 600,
            backgroundColor: "rgba(26, 32, 44, 0.8)",
            color: "#ffffff",
            borderRadius: "10px",
            maxWidth: "720px",
            margin: "40px auto",
            boxShadow: "0 6px 12px rgba(0, 0, 0, 0.4)",
          }}
        >
          <p>Enhance Your Sales with AutoWatch</p>
          <p style={{ fontSize: "2.5rem", color: "rgb(164, 140, 61)" }}>
            $49 <span style={{ fontSize: "1rem" }}>/ month</span>
          </p>
          <button
            style={{
              marginTop: "20px",
              padding: "10px 20px",
              fontSize: "1.5rem",
              color: "#ffffff",
              backgroundColor: "#ff4500",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            Join Now
          </button>
        </div> */}

        <p style={{ margin: "37px", fontSize: "2.4rem" }}>
          Over 33k+ watches supported from most luxury watch brands!
        </p>

        <div className="brand-tiles-container">{displayBrands()}</div>

        <div>
          {" "}
          <Link
            to="/"
            style={{ fontSize: "1rem" }}
            className="watchlist-highlight-button-special"
            onClick={() => window.scrollTo(0, 0)}
          >
            Find Your Perfect Timepiece Now
          </Link>
        </div>
      </div>
    </>
  );
};

const AnimatedCarousel = ({ images, smaller = false }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [preloadedImages, setPreloadedImages] = useState([]);

  useEffect(() => {
    const preloadImages = (imageUrls) => {
      const loadedImages = imageUrls.map((img) => {
        const image = new Image();
        image.src = `https://images.autowatch.io/images/no_watermark/${sanitizeFilename(
          img.image_link
        )}`;
        return image;
      });
      setPreloadedImages(loadedImages);
    };

    preloadImages(images);
  }, [images]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % preloadedImages.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [preloadedImages]);

  let carouselClassName = "animated-carousel";

  if (smaller) {
    carouselClassName = "animated-carousel-smaller";
  }

  return (
    <div className={carouselClassName}>
      {preloadedImages.map((img, index) => (
        <img
          key={`${img.src}-${index}`} // Add index to make key unique
          src={img.src}
          alt={`Watch ${images[index].ref.split("-")[0]}`}
          className="carousel-image"
          style={{
            opacity: index === currentIndex ? 1 : 0,
            zIndex: index === currentIndex ? 1 : 0,
            pointerEvents: "none", // so clicks pass through
          }}
        />
      ))}
    </div>
  );
};

function sanitizeFilename(filename) {
  if (!filename) {
    return filename;
  }
  // This regex replaces any character that is not a letter (including those with diacritics), number, period, underscore, or hyphen.
  return filename.replace(/[^\p{L}\p{N}._-]/gu, "_");
}

function timeAgo(dateStr) {
  const date = new Date(dateStr); // Convert the input string to a Date object
  const now = new Date(); // Get the current time

  const diffInMs = now - date; // Calculate the difference directly

  const seconds = Math.floor(diffInMs / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 0) {
    return `${days} day${days > 1 ? "s" : ""} ago`;
  } else if (hours > 0) {
    return `${hours} hour${hours > 1 ? "s" : ""} ago`;
  } else if (minutes > 0) {
    return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
  } else {
    return `${seconds} second${seconds > 1 ? "s" : ""} ago`;
  }
}

const QuickSearchFilter = ({
  onSearchChange,
  setShowFilters,
  showFilters,
  setIsColorCollapsed,
  setIsBoxPapersCollapsed,
  setIsConditionCollapsed,
  setIsOthersCollapsed,
  setIsPriceLeadsCollapsed,
  selectedColors,
  buyerFilter,
  condition,
  fullSet,
  partialSet,
  onlyWatch,
  priceRange,
  priceDirection,
  meanUsdPrice,
  dateMonth,
  dateYear,
  newerThan,
  manualTags,
  setManualTags,
}) => {
  const [searchInput, setSearchInput] = useState("");

  // Function to generate tile text based on filters
  const getTileText = (category) => {
    switch (category) {
      case "buyers & sellers":
        switch (buyerFilter) {
          case "buyers":
            return "buyers only";
          case "sellers":
            return "sellers only";
          default:
            return "buyers & sellers";
        }

      case "any color":
        return selectedColors.length > 0
          ? selectedColors.join(", ")
          : "any color";

      case "any condition":
        return condition.length > 0 ? condition.join(", ") : "any condition";

      case "any set completeness":
        const setStates = [];
        if (fullSet) setStates.push("full set");
        if (partialSet) setStates.push("partial set");
        if (onlyWatch) setStates.push("only watch");
        return setStates.length > 0
          ? setStates.join(", ")
          : "any set completeness";
      //just returning true below instead of !meanUsdPrice until I add this feature in
      case "any price":
        if (true) return "any price";
        const direction =
          priceDirection === "both"
            ? "±"
            : priceDirection === "above"
            ? ">"
            : "<";
        return `${direction}${priceRange}% of $${meanUsdPrice.toLocaleString()}`;

      case "any date":
        if (!dateMonth && !dateYear) return "any date";
        const month = dateMonth
          ? new Date(2000, parseInt(dateMonth) - 1).toLocaleString("default", {
              month: "short",
            })
          : "";
        const year = dateYear || "";
        const prefix = newerThan ? "after " : "";
        return prefix + [month, year].filter(Boolean).join(" ");

      default:
        return category;
    }
  };

  const [defaultCategories] = useState([
    "buyers & sellers",
    "any color",
    "any condition",
    "any set completeness",
    "any price",
    "any date",
  ]);

  const handleInputChange = (e) => {
    setSearchInput(e.target.value);
  };

  const handleInputKeyDown = (e) => {
    if (e.key === "Enter" && searchInput.trim()) {
      const newTag = searchInput.trim();
      setManualTags((prev) => [...prev, newTag]);
      setSearchInput("");
    }
  };

  const removeManualTag = (tagToRemove) => {
    setManualTags((prev) => prev.filter((tag) => tag !== tagToRemove));
  };
  const handleTileClick = (tag) => {
    setShowFilters(true);

    // Close all sections first
    setIsColorCollapsed(true);
    setIsBoxPapersCollapsed(true);
    setIsConditionCollapsed(true);
    setIsOthersCollapsed(true);
    setIsPriceLeadsCollapsed(true);

    // Then open the relevant section
    switch (tag) {
      case "buyers & sellers":
      case "any price":
        setIsPriceLeadsCollapsed(false);
        break;
      case "any color":
        setIsColorCollapsed(false);
        break;
      case "any condition":
        setIsConditionCollapsed(false);
        break;
      case "any set completeness":
        setIsBoxPapersCollapsed(false);
        break;
      case "any date":
        setIsOthersCollapsed(false);
        break;
      default:
        break;
    }
  };

  return (
    <div className="quick-search-container">
      <div className="search-input-wrapper">
        <label
          htmlFor="filter-search-field"
          className="reference-label"
          style={{ marginTop: "-10px" }}
        >
          Type and press Enter to add manual filters...
        </label>
        <input
          id="filter-search-field"
          type="text"
          value={searchInput}
          onChange={handleInputChange}
          onKeyDown={handleInputKeyDown}
          placeholder="eg., jubilee (or any other keyword)"
          className="quick-search-input"
        />
      </div>

      <div className="search-tags-container">
        {/* Default category tiles */}
        {defaultCategories.map((category, index) => (
          <div
            key={index}
            className="search-tag"
            onClick={() => handleTileClick(category)}
            style={{ cursor: "pointer" }}
          >
            {getTileText(category)}
            <button
              className="tag-edit-btn"
              onClick={(e) => {
                e.stopPropagation();
                handleTileClick(category);
              }}
            >
              ✎
            </button>
          </div>
        ))}

        {/* Manual filter tiles */}
        {manualTags.map((tag, index) => (
          <div key={`manual-${index}`} className="search-tag manual-tag">
            <span className="manual-tag-plus">+</span>
            {tag}
            <button
              className="tag-remove-btn"
              onClick={() => removeManualTag(tag)}
            >
              ×
            </button>
          </div>
        ))}
      </div>

      {/* {!showFilters && (
        <div
          onClick={() => setShowFilters(true)}
          className="experimental-tag"
          style={{ cursor: "pointer", marginBottom: "7px" }}
        >
          More Filters
        </div>
      )} */}
    </div>
  );
};

const LoadingMessage = ({
  watchRef,
  manualTags,
  loading,
  selectedColors,
  buyerFilter,
  priceRange,
  priceDirection,
  fullSet,
  partialSet,
  onlyWatch,
  condition,
  dateMonth,
  dateYear,
  newerThan,
  serial,
  meanUsdPrice,
}) => {
  if (!loading) return null;

  // Helper function to format month
  const getMonthName = (monthNum) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return months[parseInt(monthNum) - 1];
  };

  // Create arrays of active filters by category
  const activeFilters = {
    colors: selectedColors,
    leadType:
      buyerFilter !== "all"
        ? [buyerFilter === "buyers" ? "Buyers Only" : "Sellers Only"]
        : [],
    boxPapers: [
      ...(fullSet ? ["Full Set"] : []),
      ...(partialSet ? ["Partial Set"] : []),
      ...(onlyWatch ? ["Only Watch"] : []),
    ],
    condition: condition,
    date:
      dateMonth || dateYear
        ? [
            `${dateMonth ? getMonthName(dateMonth) : ""} ${
              dateYear || ""
            }`.trim() + (newerThan ? " or newer" : ""),
          ]
        : [],
    serial: serial ? [`Serial: N${serial.replace("N", "")}`] : [],
    price:
      meanUsdPrice && priceRange
        ? [
            `${
              priceDirection === "both"
                ? "±"
                : priceDirection === "above"
                ? ">"
                : "<"
            }${priceRange}% from baseline`,
          ]
        : [],
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "250px",
        width: "100%",
      }}
    >
      <div
        style={{
          fontSize: "1.25rem",
          marginBottom: "1rem",
          textAlign: "center",
        }}
      >
        <span style={{ color: "#ffd700" }}>Searching for leads matching:</span>
      </div>

      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
          gap: "8px",
          marginBottom: "1.5rem",
          maxWidth: "700px",
          padding: "0 20px",
        }}
      >
        {/* Watch ref tag */}
        <span
          style={{
            padding: "4px 12px",
            backgroundColor: "rgba(255, 215, 0, 0.3)",
            color: "#ffe169",
            borderRadius: "20px",
          }}
        >
          {watchRef}
        </span>

        {/* Manual filter tags */}
        {manualTags.map((tag, index) => (
          <span
            key={`manual-${index}`}
            style={{
              padding: "4px 12px",
              backgroundColor: "rgba(34, 197, 94, 0.3)",
              color: "#86efac",
              borderRadius: "20px",
            }}
          >
            {tag}
          </span>
        ))}

        {/* Color filters */}
        {activeFilters.colors.map((color, index) => (
          <span
            key={`color-${index}`}
            style={{
              padding: "4px 12px",
              backgroundColor: "rgba(123, 32, 42, 0.3)",
              color: "#f4a4ae",
              borderRadius: "20px",
            }}
          >
            {color}
          </span>
        ))}

        {/* Lead Type filter */}
        {activeFilters.leadType.map((type, index) => (
          <span
            key={`type-${index}`}
            style={{
              padding: "4px 12px",
              backgroundColor: "rgba(0, 127, 255, 0.3)",
              color: "#99ccff",
              borderRadius: "20px",
            }}
          >
            {type}
          </span>
        ))}

        {/* Box & Papers filters */}
        {activeFilters.boxPapers.map((status, index) => (
          <span
            key={`box-${index}`}
            style={{
              padding: "4px 12px",
              backgroundColor: "rgba(29, 51, 56, 0.3)",
              color: "#a7bdc2",
              borderRadius: "20px",
            }}
          >
            {status}
          </span>
        ))}

        {/* Condition filters */}
        {activeFilters.condition.map((cond, index) => (
          <span
            key={`condition-${index}`}
            style={{
              padding: "4px 12px",
              backgroundColor: "rgba(0, 127, 127, 0.3)",
              color: "#80ffff",
              borderRadius: "20px",
            }}
          >
            {cond}
          </span>
        ))}

        {/* Date filters */}
        {activeFilters.date.map((date, index) => (
          <span
            key={`date-${index}`}
            style={{
              padding: "4px 12px",
              backgroundColor: "rgba(212, 160, 23, 0.3)",
              color: "#ffe169",
              borderRadius: "20px",
            }}
          >
            {date}
          </span>
        ))}

        {/* Serial filter */}
        {activeFilters.serial.map((serialNum, index) => (
          <span
            key={`serial-${index}`}
            style={{
              padding: "4px 12px",
              backgroundColor: "rgba(33, 124, 95, 0.3)",
              color: "#8de4c8",
              borderRadius: "20px",
            }}
          >
            {serialNum}
          </span>
        ))}

        {/* Price Range filters */}
        {activeFilters.price.map((price, index) => (
          <span
            key={`price-${index}`}
            style={{
              padding: "4px 12px",
              backgroundColor: "rgba(255, 215, 0, 0.3)",
              color: "#ffe169",
              borderRadius: "20px",
            }}
          >
            {price}
          </span>
        ))}
      </div>

      {/* Loading animation */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "4px",
        }}
      >
        {[0, 1, 2].map((i) => (
          <div
            key={i}
            style={{
              width: "8px",
              height: "8px",
              backgroundColor: "rgba(255, 255, 255, 0.7)",
              borderRadius: "50%",
              animation: "bounce 0.8s infinite",
              animationDelay: `${i * 150}ms`,
            }}
          />
        ))}
      </div>

      <style>
        {`
          @keyframes bounce {
            0%, 100% {
              transform: translateY(0);
            }
            50% {
              transform: translateY(-10px);
            }
          }
        `}
      </style>
    </div>
  );
};

const PER_PAGE = 10;

const SessionMatchesModal = ({ sessionId, onClose, openWhatsAppChat }) => {
  const [allMatches, setAllMatches] = useState([]);
  const [keywordIdToRef, setKeywordIdToRef] = useState({}); // <-- State to store watch_ref
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Pagination & tab
  const [page, setPage] = useState(1);
  const [selectedTab, setSelectedTab] = useState("all");

  // Fetch matches (all at once, ignoring page on the server)
  const fetchSessionMatches = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/autowatch-sessions/${sessionId}/matches?per_page=99999`,
        { withCredentials: true }
      );
      const fetchedMatches = response.data.matches || [];

      // 1) Build a lookup from user_keyword_id -> watch_ref
      const tempMap = {};
      fetchedMatches.forEach((match) => {
        const userKeywordId = match.user_keyword_id;
        const ref = match?.watch_lead?.watch_ref.split("-!-", 2)[0];
        // Only set it if we have a ref and haven't stored one yet
        if (ref && !tempMap[userKeywordId]) {
          tempMap[userKeywordId] = ref;
        }
      });

      setKeywordIdToRef(tempMap);
      setAllMatches(fetchedMatches);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setError("Error fetching session matches");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSessionMatches();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId]);

  // Whenever we change tabs, reset page to 1
  useEffect(() => {
    setPage(1);
  }, [selectedTab]);

  // Unique user_keyword_ids for the tab buttons
  const uniqueTabs = Array.from(
    new Set(allMatches.map((match) => match.user_keyword_id))
  );

  // Filter for the selected tab
  const filteredMatches =
    selectedTab === "all"
      ? allMatches
      : allMatches.filter((m) => m.user_keyword_id === selectedTab);

  // Local pagination
  const totalPages = Math.ceil(filteredMatches.length / PER_PAGE);
  const startIdx = (page - 1) * PER_PAGE;
  const endIdx = startIdx + PER_PAGE;
  const displayedMatches = filteredMatches.slice(startIdx, endIdx);

  return (
    <div
      className="modal-container"
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgba(0,0,0,0.5)",
        zIndex: 10000,
      }}
    >
      <div
        className="modal"
        style={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#4f4f4f",
          borderRadius: "25px",
          maxWidth: "900px",
          width: "80%",
          maxHeight: "80vh",
          overflow: "hidden",
          position: "relative",
        }}
      >
        {/* Scrollable Content */}
        <div
          className="modal-content"
          style={{
            flex: 1,
            overflowY: "auto",
            padding: "20px",
            color: "#fff",
          }}
        >
          {loading ? (
            <div className="loader-container">
              <div className="loader-text-extra">Fetching Session Matches</div>
              <div className="loader"></div>
            </div>
          ) : error ? (
            <div>{error}</div>
          ) : (
            <>
              {/* HEADER */}
              <div style={{ textAlign: "center", marginBottom: "20px" }}>
                <h2>Session {sessionId} Matches</h2>
                <div>Total Matches: {allMatches.length}</div>
              </div>

              {/* TABS */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "10px",
                  marginBottom: "20px",
                  flexWrap: "wrap",
                }}
              >
                <button
                  onClick={() => setSelectedTab("all")}
                  style={{
                    padding: "0.5em 1em",
                    borderRadius: "8px",
                    backgroundColor:
                      selectedTab === "all" ? "#ff5f5f" : "rgba(0,0,0,0.15)",
                    border: "none",
                    color: "#fff",
                    cursor: "pointer",
                  }}
                >
                  All
                </button>
                {uniqueTabs.map((tab) => (
                  <button
                    key={tab}
                    onClick={() => setSelectedTab(tab)}
                    style={{
                      padding: "0.5em 1em",
                      borderRadius: "8px",
                      backgroundColor:
                        selectedTab === tab ? "#ff5f5f" : "rgba(0,0,0,0.15)",
                      border: "none",
                      color: "#fff",
                      cursor: "pointer",
                    }}
                  >
                    {/* If we have a watch_ref in keywordIdToRef[tab], show it */}
                    {keywordIdToRef[tab] ? `${keywordIdToRef[tab]}` : `${tab}`}
                  </button>
                ))}
              </div>

              {/* MATCHES GRID */}
              <div
                className="matches-grid"
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(auto-fill, minmax(220px, 1fr))",
                  gap: "20px",
                  margin: "auto",
                  width: "60%",
                }}
              >
                {displayedMatches
                  .sort(
                    (a, b) => new Date(b.created_at) - new Date(a.created_at)
                  )
                  .map((match) => {
                    const { watch_lead } = match || {};
                    return (
                      <div
                        key={match.id}
                        className="match-card"
                        style={{
                          position: "relative",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          backgroundColor: "rgba(0, 0, 0, 0.2)",
                          padding: "1em",
                          borderRadius: "25px",
                          boxShadow: "0 1px 3px rgba(0, 0, 0, 0.15)",
                          transition: "transform 0.2s",
                          color: "#fff",
                          border: "1px solid rgba(255, 215, 0, 0.3)",
                        }}
                      >
                        <div
                          style={{
                            fontSize: "1.35em",
                            textAlign: "center",
                            padding: "0.5em",
                            backgroundColor: "rgba(0, 0, 0, 0.3)",
                            borderRadius: "8px",
                            width: "100%",
                            marginBottom: "0.75em",
                          }}
                        >
                          Match {match.id}
                        </div>
                        <div style={{ fontSize: "0.9em" }}>
                          {new Date(match.created_at).toLocaleString()}
                        </div>
                        <div style={{ marginTop: "1em" }}>
                          {/* Example usage of watch_lead fields */}
                          {watch_lead?.segment_text && (
                            <div>Segment: {watch_lead.segment_text}</div>
                          )}

                          {/* "View Source" ICONS ROW (WhatsApp + Messenger) */}
                          <div
                            style={{
                              display: "flex",
                              gap: "8px",
                              marginTop: "10px",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "100%",
                              flexWrap: "wrap",
                            }}
                            onClick={() =>
                              openWhatsAppChat(
                                match.contact_phone_number || match.phone_number
                              )
                            }
                          >
                            <span style={{ fontSize: "0.85em", opacity: 0.7 }}>
                              View Source:
                            </span>

                            {/* WhatsApp Icon */}
                            <svg
                              width="12"
                              height="12"
                              viewBox="0 0 24 24"
                              style={{ opacity: true ? 1 : 0.3 }}
                            >
                              <path
                                fill={true ? "#25D366" : "#808080"}
                                d="M17.472 14.382c-.297-.149-1.758-.867-2.03-.967-.273-.099-.471-.148-.67.15-.197.297-.767.966-.94 1.164-.173.199-.347.223-.644.075-.297-.15-1.255-.463-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.298-.347.446-.52.149-.174.198-.298.298-.497.099-.198.05-.371-.025-.52-.075-.149-.669-1.612-.916-2.207-.242-.579-.487-.5-.669-.51-.173-.008-.371-.01-.57-.01-.198 0-.52.074-.792.372-.272.297-1.04 1.016-1.04 2.479 0 1.462 1.065 2.875 1.213 3.074.149.198 2.096 3.2 5.077 4.487.709.306 1.262.489 1.694.625.712.227 1.36.195 1.871.118.571-.085 1.758-.719 2.006-1.413.248-.694.248-1.289.173-1.413-.074-.124-.272-.198-.57-.347m-5.421 7.403h-.004a9.87 9.87 0 01-5.031-1.378l-.361-.214-3.741.982.998-3.648-.235-.374a9.86 9.86 0 01-1.51-5.26c.001-5.45 4.436-9.884 9.888-9.884 2.64 0 5.122 1.03 6.988 2.898a9.825 9.825 0 012.893 6.994c-.003 5.45-4.437 9.884-9.885 9.884m8.413-18.297A11.815 11.815 0 0012.05 0C5.495 0 .16 5.335.157 11.892c0 2.096.547 4.142 1.588 5.945L.057 24l6.305-1.654a11.882 11.882 0 005.683 1.448h.005c6.554 0 11.89-5.335 11.893-11.893a11.821 11.821 0 00-3.48-8.413"
                              />
                            </svg>

                            {/* Messenger Icon */}
                            <svg
                              width="12"
                              height="12"
                              viewBox="0 0 24 24"
                              style={{
                                opacity: match.source === "messenger" ? 1 : 0.3,
                              }}
                            >
                              <path
                                fill={
                                  match.source === "messenger"
                                    ? "#00B2FF"
                                    : "#808080"
                                }
                                d="M12 2C6.477 2 2 6.145 2 11.243c0 2.966 1.475 5.611 3.778 7.322V22l3.455-1.9c.88.246 1.82.378 2.777.378 5.523 0 10-4.145 10-9.243C22 6.146 17.523 2 12 2zm1.093 12.408l-2.586-2.77-5.046 2.77 5.543-5.89 2.648 2.768 4.971-2.768-5.53 5.89z"
                              />
                            </svg>
                          </div>

                          {/* Contact Info / "Viewed" / Reposts */}
                          <div
                            style={{
                              marginTop: "0.75em",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              gap: "10px",
                              width: "100%",
                            }}
                          >
                            <button
                              onClick={() =>
                                openWhatsAppChat(
                                  match.contact_phone_number ||
                                    match.phone_number
                                )
                              }
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                                background: "none",
                                border: "none",
                                cursor: "pointer",
                                color: "#fff",
                                padding: "0.5em",
                                borderRadius: "8px",
                                transition: "background-color 0.2s",
                                width: "100%",
                                justifyContent: "center",
                              }}
                            >
                              <img
                                src={
                                  match.profile_image_url ||
                                  `https://images.autowatch.io/images/screenshots/${match.contact_phone_number}.png`
                                }
                                // alt={watch_lead.contact_phone_number}
                                style={{
                                  width: "30px",
                                  height: "30px",
                                  borderRadius: "50%",
                                  objectFit: "cover",
                                }}
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.style.display = "none";
                                }}
                              />
                              <span>
                                {match.phone_number ||
                                  match.contact_phone_number ||
                                  match.contact_name ||
                                  match.message_sender}
                              </span>
                            </button>
                          </div>

                          {/* {watch_lead?.watch_ref && (
                            <div>Reference: {watch_lead.watch_ref}</div>
                          )}
                          {watch_lead?.brand && (
                            <div>Brand: {watch_lead.brand}</div>
                          )} */}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </>
          )}
        </div>

        {/* FOOTER */}
        <div
          style={{
            backgroundColor: "#6f6f6f",
            padding: "10px",
            borderRadius: "0 0 25px 25px",
          }}
        >
          {/* Pagination controls (always show if not loading) */}
          <div
            style={{
              marginBottom: "20px",
              display: "flex",
              gap: "20px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <button
              onClick={() => setPage((prev) => Math.max(prev - 1, 1))}
              disabled={loading || page === 1}
              style={{
                backgroundColor: "rgba(0,0,0,0.15)",
                border: "none",
                padding: "0.5em 1em",
                borderRadius: "8px",
                color: loading ? "#aaa" : "#fff",
                cursor: loading ? "not-allowed" : "pointer",
              }}
            >
              Previous
            </button>
            <span style={{ fontWeight: "bold" }}>
              Page {page} of {totalPages}
            </span>
            <button
              onClick={() => setPage((prev) => Math.min(prev + 1, totalPages))}
              disabled={loading || page === totalPages || totalPages === 0}
              style={{
                backgroundColor: "rgba(0,0,0,0.15)",
                border: "none",
                padding: "0.5em 1em",
                borderRadius: "8px",
                color: loading ? "#aaa" : "#fff",
                cursor: loading ? "not-allowed" : "pointer",
              }}
            >
              Next
            </button>
          </div>

          <button
            onClick={onClose}
            disabled={loading}
            style={{
              backgroundColor: loading ? "rgba(0,0,0,0.2)" : "rgba(0,0,0,0.15)",
              border: "none",
              padding: "0.5em 1em",
              borderRadius: "8px",
              color: loading ? "#aaa" : "#fff",
              cursor: loading ? "not-allowed" : "pointer",
              display: "block",
              margin: "0 auto",
            }}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

const SessionsModal = ({
  userId,
  onClose,
  fetchSessionMatches,
  openWhatsAppChat,
}) => {
  const [sessions, setSessions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [showSessionMatches, setShowSessionMatches] = useState(false);
  const [selectedSessionId, setSelectedSessionId] = useState(null);

  const openSessionMatches = (sessionId) => {
    fetchSessionMatches(sessionId);
    setSelectedSessionId(sessionId);
    setShowSessionMatches(true);
  };

  useEffect(() => {
    const fetchSessions = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/autowatch-sessions/${userId}`,
          { withCredentials: true }
        );
        setSessions(response.data);
      } catch (err) {
        console.error(err);
        setError("Error fetching sessions");
      } finally {
        setLoading(false);
      }
    };
    fetchSessions();
  }, [userId]);

  const calculateDuration = (start, end) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    const durationMs = endDate - startDate;
    const minutes = Math.floor(durationMs / 60000);
    const seconds = Math.floor((durationMs % 60000) / 1000);
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return hours > 0
      ? `${hours}h ${remainingMinutes}m`
      : `${minutes}m ${seconds}s`;
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content-sessions">
        <button className="modal-sessions-close" onClick={onClose}>
          ×
        </button>
        <h2>Your Autowatch Sessions</h2>
        {loading && <p>Loading sessions...</p>}
        {error && <p>{error}</p>}
        {!loading && !error && (
          <div className="sessions-list">
            {sessions.length > 0 ? (
              sessions.map((session) => (
                <div key={session.id} className="session-item">
                  <div className="session-header">
                    <span className="session-id">Session #{session.id}</span>
                    <span className="session-status">{session.status}</span>
                  </div>
                  <div className="session-details">
                    <div className="session-duration">
                      <strong>Duration:</strong>{" "}
                      {calculateDuration(session.started_at, session.ends_at)}
                    </div>
                    <div className="session-started">
                      <strong>Started:</strong>{" "}
                      {new Date(session.started_at).toLocaleString()}
                    </div>
                  </div>
                  <button
                    className="total-matches-btn"
                    onClick={() => openSessionMatches(session.id)}
                  >
                    Total Matches: {session.computed_matches}
                  </button>
                </div>
              ))
            ) : (
              <p>No sessions found.</p>
            )}
            {showSessionMatches && (
              <SessionMatchesModal
                sessionId={selectedSessionId}
                onClose={() => setShowSessionMatches(false)}
                openWhatsAppChat={openWhatsAppChat}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

function WatchLeadsModal({
  selectedRef,
  showLeadsModal,
  setShowLeadsModal,
  watchIds,
  meanUsdPrice,
  uniqueColors,
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedColors, setSelectedColors] = useState([]);
  const [filteredLeads, setFilteredLeads] = useState([]);

  const [fullSet, setFullSet] = useState(false);
  const [partialSet, setPartialSet] = useState(false);
  const [onlyWatch, setOnlyWatch] = useState(false);
  const [noPapersTags, setNoPapersTags] = useState(false);
  const [condition, setCondition] = useState([]);
  const [dateMonth, setDateMonth] = useState("");
  const [dateYear, setDateYear] = useState("");
  const [newerThan, setNewerThan] = useState(false);
  const [serial, setSerial] = useState("");
  const [convertToUsd, setConvertToUsd] = useState(false);
  const [onlyUsd, setOnlyUsd] = useState(false);

  const [buyerFilter, setBuyerFilter] = useState("all");
  const [priceRange, setPriceRange] = useState(5);
  const [priceDirection, setPriceDirection] = useState("both");

  const [isColorCollapsed, setIsColorCollapsed] = useState(true);
  const [isBoxPapersCollapsed, setIsBoxPapersCollapsed] = useState(true);
  const [isConditionCollapsed, setIsConditionCollapsed] = useState(true);
  const [isOthersCollapsed, setIsOthersCollapsed] = useState(true);
  const [isPriceLeadsCollapsed, setIsPriceLeadsCollapsed] = useState(true);

  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 6;

  const [loading, setLoading] = useState(false);
  const [showLeadImageModal, setShowLeadImageModal] = useState(false);
  const [modalImageUrl, setModalImageUrl] = useState("");
  const [showFilters, setShowFilters] = useState(false);

  const [manualTags, setManualTags] = useState([]);
  const [quickSearchTerms, setQuickSearchTerms] = useState([]);

  const { addAlert } = useAlert();
  const { currentUser } = useAuth();

  const { currentSelectedReference } = useMain();

  const handleIncorrectDetection = (watchLeadId) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/submit_feedback`,
        {
          user_id: currentUser.id,
          watch_lead_id: watchLeadId,
        },
        { withCredentials: true }
      )
      .then(() => {
        addAlert({
          message: "Feedback submitted. Thank you.",
          type: "success",
        });
      })
      .catch((error) => console.error("Error:", error));
  };

  const openLeadImageModal = (imageUrl) => {
    setModalImageUrl(imageUrl);
    setShowLeadImageModal(true);
  };

  const closeLeadImageModal = () => {
    setShowLeadImageModal(false);
    setModalImageUrl("");
  };

  const openWhatsAppChat = (phoneNumber) => {
    if (!phoneNumber) {
      alert("Phone number not available");
      return;
    }
    const formattedPhoneNumber = phoneNumber.replace(/\D/g, "");
    window.open(`https://wa.me/${formattedPhoneNumber}`, "_blank");
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/filter-watch-leads`,
          {
            watch_ids: watchIds,
            color: selectedColors.length > 0 ? selectedColors.join(",") : "",
            full_set: fullSet,
            partial_set: partialSet,
            only_watch: onlyWatch,
            no_papers_tags: noPapersTags,
            condition: condition,
            date_month: dateMonth,
            date_year: dateYear,
            newer_than: newerThan,
            serial: serial,
            convert_to_usd: convertToUsd,
            only_usd: onlyUsd,
            mean_usd_price: onlyUsd ? meanUsdPrice : null,
            buyer_filter: buyerFilter,
            price_range: meanUsdPrice ? priceRange : null,
            price_direction: meanUsdPrice ? priceDirection : null,
            baseline_price: meanUsdPrice || null,
            page: currentPage,
            items_per_page: itemsPerPage,
            manual_filters: manualTags,
          },
          {
            withCredentials: true,
          }
        );

        if (response.status === 200) {
          setFilteredLeads(response.data.watch_leads || []);
          setTotalPages(response.data.total_pages || 1);
        }
      } catch (error) {
        console.error("Error fetching filtered watch leads:", error);
      } finally {
        setLoading(false);
      }
    };

    // If filters change, reset to page 1
    if (currentPage === 1) {
      fetchData();
    } else {
      setCurrentPage(1);
    }
  }, [
    watchIds,
    selectedColors,
    fullSet,
    partialSet,
    onlyWatch,
    noPapersTags,
    condition,
    dateMonth,
    dateYear,
    newerThan,
    serial,
    convertToUsd,
    onlyUsd,
    buyerFilter,
    priceRange,
    priceDirection,
    quickSearchTerms,
    manualTags,
  ]);

  // Separate effect for page changes only
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/filter-watch-leads`,
          {
            watch_ids: watchIds,
            color: selectedColors.length > 0 ? selectedColors.join(",") : "",
            full_set: fullSet,
            partial_set: partialSet,
            only_watch: onlyWatch,
            no_papers_tags: noPapersTags,
            condition: condition,
            date_month: dateMonth,
            date_year: dateYear,
            newer_than: newerThan,
            serial: serial,
            convert_to_usd: convertToUsd,
            only_usd: onlyUsd,
            mean_usd_price: onlyUsd ? meanUsdPrice : null,
            buyer_filter: buyerFilter,
            price_range: meanUsdPrice ? priceRange : null,
            price_direction: meanUsdPrice ? priceDirection : null,
            baseline_price: meanUsdPrice || null,
            page: currentPage,
            items_per_page: itemsPerPage,
            manual_filters: manualTags,
          },
          {
            withCredentials: true,
          }
        );

        if (response.status === 200) {
          setFilteredLeads(response.data.watch_leads || []);
          setTotalPages(response.data.total_pages || 1);
        }
      } catch (error) {
        console.error("Error fetching filtered watch leads:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [currentPage]);

  const handleColorChange = (color) => {
    setSelectedColors((prevSelected) =>
      prevSelected.includes(color)
        ? prevSelected.filter((c) => c !== color)
        : [...prevSelected, color]
    );
  };

  const handleConditionChange = (selectedCondition) => {
    setCondition((prevConditions) =>
      prevConditions.includes(selectedCondition)
        ? prevConditions.filter((c) => c !== selectedCondition)
        : [...prevConditions, selectedCondition]
    );
  };
  const returnTitleIfFilteredLeadsLoaded = () => {
    if (!filteredLeads || filteredLeads.length === 0) {
      return <h3 style={{ marginTop: "-17px" }}>Loading leads...</h3>;
    }

    return (
      <h3 style={{ marginTop: "-17px" }}>
        Recent{" "}
        <span style={{ color: "rgb(255, 204, 0)" }}>
          {currentSelectedReference}
        </span>{" "}
        leads
      </h3>
    );
  };

  return (
    showLeadsModal && (
      <div className="modal-container">
        <div className="modal">
          <div className="modal-content">
            {returnTitleIfFilteredLeadsLoaded()}
            <div className="filter-console">
              <QuickSearchFilter
                onSearchChange={setQuickSearchTerms}
                setShowFilters={setShowFilters}
                showFilters={showFilters}
                setIsColorCollapsed={setIsColorCollapsed}
                setIsBoxPapersCollapsed={setIsBoxPapersCollapsed}
                setIsConditionCollapsed={setIsConditionCollapsed}
                setIsOthersCollapsed={setIsOthersCollapsed}
                setIsPriceLeadsCollapsed={setIsPriceLeadsCollapsed}
                selectedColors={selectedColors}
                buyerFilter={buyerFilter}
                condition={condition}
                fullSet={fullSet}
                partialSet={partialSet}
                onlyWatch={onlyWatch}
                priceRange={priceRange}
                priceDirection={priceDirection}
                meanUsdPrice={meanUsdPrice}
                dateMonth={dateMonth}
                dateYear={dateYear}
                newerThan={newerThan}
                manualTags={manualTags}
                setManualTags={setManualTags}
              />
              {showFilters && (
                <div className="filter-console-grid">
                  <button onClick={() => setShowFilters(false)}>
                    Hide Filters
                  </button>
                  <div>
                    {/* Color Filter */}
                    <div className="filter-section">
                      <h4
                        onClick={() => setIsColorCollapsed(!isColorCollapsed)}
                      >
                        Filter by Color:{" "}
                        <span>{isColorCollapsed ? "▼" : "▲"}</span>
                      </h4>
                      {!isColorCollapsed && (
                        <div className="color-filters">
                          {uniqueColors.map((color, index) => (
                            <label key={index}>
                              <input
                                disabled={loading}
                                type="checkbox"
                                value={color}
                                onChange={() => handleColorChange(color)}
                                checked={selectedColors.includes(color)}
                              />
                              {color}
                            </label>
                          ))}
                        </div>
                      )}
                    </div>

                    {/* Price & Lead Type */}
                    <div className="filter-section">
                      <h4
                        onClick={() =>
                          setIsPriceLeadsCollapsed(!isPriceLeadsCollapsed)
                        }
                      >
                        Price & Lead Type:{" "}
                        <span>{isPriceLeadsCollapsed ? "▼" : "▲"}</span>
                      </h4>
                      {!isPriceLeadsCollapsed && (
                        <div>
                          <div
                            className="lead-type-filter"
                            style={{ marginBottom: "15px" }}
                          >
                            <h5 style={{ marginBottom: "5px" }}>Lead Type:</h5>
                            <div
                              className="radio-group"
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "8px",
                              }}
                            >
                              <label
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "8px",
                                }}
                              >
                                <input
                                  disabled={loading}
                                  type="radio"
                                  name="leadType"
                                  value="all"
                                  checked={buyerFilter === "all"}
                                  onChange={(e) =>
                                    setBuyerFilter(e.target.value)
                                  }
                                />
                                All Leads
                              </label>
                              <label
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "8px",
                                }}
                              >
                                <input
                                  disabled={loading}
                                  type="radio"
                                  name="leadType"
                                  value="buyers"
                                  checked={buyerFilter === "buyers"}
                                  onChange={(e) =>
                                    setBuyerFilter(e.target.value)
                                  }
                                />
                                Buyers Only
                              </label>
                              <label
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "8px",
                                }}
                              >
                                <input
                                  disabled={loading}
                                  type="radio"
                                  name="leadType"
                                  value="sellers"
                                  checked={buyerFilter === "sellers"}
                                  onChange={(e) =>
                                    setBuyerFilter(e.target.value)
                                  }
                                />
                                Sellers Only
                              </label>
                            </div>
                          </div>

                          <div className="price-range-filter">
                            <h5 style={{ marginBottom: "5px" }}>
                              Price Range:
                            </h5>
                            {meanUsdPrice == 1 ? (
                              <>
                                <div style={{ marginBottom: "10px" }}>
                                  <select
                                    disabled={loading}
                                    value={priceDirection}
                                    onChange={(e) =>
                                      setPriceDirection(e.target.value)
                                    }
                                    style={{
                                      width: "100%",
                                      marginBottom: "10px",
                                    }}
                                  >
                                    <option value="both">
                                      Above &amp; Below Baseline
                                    </option>
                                    <option value="below">
                                      Below Baseline Only
                                    </option>
                                    <option value="above">
                                      Above Baseline Only
                                    </option>
                                  </select>

                                  <select
                                    disabled={loading}
                                    value={priceRange}
                                    onChange={(e) =>
                                      setPriceRange(Number(e.target.value))
                                    }
                                    style={{ width: "100%" }}
                                  >
                                    <option value="3">±3% from baseline</option>
                                    <option value="5">±5% from baseline</option>
                                    <option value="7">±7% from baseline</option>
                                    <option value="10">
                                      ±10% from baseline
                                    </option>
                                  </select>
                                </div>

                                <div
                                  className="price-range-display"
                                  style={{
                                    padding: "10px",
                                    borderRadius: "4px",
                                    fontSize: "0.9em",
                                  }}
                                >
                                  <p
                                    style={{
                                      fontWeight: "500",
                                      marginBottom: "5px",
                                    }}
                                  >
                                    Current Range:
                                  </p>
                                  {priceDirection !== "above" && (
                                    <p>
                                      Min: $
                                      {(
                                        (meanUsdPrice || 0) *
                                        (1 - priceRange / 100)
                                      ).toLocaleString()}
                                    </p>
                                  )}
                                  <p style={{ color: "#666" }}>
                                    Baseline: $
                                    {(meanUsdPrice || 0).toLocaleString()}
                                  </p>
                                  {priceDirection !== "below" && (
                                    <p>
                                      Max: $
                                      {(
                                        (meanUsdPrice || 0) *
                                        (1 + priceRange / 100)
                                      ).toLocaleString()}
                                    </p>
                                  )}
                                </div>
                              </>
                            ) : (
                              <p style={{ color: "#666" }}>
                                Price filtering coming soon.
                              </p>
                            )}
                          </div>
                        </div>
                      )}
                    </div>

                    {/* Box & Papers */}
                    <div className="filter-section">
                      <h4
                        onClick={() =>
                          setIsBoxPapersCollapsed(!isBoxPapersCollapsed)
                        }
                      >
                        Box &amp; Papers:{" "}
                        <span>{isBoxPapersCollapsed ? "▼" : "▲"}</span>
                      </h4>
                      {!isBoxPapersCollapsed && (
                        <div className="box-paper-filters">
                          <label>
                            <input
                              disabled={loading}
                              type="checkbox"
                              checked={fullSet}
                              onChange={() => setFullSet(!fullSet)}
                            />
                            Full Set
                          </label>
                          <label>
                            <input
                              disabled={loading}
                              type="checkbox"
                              checked={partialSet}
                              onChange={() => setPartialSet(!partialSet)}
                            />
                            Partial Set
                          </label>
                          <label>
                            <input
                              disabled={loading}
                              type="checkbox"
                              checked={onlyWatch}
                              onChange={() => setOnlyWatch(!onlyWatch)}
                            />
                            Only Watch
                          </label>
                        </div>
                      )}
                    </div>

                    {/* Condition */}
                    <div className="filter-section">
                      <h4
                        onClick={() =>
                          setIsConditionCollapsed(!isConditionCollapsed)
                        }
                      >
                        Condition:{" "}
                        <span>{isConditionCollapsed ? "▼" : "▲"}</span>
                      </h4>
                      {!isConditionCollapsed && (
                        <div className="condition-filter">
                          {[
                            "Brand New",
                            "Like New / Mint",
                            "Preowned or Used",
                            "Unpolished",
                            "Polished",
                          ].map((cond, index) => (
                            <label key={index}>
                              <input
                                disabled={loading}
                                type="checkbox"
                                value={cond}
                                onChange={() => handleConditionChange(cond)}
                                checked={condition.includes(cond)}
                              />
                              {cond}
                            </label>
                          ))}
                        </div>
                      )}
                    </div>

                    {/* Other Filters */}
                    <div className="filter-section">
                      <h4
                        onClick={() => setIsOthersCollapsed(!isOthersCollapsed)}
                      >
                        Other Filters:{" "}
                        <span>{isOthersCollapsed ? "▼" : "▲"}</span>
                      </h4>
                      {!isOthersCollapsed && (
                        <div>
                          <div className="dated-filter">
                            <h5 style={{ marginBottom: "-2px" }}>
                              Dated (Month, Year):
                            </h5>
                            <select
                              value={dateMonth}
                              onChange={(e) => setDateMonth(e.target.value)}
                            >
                              <option disabled={loading} value="">
                                Month
                              </option>
                              <option disabled={loading} value="01">
                                January
                              </option>
                              <option disabled={loading} value="02">
                                February
                              </option>
                              <option disabled={loading} value="03">
                                March
                              </option>
                              <option disabled={loading} value="04">
                                April
                              </option>
                              <option disabled={loading} value="05">
                                May
                              </option>
                              <option disabled={loading} value="06">
                                June
                              </option>
                              <option disabled={loading} value="07">
                                July
                              </option>
                              <option disabled={loading} value="08">
                                August
                              </option>
                              <option disabled={loading} value="09">
                                September
                              </option>
                              <option disabled={loading} value="10">
                                October
                              </option>
                              <option disabled={loading} value="11">
                                November
                              </option>
                              <option disabled={loading} value="12">
                                December
                              </option>
                            </select>
                            <select
                              value={dateYear}
                              onChange={(e) => setDateYear(e.target.value)}
                            >
                              <option disabled={loading} value="">
                                Year
                              </option>
                              <option disabled={loading} value="2024">
                                2024
                              </option>
                              <option disabled={loading} value="2023">
                                2023
                              </option>
                              <option disabled={loading} value="2022">
                                2022
                              </option>
                              <option disabled={loading} value="2021">
                                2021
                              </option>
                              <option disabled={loading} value="2020">
                                2020
                              </option>
                              <option disabled={loading} value="2019">
                                2019
                              </option>
                              <option disabled={loading} value="2018">
                                2018
                              </option>
                              <option disabled={loading} value="2017">
                                2017
                              </option>
                              <option disabled={loading} value="2016">
                                2016
                              </option>
                              <option disabled={loading} value="2015">
                                2015
                              </option>
                              <option disabled={loading} value="2014">
                                2014
                              </option>
                              <option disabled={loading} value="2013">
                                2013
                              </option>
                              <option disabled={loading} value="2012">
                                2012
                              </option>
                            </select>
                            <label>
                              <input
                                disabled={loading}
                                type="checkbox"
                                checked={newerThan}
                                onChange={() => setNewerThan(!newerThan)}
                              />
                              Or newer than
                            </label>
                          </div>
                          <div className="serial-filter">
                            <h5 style={{ marginBottom: "-2px" }}>Serial:</h5>
                            <select
                              value={serial}
                              onChange={(e) => setSerial(e.target.value)}
                            >
                              <option disabled={loading} value="">
                                All Serials
                              </option>
                              {Array.from({ length: 12 }, (_, i) => i + 1).map(
                                (serialNum) => (
                                  <option
                                    key={serialNum}
                                    disabled={loading}
                                    value={`N${serialNum}`}
                                  >
                                    N{serialNum}
                                  </option>
                                )
                              )}
                            </select>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
            {filteredLeads.length == 0 && !loading && (
              <div>
                <img
                  className="no-results-found-pic"
                  src={noResults}
                  style={{
                    margin: "auto",
                    justifyContent: "center",
                    display: "flex",
                    width: "350px",
                  }}
                />
              </div>
            )}

            <div
              className="watch-leads-display"
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fill, minmax(220px, 1fr))",
                gap: "20px",
                padding: "20px",
                marginTop: "-20px",
                margin: loading ? "auto" : "none",
              }}
            >
              {loading ? (
                <LoadingMessage
                  watchRef={currentSelectedReference}
                  manualTags={manualTags}
                  loading={loading}
                  selectedColors={selectedColors}
                  buyerFilter={buyerFilter}
                  priceRange={priceRange}
                  priceDirection={priceDirection}
                  fullSet={fullSet}
                  partialSet={partialSet}
                  onlyWatch={onlyWatch}
                  condition={condition}
                  dateMonth={dateMonth}
                  dateYear={dateYear}
                  newerThan={newerThan}
                  serial={serial}
                  meanUsdPrice={null}
                />
              ) : (
                filteredLeads.length > 0 &&
                filteredLeads.map((lead, index) => (
                  <div
                    className="animated-lead"
                    key={lead.id}
                    style={{
                      position: "relative",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      backgroundColor: "rgba(0, 0, 0, 0.2)",
                      padding: "1em",
                      borderRadius: "25px",
                      boxShadow: "0 1px 3px rgba(0, 0, 0, 0.15)",
                      transition: "transform 0.2s",
                      color: "#fff",
                      border: "1px solid rgba(255, 215, 0, 0.3)",
                    }}
                  >
                    {/* Top info: segment text and the lead item */}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        width: "100%",
                        gap: "0.5em",
                      }}
                    >
                      <div
                        style={{
                          fontSize: "1.35em",
                          textAlign: "center",
                          padding: "0.5em",
                          backgroundColor: "rgba(0, 0, 0, 0.3)",
                          borderRadius: "8px",
                          width: "100%",
                        }}
                      >
                        {lead.segment_text}
                      </div>

                      <LeadItem
                        key={lead.id}
                        lead={lead}
                        handleIncorrectDetection={handleIncorrectDetection}
                      />

                      {/* "View Source" or icons row */}
                      <div
                        style={{
                          display: "flex",
                          gap: "8px",
                          marginTop: "4px",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                          flexWrap: "wrap",
                        }}
                        onClick={() =>
                          openWhatsAppChat(
                            lead.contact_phone_number || lead.phone_number
                          )
                        }
                      >
                        <span style={{ fontSize: "0.85em", opacity: 0.7 }}>
                          Source:
                        </span>

                        {/* WhatsApp Icon */}
                        <svg
                          width="12"
                          height="12"
                          viewBox="0 0 24 24"
                          style={{ opacity: true ? 1 : 0.3 }}
                        >
                          <path
                            fill={true ? "#25D366" : "#808080"}
                            d="M17.472 14.382c-.297-.149-1.758-.867-2.03-.967-.273-.099-.471-.148-.67.15-.197.297-.767.966-.94 1.164-.173.199-.347.223-.644.075-.297-.15-1.255-.463-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.298-.347.446-.52.149-.174.198-.298.298-.497.099-.198.05-.371-.025-.52-.075-.149-.669-1.612-.916-2.207-.242-.579-.487-.5-.669-.51-.173-.008-.371-.01-.57-.01-.198 0-.52.074-.792.372-.272.297-1.04 1.016-1.04 2.479 0 1.462 1.065 2.875 1.213 3.074.149.198 2.096 3.2 5.077 4.487.709.306 1.262.489 1.694.625.712.227 1.36.195 1.871.118.571-.085 1.758-.719 2.006-1.413.248-.694.248-1.289.173-1.413-.074-.124-.272-.198-.57-.347m-5.421 7.403h-.004a9.87 9.87 0 01-5.031-1.378l-.361-.214-3.741.982.998-3.648-.235-.374a9.86 9.86 0 01-1.51-5.26c.001-5.45 4.436-9.884 9.888-9.884 2.64 0 5.122 1.03 6.988 2.898a9.825 9.825 0 012.893 6.994c-.003 5.45-4.437 9.884-9.885 9.884m8.413-18.297A11.815 11.815 0 0012.05 0C5.495 0 .16 5.335.157 11.892c0 2.096.547 4.142 1.588 5.945L.057 24l6.305-1.654a11.882 11.882 0 005.683 1.448h.005c6.554 0 11.89-5.335 11.893-11.893a11.821 11.821 0 00-3.48-8.413"
                          />
                        </svg>

                        {/* Facebook Messenger Icon */}
                        <svg
                          width="12"
                          height="12"
                          viewBox="0 0 24 24"
                          style={{
                            opacity: lead.source === "messenger" ? 1 : 0.3,
                          }}
                        >
                          <path
                            fill={
                              lead.source === "messenger"
                                ? "#00B2FF"
                                : "#808080"
                            }
                            d="M12 2C6.477 2 2 6.145 2 11.243c0 2.966 1.475 5.611 3.778 7.322V22l3.455-1.9c.88.246 1.82.378 2.777.378 5.523 0 10-4.145 10-9.243C22 6.146 17.523 2 12 2zm1.093 12.408l-2.586-2.77-5.046 2.77 5.543-5.89 2.648 2.768 4.971-2.768-5.53 5.89z"
                          />
                        </svg>
                      </div>

                      {/* Button to open WhatsApp chat */}
                      <button
                        onClick={() =>
                          openWhatsAppChat(
                            lead.contact_phone_number || lead.phone_number
                          )
                        }
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                          background: "none",
                          border: "none",
                          cursor: "pointer",
                          color: "#fff",
                          padding: "0.5em",
                          borderRadius: "8px",
                          transition: "background-color 0.2s",
                          width: "100%",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src={`https://images.autowatch.io/images/screenshots/${lead.contact_phone_number}.png`}
                          // alt={lead.contact_phone_number}
                          style={{
                            width: "30px",
                            height: "30px",
                            borderRadius: "50%",
                            objectFit: "cover",
                          }}
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.style.display = "none";
                          }}
                        />
                        <span>
                          {lead.phone_number ||
                            lead.contact_phone_number ||
                            lead.contact_name ||
                            lead.message_sender}
                        </span>
                      </button>

                      {/* Time + post count */}
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                          fontSize: "0.8em",
                          color: "#ccc",
                        }}
                      >
                        <span>{timeAgo(lead.message_time)}</span>
                        <span>
                          {lead.post_count === 0
                            ? "Fresh Lead"
                            : `Posted x${lead.post_count}`}
                        </span>
                      </div>

                      <HighlightedMessage
                        message={lead.message}
                        segmentText={lead.segment_text}
                      />
                    </div>

                    {/* Polaroid-style image container (small) below the "View Source" + Button */}
                    {lead.image_url && (
                      <div
                        style={{
                          marginTop: "1em",
                          backgroundColor: "#fff",
                          border: "1px solid #ccc",
                          padding: "10px",
                          borderRadius: "5px",
                          width: "90px",
                          textAlign: "center",
                          boxShadow: "0 2px 6px rgba(0,0,0,0.2)",
                        }}
                      >
                        <img
                          style={{
                            width: "100%",
                            borderRadius: "3px",
                            objectFit: "cover",
                            cursor: "pointer",
                          }}
                          src={lead.image_url}
                          alt="Watch"
                          onClick={() => openLeadImageModal(lead.image_url)}
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.style.display = "none";
                          }}
                        />
                      </div>
                    )}

                    {/* TAGS at the bottom, displayed in a row, responsive & centered */}
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "center",
                        gap: "5px",
                        marginTop: "1em",
                      }}
                    >
                      {lead.year && (
                        <div
                          style={{
                            backgroundColor: "rgba(212, 160, 23, 0.85)",
                            color: "#000000",
                            padding: "3px 8px",
                            borderRadius: "12px",
                            boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
                            textTransform: "uppercase",
                            fontSize: "0.5em",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {lead.year}
                        </div>
                      )}

                      {lead.condition && (
                        <div
                          style={{
                            backgroundColor: "rgba(0, 127, 127, 0.85)",
                            color: "#ffffff",
                            padding: "3px 8px",
                            borderRadius: "12px",
                            boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
                            textTransform: "uppercase",
                            fontSize: "0.5em",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {lead.condition}
                        </div>
                      )}

                      {lead.material && (
                        <div
                          style={{
                            backgroundColor: "rgba(33, 124, 95, 0.85)",
                            color: "#ffffff",
                            padding: "3px 8px",
                            borderRadius: "12px",
                            boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
                            textTransform: "uppercase",
                            fontSize: "0.5em",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {lead.material}
                        </div>
                      )}

                      {lead.color && (
                        <div
                          style={{
                            backgroundColor:
                              lead.color.toLowerCase() === "gold"
                                ? "rgba(212, 160, 23, 0.85)"
                                : "rgba(123, 32, 42, 0.85)",
                            color: "#ffffff",
                            padding: "3px 8px",
                            borderRadius: "12px",
                            boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
                            textTransform: "uppercase",
                            fontSize: "0.5em",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {lead.color}
                        </div>
                      )}

                      {lead.box_paper_tag && (
                        <div
                          style={{
                            backgroundColor: "rgba(29, 51, 56, 0.9)",
                            color: "#ffffff",
                            padding: "3px 8px",
                            borderRadius: "12px",
                            boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
                            textTransform: "uppercase",
                            fontSize: "0.5em",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {lead.box_paper_tag}
                        </div>
                      )}

                      {lead.special_features &&
                        lead.special_features.split(",").map((feature, i) => (
                          <div
                            key={i}
                            style={{
                              backgroundColor: "rgba(0, 107, 107, 0.85)",
                              color: "#ffffff",
                              padding: "3px 8px",
                              borderRadius: "12px",
                              fontSize: "0.5em",
                              boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
                              textTransform: "uppercase",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {feature.trim()}
                          </div>
                        ))}
                    </div>
                  </div>
                ))
              )}
            </div>

            {loading && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  margin: "-5px",
                }}
              >
                <h2 className="loader-text-extra">Fetching leads...</h2>

                <p style={{ fontSize: " 11px" }}>(this might be slow...)</p>
              </div>
            )}
            {!loading && filteredLeads.length === 0 && (
              <h2>No results found.</h2>
            )}

            {/* PAGINATION */}
            <div className="pagination-controls">
              <button
                style={{ marginRight: "20px" }}
                disabled={currentPage === 1 || loading}
                onClick={() => setCurrentPage((prev) => prev - 1)}
              >
                ←
              </button>
              <span>
                Page {currentPage} of {totalPages}
              </span>
              <button
                style={{ marginLeft: "20px" }}
                disabled={currentPage === totalPages || loading}
                onClick={() => setCurrentPage((prev) => prev + 1)}
              >
                →
              </button>
            </div>

            <button
              className="modal-close-btn"
              onClick={() => {
                setShowLeadsModal(false);
                toggleBodyScroll(false);
              }}
            >
              Close
            </button>
          </div>
        </div>

        {/* Lead Image Modal */}
        {showLeadImageModal && (
          <div className="modal-overlay">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <img
                src={modalImageUrl}
                alt="Lead Fullsize"
                style={{ maxWidth: "50%", maxHeight: "50%", margin: "auto" }}
              />
              <button
                style={{ width: "20%", marginTop: "10px", margin: "auto" }}
                onClick={closeLeadImageModal}
              >
                Close
              </button>
            </div>
          </div>
        )}
      </div>
    )
  );
}

function WatchlistFilterModalLayout({
  showFilterModalLayout,
  setShowFilterModalLayout,
  currentUser,
  selectedWatchId,
  isBuy,
  fetchKeywords,
  addAlert,
  existingFilters,
}) {
  // Existing filter states initialized with props
  const [selectedColors, setSelectedColors] = useState(
    existingFilters.selected_colors || []
  );
  const [fullSet, setFullSet] = useState(existingFilters.full_set);
  const [partialSet, setPartialSet] = useState(existingFilters.partial_set);
  const [onlyWatch, setOnlyWatch] = useState(existingFilters.only_watch);
  const [noPapersTags, setNoPapersTags] = useState(
    existingFilters.no_papers_tags
  );
  const [condition, setCondition] = useState(existingFilters.condition || []);
  const [dateMonth, setDateMonth] = useState(existingFilters.date_month);
  const [dateYear, setDateYear] = useState(existingFilters.date_year);
  const [newerThan, setNewerThan] = useState(existingFilters.newer_than);
  const [serial, setSerial] = useState(existingFilters.serial);
  const [convertToUsd, setConvertToUsd] = useState(
    existingFilters.convert_to_usd
  );
  const [onlyUsd, setOnlyUsd] = useState(existingFilters.only_usd);

  // New states for quick search filter
  const [manualTags, setManualTags] = useState(
    existingFilters.manual_filters || []
  );
  const [showFilters, setShowFilters] = useState(false);
  const [loading, setLoading] = useState(false);

  // Collapse states
  const [isColorCollapsed, setIsColorCollapsed] = useState(true);
  const [isBoxPapersCollapsed, setIsBoxPapersCollapsed] = useState(true);
  const [isConditionCollapsed, setIsConditionCollapsed] = useState(true);
  const [isOthersCollapsed, setIsOthersCollapsed] = useState(true);
  const [isPriceLeadsCollapsed, setIsPriceLeadsCollapsed] = useState(true);

  // Extract unique colors from existingFilters
  const uniqueColors = existingFilters.unique_colors || [];

  // Handlers
  const handleColorChange = (color) => {
    setSelectedColors((prevSelected) =>
      prevSelected.includes(color)
        ? prevSelected.filter((c) => c !== color)
        : [...prevSelected, color]
    );
  };

  const handleConditionChange = (selectedCondition) => {
    setCondition((prevConditions) => {
      if (!Array.isArray(prevConditions)) {
        prevConditions = [];
      }
      return prevConditions.includes(selectedCondition)
        ? prevConditions.filter((c) => c !== selectedCondition)
        : [...prevConditions, selectedCondition];
    });
  };

  const handleConfirm = async () => {
    try {
      setLoading(true);

      const filters = {
        selected_colors: selectedColors,
        full_set: fullSet,
        partial_set: partialSet,
        only_watch: onlyWatch,
        no_papers_tags: noPapersTags,
        condition: condition,
        date_month: dateMonth,
        date_year: dateYear,
        newer_than: newerThan,
        serial: serial,
        convert_to_usd: convertToUsd,
        only_usd: onlyUsd,
        manual_filters: manualTags, // Add manual filters to the update
      };

      await axios.post(
        `${process.env.REACT_APP_API_URL}/edit_keyword`,
        {
          user_id: currentUser.id,
          keyword_id: selectedWatchId,
          ...filters,
        },
        { withCredentials: true }
      );

      await fetchKeywords(1, selectedWatchId);

      addAlert({
        message: "Watch filters updated successfully.",
        type: "success",
      });

      setShowFilterModalLayout(false);
      toggleBodyScroll(false);
    } catch (error) {
      console.error("Error in handleConfirm:", error);
      addAlert({
        message: "Error updating filters. Please try again.",
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  // Effect to update states when existingFilters changes
  useEffect(() => {
    setSelectedColors(existingFilters.selected_colors || []);
    setFullSet(existingFilters.full_set || false);
    setPartialSet(existingFilters.partial_set || false);
    setOnlyWatch(existingFilters.only_watch || false);
    setNoPapersTags(existingFilters.no_papers_tags || false);
    setCondition(existingFilters.condition || []);
    setDateMonth(existingFilters.date_month || "");
    setDateYear(existingFilters.date_year || "");
    setNewerThan(existingFilters.newer_than || false);
    setSerial(existingFilters.serial || "");
    setConvertToUsd(existingFilters.convert_to_usd || false);
    setOnlyUsd(existingFilters.only_usd || false);
    setManualTags(existingFilters.manual_filters || []); // Add this line
  }, [existingFilters]);

  return (
    showFilterModalLayout && (
      <div className="modal-container">
        <div className="modal">
          <div style={{ width: "80vw" }} className="modal-content">
            <div style={{ height: "70vh", zIndex: 1 }}>
              <h3>Find a specific lead via filter(s)</h3>

              <div className="filter-console">
                {/* QuickSearchFilter Component */}
                <QuickSearchFilter
                  onSearchChange={setManualTags}
                  setShowFilters={setShowFilters}
                  showFilters={showFilters}
                  setIsColorCollapsed={setIsColorCollapsed}
                  setIsBoxPapersCollapsed={setIsBoxPapersCollapsed}
                  setIsConditionCollapsed={setIsConditionCollapsed}
                  setIsOthersCollapsed={setIsOthersCollapsed}
                  setIsPriceLeadsCollapsed={setIsPriceLeadsCollapsed}
                  selectedColors={selectedColors}
                  condition={condition}
                  fullSet={fullSet}
                  partialSet={partialSet}
                  onlyWatch={onlyWatch}
                  dateMonth={dateMonth}
                  dateYear={dateYear}
                  newerThan={newerThan}
                  manualTags={manualTags}
                  setManualTags={setManualTags}
                />

                {showFilters && (
                  <div className="filter-console-grid">
                    <button onClick={() => setShowFilters(false)}>
                      Hide Filters
                    </button>

                    <div>
                      {/* Color Filter Section */}
                      <div className="filter-section">
                        <h4
                          onClick={() => setIsColorCollapsed(!isColorCollapsed)}
                        >
                          Filter by Color:{" "}
                          <span>{isColorCollapsed ? "▼" : "▲"}</span>
                        </h4>
                        {!isColorCollapsed && (
                          <div className="color-filters">
                            {uniqueColors.map((color, index) => (
                              <label key={index}>
                                <input
                                  disabled={loading}
                                  type="checkbox"
                                  value={color}
                                  onChange={() => handleColorChange(color)}
                                  checked={selectedColors.includes(color)}
                                />
                                {color}
                              </label>
                            ))}
                          </div>
                        )}
                      </div>

                      {/* Box & Papers Section */}
                      <div className="filter-section">
                        <h4
                          onClick={() =>
                            setIsBoxPapersCollapsed(!isBoxPapersCollapsed)
                          }
                        >
                          Box & Papers:{" "}
                          <span>{isBoxPapersCollapsed ? "▼" : "▲"}</span>
                        </h4>
                        {!isBoxPapersCollapsed && (
                          <div className="box-paper-filters">
                            <label>
                              <input
                                disabled={loading}
                                type="checkbox"
                                checked={fullSet}
                                onChange={() => setFullSet(!fullSet)}
                              />
                              Full Set
                            </label>
                            <label>
                              <input
                                disabled={loading}
                                type="checkbox"
                                checked={partialSet}
                                onChange={() => setPartialSet(!partialSet)}
                              />
                              Partial Set
                            </label>
                            <label>
                              <input
                                disabled={loading}
                                type="checkbox"
                                checked={onlyWatch}
                                onChange={() => setOnlyWatch(!onlyWatch)}
                              />
                              Only Watch
                            </label>
                            <label>
                              <input
                                disabled={loading}
                                type="checkbox"
                                checked={noPapersTags}
                                onChange={() => setNoPapersTags(!noPapersTags)}
                              />
                              No Papers/Tags
                            </label>
                          </div>
                        )}
                      </div>

                      {/* Condition Section */}
                      <div className="filter-section">
                        <h4
                          onClick={() =>
                            setIsConditionCollapsed(!isConditionCollapsed)
                          }
                        >
                          Condition:{" "}
                          <span>{isConditionCollapsed ? "▼" : "▲"}</span>
                        </h4>
                        {!isConditionCollapsed && (
                          <div className="condition-filter">
                            {[
                              "Brand New",
                              "Like New / Mint",
                              "Preowned or Used",
                              "Unpolished",
                              "Polished",
                              "Service or Repair-Related",
                            ].map((cond, index) => (
                              <label key={index}>
                                <input
                                  disabled={loading}
                                  type="checkbox"
                                  value={cond}
                                  onChange={() => handleConditionChange(cond)}
                                  checked={condition.includes(cond)}
                                />
                                {cond}
                              </label>
                            ))}
                          </div>
                        )}
                      </div>

                      {/* Other Filters Section */}
                      <div className="filter-section">
                        <h4
                          onClick={() =>
                            setIsOthersCollapsed(!isOthersCollapsed)
                          }
                        >
                          Other Filters:{" "}
                          <span>{isOthersCollapsed ? "▼" : "▲"}</span>
                        </h4>
                        {!isOthersCollapsed && (
                          <div>
                            <div className="only-usd-filter">
                              <label>
                                <input
                                  disabled={loading}
                                  type="checkbox"
                                  checked={onlyUsd}
                                  onChange={() => setOnlyUsd(!onlyUsd)}
                                />
                                Only USD/EU advertised prices
                              </label>
                            </div>
                            <div className="dated-filter">
                              <h5 style={{ marginBottom: "-2px" }}>
                                Dated (Month, Year):
                              </h5>
                              <select
                                value={dateMonth}
                                onChange={(e) => setDateMonth(e.target.value)}
                              >
                                <option disabled={loading} value="">
                                  Month
                                </option>
                                {[...Array(12)].map((_, i) => {
                                  const month = (i + 1)
                                    .toString()
                                    .padStart(2, "0");
                                  return (
                                    <option
                                      key={month}
                                      disabled={loading}
                                      value={month}
                                    >
                                      {new Date(2000, i).toLocaleString(
                                        "default",
                                        { month: "long" }
                                      )}
                                    </option>
                                  );
                                })}
                              </select>
                              <select
                                value={dateYear}
                                onChange={(e) => setDateYear(e.target.value)}
                              >
                                <option disabled={loading} value="">
                                  Year
                                </option>
                                {[...Array(13)].map((_, i) => {
                                  const year = 2024 - i;
                                  return (
                                    <option
                                      key={year}
                                      disabled={loading}
                                      value={year.toString()}
                                    >
                                      {year}
                                    </option>
                                  );
                                })}
                              </select>
                              <label>
                                <input
                                  disabled={loading}
                                  type="checkbox"
                                  checked={newerThan}
                                  onChange={() => setNewerThan(!newerThan)}
                                />
                                Or newer than
                              </label>
                            </div>
                            <div className="serial-filter">
                              <h5 style={{ marginBottom: "-2px" }}>Serial:</h5>
                              <select
                                value={serial}
                                onChange={(e) => setSerial(e.target.value)}
                              >
                                <option disabled={loading} value="">
                                  All Serials
                                </option>
                                {[...Array(12)].map((_, i) => {
                                  const serialNum = i + 1;
                                  return (
                                    <option
                                      key={serialNum}
                                      disabled={loading}
                                      value={`N${serialNum}`}
                                    >
                                      N{serialNum}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="modal-actions">
              <button
                onClick={() => {
                  setShowFilterModalLayout(false);
                  toggleBodyScroll(false);
                }}
              >
                Cancel
              </button>
              <button style={{ marginLeft: "0.5rem" }} onClick={handleConfirm}>
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  );
}

const LineChart = ({ priceAnalysis, prepareChartData, leads }) => {
  const [selectedRange, setSelectedRange] = useState(90); // Manage range inside LineChart
  const [meanPrice, setMeanPrice] = useState(null);
  const [percentageChange, setPercentageChange] = useState(null);
  const [showDetails, setShowDetails] = useState(false); // Ensure showDetails is defined

  const options = {
    maintainAspectRatio: false, // Allows the chart to resize freely
    plugins: {
      datalabels: {
        display: false,
      },
    },
  };

  // Calculate mean price and percentage change whenever the selected range changes
  useEffect(() => {
    const filteredLeads = leads.slice(-selectedRange); // Filter leads by selected range (7, 30, or 90 days)

    if (filteredLeads.length > 0) {
      // Calculate mean price
      const totalPrice = filteredLeads.reduce(
        (sum, lead) => sum + lead.usd_price,
        0
      );
      const calculatedMeanPrice = (totalPrice / filteredLeads.length).toFixed(
        2
      );
      setMeanPrice(calculatedMeanPrice);

      // Calculate percentage change for different ranges
      if (selectedRange === 90 && filteredLeads.length >= 14) {
        const first14Days = filteredLeads.slice(0, 14);
        const last14Days = filteredLeads.slice(-14);

        const first14DaysAvg =
          first14Days.reduce((sum, lead) => sum + lead.usd_price, 0) /
          first14Days.length;
        const last14DaysAvg =
          last14Days.reduce((sum, lead) => sum + lead.usd_price, 0) /
          last14Days.length;

        const calculatedPercentageChange = (
          ((last14DaysAvg - first14DaysAvg) / first14DaysAvg) *
          100
        ).toFixed(2);
        setPercentageChange(calculatedPercentageChange);
      } else if (selectedRange === 30 && filteredLeads.length >= 7) {
        const first7Days = filteredLeads.slice(0, 7);
        const last7Days = filteredLeads.slice(-7);

        const first7DaysAvg =
          first7Days.reduce((sum, lead) => sum + lead.usd_price, 0) /
          first7Days.length;
        const last7DaysAvg =
          last7Days.reduce((sum, lead) => sum + lead.usd_price, 0) /
          last7Days.length;

        const calculatedPercentageChange = (
          ((last7DaysAvg - first7DaysAvg) / first7DaysAvg) *
          100
        ).toFixed(2);
        setPercentageChange(calculatedPercentageChange);
      } else if (selectedRange === 7) {
        const first2Days = filteredLeads.slice(0, 2);
        const last2Days = filteredLeads.slice(-2);

        const first2DaysAvg =
          first2Days.reduce((sum, lead) => sum + lead.usd_price, 0) /
          first2Days.length;
        const last2DaysAvg =
          last2Days.reduce((sum, lead) => sum + lead.usd_price, 0) /
          last2Days.length;

        const calculatedPercentageChange = (
          ((last2DaysAvg - first2DaysAvg) / first2DaysAvg) *
          100
        ).toFixed(2);
        setPercentageChange(calculatedPercentageChange);
      } else {
        setPercentageChange(null); // In case of not enough data
      }
    } else {
      setMeanPrice(null);
      setPercentageChange(null);
    }
  }, [selectedRange, leads]); // Run this effect whenever the selected range or leads data changes

  // Handle range change when user clicks a tab
  const handleRangeChange = (range) => {
    setSelectedRange(range); // Change the range within the chart
  };

  // Toggle the details
  const handleToggleDetails = () => {
    setShowDetails((prev) => !prev); // Ensure handleToggleDetails is defined
  };

  const filteredChartData = prepareChartData(leads, selectedRange); // Prepare data for the chart

  // Styles for the tab container and the tabs
  const tabContainerStyle = {
    display: "flex",
    position: "relative",
    top: "-30px",
    justifyContent: "center",
  };

  const tabStyle = {
    padding: "5px 15px",
    marginRight: "5px",
    backgroundColor: "#1f4f4f", // Dark teal color for the tab
    color: "white",
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
    cursor: "pointer",
    border: "1px solid #1f4f4f",
    borderBottom: "none", // To give it the folder tab look
    transition: "background-color 0.3s ease, border 0.3s ease",
    fontSize: "0.8rem",
  };

  const activeTabStyle = {
    ...tabStyle,
    backgroundColor: "#007f7f", // Lighter teal for the active tab
    border: "1px solid #007f7f",
    color: "white",
    transition: "background-color 0.3s ease, border 0.3s ease",
  };

  const chartContainerStyle = {
    position: "relative", // So that tabs can be positioned inside this container
    padding: "20px",
    backgroundColor: "#f4f4f4", // Light background for the chart
    borderRadius: "10px 10px 0 0",
    border: "1px solid #ddd",
    minWidth: "200px",
    minHeight: "200px",
  };

  return (
    <div className="price-summary" style={chartContainerStyle}>
      {/* Tab Container */}
      <div style={tabContainerStyle}>
        <button
          style={selectedRange === 7 ? activeTabStyle : tabStyle}
          onClick={() => handleRangeChange(7)}
        >
          7 Days
        </button>
        <button
          style={selectedRange === 30 ? activeTabStyle : tabStyle}
          onClick={() => handleRangeChange(30)}
        >
          30 Days
        </button>
        <button
          style={selectedRange === 90 ? activeTabStyle : tabStyle}
          onClick={() => handleRangeChange(90)}
        >
          90 Days
        </button>
      </div>

      {/* Main Content */}
      {meanPrice ? (
        <>
          <p style={{ fontSize: "1.5rem", margin: "auto", marginTop: "-20px" }}>
            ~${meanPrice}
          </p>
          {percentageChange !== null ? (
            <p
              style={{
                color: percentageChange >= 0 ? "green" : "red",
                fontSize: "1.0rem",
                margin: "auto",
              }}
            >
              {percentageChange}% {/* Show percentage change if available */}
            </p>
          ) : (
            <p
              style={{
                color: "gray",
                fontSize: "1.5rem",
                margin: "auto",
              }}
            >
              Not enough data {/* Show this if there's no percentage change */}
            </p>
          )}

          <div style={{ width: "100%", height: "190px" }}>
            <Line data={filteredChartData} options={options} />
          </div>

          {/* Show/Hide Details Button */}
          <button
            style={{
              marginTop: "10px",
              cursor: "pointer",
              color: "white",
              backgroundColor: "#2b2443",
              fontSize: "0.8rem",
              padding: "0.25rem 0.5rem",
            }}
            onClick={handleToggleDetails} // Properly defined the handler
          >
            {showDetails ? "Hide Details" : "Show Details"}{" "}
            {/* Properly defined the state */}
          </button>

          {/* Conditionally render PriceClusterAnalysis or any other details */}
          {showDetails && (
            <div style={{ marginTop: "20px" }}>
              <PriceClusterAnalysis priceAnalysis={priceAnalysis} />
            </div>
          )}
        </>
      ) : (
        <h1>No activity detected for this watch.</h1>
      )}
    </div>
  );
};

const PriceClusterAnalysis = ({ priceAnalysis }) => {
  const { currency, confidence, clusters } = priceAnalysis;

  // Sort clusters: USD first, HKD second, then others by size
  const sortedClusters = [...clusters].sort((a, b) => {
    if (a.name === "USD Cluster") return -1;
    if (b.name === "USD Cluster") return 1;
    if (a.name === "HKD Cluster") return -1;
    if (b.name === "HKD Cluster") return 1;
    return b.data_points - a.data_points;
  });

  const data = {
    labels: sortedClusters.map((cluster) => cluster.name),
    datasets: [
      {
        label: "Average Price",
        data: sortedClusters.map((cluster) => cluster.average_price),
        backgroundColor: sortedClusters.map((cluster) =>
          cluster.name === "USD Cluster"
            ? "rgba(75, 192, 192, 0.6)"
            : cluster.name === "HKD Cluster"
            ? "rgba(255, 159, 64, 0.6)"
            : "rgba(153, 102, 255, 0.6)"
        ),
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    indexAxis: "y",
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: "Price Cluster Analysis",
        font: {
          size: 18,
          weight: "bold",
        },
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const cluster = sortedClusters[context.dataIndex];
            return [
              `Average Price: $${cluster.average_price.toFixed(2)}`,
              `Range: $${cluster.min_price.toFixed(
                2
              )} - $${cluster.max_price.toFixed(2)}`,
              `Data Points: ${cluster.data_points}`,
            ];
          },
        },
      },
      datalabels: {
        display: true,
        align: "end",
        anchor: "end",
        color: "#fff",
        font: {
          weight: "bold",
        },
        formatter: (value, context) => {
          const cluster = sortedClusters[context.dataIndex];
          return `${cluster.data_points} prices`;
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Price",
          font: {
            size: 14,
            weight: "bold",
          },
        },
        ticks: {
          callback: function (value) {
            return "$" + value.toLocaleString();
          },
        },
      },
      y: {
        title: {
          display: true,
          text: "Clusters",
          font: {
            size: 14,
            weight: "bold",
          },
        },
      },
    },
  };

  return (
    <div className="price-cluster-analysis">
      {/* <p>
        Confidence: {confidence} ({currency} distinction)
      </p> */}
      <div style={{ height: "400px" }}>
        <Bar data={data} options={options} />
      </div>

      <p style={{ marginTop: "20px", fontSize: "14px" }}>
        {confidence === "high"
          ? "The USD and HKD clusters represent the primary price points. Additional clusters may indicate alternative market segments or outliers."
          : "These clusters represent potential price points in the market. Due to limited data or price variation, a clear USD/HKD distinction couldn't be made."}
      </p>
    </div>
  );
};

const SelectedWatchModal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null; // Don't render anything if the modal is not open

  // Inline styles for the modal components
  const modalOverlayStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.8)", // Semi-transparent background
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000, // Ensure it's on top of other elements
  };

  const modalContentStyle = {
    position: "relative",
    padding: "20px",
    borderRadius: "8px",
    maxWidth: "90%",
    maxHeight: "90%",
    overflowY: "auto", // Scroll if content exceeds viewport
  };

  const modalCloseButtonStyle = {
    position: "absolute",
    top: "10px",
    right: "10px",
    background: "none",
    border: "none",
    fontSize: "2rem",
    cursor: "pointer",
    zIndex: 1,
  };

  return (
    <div style={modalOverlayStyle} onClick={onClose}>
      <div
        style={modalContentStyle}
        onClick={(e) => e.stopPropagation()} // Prevent closing modal when clicking inside content
      >
        {/* Close button */}
        <button style={modalCloseButtonStyle} onClick={onClose}>
          &times;
        </button>
        {/* Modal content */}
        {children}
      </div>
    </div>
  );
};

const SelectedWatchComponent = React.memo(
  ({
    setUniqueColors,
    startAnimation,
    setSelectedWatchId,
    setShowFilterModalCreateWatchlist,
    searchLeadsPerFamilyWatch,
    LineChart,
    chartData,
    prepareChartData,
    priceAnalysis,
    variation_count,
    variations,
    watchesIds,
    coreRef,
    frequency,
    uniqueSenders,
    fetchUniqueColors,
    addKeyword,
  }) => {
    const watchId = variations[0]["watch_id"];
    const renderVariationsWatchComponent = (variations) => {
      const image_link = variations[0]["image_link"];

      if (Array.isArray(variations) && variations.length > 0) {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <div
              style={{
                alignContent: "center",
                borderRadius: "10px",
                background: "linear-gradient(to bottom, #161a23, #140c0c)",
                border: "1px solid #ddd",
                padding: "20px",
                height: "75%",
                width: "15%",
                fontSize: "0.7rem",
                alignSelf: "center",
              }}
            >
              Lead Every: ~{frequency}
            </div>{" "}
            <AnimatedCarousel images={variations} />{" "}
            <div
              style={{
                alignContent: "center",
                borderRadius: "10px",
                background: "linear-gradient(to bottom, #161a23, #0c1114)",
                border: "1px solid #ddd",
                padding: "20px",
                height: "75%",
                width: "15%",
                fontSize: "0.7rem",
                alignSelf: "center",
              }}
            >
              Unique Dealers: {uniqueSenders}
            </div>
          </div>
        );
      }

      return (
        <img
          src={`https://images.autowatch.io/images/no_watermark/${sanitizeFilename(
            image_link
          )}`}
          alt={`Watch ${coreRef ? coreRef.split("-")[0] : coreRef}`}
          style={{ width: "100%", height: "auto" }}
        />
      );
    };

    return (
      <div className="watches-grid" style={{ height: "75vh" }}>
        <div key={watchId} className={`watch-card show special-watch-card`}>
          {renderVariationsWatchComponent(variations)}
          <div>
            <h3
              style={{
                fontSize: "2rem",
                marginTop: "-5px",
                marginBottom: "-10px",
              }}
            >
              {coreRef}
            </h3>
          </div>
          <p>
            {variation_count > 0
              ? `${variation_count} variations`
              : "1 variation"}{" "}
            {/* Show "1 variation" when there are no other variations */}
          </p>

          <LineChart
            prepareChartData={prepareChartData}
            priceAnalysis={priceAnalysis}
            leads={chartData}
          />

          <div
            className="adjust-margin"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              margin: "auto",
              marginTop: "-24px",
              marginBottom: "-15px",
            }}
          >
            <button
              style={{
                border: "1px solid #FF6F61",
                borderRadius: "0 0 0 10px",
                padding: "0.2em 0.5em",
                color: "#FF6F61",
                fontSize: "1.2em",
                cursor: "pointer",
                transition: "background-color 0.2s",
                marginTop: "0.2em",
                marginBottom: "0.2em",
                backgroundColor: "transparent",
              }}
              className="search-leads-button"
              disabled={startAnimation}
              onClick={async () => {
                try {
                  setSelectedWatchId(watchesIds[0]); // Store the watch ID

                  const filters = {
                    selected_colors: [],
                    full_set: false,
                    partial_set: false,
                    only_watch: false,
                    no_papers_tags: false,
                    condition: [],
                    date_month: "",
                    date_year: "",
                    newer_than: false,
                    serial: "",
                    only_usd: false,
                    manual_filters: [], // Add manual filters to the submission
                  };

                  addKeyword(filters, watchesIds[0]);

                  //commented below colors and setSHowFilterModal because I made it so that it immedietely adds to watchlist, then the user can add filters later from watchlist container
                  // setShowFilterModalCreateWatchlist(true); // Show the filter modal

                  // const colors = await fetchUniqueColors(coreRef);
                  // if (colors) {
                  //   setUniqueColors(colors);
                  // }

                  // Optional: Uncomment if needed
                  // setSelectedRef(null);
                  // setSuggestions([]);
                } catch (error) {
                  console.error("Error fetching colors:", error);
                }
              }}
            >
              + Add to Watchlist
            </button>

            <button
              style={{
                border: "1px solid #F2E6D9",
                borderRadius: " 0 0 10px 0",
                padding: "0.2em 0.5em",
                color: "#F2E6D9",
                fontSize: "1.2em",
                cursor: "pointer",
                transition: "background-color 0.2s",

                backgroundColor: "transparent",
                marginTop: "0.2em",
                marginBottom: "0.2em",
              }}
              className="search-leads-button"
              onClick={() => {
                // window.scrollTo({
                //   top: 0,
                //   behavior: "smooth",
                // });
                searchLeadsPerFamilyWatch(watchesIds, coreRef);
              }}
            >
              Explore Leads {">"}
            </button>
          </div>
        </div>
      </div>
    );
  }
);

const HottestWatches = React.memo(
  ({
    setMeanUsdPrice,
    startAnimation,
    setSelectedWatchId,
    setShowFilterModalCreateWatchlist,
    searchLeadsPerFamilyWatch,
    LineChart,
    prepareChartData,
    fetchUniqueColors,
    setUniqueColors,
    setShowScrollToTop,
    addKeyword,
  }) => {
    const [watches, setWatches] = useState([]);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [totalPages, setTotalPages] = useState();

    const observer = useRef();

    const lastWatchElementRef = useCallback(
      (node) => {
        if (loading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver((entries) => {
          if (entries[0].isIntersecting && hasMore) {
            setPage((prevPage) => prevPage + 1);
          }
        });
        if (node) observer.current.observe(node);
      },
      [loading, hasMore]
    );

    const fetchHottestWatches = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/hottest-watches?page=${page}`,
          {
            withCredentials: true, // Ensure cookies are sent with the request
          }
        );
        setWatches((prevWatches) => [...prevWatches, ...response.data.watches]);
        setTotalPages(response.data.total_pages);
        setHasMore(response.data.current_page < response.data.total_pages);
      } catch (error) {
        console.error("Error fetching the hottest watches:", error);
      }
      setLoading(false);
    };

    useEffect(() => {
      fetchHottestWatches();
    }, [page]);

    // Handle scroll to top visibility
    useEffect(() => {
      const handleScroll = () => {
        if (window.scrollY > 400) {
          setShowScrollToTop(true);
        } else {
          setShowScrollToTop(false);
        }
      };

      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };

    const renderVariations = (watch) => {
      const { variations, image_link, ref } = watch;

      if (Array.isArray(variations) && variations.length > 0) {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <div
              style={{
                alignContent: "center",
                borderRadius: "10px",
                background: "linear-gradient(to bottom, #161a23, #140c0c)",
                border: "1px solid #ddd",
                padding: "20px",
                height: "75%",
                width: "15%",
                fontSize: "0.7rem",
                alignSelf: "center",
              }}
            >
              Lead Every: ~{watch.interest_levels.frequency}
            </div>
            <AnimatedCarousel images={variations} />
            <div
              style={{
                alignContent: "center",
                borderRadius: "10px",
                background: "linear-gradient(to bottom, #161a23, #0c1114)",
                border: "1px solid #ddd",
                padding: "20px",
                height: "75%",
                width: "15%",
                fontSize: "0.7rem",
                alignSelf: "center",
              }}
            >
              Unique Dealers: {watch.interest_levels.unique_senders}
            </div>
          </div>
        );
      }

      return (
        <img
          src={`https://images.autowatch.io/images/no_watermark/${sanitizeFilename(
            image_link
          )}`}
          alt={`Watch ${ref ? ref.split("-")[0] : ref}`}
          style={{ width: "100%", height: "auto" }}
        />
      );
    };

    return (
      <div className={`watches-container show`}>
        <h2>
          <div className="fire-icon">
            <div className="flame"></div>
            <div className="flame"></div>
            <div className="flame"></div>
          </div>
          Hottest Watches{" "}
          {watches.length > 0 && (
            <>
              {" "}
              <span>({9 * totalPages + " types seen last 2 weeks"})</span>
              <br />
              <p style={{ fontSize: "0.7rem" }}>
                Scroll down to load more watches.
              </p>
            </>
          )}
        </h2>

        {loading && page === 1 && (
          <div
            style={{ margin: "auto", marginTop: "35px" }}
            className="loader"
          ></div>
        )}

        <div style={{ minHeight: "100vh" }} className="watches-grid">
          {watches.map((watch, index) => (
            <div
              key={watch.watch_id}
              className={`watch-card show`}
              ref={index === watches.length - 1 ? lastWatchElementRef : null}
            >
              {watch.interest_levels ? (
                <>
                  <div className="watch-rank">#{index + 1}</div>
                  {renderVariations(watch)}
                  <div>
                    <h3
                      style={{
                        fontSize: "2rem",
                        marginTop: "-3px",
                        marginBottom: "-10px",
                      }}
                    >
                      {watch.core_ref}
                    </h3>
                  </div>
                  <p>
                    {watch.variation_count > 0
                      ? `${watch.variation_count} variations`
                      : "1 variation"}
                  </p>

                  <LineChart
                    priceAnalysis={watch.price_analysis}
                    leads={watch.aggregated_data_leads} // Pass all leads to be filtered within the LineChart
                    prepareChartData={prepareChartData} // Pass the prepareChartData function
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      margin: "auto",
                      marginTop: "-24px",
                      marginBottom: "-15px",
                    }}
                  >
                    <button
                      style={{
                        border: "1px solid #FF6F61",
                        borderRadius: "0 0 0 10px",
                        padding: "0.2em 0.5em",
                        color: "#FF6F61",
                        fontSize: "1.2em",
                        cursor: "pointer",
                        transition: "background-color 0.2s",
                        marginTop: "0.2em",
                        marginBottom: "0.2em",
                        backgroundColor: "transparent",
                      }}
                      className="search-leads-button"
                      disabled={startAnimation}
                      onClick={async () => {
                        try {
                          setSelectedWatchId(watch.id);

                          const filters = {
                            selected_colors: [],
                            full_set: false,
                            partial_set: false,
                            only_watch: false,
                            no_papers_tags: false,
                            condition: [],
                            date_month: "",
                            date_year: "",
                            newer_than: false,
                            serial: "",
                            only_usd: false,
                            manual_filters: [], // Add manual filters to the submission
                          };

                          addKeyword(filters, watch.id);

                          //same below, uncommented and allow user to filter lateer in watchlist
                          // setShowFilterModalCreateWatchlist(true);
                          // setMeanUsdPrice(watch.mean_usd_price); // for use in the baseline in price and lead type filter
                          // const colors = await fetchUniqueColors(
                          //   watch.core_ref
                          // );
                          // if (colors) {
                          //   setUniqueColors(colors);
                          // }
                        } catch (error) {
                          console.error("Error fetching colors:", error);
                        }
                      }}
                    >
                      + Add to Watchlist
                    </button>

                    <button
                      style={{
                        border: "1px solid #F2E6D9",
                        borderRadius: "0 0 10px 0",
                        padding: "0.2em 0.5em",
                        color: "#F2E6D9",
                        fontSize: "1.2em",
                        cursor: "pointer",
                        transition: "background-color 0.2s",

                        backgroundColor: "transparent",
                        marginTop: "0.2em",
                        marginBottom: "0.2em",
                      }}
                      className="search-leads-button"
                      onClick={() => {
                        searchLeadsPerFamilyWatch(
                          watch.watches_ids,
                          watch.core_ref
                        );
                        setMeanUsdPrice(watch.mean_usd_price); // for use in the baseline in price and lead type filter
                      }}
                    >
                      Explore Leads {">"}
                    </button>
                  </div>
                </>
              ) : (
                <div>
                  Calculating watch analytics. Please check back in a few
                  minutes.
                </div>
              )}
            </div>
          ))}
        </div>
        {loading && (
          <div
            style={{ margin: "auto", marginTop: "35px" }}
            className="loader"
          ></div>
        )}
      </div>
    );
  }
);

const WatchSearchInput = ({
  normalizeInput,
  setSuggestions,
  selectedRef,
  setSelectedRef,
  suggestions,
  setLoading,
  loading,
  inputRef,
  handleSelectSuggestion,
  setRefInput,
  refInput,
}) => {
  const [missingRef, setMissingRef] = useState(""); // State for "Missing Watch?" input
  const [submitStatus, setSubmitStatus] = useState(null); // State for submission feedback
  const [showMissingWatch, setShowMissingWatch] = useState(false); // New state
  const [isManualSearch, setIsManualSearch] = useState(false);

  const { addAlert } = useAlert();

  const debounce = useCallback((fn, delay) => {
    let timeoutId;
    return function (...args) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => fn(...args), delay);
    };
  }, []);

  // Memoize the debounced search function
  const debouncedSearch = useMemo(
    () =>
      debounce((input) => {
        const normalizedInput = normalizeInput(input);
        axios
          .get(
            `${process.env.REACT_APP_API_URL}/api/search?query=${normalizedInput}`,
            {
              withCredentials: true, // Ensure cookies are sent with the request
            }
          )
          .then((response) => {
            setSuggestions(response.data.suggestions); // Set suggestions here
            setLoading(false);
          })
          .catch((error) => {
            console.error("Error fetching filtered refs:", error);
            setLoading(false);
          });
      }, 200),
    []
  );

  const handleMissingWatchSubmit = useCallback(
    (e) => {
      e.preventDefault();
      if (!missingRef.trim()) {
        setSubmitStatus({
          success: false,
          message: "Reference number is required.",
        });
        return;
      }

      setLoading(true);
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/api/missing_watch`,
          { reference: missingRef.trim() },
          { withCredentials: true }
        )
        .then((response) => {
          setSubmitStatus({
            success: true,
            message: "Reference submitted successfully!",
          });
          setMissingRef("");
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error submitting missing watch:", error);
          setSubmitStatus({
            success: false,
            message: "Failed to submit reference. Please try again.",
          });
          setLoading(false);
        });
    },
    [missingRef, setSubmitStatus, setLoading]
  );

  const handleInputClick = useCallback(() => {
    setSelectedRef(null);
    setSuggestions([]);
    setSubmitStatus(null);
    setShowMissingWatch(false); // Hide form on input click
  }, [setSelectedRef, setSuggestions]);

  // Handle input changes
  const handleInputChange = useCallback(
    (e) => {
      const input = e.target.value;
      setRefInput(input);
      setMissingRef(input);
      setSubmitStatus(null);

      if (!input) {
        setSuggestions([]);
        setLoading(false);
        return;
      }

      setLoading(true);
      debouncedSearch(input);
    },
    [debouncedSearch, setSuggestions, setLoading]
  );

  const handleManualSearchToggle = () => {
    setIsManualSearch(true);

    // Set a timeout to revert back and show alert
    setTimeout(() => {
      setIsManualSearch(false);
      addAlert({
        message: "Manual search feature coming soon!",
        type: "info",
      });
    }, 2000);
  };

  // Auto-scroll to the "Results?" section when it's rendered
  const resultsRef = useRef(null);

  useEffect(() => {
    if (suggestions.length > 0 && resultsRef.current) {
      const offset =
        resultsRef.current.getBoundingClientRect().top + window.scrollY; // Get absolute position
      const vhOffset = window.innerHeight * 0.15; // Adjust 30vh down the screen

      window.scrollTo({
        top: offset - vhOffset, // Scroll to element minus offset
        behavior: "smooth",
      });
    }
  }, [suggestions]);

  const toggleStyles = {
    toggleContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: "8px",
      cursor: "pointer",
      userSelect: "none",
      margin: "auto",
      marginBottom: "-15px",
      marginTop: "10px",
      width: "100px",
    },
    switch: {
      position: "relative",
      width: "44px",
      height: "22px",
      backgroundColor: isManualSearch ? "#4CAF50" : "#ccc",
      borderRadius: "34px",
      transition: "background-color 0.3s",
    },
    slider: {
      position: "absolute",
      cursor: "pointer",
      top: "2px",
      left: isManualSearch ? "24px" : "2px",
      width: "18px",
      height: "18px",
      backgroundColor: "white",
      borderRadius: "50%",
      transition: "0.3s",
      boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
    },
    label: {
      fontSize: "11px",
      color: "#666",
    },
  };

  return (
    <>
      <div>
        <h2>{isManualSearch ? "Manual Search" : "Which watch?"}</h2>
      </div>

      <div
        className="center-container"
        style={{ marginBottom: "13px", width: "100%" }}
      >
        <div className="input-container-suggestions">
          <div className="reference-input-container">
            <label htmlFor="reference-input" className="reference-label">
              {isManualSearch
                ? "Search for anything in any message or lead"
                : "Enter a reference #, model series, or brand"}
            </label>
            <input
              id="reference-input"
              type="text"
              value={refInput}
              onChange={handleInputChange}
              onClick={handleInputClick}
              ref={inputRef}
              disabled={loading && selectedRef}
              placeholder={
                isManualSearch ? "Enter any search term..." : "e.g., 15550ST"
              }
              className="reference-search-input"
            />
          </div>
          <div
            style={toggleStyles.toggleContainer}
            onClick={!isManualSearch ? handleManualSearchToggle : undefined}
          >
            <div style={toggleStyles.switch}>
              <span style={toggleStyles.slider}></span>
            </div>
            <span style={toggleStyles.label}>Manual Search</span>
          </div>
          {suggestions.length > 0 && !selectedRef && (
            <div className="suggestion-dropdown" ref={resultsRef}>
              <p className="suggestion-header">Click on closest match:</p>
              <ul className="suggestions-list">
                {suggestions.map((suggestion) => (
                  <li
                    key={suggestion.reference}
                    onClick={() => handleSelectSuggestion(suggestion.reference)}
                    className="suggestion-item"
                  >
                    <small>
                      {suggestion.brand} {suggestion.model}
                    </small>{" "}
                    (
                    <big style={{ color: "rgb(255, 204, 0)" }}>
                      {suggestion.reference}
                    </big>
                    )
                  </li>
                ))}
                <li className="separator">————</li>
                <li
                  onClick={() => {
                    setSelectedRef("");
                    setSuggestions("");
                    setShowMissingWatch(true);
                  }}
                  className="suggestion-item missing-watch-option"
                >
                  Missing a watch?
                </li>
              </ul>
            </div>
          )}

          {/* "Missing Watch?" Section */}
          {(suggestions.length === 0 && !loading && refInput.trim() !== "") ||
          showMissingWatch ? (
            <div className="missing-watch-container">
              <p className="missing-watch-header">Missing Watch?</p>
              <form
                onSubmit={handleMissingWatchSubmit}
                className="missing-watch-form"
              >
                <input
                  type="text"
                  value={missingRef}
                  onChange={(e) => setMissingRef(e.target.value)}
                  placeholder="Enter reference number"
                  className="missing-watch-input"
                />
                <button
                  type="submit"
                  className="missing-watch-button"
                  disabled={loading}
                >
                  Submit
                </button>
              </form>
              {submitStatus && (
                <p
                  className={`submit-status ${
                    submitStatus.success ? "success" : "error"
                  }`}
                >
                  {submitStatus.message}
                </p>
              )}
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

const CreateWatchListStep3 = () => {
  let navigate = useNavigate();

  const location = useLocation();
  const routePath = location.pathname;

  const { addAlert, removeAlert } = useAlert();
  const [loading, setLoading] = useState(true);

  const [refsToDisplay, setRefsToDisplay] = useState([]);

  const [showLeadsModal, setShowLeadsModal] = useState(false);

  //for the selectedwatchcomponent
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [watchLeads, setWatchLeads] = useState([]);

  const [watchIdsList, setWatchIdsList] = useState([]);

  const [isChatOpen, setIsChatOpen] = useState(false);

  //bottom 2 and entire color system is retarded and needs refactored for v2 (everything does)
  const [uniqueData, setUniqueData] = useState([]);
  const [uniqueColors, setUniqueColors] = useState([]);

  // New state to store fetched reference numbers
  const [filteredRefs, setFilteredRefs] = useState([]);

  const [latestLead, setLatestLead] = useState([]);
  const [colorPercentageData, setColorPercentageData] = useState([]);

  const [mostActivePeriodsData, setMostActivePeriodsData] = useState(null);
  const [mostActiveDaysData, setMostActiveDaysData] = useState(null);

  const [averageChange90Days, setAverageChange90Days] = useState(0);

  const [percentageChange, setPercentageChange] = useState(null);
  const [priceAnalysis, setPriceAnalysis] = useState(null);
  const [variationCount, setVariationCount] = useState(null);
  const [variations, setVariations] = useState(null);
  const [watchesIds, setWatchesIds] = useState(null);
  const [frequency, setFrequency] = useState(null);
  const [uniqueSenders, setUniqueSenders] = useState(null);
  const [coreRef, setCoreRef] = useState(null);

  const [chartData, setChartData] = useState(null);

  const [suggestions, setSuggestions] = useState([]); // New state for suggestions
  const [selectedRef, setSelectedRef] = useState(null); // State for selected reference

  const [meanUsdPrice, setMeanUsdPrice] = useState(null);

  const [selectedWatchId, setSelectedWatchId] = useState(null);

  const [showScrollToTop, setShowScrollToTop] = useState(false); // for the scroll to top in hottest watches

  const [refInput, setRefInput] = useState(""); // Correctly ordered state

  const [watchFamilies, setWatchFamilies] = useState([]);

  const { setCurrentSelectedReference } = useMain();

  // Function to scroll to the top
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // Function to toggle the chat modal
  const toggleChat = () => {
    setIsChatOpen((prev) => !prev);
  };

  const [showFilterModalCreateWatchlist, setShowFilterModalCreateWatchlist] =
    useState(false);

  //for selectedwatchcomponent

  useEffect(() => {
    if (selectedRef) {
      setIsModalOpen(true);
    } else {
      setIsModalOpen(false);
    }
  }, [selectedRef]);

  useEffect(() => {
    // Extract session_id from the URL query parameters
    const queryParams = new URLSearchParams(location.search);
    const sessionId = queryParams.get("session_id");

    if (sessionId) {
      // Make API call to backend to handle the checkout session
      fetch(`${process.env.REACT_APP_API_URL}/handle_checkout_session`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include", // Include credentials for session authentication
        body: JSON.stringify({ session_id: sessionId }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.message) {
            // Subscription updated successfully
            // Optionally, update your UI or state here
            addAlert({
              message: "ChronoSpy Membership Granted",
              type: "success",
            });
            addAlert({
              message: "Welcome to ChronoSpy!",
              type: "info",
            });
          } else if (data.error) {
            console.error(data.error);
            // Handle error (e.g., show an error message to the user)
          }
          // Remove the session_id from the URL
          navigate("/", { replace: true });
        })
        .catch((error) => {
          console.error("Error handling checkout session:", error);
          // Remove the session_id from the URL
          navigate("/", { replace: true });
        });
    }
  }, [location.search, navigate]);

  const {
    setPages,
    pages,
    setIsActionButtonMinimized,
    brandInput,

    inputRef,

    setWatchList,
    watchList,
    isBuy,
    setIsBuy,
    setShowKeywordLimitModal,
    setStartAnimation,
    startAnimation,
    setShowTooltip,
    showTooltip,
  } = useMain();

  const { currentUser, refreshUser } = useAuth();

  const [isSubscriptionModalOpen, setIsSubscriptionModalOpen] = useState(false);

  const [activeTab, setActiveTab] = useState("hottest"); // 'hottest' or 'leadFeed'

  const [hottestKey, setHottestKey] = useState(0);

  const fetchUniqueColors = useCallback((reference) => {
    // Notify the user that the request is in progress

    // Return the promise so the caller can chain .then()
    return axios
      .get(`${process.env.REACT_APP_API_URL}/api/search/colors`, {
        params: { ref: reference },
        withCredentials: true,
      })
      .then((response) => {
        // Extract the list of unique colors from the response
        const { unique_colors } = response.data;

        return unique_colors; // Return the unique colors
      })
      .catch((error) => {
        console.error("Error:", error);

        return null; // Return null in case of an error
      });
  });

  //FOR TOPBUTTONS STUFF

  const [expanded, setExpanded] = useState(true);

  // If there's a selectedRef, you want to hide everything, as in your original code:
  // if (selectedRef) return null;

  useEffect(() => {
    const timer = setTimeout(() => {
      setExpanded(false);
    }, 5500);

    // Cleanup if the user leaves before 4s
    return () => clearTimeout(timer);
  }, []);

  // Effect to handle subscription modal based on currentUser
  useEffect(() => {
    if (currentUser?.trial_ended && !currentUser?.is_subscribed) {
      setIsSubscriptionModalOpen(true); // Open the subscription modal if the trial has ended
    } else {
      setIsSubscriptionModalOpen(false); // Ensure the modal is closed otherwise
    }
  }, [currentUser]);

  // Handler to close the subscription modal
  const handleCloseSubscriptionModal = async () => {
    try {
      await refreshUser(); // Properly call refreshUser
      setHottestKey((prevKey) => prevKey + 1); // Increment the key to remount
    } catch (error) {
      console.error("Failed to refresh user:", error);
      addAlert({
        message: "Failed to refresh user information.",
        type: "error",
      });
    }
  };

  // Make searchLeadsPerFamilyWatch an async function
  const searchLeadsPerFamilyWatch = useCallback(
    async (watches_ids, core_ref) => {
      // Optional: set loading state

      addAlert({
        message: `Exploring leads for ${core_ref}`,
        type: "info",
        duration: 4000,
      });
      setLoading(true);

      if (watches_ids) {
        setWatchIdsList(watches_ids);

        setCurrentSelectedReference(core_ref);

        const colors = await fetchUniqueColors(core_ref);
        if (colors) {
          setUniqueColors(colors);
        }

        // Proceed with other actions
        // fetchWatchLeads(watchIdsList); // Uncomment if needed

        toggleBodyScroll(true);

        setShowLeadsModal(true); // Show the leads modal
      } else {
        alert("Error in searchLeadsPerFamilyWatch: watches_ids is undefined");
      }

      // Optional: unset loading state
      setLoading(false);
    },
    [fetchUniqueColors]
  );

  const normalizeInput = useCallback((input) => {
    if (input.toLowerCase().startsWith("rm")) {
      // Remove any hyphens and the part after the hyphen
      // input = input.replace(/-.*/, "");
      // Add a space after "RM" if not present
      return input.replace(/^rm(\d{2,})$/i, "RM $1");
    }
    return input;
  });

  const prepareChartData = useCallback((leads, range) => {
    if (!leads || leads.length === 0) {
      // Return empty data if no leads are present
      return {
        labels: [],
        datasets: [
          {
            label: `No Data Available (${range} Days)`,
            data: [],
            borderColor: "rgba(75,192,192,1)",
            backgroundColor: "rgba(75,192,192,0.2)",
            fill: true,
          },
        ],
      };
    }

    // Sort leads by date
    const sortedLeads = leads.sort(
      (a, b) => new Date(a.time) - new Date(b.time)
    );

    // Slice the data based on the selected range (7, 30, or 90 days)
    const filteredLeads = sortedLeads.slice(-range); // Get the last 'range' entries

    const labels = filteredLeads.map((lead) => lead.time); // Extracting the time for X-axis
    const data = filteredLeads.map((lead) => lead.usd_price); // Extracting the price for Y-axis

    return {
      labels, // X-axis labels
      datasets: [
        {
          label: `Daily Average Price Over Time (${range} Days)`,
          data: data, // Y-axis data
          borderColor: "rgba(75,192,192,1)",
          backgroundColor: "rgba(75,192,192,0.2)",
          fill: true,
        },
      ],
    };
  }, []);

  // Handle suggestion selection
  const handleSelectSuggestion = useCallback(
    (reference, isManual = false) => {
      const normalizedInput = normalizeInput(reference);
      setSelectedRef(reference);
      setRefInput(""); // Clear the input field
      setSuggestions([]);
      setLoading(true); // Set loading for details
      toggleBodyScroll(true);
      const queryParam = isManual ? "manual=true" : "manual=false";
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 0);

      axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/search/details?ref=${normalizedInput}&${queryParam}`,
          { withCredentials: true }
        )
        .then((response) => {
          setUniqueData(response.data.unique_data); // Keep unique data as is
          setUniqueColors(response.data.unique_data.unique_colors);
          setLatestLead(response.data.latest_last_lead_date);
          setWatchIdsList(response.data.watches_ids);
          setColorPercentageData(response.data.color_percentage);
          setWatchFamilies(response.data.grouped_watch_families);
          setPercentageChange(response.data.percentage_change);
          setPriceAnalysis(response.data.price_analysis);
          setVariationCount(response.data.variation_count);
          setVariations(response.data.variations);
          setWatchesIds(response.data.watches_ids);
          setCoreRef(response.data.core_ref);
          setFrequency(response.data.interest_levels.frequency);
          setUniqueSenders(response.data.interest_levels.unique_senders);
          setMeanUsdPrice(response.data.mean_usd_price);

          const interestLevel = response.data.interest_levels || {}; // Defensive coding
          const mostActivePeriods = interestLevel.most_active_periods || [];
          const mostActiveDays = interestLevel.busiest_days || [];

          setChartData(response.data.aggregated_data_leads);

          // Prepare most active hours data for charting
          const mostActivePeriodsData = mostActivePeriods.map(
            ({ hour, count }) => ({
              hour: hour.padStart(5, "0"), // Ensure "02:00" format
              count,
            })
          );
          setMostActivePeriodsData(mostActivePeriodsData);
          setMostActiveDaysData(mostActiveDays);

          setLoading(false); // Unset loading for details
        })
        .catch((error) => {
          console.error("Error fetching watch details:", error);
          setLoading(false); // Unset loading for details
        });
    },
    [
      normalizeInput,
      setSelectedRef,
      setSuggestions,
      setLoading,
      setUniqueData,
      setLatestLead,
      setWatchIdsList,
      setColorPercentageData,
      setWatchFamilies,
      prepareChartData,
      setChartData,
      setMostActivePeriodsData,
      setMostActiveDaysData,
      setPercentageChange,
      setPriceAnalysis,
      setVariationCount,
      setVariations,
      setWatchesIds,
      setCoreRef,
      setFrequency,
      setUniqueSenders,
      setMeanUsdPrice,
    ]
  );

  const processKeywords = useCallback((responseData) => {
    for (var i = 0; i < responseData.length; i++) {
      if (responseData[i]["scanning_on"]) {
        setStartAnimation(true);
      }
    }

    setWatchList(responseData);
  });

  // Define the addKeyword function here
  const addKeyword = (filters, watchId) => {
    setTimeout(() => {
      setIsActionButtonMinimized(false);
    }, 1000);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/add_keyword`,
        {
          user_id: currentUser.id,
          watch_id: watchId, // You'll need to pass this down or set it in the modal's state
          is_buy: isBuy,
          ...filters, // Spread the filters object to include them in the request
        },
        {
          withCredentials: true, // Add credentials option here
        }
      )
      .then(() => {
        fetchKeywords(); // Update the keyword list
        addAlert({
          message: "Watch added.",
          type: "success",
        });
      })
      .catch((error) =>
        addAlert({
          message:
            error.response.data?.error ||
            error.response.data?.message ||
            JSON.stringify(error.response.data),
          type: "error",
        })
      );
  };

  const fetchKeywords = useCallback(async (page = 1) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/user-keywords/${currentUser.id}?page=${page}`,
        {
          withCredentials: true, // Ensure cookies are sent with the request
        }
      );
      processKeywords(response.data);
    } catch (error) {
      console.error("Error fetching keywords:", error);
    }
  });

  const dayMapping = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const BusiestDaysBarChart = ({ busiestDaysData }) => {
    if (!busiestDaysData || busiestDaysData.length === 0) {
      return <div>No Data Available</div>;
    }

    // Map the day numbers to day names using the dayMapping array
    const labels = busiestDaysData.map((dayData) => dayMapping[dayData.day]);
    const data = busiestDaysData.map((dayData) => dayData.count);

    return (
      <Bar
        data={{
          labels, // Day names like "Monday", "Tuesday", etc.
          datasets: [
            {
              label: "Busiest Days of the Week",
              data: data, // Counts for each day
              backgroundColor: "rgba(75,192,192,0.4)",
              borderColor: "rgba(75,192,192,1)",
              borderWidth: 1,
            },
          ],
        }}
        options={{
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                precision: 0, // To ensure whole numbers
              },
            },
          },
        }}
      />
    );
  };

  // const PieChartComponent = ({ colorData }) => {
  //   const colorHexMapping = {
  //     red: "#FF0000",
  //     green: "#008000",
  //     blue: "#0000FF",
  //     yellow: "#FFFF00",
  //     purple: "#800080",
  //     orange: "#FFA500",
  //     pink: "#FFC0CB",
  //     brown: "#A52A2A",
  //     gray: "#808080",
  //     champagne: "#F7E7CE",
  //     "tiffany blue": "#0ABAB5",
  //     chocolate: "#7B3F00",
  //     silver: "#C0C0C0",
  //     "mint green": "#98FF98",
  //     "ice blue": "#AFDBF5",
  //     celestial: "#9D81BA",
  //     sundust: "#FFD700",
  //     rainbow: "#FF69B4", // A blend isn't possible in one hex, so picked a vibrant color
  //     "dark blue": "#00008B",
  //     "dark gray": "#A9A9A9",
  //     "slate gray": "#708090",
  //     "baby blue": "#89CFF0",
  //     "candy pink": "#FFB6C1",
  //     ivory: "#FFFFF0",
  //     salmon: "#FA8072",
  //     turquoise: "#40E0D0",
  //     "smoke grey": "#636363",
  //     "rose pink": "#FF66CC",
  //     white: "#FFFFFF",
  //     black: "#000000",
  //     "smoked brown": "#8B4513",
  //     sun: "#FFD700",
  //     "bright blue": "#0096FF",
  //     "old pink": "#C08081",
  //     "smoked blue": "#4A708B",
  //     "dark night": "#002244",
  //     "elephant grey": "#A9A9A9",
  //     aventurine: "#00A36C",
  //     "white blue": "#ADD8E6",
  //     old: "#808080", // Assuming "old" as a generic gray
  //     snow: "#FFFAFA",
  //     celebration: "#FFD700", // Gold for celebration
  //     "cookie monster": "#0057B8",
  //     ferrite: "#4B5320",
  //     panda: "#D7D7D7", // Black and white, but averaging for simplicity
  //     "blue red": "#8B0000", // Dark red to contrast blue
  //     "blue black": "#00008B",
  //     "chocolate black": "#3D2B1F",
  //     "red black": "#8B0000",
  //     tiger: "#FD6A02",
  //     butterfly: "#6A5ACD", // A vibrant color to represent butterflies
  //     any: "#808080", // Neutral gray for 'any'
  //     burgundy: "#800020",
  //     cherry: "#DE3163",
  //     coffee: "#6F4E37",
  //     meteorite: "#4E4E50",
  //     plain: "#D3D3D3",
  //     "two-tone": "#D4AF37",
  //     wimbledon: "#2E8B57",
  //     titanium: "#878787",
  //     olive: "#808000",
  //     "olive green": "#556B2F",
  //     palm: "#A9A17D",
  //     "white black": "#C0C0C0", // Assuming a mix, so light gray
  //   };
  //   const data = {
  //     labels: Object.keys(colorData), // Color names
  //     datasets: [
  //       {
  //         label: "Color Distribution",
  //         data: Object.values(colorData), // Percentages
  //         backgroundColor: Object.keys(colorData).map(
  //           (color) => colorHexMapping[color.toLowerCase()] || "#808080"
  //         ), // Use the mapped hex values, default to gray if not found
  //         hoverBackgroundColor: Object.keys(colorData).map(
  //           (color) => colorHexMapping[color.toLowerCase()] || "#808080"
  //         ),
  //       },
  //     ],
  //   };

  //   return <Pie data={data} />;
  // };

  // const RadialBarChartComponent = ({ mostActivePeriodsData }) => {
  //   if (!mostActivePeriodsData || mostActivePeriodsData.length === 0) {
  //     return <div>No Data Available</div>;
  //   }

  //   // Create a data array where each hour's label is mapped correctly
  //   const data = mostActivePeriodsData.map((periodData) => periodData.count);

  //   const labels = mostActivePeriodsData.map((periodData) => {
  //     const hour = periodData.hour;
  //     // Formatting labels to a more readable form for clock face (e.g., "0:00" -> "12:00 AM", "13:00" -> "1:00 PM")
  //     const hourNumber = parseInt(hour.split(":")[0]);
  //     const formattedLabel =
  //       hourNumber === 0
  //         ? "12 AM"
  //         : hourNumber < 12
  //         ? `${hourNumber} AM`
  //         : hourNumber === 12
  //         ? "12 PM"
  //         : `${hourNumber - 12} PM`;
  //     return formattedLabel;
  //   });

  //   return (
  //     <Radar
  //       style={{ margin: "auto" }}
  //       data={{
  //         labels, // Clock face labels
  //         datasets: [
  //           {
  //             label: "Most Active Hours",
  //             data: data, // Corresponding data counts
  //             backgroundColor: "rgba(75,192,192,0.4)",
  //             borderColor: "rgba(75,192,192,1)",
  //             borderWidth: 1,
  //           },
  //         ],
  //       }}
  //       options={{
  //         scales: {
  //           r: {
  //             min: 0,
  //             max: Math.max(...data) || 1, // Ensure at least max of 1 to avoid issues
  //             angleLines: {
  //               display: true,
  //             },
  //             pointLabels: {
  //               font: {
  //                 size: 14,
  //               },
  //               color: "#000",
  //             },
  //             grid: {
  //               display: true,
  //             },
  //           },
  //         },
  //         startAngle: 90, // Start at the top of the chart
  //       }}
  //     />
  //   );
  // };

  function WatchlistFilterModalCreateWatchList({
    currentUser,
    selectedWatchId,
    setSelectedRef,
    setSuggestions,
    isBuy,
    fetchKeywords,
    addAlert,
    uniqueColors,
  }) {
    // Filter states
    const [selectedColors, setSelectedColors] = useState([]);
    const [fullSet, setFullSet] = useState(false);
    const [partialSet, setPartialSet] = useState(false);
    const [onlyWatch, setOnlyWatch] = useState(false);
    const [noPapersTags, setNoPapersTags] = useState(false);
    const [condition, setCondition] = useState([]);
    const [dateMonth, setDateMonth] = useState("");
    const [dateYear, setDateYear] = useState("");
    const [newerThan, setNewerThan] = useState(false);
    const [serial, setSerial] = useState("");
    const [onlyUsd, setOnlyUsd] = useState(false);

    // UI states for new filter system
    const [showFilters, setShowFilters] = useState(false);
    const [manualTags, setManualTags] = useState([]);
    const [loading, setLoading] = useState(false);

    // Collapse states
    const [isColorCollapsed, setIsColorCollapsed] = useState(true);
    const [isBoxPapersCollapsed, setIsBoxPapersCollapsed] = useState(true);
    const [isConditionCollapsed, setIsConditionCollapsed] = useState(true);
    const [isOthersCollapsed, setIsOthersCollapsed] = useState(true);
    const [isPriceLeadsCollapsed, setIsPriceLeadsCollapsed] = useState(true);

    // Handlers

    const handleColorChange = (color) => {
      setSelectedColors((prevSelected) =>
        prevSelected.includes(color)
          ? prevSelected.filter((c) => c !== color)
          : [...prevSelected, color]
      );
    };

    const handleConditionChange = (selectedCondition) => {
      setCondition((prevConditions) =>
        prevConditions.includes(selectedCondition)
          ? prevConditions.filter((c) => c !== selectedCondition)
          : [...prevConditions, selectedCondition]
      );
    };

    // Submit handler
    const handleConfirm = () => {
      const filters = {
        selected_colors: selectedColors,
        full_set: fullSet,
        partial_set: partialSet,
        only_watch: onlyWatch,
        no_papers_tags: noPapersTags,
        condition: condition,
        date_month: dateMonth,
        date_year: dateYear,
        newer_than: newerThan,
        serial: serial,
        only_usd: onlyUsd,
        manual_filters: manualTags, // Add manual filters to the submission
      };

      addKeyword(filters, selectedWatchId);
      setSelectedRef(null);
      setSuggestions([]);
      setShowFilterModalCreateWatchlist(false);
    };

    return (
      showFilterModalCreateWatchlist && (
        <div className="modal-container">
          <div className="modal">
            <div style={{ width: "80vw" }} className="modal-content">
              <div style={{ height: "70vh", zIndex: 1 }}>
                <h3>Find a specific lead via filter(s)</h3>

                <div className="filter-console">
                  {/* QuickSearchFilter Component */}
                  <QuickSearchFilter
                    onSearchChange={setManualTags}
                    setShowFilters={setShowFilters}
                    showFilters={showFilters}
                    setIsColorCollapsed={setIsColorCollapsed}
                    setIsBoxPapersCollapsed={setIsBoxPapersCollapsed}
                    setIsConditionCollapsed={setIsConditionCollapsed}
                    setIsOthersCollapsed={setIsOthersCollapsed}
                    setIsPriceLeadsCollapsed={setIsPriceLeadsCollapsed}
                    selectedColors={selectedColors}
                    condition={condition}
                    fullSet={fullSet}
                    partialSet={partialSet}
                    onlyWatch={onlyWatch}
                    dateMonth={dateMonth}
                    dateYear={dateYear}
                    newerThan={newerThan}
                    manualTags={manualTags}
                    setManualTags={setManualTags}
                  />

                  {showFilters && (
                    <div className="filter-console-grid">
                      <button onClick={() => setShowFilters(false)}>
                        Hide Filters
                      </button>

                      <div>
                        {/* Color Filter Section */}
                        <div className="filter-section">
                          <h4
                            onClick={() =>
                              setIsColorCollapsed(!isColorCollapsed)
                            }
                          >
                            Filter by Color:{" "}
                            <span>{isColorCollapsed ? "▼" : "▲"}</span>
                          </h4>
                          {!isColorCollapsed && (
                            <div className="color-filters">
                              {uniqueColors.map((color, index) => (
                                <label key={index}>
                                  <input
                                    disabled={loading}
                                    type="checkbox"
                                    value={color}
                                    onChange={() => handleColorChange(color)}
                                    checked={selectedColors.includes(color)}
                                  />
                                  {color}
                                </label>
                              ))}
                            </div>
                          )}
                        </div>

                        {/* Box & Papers Section */}
                        <div className="filter-section">
                          <h4
                            onClick={() =>
                              setIsBoxPapersCollapsed(!isBoxPapersCollapsed)
                            }
                          >
                            Box & Papers:{" "}
                            <span>{isBoxPapersCollapsed ? "▼" : "▲"}</span>
                          </h4>
                          {!isBoxPapersCollapsed && (
                            <div className="box-paper-filters">
                              <label>
                                <input
                                  disabled={loading}
                                  type="checkbox"
                                  checked={fullSet}
                                  onChange={() => setFullSet(!fullSet)}
                                />
                                Full Set
                              </label>
                              <label>
                                <input
                                  disabled={loading}
                                  type="checkbox"
                                  checked={partialSet}
                                  onChange={() => setPartialSet(!partialSet)}
                                />
                                Partial Set
                              </label>
                              <label>
                                <input
                                  disabled={loading}
                                  type="checkbox"
                                  checked={onlyWatch}
                                  onChange={() => setOnlyWatch(!onlyWatch)}
                                />
                                Only Watch
                              </label>
                            </div>
                          )}
                        </div>

                        {/* Condition Section */}
                        <div className="filter-section">
                          <h4
                            onClick={() =>
                              setIsConditionCollapsed(!isConditionCollapsed)
                            }
                          >
                            Condition:{" "}
                            <span>{isConditionCollapsed ? "▼" : "▲"}</span>
                          </h4>
                          {!isConditionCollapsed && (
                            <div className="condition-filter">
                              {[
                                "Brand New",
                                "Like New / Mint",
                                "Preowned or Used",
                                "Unpolished",
                                "Polished",
                              ].map((cond, index) => (
                                <label key={index}>
                                  <input
                                    disabled={loading}
                                    type="checkbox"
                                    value={cond}
                                    onChange={() => handleConditionChange(cond)}
                                    checked={condition.includes(cond)}
                                  />
                                  {cond}
                                </label>
                              ))}
                            </div>
                          )}
                        </div>

                        {/* Other Filters Section */}
                        <div className="filter-section">
                          <h4
                            onClick={() =>
                              setIsOthersCollapsed(!isOthersCollapsed)
                            }
                          >
                            Other Filters:{" "}
                            <span>{isOthersCollapsed ? "▼" : "▲"}</span>
                          </h4>
                          {!isOthersCollapsed && (
                            <div>
                              <div className="dated-filter">
                                <h5 style={{ marginBottom: "-2px" }}>
                                  Dated (Month, Year):
                                </h5>
                                <select
                                  value={dateMonth}
                                  onChange={(e) => setDateMonth(e.target.value)}
                                >
                                  <option value="">Month</option>
                                  {[...Array(12)].map((_, i) => {
                                    const month = (i + 1)
                                      .toString()
                                      .padStart(2, "0");
                                    const monthName = new Date(
                                      2000,
                                      i
                                    ).toLocaleString("default", {
                                      month: "long",
                                    });
                                    return (
                                      <option key={month} value={month}>
                                        {monthName}
                                      </option>
                                    );
                                  })}
                                </select>
                                <select
                                  value={dateYear}
                                  onChange={(e) => setDateYear(e.target.value)}
                                >
                                  <option value="">Year</option>
                                  {[...Array(13)].map((_, i) => {
                                    const year = 2024 - i;
                                    return (
                                      <option
                                        key={year}
                                        value={year.toString()}
                                      >
                                        {year}
                                      </option>
                                    );
                                  })}
                                </select>
                                <label>
                                  <input
                                    type="checkbox"
                                    checked={newerThan}
                                    onChange={() => setNewerThan(!newerThan)}
                                  />
                                  Or newer than
                                </label>
                              </div>

                              <div className="serial-filter">
                                <h5 style={{ marginBottom: "-2px" }}>
                                  Serial:
                                </h5>
                                <select
                                  value={serial}
                                  onChange={(e) => setSerial(e.target.value)}
                                >
                                  <option value="">All Serials</option>
                                  {[...Array(12)].map((_, i) => {
                                    const serialNum = i + 1;
                                    return (
                                      <option
                                        key={serialNum}
                                        value={`N${serialNum}`}
                                      >
                                        N{serialNum}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="modal-actions">
                <button
                  onClick={() => setShowFilterModalCreateWatchlist(false)}
                >
                  Cancel
                </button>
                <button
                  style={{ marginLeft: "0.5rem" }}
                  onClick={handleConfirm}
                >
                  Add To Watchlist
                </button>
              </div>
            </div>
          </div>
        </div>
      )
    );
  }

  return (
    <>
      {routePath == "/" && (
        <div>
          <>
            <div style={{ margin: "-20px" }}>
              {/* 1) Toggle button (hamburger vs up-arrow) */}
              {!expanded ? (
                <button
                  className="shake"
                  onClick={() => setExpanded(true)}
                  style={{ marginRight: "10px" }}
                >
                  {/* Hamburger icon */}☰
                </button>
              ) : (
                <button
                  onClick={() => setExpanded(false)}
                  style={{ marginRight: "10px" }}
                >
                  {/* Up arrow / minimize */}▲
                </button>
              )}
            </div>
            {/* 2) The 4 buttons, shown only when expanded is true */}
            <div
              className={`top-buttons-container ${!expanded ? "hidden" : ""}`}
            >
              {/* Removed the expanded && conditional here */}
              <button className="chat-button" onClick={toggleChat}>
                <img
                  src={chatIcon}
                  width="20"
                  height="20"
                  style={{ marginRight: "7px" }}
                />
              </button>
              <button
                onClick={() => navigate("/about")}
                className="autowatch-button"
              >
                <img
                  src={infoIcon}
                  width="20"
                  height="20"
                  style={{ marginRight: "7px" }}
                />
              </button>
              <button
                onClick={() => navigate("/dashboard")}
                className="goto-account-button"
              >
                <img
                  src={accountIcon}
                  width="20"
                  height="20"
                  style={{ marginRight: "7px" }}
                />
              </button>
              <TutorialModal currentUser={currentUser} mode={"engine"} />
            </div>
          </>

          <>
            <hr />
            <WatchSearchInput
              normalizeInput={normalizeInput}
              setSuggestions={setSuggestions}
              selectedRef={selectedRef}
              setSelectedRef={setSelectedRef}
              suggestions={suggestions}
              setLoading={setLoading}
              loading={loading}
              inputRef={inputRef}
              handleSelectSuggestion={handleSelectSuggestion}
              setRefInput={setRefInput}
              refInput={refInput}
            />

            <ChatModal isOpen={isChatOpen} onClose={() => setIsChatOpen(false)}>
              <Chat />
            </ChatModal>

            {isModalOpen && (
              <SelectedWatchModal
                isOpen={isModalOpen}
                onClose={() => {
                  toggleBodyScroll(false);
                  setIsModalOpen(false);
                  setSelectedRef(null);
                  setWatchFamilies(null);
                  setVariations(null); // Reset all when closing seelctedwatchcomponent
                }}
              >
                {!showLeadsModal && watchFamilies && variations ? (
                  <SelectedWatchComponent
                    setUniqueColors={setUniqueColors}
                    startAnimation={startAnimation}
                    setSelectedWatchId={setSelectedWatchId}
                    setShowFilterModalCreateWatchlist={
                      setShowFilterModalCreateWatchlist
                    }
                    searchLeadsPerFamilyWatch={searchLeadsPerFamilyWatch}
                    LineChart={LineChart}
                    chartData={chartData}
                    prepareChartData={prepareChartData}
                    priceAnalysis={priceAnalysis}
                    variation_count={variationCount}
                    variations={variations}
                    watchesIds={watchesIds}
                    coreRef={coreRef}
                    frequency={frequency}
                    uniqueSenders={uniqueSenders}
                    fetchUniqueColors={fetchUniqueColors}
                    addKeyword={addKeyword}
                  />
                ) : (
                  <div className="loader-container">
                    <div className="loader-text-extra">Fetching Results</div>
                    <div className="loader"></div>
                  </div>
                )}
              </SelectedWatchModal>
            )}

            <LeadFeed
              setWatchIdsList={setWatchIdsList}
              setShowLeadsModal={setShowLeadsModal}
              setUniqueColors={setUniqueColors}
              setMeanUsdPrice={setMeanUsdPrice}
              setSelectedWatchId={setSelectedWatchId}
              setShowFilterModalCreateWatchlist={
                setShowFilterModalCreateWatchlist
              }
              fetchUniqueColors={fetchUniqueColors}
              startAnimation={startAnimation}
              addKeyword={addKeyword}
              handleSelectSuggestion={handleSelectSuggestion}
            />
          </>

          <WatchlistFilterModalCreateWatchList
            currentUser={currentUser}
            selectedWatchId={selectedWatchId}
            setSelectedRef={setSelectedRef}
            setSuggestions={setSuggestions}
            isBuy={isBuy}
            fetchKeywords={fetchKeywords}
            addAlert={addAlert}
            uniqueColors={uniqueColors}
          />

          <div>
            <div className="tabs-container">
              {/* Hottest Watches Button */}
              <button
                className={`tab-button ${
                  activeTab === "hottest" ? "active" : ""
                }`}
                onClick={() => setActiveTab("hottest")}
                // onMouseEnter={() =>
                //   handleMouseEnter(
                //     "tooltip-hottest",
                //     "Explore the top trending watches from the past two weeks"
                //   )
                // }
                // onMouseLeave={() => handleMouseLeave("tooltip-hottest")}
              >
                <div className="fire-icon">
                  <div className="flame"></div>
                  <div className="flame"></div>
                  <div className="flame"></div>
                </div>
                Hottest Watches
              </button>

              {/* Rare Timepieces Button */}
              <button
                style={{ fontStyle: "italic" }}
                className={`tab-button ${
                  activeTab === "limitedEdition" ? "active" : ""
                }`}
                // onClick={() => setActiveTab("limitedEdition")}
                // onMouseEnter={() =>
                //   handleMouseEnter(
                //     "tooltip-limitedEdition",
                //     "*Coming soon* Discover exclusive and limited-edition timepieces"
                //   )
                // }
                // onMouseLeave={() => handleMouseLeave("tooltip-limitedEdition")}
              >
                <div className="limited-icon">
                  <div className="star-feed"></div>
                  <div className="star-feed"></div>
                </div>
                Rare Timepieces
              </button>

              {/* Stale Listings Button */}
              <button
                style={{ fontStyle: "italic" }}
                className={`tab-button ${
                  activeTab === "staleListings" ? "active" : ""
                }`}
                // onClick={() => setActiveTab("staleListings")}
                // onMouseEnter={() =>
                //   handleMouseEnter(
                //     "tooltip-staleListings",
                //     "*Coming soon* Uncover watches with steady or dropping prices"
                //   )
                // }
                // onMouseLeave={() => handleMouseLeave("tooltip-staleListings")}
              >
                <div className="stale-icon">
                  <div className="clock">
                    <div className="hand-stale hour-hand-stale"></div>
                    <div className="hand-stale minute-hand-stale"></div>
                  </div>
                  <div className="dust"></div>
                  <div className="dust"></div>
                  <div className="dust"></div>
                </div>
                Stale Listings
              </button>
            </div>

            <div className="tab-content">
              {activeTab === "hottest" && (
                <>
                  <HottestWatches
                    key={hottestKey} // Unique key prop
                    setMeanUsdPrice={setMeanUsdPrice}
                    startAnimation={startAnimation}
                    setSelectedWatchId={setSelectedWatchId}
                    setShowFilterModalCreateWatchlist={
                      setShowFilterModalCreateWatchlist
                    }
                    searchLeadsPerFamilyWatch={searchLeadsPerFamilyWatch}
                    LineChart={LineChart}
                    prepareChartData={prepareChartData}
                    fetchUniqueColors={fetchUniqueColors}
                    setUniqueColors={setUniqueColors}
                    setShowScrollToTop={setShowScrollToTop}
                    addKeyword={addKeyword}
                  />
                  {showScrollToTop && (
                    <button
                      onClick={scrollToTop}
                      style={{
                        position: "fixed",
                        bottom: "170px",
                        right: "10px",
                        background: "#333",
                        color: "#fff",
                        padding: "10px 15px",
                        borderRadius: "50%",
                        border: "none",
                        cursor: "pointer",
                        fontSize: "20px",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
                        zIndex: 1000,
                      }}
                    >
                      ↑
                    </button>
                  )}
                </>
              )}
              {activeTab === "staleListings" && (
                <div className="lead-feed-placeholder">
                  <h2 style={{ color: "white" }}>
                    <div className="icon stale-icon">
                      <div className="clock">
                        <div className="hand-stale hour-hand-stale"></div>
                        <div className="hand-stale minute-hand-stale"></div>
                      </div>
                      <div className="dust"></div>
                      <div className="dust"></div>
                      <div className="dust"></div>
                    </div>
                    Stale Listings
                  </h2>
                </div>
              )}
              {activeTab === "limitedEdition" && (
                <div className="lead-feed-placeholder">
                  <h2 style={{ color: "white" }}>
                    <div className="icon limited-icon">
                      <div className="star-feed"></div>
                      <div className="star-feed"></div>
                    </div>
                    Rare Timepieces
                  </h2>
                </div>
              )}
            </div>
          </div>

          <div>
            {showLeadsModal && (
              <WatchLeadsModal
                selectedRef={selectedRef}
                showLeadsModal={showLeadsModal}
                setShowLeadsModal={setShowLeadsModal}
                watchIds={watchIdsList}
                meanUsdPrice={meanUsdPrice}
                uniqueColors={uniqueColors}
              />
            )}
          </div>

          <div>
            {/* Use the SubscriptionCheckoutModal component */}
            <SubscriptionCheckoutModal
              isOpen={isSubscriptionModalOpen}
              onClose={handleCloseSubscriptionModal}
              hideCloseButton={true}
            />
          </div>
        </div>
      )}
    </>
  );
};

const LoginForm = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(""); // State to hold login error message
  const { setCurrentUser } = useAuth(); // Get setCurrentUser function from auth context
  const navigate = useNavigate(); // Hook to navigate to different routes
  const { addAlert } = useAlert();
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [emailForReset, setEmailForReset] = useState("");
  const [resetMessage, setResetMessage] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission
    try {
      // For the login request
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/login`,
        {
          phone_number: phoneNumber,
          password,
        },
        { withCredentials: true }
      ); // Make sure credentials are sent with the request

      addAlert({
        message: "Welcome Back!",
        type: "info",
      });

      window.scrollTo(0, 0);
      setCurrentUser(response.data); // Set the current user in context on successful login
      navigate("/"); // Navigate to the home page on successful login
    } catch (error) {
      console.error("Login failed:", error); // Log error to the console
      setError("Failed to log in. Please check your credentials."); // Set error message for display
    }
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/forgot_password`,
        {
          email: emailForReset,
        }
      );
      setResetMessage(
        "If the email is registered, a password reset link has been sent."
      );
    } catch (error) {
      console.error("Forgot password error:", error);
      setResetMessage("An error occurred. Please try again later.");
    }
  };

  return (
    <div className="login-modal">
      {showForgotPassword ? (
        <form className="forgot-password-form" onSubmit={handleForgotPassword}>
          <h3 className="forgot-password-heading">Reset Password</h3>
          <input
            className="forgot-password-input"
            type="email"
            placeholder="Enter your email"
            value={emailForReset}
            onChange={(e) => setEmailForReset(e.target.value)}
            required
          />
          <button type="submit" className="forgot-password-submit-button">
            Send Reset Link
          </button>
          <p className="reset-message">{resetMessage}</p>
          <button
            type="button"
            className="back-to-login-button"
            onClick={() => setShowForgotPassword(false)}
          >
            Back to Login
          </button>
        </form>
      ) : (
        <>
          <h2 className="login-heading">Login</h2>
          <form onSubmit={handleSubmit} className="login-form">
            <label htmlFor="phoneNumber" className="login-label">
              Phone Number
            </label>
            <input
              type="tel"
              id="phoneNumber"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              required
              placeholder="Enter your phone number"
              className="login-input"
            />
            <label htmlFor="password" className="login-label">
              Password
            </label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              placeholder="Enter your password"
              className="login-input"
            />
            <button type="submit" className="login-submit-button">
              Log In
            </button>
            <button
              type="button"
              className="forgot-password-button"
              onClick={() => setShowForgotPassword(true)}
            >
              Forgot Password?
            </button>
            {error && <div className="login-error">{error}</div>}
          </form>
        </>
      )}
    </div>
  );
};

const ProtectedRoute = ({ children }) => {
  const { currentUser } = useAuth();

  return currentUser ? children : <Navigate to="/" replace />;
};

const SubscriptionProtectedRoute = ({ children }) => {
  const { currentUser } = useAuth();
  const [trialStatus, setTrialStatus] = useState(null);
  const [loading, setLoading] = useState(true);

  const { addAlert } = useAlert();
  const navigate = useNavigate();

  const handleScrollToRegister = () => {
    navigate("/about"); // First, navigate to the page
    setTimeout(() => {
      const element = document.getElementById("register-form"); // Wait for DOM to load
      if (element) {
        const yOffset = -80; // Optional: Adjust for headers
        const y =
          element.getBoundingClientRect().top + window.scrollY + yOffset;

        window.scrollTo({
          top: y,
          behavior: "smooth",
        });
      }
    }, 200); // Delay ensures DOM is rendered before scroll occurs
  };

  useEffect(() => {
    const checkTrial = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/trial-status`,
          {
            withCredentials: true, // Ensure cookies are sent with the request
          }
        );
        setTrialStatus(response.data);

        if (response.data.daysRemaining == 999) {
          // setTimeout(() => {
          //   const hasSeenAlert = getCookie("hasSeenAlertAnon");
          //   if (!hasSeenAlert) {
          //     addAlert({
          //       message: (
          //         <>
          //           <div
          //             style={{
          //               display: "block",
          //               textAlign: "left",
          //               padding: "8px", // Add padding for mobile
          //               maxWidth: "90%", // Prevent content from stretching too wide
          //               margin: "0 auto", // Center the content
          //             }}
          //           >
          //             <span
          //               style={{ lineHeight: "1.2em", fontSize: "0.95rem" }}
          //             >
          //               {/* Adjust lineHeight and fontSize for mobile */}
          //               Early adopters get lifetime Alpha perks.{" "}
          //               <span
          //                 style={{
          //                   color: "#ffd700",
          //                   textDecoration: "underline",
          //                   cursor: "pointer",
          //                 }}
          //                 onClick={handleScrollToRegister} // Attach the scroll-to handler
          //               >
          //                 Register now
          //               </span>{" "}
          //               while spots last!
          //             </span>
          //             <hr
          //               style={{
          //                 margin: "16px 0",
          //                 border: "none",
          //                 borderTop: "1px solid #ccc",
          //               }}
          //             />
          //             <div style={{ lineHeight: "1.4em", fontSize: "0.85rem" }}>
          //               <strong style={{ fontSize: "1.2rem" }}>
          //                 🚀 Exciting Updates Coming Soon!
          //               </strong>
          //               <ul
          //                 style={{
          //                   marginTop: "8px",
          //                   marginBottom: "0",
          //                   paddingLeft: "20px",
          //                 }}
          //               >
          //                 <li>✨ Adding new popular watches to database.</li>
          //                 <li>✨ Much more lead sources and groups.</li>
          //                 <li>✨ Internal chat system w/ lead propagation.</li>
          //                 <li>
          //                   ✨ Refined decoder to narrow down better leads.
          //                 </li>
          //               </ul>
          //               <div
          //                 style={{
          //                   marginTop: "10px",
          //                   fontSize: "0.85rem",
          //                   color: "gold",
          //                   cursor: "pointer",
          //                 }}
          //                 onClick={() => showDetailedAlert(addAlert)} // Trigger detailed alert
          //               >
          //                 Read more about upcoming changes
          //               </div>
          //             </div>
          //           </div>
          //         </>
          //       ),
          //       type: "info",
          //       isTooltip: false,
          //       duration: 7700,
          //     });
          //     setCookie("hasSeenAlertAnon", "true", 14);
          //   }
          // }, 4000);
        } else if (
          response.data.daysRemaining >= 0 &&
          response.data.daysRemaining < 100
        ) {
          addAlert({
            message: `Your AutoWatch trial has ${response.data.daysRemaining} days left.`,
            type: "info",
          });
        }
      } catch (error) {
        console.error("Trial check failed:", error);
      } finally {
        setLoading(false);
      }
    };

    if (currentUser) {
      checkTrial();
    } else {
      setLoading(false); // No user, no need to check trial
    }
  }, [currentUser]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!currentUser) {
    return <Navigate to="/" replace />;
  }

  if (trialStatus?.trialEnded && trialStatus?.status !== "subscribed") {
    addAlert({
      message: "Your AutoWatch trial has ended.",
      type: "info",
    });
  }

  return children;
};

const AddContactModal = ({ isOpen, onClose, onAddContact }) => {
  const [newContact, setNewContact] = useState({
    phone_number: "",
    name: "",
    status: "",
    rank: "",
    notes: "",
  });

  const [adding, setAdding] = useState(false);

  const handleNewContactChange = (e) => {
    setNewContact({ ...newContact, [e.target.name]: e.target.value });
  };

  const handleAddNewContact = async (e) => {
    e.preventDefault();
    setAdding(true);

    // Prepare the data to send
    const contactData = { ...newContact };

    // Convert empty strings to null
    for (let key in contactData) {
      if (contactData[key] === "") {
        contactData[key] = null;
      }
    }

    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/contacts`,
        contactData,
        { withCredentials: true }
      );
      alert("Contact added successfully.");
      setNewContact({
        phone_number: "",
        name: "",
        status: "",
        rank: "",
        notes: "",
      });
      onAddContact(); // Callback to refresh contacts
      onClose(); // Close the modal
    } catch (error) {
      alert(error.response?.data?.error || "Failed to add contact.");
    } finally {
      setAdding(false);
    }
  };

  // Styles for AddContactModal
  const modalStyles = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0,0,0,0.7)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 1000,
    },
    content: {
      background: "#1f1f1f",
      color: "#e0e0e0",
      padding: "20px",
      borderRadius: "8px",
      width: "400px",
      maxWidth: "90%",
      maxHeight: "80vh",
      overflowY: "auto",
      boxShadow: "0 5px 15px rgba(0,0,0,0.5)",
    },
  };

  const inputStyle = {
    width: "100%",
    padding: "10px",
    fontSize: "16px",
    borderRadius: "4px",
    border: "1px solid #444",
    backgroundColor: "#2c2c2c",
    color: "#f0f0f0",
  };

  const buttonStyle = {
    padding: "10px 20px",
    fontSize: "16px",
    borderRadius: "4px",
    border: "none",
    color: "#fff",
    cursor: "pointer",
  };

  if (!isOpen) return null;

  return (
    <div style={modalStyles.overlay}>
      <div style={modalStyles.content}>
        <h3 style={{ marginBottom: "20px" }}>Add New Contact</h3>
        <form onSubmit={handleAddNewContact}>
          <input
            type="text"
            name="phone_number"
            placeholder="Phone Number"
            value={newContact.phone_number}
            onChange={handleNewContactChange}
            required
            style={{ ...inputStyle, marginBottom: "10px" }}
          />
          <input
            type="text"
            name="name"
            placeholder="Name"
            value={newContact.name}
            onChange={handleNewContactChange}
            style={{ ...inputStyle, marginBottom: "10px" }}
          />
          <select
            name="status"
            value={newContact.status}
            onChange={handleNewContactChange}
            style={{ ...inputStyle, marginBottom: "10px" }}
          >
            <option value="">Select Status</option>
            <option value="New">New</option>
            <option value="Contacted">Contacted</option>
            <option value="Interested">Interested</option>
            <option value="Not Interested">Not Interested</option>
            <option value="Client">Client</option>
          </select>
          <input
            type="number"
            name="rank"
            placeholder="Rank"
            value={newContact.rank}
            onChange={handleNewContactChange}
            min="0"
            step="1"
            style={{ ...inputStyle, marginBottom: "10px" }}
          />
          <textarea
            name="notes"
            placeholder="Notes"
            value={newContact.notes}
            onChange={handleNewContactChange}
            style={{ ...inputStyle, marginBottom: "10px", height: "80px" }}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
            }}
          >
            <button
              type="button"
              onClick={onClose}
              style={{
                ...buttonStyle,
                backgroundColor: "#6c757d",
                marginRight: "10px",
              }}
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={adding}
              style={{ ...buttonStyle, backgroundColor: "#28a745" }}
            >
              {adding ? "Adding..." : "Add Contact"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

// GrowthAdmin Component
const GrowthAdmin = () => {
  const { currentUser } = useAuth();
  if (!currentUser || !currentUser.is_admin) {
    return <p>Access denied. You do not have permission to view this area.</p>;
  }
  return (
    <div style={{ padding: "20px" }}>
      <h1>Growth Admin Dashboard</h1>
      <div style={{ display: "flex", gap: "40px", marginTop: "20px" }}>
        <ContactsManagement />
      </div>
    </div>
  );
};

// MassCampaigns Component
const MassCampaigns = () => {
  const [campaigns, setCampaigns] = useState([]);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  useEffect(() => {
    fetchCampaigns();
  }, []);

  const fetchCampaigns = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/campaigns`,
        {
          withCredentials: true,
        }
      );
      setCampaigns(response.data.campaigns);
    } catch (error) {
      console.error("Error fetching campaigns:", error);
    }
  };

  return (
    <div>
      <div
        style={{
          position: "relative",
          marginBottom: "20px",
        }}
      >
        <h2 style={{ textAlign: "center", margin: 0 }}>Mass Campaigns</h2>
        <button
          onClick={() => setIsCreateModalOpen(true)}
          style={{
            position: "absolute",
            right: 0,
            top: 0,
            padding: "8px 16px",
            fontSize: "16px",
            borderRadius: "4px",
            border: "none",
            backgroundColor: "#28a745",
            color: "#fff",
            cursor: "pointer",
          }}
        >
          + New Campaign
        </button>
      </div>

      {/* Campaign List */}
      <CampaignsTable campaigns={campaigns} />

      {/* Create Campaign Modal */}
      {isCreateModalOpen && (
        <CreateCampaignModal
          onClose={() => {
            setIsCreateModalOpen(false);
            fetchCampaigns(); // Refresh campaigns after creation
          }}
        />
      )}
    </div>
  );
};

// CreateCampaignModal Component
const CreateCampaignModal = ({ onClose }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [campaignData, setCampaignData] = useState({
    name: "",
    type: "Text", // or 'Email'
    message: "",
    recipients: "all", // 'all', 'tags', 'selected'
    selectedTags: [],
    selectedContacts: [],
    scheduleDate: "",
  });

  const [contacts, setContacts] = useState([]);
  const [tags, setTags] = useState([]);

  useEffect(() => {
    fetchContacts();
    fetchTags();
  }, []);

  const fetchContacts = async () => {
    setLoading(true);
    try {
      const endpoint = `${process.env.REACT_APP_API_URL}/contacts`;
      const response = await axios.get(endpoint, {
        withCredentials: true,
      });
      setContacts(response.data.contacts);
      setLoading(false);
    } catch (err) {
      console.error("Error fetching contacts:", err);
      setError("Failed to fetch contacts");
      setLoading(false);
    }
  };

  const fetchTags = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/tags`,
        { withCredentials: true }
      );
      setTags(response.data.tags);
    } catch (error) {
      console.error("Error fetching tags:", error);
      setError("Failed to load tags.");
    }
  };

  const handleInputChange = (e) => {
    setCampaignData({ ...campaignData, [e.target.name]: e.target.value });
  };

  const handleCreateCampaign = async (e) => {
    e.preventDefault();
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/campaigns`,
        campaignData,
        { withCredentials: true }
      );
      alert("Campaign created successfully.");
      onClose();
    } catch (error) {
      alert(error.response?.data?.error || "Failed to create campaign.");
    }
  };

  const inputStyle = {
    width: "100%",
    padding: "10px",
    fontSize: "16px",
    borderRadius: "4px",
    border: "1px solid #444",
    backgroundColor: "#2c2c2c",
    color: "#f0f0f0",
  };

  const buttonStyle = {
    padding: "10px 20px",
    fontSize: "16px",
    borderRadius: "4px",
    border: "none",
    color: "#fff",
    cursor: "pointer",
  };

  return (
    <div style={modalStyles.overlay}>
      <div style={modalStyles.content}>
        <h3>Create New Campaign</h3>
        <form onSubmit={handleCreateCampaign}>
          <input
            type="text"
            name="name"
            placeholder="Campaign Name"
            value={campaignData.name}
            onChange={handleInputChange}
            required
            style={{ ...inputStyle, marginBottom: "10px" }}
          />
          <select
            name="type"
            value={campaignData.type}
            onChange={handleInputChange}
            style={{ ...inputStyle, marginBottom: "10px" }}
          >
            <option value="Text">Text Message</option>
            <option value="Email">Email</option>
          </select>
          <textarea
            name="message"
            placeholder="Message Content"
            value={campaignData.message}
            onChange={handleInputChange}
            required
            style={{ ...inputStyle, marginBottom: "10px", height: "100px" }}
          />
          {/* Recipients Selection */}
          <div style={{ marginBottom: "10px" }}>
            <label>
              <input
                type="radio"
                name="recipients"
                value="all"
                checked={campaignData.recipients === "all"}
                onChange={handleInputChange}
              />{" "}
              All Contacts
            </label>
            <label style={{ marginLeft: "10px" }}>
              <input
                type="radio"
                name="recipients"
                value="tags"
                checked={campaignData.recipients === "tags"}
                onChange={handleInputChange}
              />{" "}
              By Tags
            </label>
            <label style={{ marginLeft: "10px" }}>
              <input
                type="radio"
                name="recipients"
                value="selected"
                checked={campaignData.recipients === "selected"}
                onChange={handleInputChange}
              />{" "}
              Selected Contacts
            </label>
          </div>
          {/* Conditionally Render Tag or Contact Selection */}
          {campaignData.recipients === "tags" && (
            <select
              multiple
              name="selectedTags"
              value={campaignData.selectedTags}
              onChange={(e) =>
                setCampaignData({
                  ...campaignData,
                  selectedTags: Array.from(
                    e.target.selectedOptions,
                    (option) => option.value
                  ),
                })
              }
              style={{ ...inputStyle, marginBottom: "10px" }}
            >
              {tags.map((tag) => (
                <option key={tag.id} value={tag.id}>
                  {tag.name}
                </option>
              ))}
            </select>
          )}
          {campaignData.recipients === "selected" && (
            <select
              multiple
              name="selectedContacts"
              value={campaignData.selectedContacts}
              onChange={(e) =>
                setCampaignData({
                  ...campaignData,
                  selectedContacts: Array.from(
                    e.target.selectedOptions,
                    (option) => option.value
                  ),
                })
              }
              style={{ ...inputStyle, marginBottom: "10px" }}
            >
              {contacts.map((contact) => (
                <option key={contact.id} value={contact.id}>
                  {contact.name || contact.phone_number}
                </option>
              ))}
            </select>
          )}
          {/* Schedule Date */}
          <input
            type="datetime-local"
            name="scheduleDate"
            value={campaignData.scheduleDate}
            onChange={handleInputChange}
            style={{ ...inputStyle, marginBottom: "10px" }}
          />
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <button
              type="button"
              onClick={onClose}
              style={{
                ...buttonStyle,
                backgroundColor: "#6c757d",
                marginRight: "10px",
              }}
            >
              Cancel
            </button>
            <button
              type="submit"
              style={{ ...buttonStyle, backgroundColor: "#28a745" }}
            >
              Create Campaign
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

// CampaignsTable Component
const CampaignsTable = ({ campaigns }) => {
  return (
    <table
      border="1"
      cellPadding="10"
      cellSpacing="0"
      style={{ width: "100%", marginTop: "10px" }}
    >
      <thead>
        <tr>
          <th>Name</th>
          <th>Type</th>
          <th>Recipients</th>
          <th>Status</th>
          <th>Scheduled Date</th>
        </tr>
      </thead>
      <tbody>
        {campaigns.map((campaign) => (
          <tr key={campaign.id}>
            <td>{campaign.name}</td>
            <td>{campaign.type}</td>
            <td>{campaign.recipientsDescription}</td>
            <td>{campaign.status}</td>
            <td>{campaign.scheduleDate}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

// ContactsManagement Component
const ContactsManagement = () => {
  const [contacts, setContacts] = useState([]);
  const [displayedContacts, setDisplayedContacts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedContact, setSelectedContact] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const contactsPerPage = 10;
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState(searchQuery);
  const [assignedUserFilter, setAssignedUserFilter] = useState("");
  const [recentInteractions, setRecentInteractions] = useState([]);
  const [affiliates, setAffiliates] = useState([]);
  const [tagFilter, setTagFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");

  // Added state variables for dashboard stats
  const [statsLoading, setStatsLoading] = useState(true);
  const [totalUsers, setTotalUsers] = useState(0);
  const [payingUsers, setPayingUsers] = useState(0);
  const [statsError, setStatsError] = useState(null);

  // Add state to manage the modal's visibility
  const [isAddContactModalOpen, setIsAddContactModalOpen] = useState(false);

  // Function to refresh contacts after adding a new contact
  const handleAddContact = () => {
    fetchContacts(); // Refresh the contacts list
  };

  // Extract unique assigned users for the dropdown
  const uniqueAssignedUsers = [
    ...new Set(contacts.map((contact) => contact.assigned_to).filter(Boolean)),
  ];

  const uniqueTags = [
    ...new Set(
      contacts.flatMap((contact) => contact.tags?.map((tag) => tag.name) || [])
    ),
  ];

  const uniqueStatuses = [
    ...new Set(contacts.map((contact) => contact.status).filter(Boolean)),
  ];

  const handleTagFilterChange = (e) => {
    setTagFilter(e.target.value);
    setCurrentPage(1);
  };

  const handleStatusFilterChange = (e) => {
    setStatusFilter(e.target.value);
    setCurrentPage(1);
  };

  // Normalize the debounced search query
  const normalizedSearchQuery = searchQuery.replace(/\D/g, "");

  // Define achievements/goals
  const goals = [
    {
      id: 1,
      description: "Get our first paying subscriber",
      achieved: payingUsers >= 1,
    },
    {
      id: 2,
      description: "Get 10 paying subscribers",
      achieved: payingUsers >= 10,
    },
    {
      id: 3,
      description: "Get 50 paying subscribers",
      achieved: payingUsers >= 50,
    },
    {
      id: 4,
      description: "Get 100 paying subscribers",
      achieved: payingUsers >= 100,
    },
  ];

  // Fetch contacts and dashboard stats when the component mounts
  useEffect(() => {
    fetchContacts();
    fetchDashboardStats();
    fetchRecentInteractions();
  }, []);

  const fetchRecentInteractions = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/interactions/recent`,
        { withCredentials: true }
      );
      setRecentInteractions(response.data.recent_interactions);
    } catch (err) {
      console.error("Error fetching recent interactions:", err);
    }
  };

  // Debounce search input
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchQuery(searchQuery);
      setCurrentPage(1); // Reset to first page on new search
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [searchQuery]);

  // Refetch contacts and re-filter when dependencies change
  useEffect(() => {
    filterAndPaginateContacts();
  }, [
    contacts,
    currentPage,
    debouncedSearchQuery,
    assignedUserFilter,
    tagFilter,
    statusFilter,
  ]);

  // New useEffect for fetching affiliates with last login info
  useEffect(() => {
    const fetchAffiliates = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/affiliates/last_logged_in`,
          {
            withCredentials: true,
          }
        );
        setAffiliates(response.data.affiliates);
      } catch (error) {
        console.error("Error fetching affiliates:", error);
      }
    };

    fetchAffiliates();
  }, []);

  const fetchContacts = async () => {
    setLoading(true);
    try {
      const endpoint = `${process.env.REACT_APP_API_URL}/contacts`;
      const response = await axios.get(endpoint, {
        withCredentials: true,
      });
      setContacts(response.data.contacts);
      setLoading(false);
    } catch (err) {
      console.error("Error fetching contacts:", err);
      setError("Failed to fetch contacts");
      setLoading(false);
    }
  };

  const fetchDashboardStats = async () => {
    setStatsLoading(true);
    try {
      const endpoint = `${process.env.REACT_APP_API_URL}/dashboard_stats`;
      const response = await axios.get(endpoint, {
        withCredentials: true,
      });
      setTotalUsers(response.data.total_users);
      setPayingUsers(response.data.paying_users);
      setStatsLoading(false);
    } catch (err) {
      console.error("Error fetching dashboard stats:", err);
      setStatsError("Failed to fetch dashboard statistics");
      setStatsLoading(false);
    }
  };

  const handleAssignedUserChange = (e) => {
    setAssignedUserFilter(e.target.value);
    setCurrentPage(1); //
  };

  const getFilteredContacts = () => {
    const lowerCaseQuery = debouncedSearchQuery.toLowerCase();

    return contacts.filter((contact) => {
      const normalizedPhone = contact.phone_number.replace(/\D/g, "");
      const nameMatch = contact.name
        ? contact.name.toLowerCase().includes(lowerCaseQuery)
        : false;
      const phoneMatch = normalizedSearchQuery
        ? normalizedPhone.includes(normalizedSearchQuery)
        : false;
      const assignedMatch = assignedUserFilter
        ? contact.assigned_to === assignedUserFilter
        : true;

      // New tag filter
      const tagMatch = tagFilter
        ? contact.tags?.some((tag) => tag.name === tagFilter)
        : true;

      // New status filter
      const statusMatch = statusFilter ? contact.status === statusFilter : true;

      return (
        (nameMatch || phoneMatch) && assignedMatch && tagMatch && statusMatch
      );
    });
  };

  const filterAndPaginateContacts = () => {
    const filtered = getFilteredContacts();

    const startIndex = (currentPage - 1) * contactsPerPage;
    const endIndex = startIndex + contactsPerPage;
    setDisplayedContacts(filtered.slice(startIndex, endIndex));
  };

  const handlePageChange = (newPage) => {
    const filteredCount = getFilteredContacts().length;
    const totalPages = Math.ceil(filteredCount / contactsPerPage);

    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleAssignContact = async (contactId) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/assign_contact`,
        { contact_id: contactId },
        { withCredentials: true }
      );
      alert(response.data.message);
      fetchContacts(); // Refresh contacts to show the updated assignment
      fetchDashboardStats(); // Refresh dashboard stats if necessary
    } catch (err) {
      console.error("Error assigning contact:", err);
      alert(err.response?.data?.error || "Failed to assign contact");
    }
  };

  if (loading || statsLoading)
    return (
      <p style={{ margin: "auto" }}>
        Loading contacts and dashboard statistics...
      </p>
    );
  if (error) return <p>{error}</p>;
  if (statsError) return <p>{statsError}</p>;

  const filteredCount = getFilteredContacts().length;
  const totalPages = Math.ceil(filteredCount / contactsPerPage);

  return (
    <div style={{ flex: 1, padding: "20px" }}>
      <div
        style={{
          marginBottom: "20px",
          padding: "15px",
          border: "1px solid #444",
          borderRadius: "8px",
          backgroundColor: "#2c2c2c",
          color: "#f0f0f0",
        }}
      >
        <h3>Admins Last Logged In</h3>
        <ul>
          {affiliates.map((affiliate) => (
            <li key={affiliate.id}>
              {affiliate.name} (Email: {affiliate.email}) - Last Logged In:{" "}
              {affiliate.last_logged_in
                ? new Date(affiliate.last_logged_in).toLocaleString()
                : "Never"}
            </li>
          ))}
        </ul>
      </div>
      <div
        style={{
          marginBottom: "20px",
          padding: "15px",
          border: "1px solid #444",
          borderRadius: "8px",
          backgroundColor: "#2c2c2c",
          color: "#f0f0f0",
        }}
      >
        <h3>Recent Interactions</h3>
        {recentInteractions.length === 0 ? (
          <p>No recent interactions found.</p>
        ) : (
          <ul style={{ listStyleType: "none", padding: 0 }}>
            {recentInteractions.map((interaction, index) => (
              <li key={index} style={{ marginBottom: "10px" }}>
                <span style={{ fontWeight: "bold" }}>
                  {interaction.affiliate_name}
                </span>{" "}
                has{" "}
                {interaction.method.startsWith("Status Change") ? (
                  <span>changed the status to</span>
                ) : (
                  <span>added a</span>
                )}{" "}
                <span style={{ fontStyle: "italic" }}>
                  {interaction.method}
                </span>{" "}
                {interaction.method.startsWith("Status Change") && (
                  <span>for</span>
                )}{" "}
                <span style={{ fontWeight: "bold" }}>
                  {interaction.contact_name}
                </span>{" "}
                on {new Date(interaction.date).toLocaleString()}.
                {interaction.method.startsWith("Status Change") && (
                  <div>
                    <em>Details: {interaction.content}</em>
                  </div>
                )}
              </li>
            ))}
          </ul>
        )}
      </div>

      {/* Dashboard Section */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "20px",
          flexWrap: "wrap",
          gap: "20px",
        }}
      >
        {/* Current Users */}
        <div
          style={{
            flex: "1",
            minWidth: "200px",
            padding: "15px",
            border: "1px solid #444",
            borderRadius: "8px",
            backgroundColor: "#2c2c2c",
            color: "#f0f0f0",
          }}
        >
          <h3>Dashboard</h3>
          <p>
            <strong>Total Users:</strong> {totalUsers}
          </p>
          <p>
            <strong>Paying Users:</strong> {payingUsers}
          </p>
        </div>

        {/* Goals/Achievements Section */}
        <div
          style={{
            flex: "1",
            minWidth: "200px",
            padding: "15px",
            border: "1px solid #444",
            borderRadius: "8px",
            backgroundColor: "#2c2c2c",
            color: "#f0f0f0",
          }}
        >
          <h3>Achievements & Goals</h3>
          <ul style={{ listStyleType: "none", padding: 0 }}>
            {goals.map((goal) => (
              <li
                key={goal.id}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "8px",
                }}
              >
                <input
                  type="checkbox"
                  checked={goal.achieved}
                  readOnly
                  style={{ marginRight: "10px" }}
                />
                <span>{goal.description}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>

      {/* Filter Controls Section */}
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
          gap: "20px",
          marginBottom: "20px",
        }}
      >
        {/* Existing Assigned User Filter */}
        <div>
          <label htmlFor="assignedFilter">Filter by Assigned User:</label>
          <select
            id="assignedFilter"
            value={assignedUserFilter}
            onChange={handleAssignedUserChange}
            style={{
              display: "block",
              width: "100%",
              padding: "8px",
              marginTop: "5px",
              backgroundColor: "#3a3a3a",
              color: "#f0f0f0",
              border: "1px solid #444",
              borderRadius: "4px",
            }}
          >
            <option value="">All</option>
            {uniqueAssignedUsers.map((user, index) => (
              <option key={index} value={user}>
                {user}
              </option>
            ))}
          </select>
        </div>

        {/* New Tag Filter */}
        <div>
          <label htmlFor="tagFilter">Filter by Tag:</label>
          <select
            id="tagFilter"
            value={tagFilter}
            onChange={handleTagFilterChange}
            style={{
              display: "block",
              width: "100%",
              padding: "8px",
              marginTop: "5px",
              backgroundColor: "#3a3a3a",
              color: "#f0f0f0",
              border: "1px solid #444",
              borderRadius: "4px",
            }}
          >
            <option value="">All</option>
            {uniqueTags.map((tag, index) => (
              <option key={index} value={tag}>
                {tag}
              </option>
            ))}
          </select>
        </div>

        {/* New Status Filter */}
        <div>
          <label htmlFor="statusFilter">Filter by Status:</label>
          <select
            id="statusFilter"
            value={statusFilter}
            onChange={handleStatusFilterChange}
            style={{
              display: "block",
              width: "100%",
              padding: "8px",
              marginTop: "5px",
              backgroundColor: "#3a3a3a",
              color: "#f0f0f0",
              border: "1px solid #444",
              borderRadius: "4px",
            }}
          >
            <option value="">All</option>
            {uniqueStatuses.map((status, index) => (
              <option key={index} value={status}>
                {status}
              </option>
            ))}
          </select>
        </div>
      </div>

      {/* Search Input Field */}
      <div style={{ marginBottom: "20px" }}>
        <input
          type="text"
          placeholder="Search by Name or Phone Number"
          value={searchQuery}
          onChange={handleSearchChange}
          style={{
            width: "100%",
            padding: "10px",
            fontSize: "16px",
            borderRadius: "4px",
            border: "1px solid #444",
            backgroundColor: "#3a3a3a",
            color: "#f0f0f0",
          }}
        />
      </div>
      {/* Add a button to open the Add Contact modal */}

      {/* Include the AddContactModal */}
      <AddContactModal
        isOpen={isAddContactModalOpen}
        onClose={() => setIsAddContactModalOpen(false)}
        onAddContact={handleAddContact}
      />
      {/* Contacts Table */}
      {displayedContacts.length === 0 ? (
        <p>No contacts found.</p>
      ) : (
        <>
          <div
            style={{
              position: "relative",
              marginBottom: "20px",
            }}
          >
            <h2 style={{ textAlign: "center", margin: 0 }}>
              Potential Clients ({contacts.length})
            </h2>
            <button
              onClick={() => setIsAddContactModalOpen(true)}
              style={{
                position: "absolute",
                right: "5%",
                top: 0,
                padding: "8px 16px",
                fontSize: "16px",
                borderRadius: "4px",
                border: "none",
                backgroundColor: "#28a745", // A pleasant green color
                color: "#fff",
                cursor: "pointer",
              }}
            >
              + Add Contact
            </button>
          </div>
          <ContactsTable
            contacts={displayedContacts}
            onViewDetails={setSelectedContact}
            onAssign={handleAssignContact}
          />
        </>
      )}

      {/* Pagination Controls */}
      {filteredCount > contactsPerPage && (
        <div
          style={{
            marginTop: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "10px",
          }}
        >
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            style={{
              padding: "8px 16px",
              borderRadius: "4px",
              border: "1px solid #444",
              backgroundColor: currentPage === 1 ? "#555" : "#333",
              color: "#f0f0f0",
              cursor: currentPage === 1 ? "not-allowed" : "pointer",
            }}
          >
            Previous
          </button>
          <span style={{ color: "#f0f0f0" }}>
            Page {currentPage} of {totalPages}
          </span>
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            style={{
              padding: "8px 16px",
              borderRadius: "4px",
              border: "1px solid #444",
              backgroundColor: currentPage === totalPages ? "#555" : "#333",
              color: "#f0f0f0",
              cursor: currentPage === totalPages ? "not-allowed" : "pointer",
            }}
          >
            Next
          </button>
        </div>
      )}

      {/* Contact Details Modal */}
      {selectedContact && (
        <ContactDetailsModal
          contact={selectedContact}
          onClose={() => {
            setSelectedContact(null);
            fetchContacts(); // Refresh contacts after closing
            fetchRecentInteractions(); // Refresh recent interactions
          }}
        />
      )}
      <hr style={{ margin: "5%" }} />
      <MassCampaigns style={{ marginBottom: "5%" }} />
    </div>
  );
};

// ContactsTable Component
const ContactsTable = ({ contacts, onViewDetails, onAssign }) => {
  const { currentUser } = useAuth();

  return (
    <table
      border="1"
      cellPadding="10"
      cellSpacing="0"
      style={{ width: "100%", marginTop: "10px" }}
    >
      <thead>
        <tr>
          <th>Phone Number</th>
          <th>Name</th>
          <th>Status</th>
          <th>Rank</th>
          <th>Assigned To</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {contacts.map((contact) => (
          <tr key={contact.id}>
            <td>{contact.phone_number}</td>
            <td>{contact.name || "N/A"}</td>
            <td>{contact.status}</td>
            <td>{contact.rank || "N/A"}</td>
            <td>{contact.assigned_to ? contact.assigned_to : "Unassigned"}</td>
            <td>
              {contact.assigned_to === currentUser.name ? (
                <button onClick={() => onViewDetails(contact)}>
                  View Details
                </button>
              ) : (
                <button disabled style={{ cursor: "not-allowed" }}>
                  View Details
                </button>
              )}
              {!contact.assigned_to && (
                <button onClick={() => onAssign(contact.id)}>
                  Assign to Me
                </button>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

// ContactDetailsModal Component (Updated for Interactions)
const ContactDetailsModal = ({ contact, onClose }) => {
  const { currentUser } = useAuth();
  const [interactions, setInteractions] = useState([]);
  const [loadingInteractions, setLoadingInteractions] = useState(true);
  const [newInteraction, setNewInteraction] = useState({
    method: "",
    content: "",
    notes: "",
  });
  const [status, setStatus] = useState(contact.status || "New"); // Initialize with the current status
  const [updating, setUpdating] = useState(false);
  const [error, setError] = useState(null);

  const [tags, setTags] = useState([]);
  const [contactTags, setContactTags] = useState(contact.tags || []);
  const [selectedTagId, setSelectedTagId] = useState("");
  const [newTagName, setNewTagName] = useState("");

  useEffect(() => {
    fetchInteractions();
    fetchTags();
  }, []);

  const fetchTags = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/tags`,
        { withCredentials: true }
      );
      setTags(response.data.tags);
    } catch (error) {
      console.error("Error fetching tags:", error);
      setError("Failed to load tags.");
    }
  };

  const handleCreateTag = async () => {
    if (!newTagName) {
      alert("Tag name is required.");
      return;
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/tags`,
        { name: newTagName },
        { withCredentials: true }
      );
      setTags([...tags, response.data.tag]);
      setNewTagName(""); // Clear the input field after adding
    } catch (error) {
      console.error("Error creating tag:", error);
      alert("Failed to create tag.");
    }
  };

  const handleAssignTag = async () => {
    if (!selectedTagId) return;
    const tagIdAsNumber = parseInt(selectedTagId, 10);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/contacts/${contact.id}/tags`,
        { tag_ids: [tagIdAsNumber] },
        { withCredentials: true }
      );

      // Use the returned tags from the response
      const assignedTags = response.data.assigned_tags;
      setContactTags((prevTags) => [...prevTags, ...assignedTags]);
      setSelectedTagId("");
    } catch (error) {
      console.error(
        "Error assigning tag:",
        error.response?.data?.error || error.message
      );
      alert(
        "Failed to assign tag: " +
          (error.response?.data?.error || "Unknown error")
      );
    }
  };

  const handleRemoveTag = async (tagId) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/contacts/${contact.id}/tags/${tagId}`,
        { withCredentials: true }
      );
      setContactTags(contactTags.filter((tag) => tag.id !== tagId));
    } catch (error) {
      console.error("Error removing tag:", error);
      alert("Failed to remove tag.");
    }
  };

  const fetchInteractions = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/contacts/${contact.id}/interactions`,
        { withCredentials: true }
      );
      setInteractions(response.data.interactions);
      setLoadingInteractions(false);
    } catch (err) {
      console.error("Error fetching interactions:", err);
      setError("Failed to fetch interactions");
      setLoadingInteractions(false);
    }
  };

  const updateContactStatus = async (contactId, status, notes) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/update_contact_status`,
        { contact_id: contactId, status, notes },
        { withCredentials: true }
      );
      fetchInteractions(); // Optional: Refresh interactions if needed
      alert("Contact status updated successfully.");
    } catch (error) {
      console.error("Error updating contact status:", error);
      alert("Failed to update contact status.");
    }
  };

  const handleStatusChange = async (e) => {
    const newStatus = e.target.value;
    setStatus(newStatus);

    try {
      setUpdating(true);
      await updateContactStatus(contact.id, newStatus, contact.notes); // Call the function
    } catch (err) {
      console.error("Error updating contact status:", err);
      alert("Failed to update contact status.");
    } finally {
      setUpdating(false);
    }
  };

  const handleAddInteraction = async (e) => {
    e.preventDefault();
    if (!newInteraction.method || !newInteraction.content) {
      alert("Method and content are required.");
      return;
    }

    const assignment = contact.assignments.find(
      (a) => a.affiliate_id === currentUser.id
    );

    if (!assignment) {
      alert("This contact is not assigned to you.");
      return;
    }

    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/contacts/${contact.id}/interactions`,
        {
          assignment_id: assignment.id,
          method: newInteraction.method,
          content: newInteraction.content,
          notes: newInteraction.notes,
        },
        { withCredentials: true }
      );
      setNewInteraction({ method: "", content: "", notes: "" });
      fetchInteractions(); // Refresh the interactions list
    } catch (err) {
      console.error("Error adding interaction:", err);
      alert("Failed to add interaction");
    }
  };

  const interactionMethods = [
    "Call",
    "Email",
    "Message",
    "Text",
    "WhatsApp",
    "Meeting",
    "Video Call",
  ];

  if (loadingInteractions) return <p>Loading interactions...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div style={modalStyles.overlay}>
      <div style={modalStyles.content}>
        <h3>Contact Details</h3>
        <div>
          <h4>Manage Tags</h4>
          <div>
            <input
              type="text"
              placeholder="New tag name"
              value={newTagName}
              onChange={(e) => setNewTagName(e.target.value)}
            />
            <button onClick={handleCreateTag}>Add New Tag</button>
          </div>

          <h4>Assign Existing Tag</h4>
          <select
            value={selectedTagId}
            onChange={(e) => setSelectedTagId(e.target.value)}
          >
            <option value="">Select a tag</option>
            {tags.map((tag) => (
              <option key={tag.id} value={tag.id}>
                {tag.name}
              </option>
            ))}
          </select>
          <button onClick={handleAssignTag}>Assign Tag</button>

          <h4>Current Tags</h4>
          <ul>
            {contactTags.map((tag) => (
              <li key={tag.id}>
                {tag.name}
                <button onClick={() => handleRemoveTag(tag.id)}>Remove</button>
              </li>
            ))}
          </ul>
        </div>
        <p>
          <strong>Name:</strong> {contact.name || "N/A"}
        </p>
        <p>
          <strong>Phone Number:</strong> {contact.phone_number}
        </p>
        <p>
          <strong>Status:</strong>
        </p>
        <select
          value={status}
          onChange={handleStatusChange}
          disabled={updating}
        >
          <option value="New">New</option>
          <option value="Contacted">Contacted</option>
          <option value="Interested">Interested</option>
          <option value="Not Interested">Not Interested</option>
          <option value="Client">Client</option>
        </select>
        <p>
          <strong>Rank:</strong> {contact.rank || "N/A"}
        </p>
        <p>
          <strong>Notes:</strong> {contact.notes || "N/A"}
        </p>

        <h4>Interactions</h4>
        <ul>
          {interactions.map((interaction) => (
            <li key={interaction.id}>
              <strong>{interaction.method}</strong> -{" "}
              {new Date(interaction.date).toLocaleString()}
              <br />
              {interaction.content}
              {interaction.notes && <em> ({interaction.notes})</em>}
              <br />
              <small>Added by: {interaction.affiliate_name || "Unknown"}</small>
            </li>
          ))}
        </ul>

        <h4>Add New Interaction</h4>
        <form onSubmit={handleAddInteraction}>
          <select
            value={newInteraction.method}
            onChange={(e) =>
              setNewInteraction({ ...newInteraction, method: e.target.value })
            }
            required
            style={{
              display: "block",
              marginBottom: "10px",
              width: "100%",
              padding: "8px",
            }}
          >
            <option value="" disabled>
              Select method
            </option>
            {interactionMethods.map((method, index) => (
              <option key={index} value={method}>
                {method}
              </option>
            ))}
          </select>
          <textarea
            placeholder="Content"
            value={newInteraction.content}
            onChange={(e) =>
              setNewInteraction({ ...newInteraction, content: e.target.value })
            }
            required
            style={{
              display: "block",
              marginBottom: "10px",
              width: "100%",
              padding: "8px",
            }}
          />
          <textarea
            placeholder="Notes"
            value={newInteraction.notes}
            onChange={(e) =>
              setNewInteraction({ ...newInteraction, notes: e.target.value })
            }
            style={{
              display: "block",
              marginBottom: "10px",
              width: "100%",
              padding: "8px",
            }}
          />
          <button type="submit" style={{ padding: "8px 16px" }}>
            Add Interaction
          </button>
        </form>

        <button onClick={onClose} style={{ marginTop: "20px" }}>
          Close
        </button>
      </div>
    </div>
  );
};

const modalStyles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0,0,0,0.7)", // Darker background overlay
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1000,
  },
  content: {
    background: "#1f1f1f", // Dark background color for the modal
    color: "#e0e0e0", // Light text color for better contrast
    padding: "20px",
    borderRadius: "8px",
    width: "600px",
    maxHeight: "80vh",
    overflowY: "auto",
    boxShadow: "0 5px 15px rgba(0,0,0,0.5)", // Darker shadow for subtle contrast
  },
};

const ResetPassword = () => {
  const { token } = useParams();
  const { addAlert } = useAlert();
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleResetPassword = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      addAlert({ message: "Passwords do not match", type: "error" });
      return;
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/reset_password/${token}`,
        {
          password: password,
        }
      );
      addAlert({
        message: "Password has been reset successfully.",
        type: "success",
      });
      addAlert({
        message: "Redirecting you back home...",
        type: "info",
      });
      // Optionally redirect to login page
      setTimeout(() => {
        navigate("/"); // Redirect to home
      }, 1200);
    } catch (error) {
      console.error("Reset password error:", error);
      addAlert({
        message: "An error occurred. Please try again.",
        type: "error",
      });
    }
  };

  return (
    <div style={{ height: "100vh" }}>
      <form onSubmit={handleResetPassword}>
        <div
          style={{
            margin: "auto",
            display: "flex",
            flexDirection: "column",
            width: "30vw",
            gap: "20px",
          }}
        >
          <h3 style={{ margin: "auto" }}>Reset Password</h3>
          <input
            type="password"
            placeholder="New Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <input
            type="password"
            placeholder="Confirm New Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
          <button type="submit">Reset Password</button>
        </div>
      </form>
    </div>
  );
};

const Chat = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [expandedMessages, setExpandedMessages] = useState({});
  const { currentUser } = useAuth();
  const messagesEndRef = useRef(null);
  const socket = useRef(null);

  // Utility to parse watch_ref, e.g., "126300-!-blue-None" -> "126300"
  const parseReference = (refStr) => {
    if (!refStr) return refStr;
    return refStr.includes("-!-") ? refStr.split("-!-")[0] : refStr;
  };

  // Helper function to determine if a field should be rendered
  const shouldRenderField = (value) => {
    if (value === null || value === undefined) return false;
    if (typeof value !== "string") value = value.toString();
    const trimmedValue = value.trim().toLowerCase();
    return (
      trimmedValue !== "none" &&
      trimmedValue !== "n/a" &&
      trimmedValue !== "null" &&
      trimmedValue !== "undefined" &&
      trimmedValue !== ""
    );
  };

  // Fetch preloaded messages from DB
  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/chat_messages`,
          { withCredentials: true }
        );
        setMessages(
          response.data.reverse().map((msg) => ({
            ...msg,
            enriched: msg.enriched,
            enrichmentData: msg.enrichmentData || [],
          }))
        );
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    };

    fetchMessages();
  }, []);

  // Setup WebSocket
  useEffect(() => {
    socket.current = io("https://autowatch.io", {
      transports: ["websocket"],
      reconnection: true,
      reconnectionAttempts: 5,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
    });

    socket.current.on("connect", () => {
      console.log("Connected to server");
    });

    socket.current.on("disconnect", (reason) => {
      console.error(`Disconnected: ${reason}`);
      if (reason === "io server disconnect") {
        socket.current.connect();
      }
    });

    socket.current.on("connect_error", (error) => {
      console.error(`Connection error: ${error.message}`);
    });

    socket.current.on("chat_message", (msg) => {
      setMessages((prev) => [
        ...prev,
        {
          id: msg.message_id,
          text: msg.text,
          sender: msg.sender,
          timestamp: msg.timestamp,
          enriched: false,
          enrichmentData: [],
        },
      ]);
    });

    socket.current.on("chat_message_update", (updatedMessage) => {
      console.log("chat message update", updatedMessage);
      setMessages((prevMessages) =>
        prevMessages.map((m) =>
          m.id === updatedMessage.message_id
            ? {
                ...m,
                enriched: true,
                enrichmentData: updatedMessage.processed_data,
              }
            : m
        )
      );
    });

    return () => {
      socket.current.disconnect();
      socket.current.off("chat_message");
      socket.current.off("chat_message_update");
    };
  }, []);

  // Scroll to bottom when messages update
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  // Send a new message
  const handleSendMessage = () => {
    if (input.trim() === "") return; // Prevent sending empty messages

    const newMessage = {
      text: input,
      sender: currentUser?.name || "Me",
      timestamp: Date.now(),
    };
    socket.current.emit("chat_message", newMessage);
    setInput("");
  };

  // Toggle the details section
  const toggleMessageExpansion = (messageId) => {
    setExpandedMessages((prev) => ({
      ...prev,
      [messageId]: !prev[messageId],
    }));
  };

  return (
    <div
      style={{
        maxWidth: "600px",
        margin: "0 auto",
        marginTop: "20px",
        display: "flex",
        flexDirection: "column",
        height: "80vh",
      }}
    >
      <h2 style={{ textAlign: "center", color: "#075E54" }}>Latest Messages</h2>
      <div
        style={{
          flex: 1,
          border: "1px solid #ccc",
          padding: "10px",
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#e5ddd5",
          borderRadius: "8px",
          marginBottom: "10px",
        }}
      >
        {messages.map((msg) => {
          const isMine = msg.sender === currentUser?.name;

          // By default, expand if enriched; otherwise, it's collapsed
          const defaultExpanded = msg.enriched ? false : false;
          const isExpanded = expandedMessages[msg.id] ?? defaultExpanded;

          return (
            <div
              key={msg.id}
              style={{
                alignSelf: isMine ? "flex-end" : "flex-start",
                backgroundColor: isMine ? "#dcf8c6" : "#fff",
                padding: "10px 14px",
                borderRadius: isMine
                  ? "7.5px 0px 7.5px 7.5px"
                  : "0px 7.5px 7.5px 7.5px",
                margin: "5px 0",
                maxWidth: "80%",
                position: "relative",
                color: "#000",
                boxShadow: "0 1px 1px rgba(0,0,0,0.1)",
              }}
            >
              {!isMine && (
                <div
                  style={{
                    fontSize: "0.8rem",
                    fontWeight: "bold",
                    marginBottom: "3px",
                    color: "#075E54",
                  }}
                >
                  {msg.sender}
                </div>
              )}

              {/* Show the user's original text as-is */}
              <div style={{ fontSize: "1rem" }}>{msg.text}</div>

              {msg.enriched ? (
                <div style={{ marginTop: "8px" }}>
                  <button
                    onClick={() => toggleMessageExpansion(msg.id)}
                    style={{
                      background: "none",
                      border: "none",
                      color: "#075E54",
                      padding: "5px 0",
                      cursor: "pointer",
                      fontSize: "0.9rem",
                      textDecoration: "underline",
                    }}
                  >
                    {isExpanded ? "Hide Details" : "Show Details"}
                  </button>
                  {isExpanded && (
                    <>
                      <hr
                        style={{ margin: "10px 0", borderColor: "#075E54" }}
                      />
                      <div style={{ fontSize: "0.9rem", color: "#555" }}>
                        <strong>Details:</strong>
                        {msg.enrichmentData?.map((watch, i) => {
                          const refParsed = parseReference(watch.watch_ref);

                          // We'll only render a field if it actually has a value
                          const brandField = shouldRenderField(watch.brand) ? (
                            <div key={`brand-${i}`}>
                              <strong style={{ color: "purple" }}>
                                Brand:
                              </strong>{" "}
                              <span style={{ color: "purple" }}>
                                {watch.brand}
                              </span>
                            </div>
                          ) : null;

                          const refField = shouldRenderField(refParsed) ? (
                            <div key={`ref-${i}`}>
                              <strong style={{ color: "green" }}>
                                Reference:
                              </strong>{" "}
                              <span style={{ color: "green" }}>
                                {refParsed}
                              </span>
                            </div>
                          ) : null;

                          const priceField = shouldRenderField(
                            watch.usd_price
                          ) ? (
                            <div key={`price-${i}`}>
                              <strong style={{ color: "red" }}>Price:</strong>{" "}
                              <span style={{ color: "red" }}>
                                ${watch.usd_price}
                              </span>
                            </div>
                          ) : null;

                          const conditionField = shouldRenderField(
                            watch.condition
                          ) ? (
                            <div key={`condition-${i}`}>
                              <strong style={{ color: "blue" }}>
                                Condition:
                              </strong>{" "}
                              <span style={{ color: "blue" }}>
                                {watch.condition}
                              </span>
                            </div>
                          ) : null;

                          const colorField = shouldRenderField(watch.color) ? (
                            <div key={`color-${i}`}>
                              <strong style={{ color: "orange" }}>
                                Color:
                              </strong>{" "}
                              <span style={{ color: "orange" }}>
                                {watch.color}
                              </span>
                            </div>
                          ) : null;

                          const specialFeaturesField = shouldRenderField(
                            watch.special_features
                          ) ? (
                            <div key={`features-${i}`}>
                              <strong style={{ color: "#ffd700" }}>
                                Special Features:
                              </strong>{" "}
                              <span style={{ color: "#ffd700" }}>
                                {watch.special_features}
                              </span>
                            </div>
                          ) : null;

                          // If none of the fields have valid data, don't render anything
                          if (
                            !(
                              brandField ||
                              refField ||
                              priceField ||
                              conditionField ||
                              colorField ||
                              specialFeaturesField
                            )
                          ) {
                            return null;
                          }

                          return (
                            <div
                              key={`watch-${i}`}
                              style={{ marginTop: "8px" }}
                            >
                              {brandField}
                              {refField}
                              {priceField}
                              {conditionField}
                              {colorField}
                              {specialFeaturesField}
                            </div>
                          );
                        })}
                      </div>
                    </>
                  )}
                </div>
              ) : (
                <div
                  style={{
                    marginTop: "8px",
                    color: "#075E54",
                    fontStyle: "italic",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      border: "2px solid #f3f3f3",
                      borderTop: "2px solid #075E54",
                      borderRadius: "50%",
                      width: "12px",
                      height: "12px",
                      animation: "spin 2s linear infinite",
                      marginRight: "8px",
                    }}
                  ></div>
                  Awaiting enrichment...
                </div>
              )}

              {/* Timestamp */}
              <div
                style={{
                  fontSize: "0.7rem",
                  color: "#999",
                  marginTop: "3px",
                  textAlign: "right",
                }}
              >
                {(() => {
                  const pacificOffset = 8 * 60 * 60; // PST is UTC-8
                  // If in DST, PDT is UTC-7. You might need additional logic to handle DST.
                  const adjustedTimestamp =
                    (msg.timestamp + pacificOffset) * 1000;
                  return new Date(adjustedTimestamp).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                  });
                })()}
              </div>
            </div>
          );
        })}
        <div ref={messagesEndRef} />
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <input
          style={{
            flex: 1,
            fontSize: "1rem",
            padding: "10px 15px",
            borderRadius: "20px",
            border: "1px solid #ccc",
            outline: "none",
            backgroundColor: "#fff",
          }}
          type="text"
          value={input}
          disabled
          onChange={(e) => setInput(e.target.value)}
          placeholder="Lead propagation coming soon..."
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSendMessage();
            }
          }}
        />
        <button
          style={{
            marginLeft: "10px",
            padding: "10px 20px",
            backgroundColor: "#075E54",
            color: "#fff",
            border: "none",
            borderRadius: "50%",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "45px",
            height: "45px",
            boxShadow: "0 1px 3px rgba(0,0,0,0.3)",
          }}
          disabled
          onClick={handleSendMessage}
          aria-label="Send Message"
        >
          &#10148;
        </button>
      </div>
      {/* Spinner Keyframes */}
      <style>
        {`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}
      </style>
    </div>
  );
};

// ChatModal Component
const ChatModal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  // Close modal when clicking outside the modal content
  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0,0,0,0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 999999,
      }}
      onClick={handleOverlayClick}
    >
      <div
        style={{
          backgroundColor: "#f0f0f0",
          borderRadius: "8px",
          width: "90%",
          maxWidth: "800px",
          // maxHeight: "75%",
          overflowY: "auto",
          position: "relative",
          padding: "20px",
          boxShadow: "0 5px 15px rgba(0,0,0,0.3)",
        }}
      >
        <button
          style={{
            position: "absolute",
            top: "10px",
            right: "15px",
            background: "none",
            border: "none",
            fontSize: "1.5rem",
            cursor: "pointer",
            color: "#333",
          }}
          onClick={onClose}
          aria-label="Close Chat Modal"
        >
          &times;
        </button>
        <div>{children}</div>
      </div>
    </div>
  );
};

const App = () => {
  return (
    <AlertProvider>
      {" "}
      {/* AlertProvider must be first */}
      <AuthProvider>
        <AnonymousProvider>
          <MainProvider>
            <MainHeader />
            <Router>
              <Routes>
                <Route
                  path="/reset_password/:token"
                  element={<ResetPassword />}
                />
                <Route path="/about" element={<HomePage />} />
                <Route path="/" element={<Layout />}>
                  {/* <Route path="/chat" element={<Chat />} /> */}
                  <Route
                    path="/"
                    element={
                      <SubscriptionProtectedRoute>
                        <CreateWatchListStep3 />
                      </SubscriptionProtectedRoute>
                    }
                  />
                  <Route
                    path="/dashboard"
                    element={
                      <SubscriptionProtectedRoute>
                        <Dashboard />
                      </SubscriptionProtectedRoute>
                    }
                  />
                  <Route
                    path="/growth/*"
                    element={
                      <SubscriptionProtectedRoute>
                        <GrowthAdmin />
                      </SubscriptionProtectedRoute>
                    }
                  />
                </Route>
              </Routes>
            </Router>
          </MainProvider>
        </AnonymousProvider>
      </AuthProvider>
    </AlertProvider>
  );
};

const Alert = React.memo(
  ({
    id,
    message,
    type = "info",
    duration = 3000,
    onClose,
    dismiss,
    isTooltip = false,
    className = "alert",
  }) => {
    const [isVisible, setIsVisible] = React.useState(false);

    useEffect(() => {
      // Trigger the animation after mount
      const timeout = setTimeout(() => setIsVisible(true), 10);
      return () => clearTimeout(timeout);
    }, []);

    useEffect(() => {
      if (duration > 0 && !isTooltip) {
        const timer = setTimeout(() => onClose(id), duration);
        return () => clearTimeout(timer);
      }
    }, [onClose, id, duration, isTooltip]);

    const dismissClass = dismiss ? "alert-dismiss" : "";
    const showClass = isVisible ? "alert-show" : "";

    return (
      <div
        className={`alert alert-${type} ${showClass} ${dismissClass} ${
          isTooltip ? "alert-tooltip" : ""
        } ${className}`}
      >
        {message}
        {!isTooltip && (
          <button onClick={() => onClose(id)} className="close-button-alert">
            &times;
          </button>
        )}
      </div>
    );
  }
);

// Alert Context
const AlertContext = createContext();

// Hook to use Alert
const useAlert = () => useContext(AlertContext);

// Alert Provider
const AlertProvider = ({ children }) => {
  const [alerts, setAlerts] = useState([]);

  const addAlert = useCallback((alert) => {
    const { id = Date.now(), isTooltip } = alert;

    // Remove tooltips
    if (isTooltip) {
      setAlerts((current) => current.filter((a) => !a.isTooltip));
    }

    // Simply add the alert without managing duration here
    setAlerts((current) => [...current, { ...alert, id }]);
  }, []);

  const removeAlert = useCallback((id) => {
    setAlerts((current) =>
      current.map((alert) =>
        alert.id === id ? { ...alert, dismiss: true } : alert
      )
    );

    setTimeout(() => {
      setAlerts((current) => current.filter((alert) => alert.id !== id));
    }, 500); // Match this duration with CSS transition
  }, []);

  const removeAllAlerts = useCallback(() => {
    setAlerts([]);
  }, []);

  const contextValue = useMemo(
    () => ({ addAlert, removeAlert, removeAllAlerts }),
    [addAlert, removeAlert, removeAllAlerts]
  );

  return (
    <AlertContext.Provider value={contextValue}>
      <div className="alert-container">
        {alerts.map((alert) => (
          <Alert key={alert.id} {...alert} onClose={removeAlert} />
        ))}
      </div>
      {children}
    </AlertContext.Provider>
  );
};

//--------------------------

const AIWatchFinder = () => {
  return (
    <div className="scene">
      {/* Processing Background */}
      <div className="processing-bg" />

      {/* Watch Container */}
      <div className="watch-container">
        {/* Regular Watches */}
        {[...Array(6)].map((_, i) => (
          <div
            key={i}
            className="watch"
            style={{
              top: `${20 + i * 20}%`,
              left: `${20 + (i % 3) * 20}%`,
              animationDelay: `${i * 0.1}s`,
            }}
          />
        ))}

        {/* Perfect Watch */}
        <div className="perfect-watch" />

        {/* AI Process Data Streams */}
        <div className="ai-process">
          {[
            "Splitting message into segments...",
            "Analyzing reference number: 116610LN...",
            "Processing color attributes: steel/black...",
            "Checking condition: BNIB...",
            "Verifying manufacturing date: 2023/06...",
            "Market price analysis: $14,500...",
          ].map((text, i) => (
            <div
              key={i}
              className="data-stream"
              style={{
                top: `${10 + i * 15}%`,
                right: i % 2 ? "60%" : "30%",
                animationDelay: `${i * 0.8}s`,
              }}
            >
              {text}
            </div>
          ))}
        </div>
      </div>

      {/* Classification UI */}
      <div className="classification-ui">
        {[
          "Style Match",
          "Feature Analysis",
          "Preference Alignment",
          "Price Optimization",
        ].map((text, i) => (
          <div key={i} className="attribute">
            {text}
            <div className="progress-bar">
              <div className="progress" />
            </div>
          </div>
        ))}
      </div>

      {/* Result Text */}
      <div className="result-text">Perfect Match Found!</div>

      <style jsx>{`
        .scene {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 300px;
          margin: auto;
          background: #1a1a2e;
          overflow: hidden;
          font-family: Arial, sans-serif;
        }

        .processing-bg {
          position: absolute;
          width: 100%;
          height: 100%;
          background: linear-gradient(
              0deg,
              rgba(26, 26, 46, 0.95),
              rgba(26, 26, 46, 0.95)
            ),
            repeating-linear-gradient(
              0deg,
              transparent,
              transparent 2px,
              rgba(32, 202, 202, 0.1) 2px,
              rgba(32, 202, 202, 0.1) 4px
            );
          animation: bgScroll 20s linear infinite;
        }

        .watch-container {
          position: relative;
          width: 50vw;
          z-index: 2;
        }

        .watch {
          position: absolute;
          width: 25px;
          height: 25px;
          border-radius: 50%;
          border: 3px solid #666;
          background: white;
          opacity: 0.3;
          animation: scanEffect 4s infinite;
        }

        .watch::before,
        .watch::after {
          content: "";
          position: absolute;
          width: 10px;
          height: 24px;
          background: #999;
          left: 50%;
          transform: translateX(-50%);
        }

        .watch::before {
          top: -28px;
          border-radius: 8px 8px 0 0;
        }

        .watch::after {
          bottom: -28px;
          border-radius: 0 0 8px 8px;
        }

        .perfect-watch {
          position: absolute;
          width: 35px;
          height: 35px;
          border-radius: 50%;
          border: 4px solid #20caca;
          background: white;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) scale(0);
          opacity: 0;
          animation: watchReveal 4s infinite;
          box-shadow: 0 0 30px rgba(32, 202, 202, 0.5);
        }

        .perfect-watch::before,
        .perfect-watch::after {
          content: "";
          position: absolute;
          width: 20px;
          height: 35px;
          background: #20caca;
          left: 50%;
          transform: translateX(-50%);
        }

        .perfect-watch::before {
          top: -32px;
          border-radius: 10px 10px 0 0;
        }

        .perfect-watch::after {
          bottom: -32px;
          border-radius: 0 0 10px 10px;
        }

        .ai-process {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          pointer-events: none;
        }

        .data-stream {
          position: absolute;
          color: #20caca;
          font-size: 11px;
          opacity: 0;
          animation: streamData 4s infinite;
          white-space: nowrap;
        }

        .classification-ui {
          position: absolute;
          width: 100px;
          right: 20px;
          top: 50%;
          transform: translateY(-50%);
          color: #20caca;
          font-size: 10px;
          opacity: 0;
          animation: uiAppear 4s infinite;
        }

        .attribute {
          margin: 10px 0;
          padding: 6px;
          border: 1px solid rgba(32, 202, 202, 0.3);
          background: rgba(32, 202, 202, 0.1);
        }

        .progress-bar {
          width: 100%;
          height: 4px;
          background: rgba(32, 202, 202, 0.2);
          margin-top: 6px;
          position: relative;
          overflow: hidden;
        }

        .progress {
          position: absolute;
          height: 100%;
          background: #20caca;
          width: 0%;
          animation: progressFill 4s infinite;
        }

        .result-text {
          position: absolute;
          top: 20px;
          left: 50%;
          transform: translateX(-50%);
          color: #20caca;
          font-size: 17px;
          opacity: 0;
          animation: textReveal 4s infinite;
          white-space: nowrap;
          text-align: center;
        }

        @keyframes bgScroll {
          from {
            background-position: 0 0;
          }
          to {
            background-position: 0 100%;
          }
        }

        @keyframes scanEffect {
          0%,
          100% {
            box-shadow: 0 0 0 0 rgba(32, 202, 202, 0);
          }
          50% {
            box-shadow: 0 0 10px 2px rgba(32, 202, 202, 0.5);
          }
        }

        @keyframes streamData {
          0%,
          100% {
            opacity: 0;
            transform: translateY(0) scale(0.8);
          }
          50% {
            opacity: 1;
            transform: translateY(-20px) scale(1);
          }
        }

        @keyframes watchReveal {
          0%,
          60% {
            opacity: 0;
            transform: translate(-50%, -50%) scale(0);
          }
          65% {
            opacity: 1;
            transform: translate(-50%, -50%) scale(1.2);
          }
          70%,
          100% {
            opacity: 1;
            transform: translate(-50%, -50%) scale(1);
          }
        }

        @keyframes progressFill {
          0%,
          30% {
            width: 0%;
          }
          60% {
            width: 100%;
          }
          100% {
            width: 100%;
          }
        }

        @keyframes uiAppear {
          0%,
          30% {
            opacity: 0;
            transform: translateY(-50%) translateX(20px);
          }
          40%,
          100% {
            opacity: 1;
            transform: translateY(-50%) translateX(0);
          }
        }

        @keyframes textReveal {
          0%,
          70% {
            opacity: 0;
            transform: translateX(-50%) translateY(-20px);
          }
          80%,
          100% {
            opacity: 1;
            transform: translateX(-50%) translateY(0);
          }
        }
      `}</style>
    </div>
  );
};

//---used for the Update Alerts in both authprovider and login area----------
const showDetailedAlert = (addAlert) => {
  addAlert({
    message: (
      <div
        style={{
          lineHeight: "1.4em",
          fontSize: "0.85rem",
          textAlign: "left",
        }}
      >
        <strong style={{ fontSize: "1.4rem" }}>
          🚀 Detailed Upcoming Changes
        </strong>
        <ul
          style={{
            marginTop: "8px",
            marginBottom: "0",
            paddingLeft: "20px",
          }}
        >
          <li>
            Adding a summary frontend for each AutoWatch session after it ends.
          </li>
          <li>
            Implementing an internal chat system that auto-decodes messages into
            leads.
          </li>
          <li>Hooking into many more dealer groups for much more leads.</li>
          <li>
            Optimizing UX for the flow of the main parts (after feedback).
          </li>
          <li>Optimizing UX/Frontend for mobile use.</li>
        </ul>

        <div style={{ marginTop: "5px" }}>
          <p style={{ fontStyle: "bold", color: "gold", fontSize: "12px" }}>
            If you'd like to see a change, please create a support ticket in the
            'Help' section at the top right.
          </p>
        </div>
      </div>
    ),
    type: "info",
    isTooltip: false,
    duration: 90000,
    className: "alert-detailed",
  });
};

const showQuickExplanationAlert = (addAlert) => {
  addAlert({
    message: (
      <div
        style={{
          lineHeight: "1.4em",
          fontSize: "0.85rem",
          textAlign: "left",
          maxWidth: "100%", // Add this
          wordBreak: "break-word", // Add this
          padding: "10px", // Add this
          overflowY: "auto",
          maxHeight: "80vh",
        }}
      >
        <h2 style={{ fontSize: "1.4rem" }}>How does this work?</h2>
        <AIWatchFinder />
        <div className="explanation-content">
          <p>
            Our AI model automatically scans and decodes watch listings from
            exclusive WhatsApp & Facebook dealer groups by:
          </p>
          <ul>
            <li>Breaking down messages into individual watch leads</li>
            <li>Extracting key details (reference, price, condition, etc.)</li>
          </ul>
          <p>
            While currently in early development, our goal is to become your
            ultimate watch-finding tool - automatically locating specific
            watches based on your criteria.
          </p>

          {/* Model History Section */}
          <div
            style={{
              marginTop: "20px",
              padding: "15px",
              background: "#1a1a1a",
              borderRadius: "6px",
            }}
          >
            <h3
              style={{
                fontSize: "1.1rem",
                marginBottom: "12px",
                color: "#ffffff",
              }}
            >
              Model History
            </h3>

            {/* Segmentor Model */}
            <div style={{ marginBottom: "15px" }}>
              <h4
                style={{
                  fontSize: "1rem",
                  marginBottom: "8px",
                  color: "#ffffff",
                }}
              >
                Message Segmentor
              </h4>
              <p
                style={{
                  marginBottom: "8px",
                  color: "#cccccc",
                }}
              >
                Breaks down messages into individual watch listings
              </p>
              <span
                style={{
                  display: "inline-block",
                  padding: "3px 8px",
                  background: "#2d2d2d",
                  color: "#00ff00",
                  borderRadius: "4px",
                  fontSize: "0.75rem",
                  marginBottom: "8px",
                }}
              >
                Current: v1.0
              </span>
              <ul
                style={{
                  marginLeft: "15px",
                  color: "#b3b3b3",
                }}
              >
                <li>Initial release - Basic message segmentation</li>
                <li>Trained on ~1,000 WhatsApp messages</li>
              </ul>
            </div>

            {/* Decoder Model */}
            <div style={{ marginBottom: "15px" }}>
              <h4
                style={{
                  fontSize: "1rem",
                  marginBottom: "8px",
                  color: "#ffffff",
                }}
              >
                Watch Decoder
              </h4>
              <p
                style={{
                  marginBottom: "8px",
                  color: "#cccccc",
                }}
              >
                Classifies details like reference numbers, prices, and
                conditions
              </p>
              <span
                style={{
                  display: "inline-block",
                  padding: "3px 8px",
                  background: "#2d2d2d",
                  color: "#00ff00",
                  borderRadius: "4px",
                  fontSize: "0.75rem",
                  marginBottom: "8px",
                }}
              >
                Current: v1.0
              </span>
              <ul
                style={{
                  marginLeft: "15px",
                  color: "#b3b3b3",
                }}
              >
                <li>Initial release - Basic feature detection</li>
                <li>Trained on ~500 segmented listings</li>
                <li>Categories: Reference, Price, Condition, Color</li>
              </ul>
            </div>
            <p
              style={{
                marginTop: "15px",
                padding: "8px",
                borderLeft: "2px solid #00ff00",
                color: "#cccccc",
                fontSize: "0.8rem",
                fontStyle: "italic",
              }}
            >
              Models are continuously trained and improved. Each update aims to
              enhance accuracy and add new features.
            </p>
          </div>

          <div className="feedback-note">
            Have suggestions? Submit a ticket via the Help menu ↗
          </div>
        </div>
      </div>
    ),
    type: "info",
    isTooltip: false,
    duration: 90000,
    className: "alert-detailed",
  });
};
//--------------

const AuthContext = createContext();

function useAuth() {
  return useContext(AuthContext);
}

const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const { addAlert } = useAlert();
  const prevUserRef = useRef(null); // Ref to track previous user

  // Define the checkUser function without currentUser in dependencies
  const checkUser = useCallback(async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/current_user`,
        {
          withCredentials: true,
        }
      );
      if (response.data) {
        setCurrentUser(response.data); // Always update with latest data
      } else {
        setCurrentUser(null); // Handle unauthenticated state
      }
    } catch (error) {
      console.error("Error checking auth status:", error);
      setCurrentUser(null); // Explicitly handle unauthenticated state
    } finally {
      setLoading(false);
    }
  }, [addAlert]);

  // Initial user check
  useEffect(() => {
    checkUser();
  }, [checkUser]);

  // useEffect to handle alerts based on user changes
  useEffect(() => {
    const prevUser = prevUserRef.current;
    if (
      currentUser &&
      (!prevUser || currentUser.id !== prevUser.id) &&
      !currentUser.anonymous
    ) {
      setTimeout(() => {
        const hasSeenAlert = getCookie("hasSeenAlertLogin");
        if (!hasSeenAlert) {
          addAlert({
            message: (
              <div style={{ lineHeight: "1.4em", fontSize: "0.85rem" }}>
                <span
                  style={{
                    lineHeight: "1em",
                    fontSize: "0.85rem",
                    textAlign: "left",
                  }}
                >
                  {/* Adjust lineHeight and fontSize for mobile */}
                  🎉 As an Alpha tester, your feedback is shaping AutoWatch’s
                  future, and you’re rewarded with lifetime access at the lowest
                  locked-in rate and exclusive affiliate perks when we launch!
                </span>

                <hr
                  style={{
                    margin: "16px 0",
                    border: "none",
                    borderTop: "1px solid #ccc",
                  }}
                />

                <strong style={{ fontSize: "1.2rem" }}>
                  🚀 Exciting Updates Coming Soon!
                </strong>
                <ul
                  style={{
                    marginTop: "8px",
                    marginBottom: "0",
                    paddingLeft: "20px",
                  }}
                >
                  <li>✨ Adding in various new, unsupported watches.</li>
                  <li>✨ Much more lead sources and groups.</li>
                  <li>✨ Internal chat system w/ lead propagation.</li>
                  <li>✨ Refined decoder to narrow down better leads.</li>
                </ul>
                <div
                  style={{
                    marginTop: "10px",
                    fontSize: "0.85rem",
                    color: "gold",
                    cursor: "pointer",
                  }}
                  onClick={() => showDetailedAlert(addAlert)} // Trigger detailed alert
                >
                  Read more about upcoming changes
                </div>
              </div>
            ),
            type: "info",
            isTooltip: false,
            duration: 10000,
          });
          setCookie("hasSeenAlertLogin", "true", 14);
        }
      }, 4000);
    }
    prevUserRef.current = currentUser; // Update the ref with current user
  }, [currentUser, addAlert]);

  // Memoize the context value
  const value = useMemo(() => {
    return {
      currentUser,
      setCurrentUser, // Available for managing login/logout
      refreshUser: checkUser, // Expose checkUser as refreshUser
    };
  }, [currentUser, checkUser]);

  // Only render children when not loading
  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

const AnonymousContext = createContext(null);

export function useAnonymous() {
  return useContext(AnonymousContext);
}

export const AnonymousProvider = ({ children }) => {
  const { currentUser, refreshUser } = useAuth(); // <--- useAuth() here
  const { addAlert } = useAlert();

  const [usageInfo, setUsageInfo] = useState(null);
  const [loading, setLoading] = useState(true);

  /**
   * initAnonymousSession:
   *   - If no 'anonymous_token' cookie exists, the server creates a new anon user,
   *     and also logs them in with Flask-Login.
   */
  const initAnonymousSession = useCallback(async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/init_anonymous_session`,
        {},
        {
          withCredentials: true,
          headers: {
            "Cache-Control": "no-cache",
            Pragma: "no-cache",
            Expires: "0",
          },
        }
      );
    } catch (err) {
      console.error("Error initializing anonymous session:", err);
    }
  }, [addAlert]);

  /**
   * fetchUsageInfo:
   *   - Queries /check_anonymous_usage to see usage_count, limit, etc.
   */
  const fetchUsageInfo = useCallback(async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/check_anonymous_usage`,
        { withCredentials: true }
      );
      setUsageInfo(response.data);
    } catch (err) {
      if (err.response) {
        setUsageInfo(err.response.data);
      } else {
        console.error("Error fetching usage info:", err);
      }
    }
  }, []);

  /**
   * incrementUsage:
   *   - Increments usage_count by 1, if under the limit.
   */
  const incrementUsage = useCallback(async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/increment_usage`,
        {},
        { withCredentials: true }
      );
      setUsageInfo({
        status: response.data.status,
        usage_count: response.data.usage_count,
        remaining: response.data.remaining,
      });
      return response.data;
    } catch (err) {
      throw err;
    }
  }, []);

  /**
   * On mount, if we have NO logged-in user, create an anonymous session;
   * then refreshUser() so that AuthContext picks up the new user object.
   */
  useEffect(() => {
    (async () => {
      if (!currentUser) {
        await initAnonymousSession();
        // Now that the server created + logged in an anon user, let's refresh
        await refreshUser();
      }
      setLoading(false);
    })();
  }, [currentUser, initAnonymousSession, refreshUser]);

  /**
   * Once everything is set, if we have an anon user, let's fetch usage info
   */
  useEffect(() => {
    if (!loading && currentUser?.anonymous) {
      fetchUsageInfo();
    }
  }, [loading, currentUser, fetchUsageInfo]);

  const contextValue = {
    usageInfo,
    incrementUsage,
    fetchUsageInfo,
  };

  return (
    <AnonymousContext.Provider value={contextValue}>
      {!loading && children}
    </AnonymousContext.Provider>
  );
};

//contexts to share vars without prop drilling

const MainContext = createContext();

const useMain = () => useContext(MainContext);

const MainProvider = ({ children }) => {
  const [isBuy, setIsBuy] = useState(true);
  const [animateNextButton, setAnimateNextButton] = useState(false);

  const [watchList, setWatchList] = useState(null);
  const [startAnimation, setStartAnimation] = useState(false);
  const [emails, setEmails] = useState({});
  const [phoneNumbers, setPhoneNumbers] = useState({});
  const [emailNotificationStatus, setEmailNotificationStatus] = useState({});
  const [textNotificationStatus, setTextNotificationStatus] = useState({});
  const [pricePulseStatus, setPricePulseStatus] = useState({});
  const [showKeywordLimitModal, setShowKeywordLimitModal] = useState(false);
  const [isActionButtonMinimized, setIsActionButtonMinimized] = useState(true);
  const [pages, setPages] = useState(1);
  const inputRef = useRef(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const [databaseInfo, setDatabaseInfo] = useState({});

  const [currentSelectedReference, setCurrentSelectedReference] = useState("");

  // Add this new fetch function
  const fetchDatabaseInfo = useCallback(async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/database-info`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
          credentials: "include",
        }
      );
      if (response.ok) {
        const data = await response.json();
        setDatabaseInfo(data);
      }
    } catch (error) {
      console.error("Error fetching database info:", error);
    }
  }, []);

  // Add this useEffect to fetch on mount
  useEffect(() => {
    fetchDatabaseInfo();
    // Optional: Set up an interval to refresh every X seconds
    const interval = setInterval(fetchDatabaseInfo, 30000); // every 30 seconds
    return () => clearInterval(interval);
  }, [fetchDatabaseInfo]);

  const contextValue = useMemo(
    () => ({
      pages,
      setPages,
      isActionButtonMinimized,
      setIsActionButtonMinimized,
      pricePulseStatus,
      setPricePulseStatus,
      isBuy,
      setIsBuy,
      animateNextButton,
      setAnimateNextButton,

      watchList,
      setWatchList,
      emails,
      setEmails,
      phoneNumbers,
      setPhoneNumbers,
      startAnimation,
      setStartAnimation,
      emailNotificationStatus,
      setEmailNotificationStatus,
      textNotificationStatus,
      setTextNotificationStatus,
      showKeywordLimitModal,
      setShowKeywordLimitModal,
      inputRef,
      showTooltip,
      setShowTooltip,
      databaseInfo,
      fetchDatabaseInfo,
      currentSelectedReference,
      setCurrentSelectedReference,
    }),
    [
      pages,
      isActionButtonMinimized,
      pricePulseStatus,
      isBuy,
      animateNextButton,
      inputRef,
      watchList,
      emails,
      phoneNumbers,
      startAnimation,
      emailNotificationStatus,
      textNotificationStatus,
      showKeywordLimitModal,
      databaseInfo,
      fetchDatabaseInfo,
      currentSelectedReference,
    ]
  );

  return (
    <MainContext.Provider value={contextValue}>{children}</MainContext.Provider>
  );
};

export default App;
